import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _startsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/starts-with";
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import styles from "./FieldIcon.module.css";
import { Icon } from '../../Icon/Icon';

var defaultIconRender = function defaultIconRender(icon, positionStyles, displayStyles, isFixedWidth) {
  return React.createElement(Icon, {
    icon: icon,
    className: cx(positionStyles, displayStyles),
    isFixedWidth: isFixedWidth
  });
};

export var fieldIconPropTypes = {
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(['left', 'right'])
};
export var FieldIcon = function FieldIcon(props) {
  var _cx2;

  var field = props.children,
      icon = props.icon,
      iconPosition = props.iconPosition,
      disabled = props.disabled,
      size = props.size,
      className = props.className;
  var renderIcon = _.isFunction(props.renderIcon) ? props.renderIcon : defaultIconRender;

  if (!icon) {
    return field;
  }

  var isRight = iconPosition === 'right';
  var isLeft = !isRight;
  var wrapperClasses = cx(styles.fieldWithIcon, className, _defineProperty({}, styles.materialIcon, icon === null || icon === void 0 ? void 0 : _startsWithInstanceProperty(icon).call(icon, 'mi')));
  var iconClasses = cx({
    disabled: disabled
  }, styles.icon, (_cx2 = {}, _defineProperty(_cx2, styles.iconSmall, size === 'small'), _defineProperty(_cx2, styles.iconLarge, size === 'large'), _defineProperty(_cx2, styles.iconWrapper, !renderIcon), _cx2));
  var isFixedWidth = true;
  var iconElement = renderIcon(icon, styles.iconWrapper, iconClasses, isFixedWidth);
  return React.createElement("div", {
    className: wrapperClasses
  }, isLeft && iconElement, field, isRight && iconElement);
};
FieldIcon.propTypes = fieldIconPropTypes;
defaultIconRender.__docgenInfo = {
  componentName: "defaultIconRender",
  packageName: "@jutro/components",
  description: "",
  displayName: "defaultIconRender",
  methods: [],
  actualName: "defaultIconRender"
};
FieldIcon.__docgenInfo = {
  componentName: "FieldIcon",
  packageName: "@jutro/components",
  description: "FieldIcon component definition",
  displayName: "FieldIcon",
  methods: [],
  actualName: "FieldIcon",
  metadataType: "element",
  props: {
    icon: {
      type: {
        name: "string"
      },
      required: false,
      description: "MI icon definition"
    },
    iconPosition: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: false
        }, {
          value: "'right'",
          computed: false
        }]
      },
      required: false,
      description: "Phrase which indicates where the icon should be placed relative to the text"
    }
  }
};