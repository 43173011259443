import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _someInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/some";
import _trimInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/trim";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import stubTrue from "lodash/stubTrue";
import get from "lodash/get";
export var createTextFilter = function createTextFilter(filterValue) {
  var _context;

  var words = _filterInstanceProperty(_context = _trimInstanceProperty(filterValue).call(filterValue).toLowerCase().split(' ')).call(_context, Boolean);

  if (!words.length) {
    return stubTrue;
  }

  return function (text) {
    var lowerText = text.toString().toLowerCase();
    return _someInstanceProperty(words).call(words, function (word) {
      return _includesInstanceProperty(lowerText).call(lowerText, word);
    });
  };
};
export var defaultColumnFilter = function defaultColumnFilter(filterValue, _ref) {
  var path = _ref.path;
  var textFilter = createTextFilter(filterValue);
  return function (item) {
    return textFilter(get(item, path, ''));
  };
};