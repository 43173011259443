import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button } from '@jutro/components';
import styles from "./Pagination.module.css";
export var NavButton = function NavButton(_ref) {
  var onClick = _ref.onClick,
      children = _ref.children,
      className = _ref.className,
      icon = _ref.icon,
      iconPosition = _ref.iconPosition;
  return React.createElement(Button, {
    className: cx(styles.paginationNavButton, className),
    type: "text",
    icon: icon,
    iconPosition: iconPosition,
    onClick: onClick
  }, children);
};
NavButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  onClick: PropTypes.func
};
NavButton.__docgenInfo = {
  componentName: "NavButton",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "NavButton",
  methods: [],
  actualName: "NavButton",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    icon: {
      type: {
        name: "string"
      },
      required: false,
      description: "Optional Material Icon name (mi-*)"
    },
    iconPosition: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: false
        }, {
          value: "'right'",
          computed: false
        }]
      },
      required: false,
      description: "Where the icon is placed relative to the text"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback when NavButton is clicked"
    }
  }
};