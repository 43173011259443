import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from 'react';
import cx from 'classnames';
export var GenericSelectControlControl = function GenericSelectControlControl(_ref) {
  var _cx;

  var children = _ref.children,
      isFocused = _ref.isFocused,
      innerRef = _ref.innerRef,
      innerProps = _ref.innerProps,
      className = _ref.className,
      _ref$selectProps = _ref.selectProps,
      genericSelectStyles = _ref$selectProps.genericSelectStyles,
      invalid = _ref$selectProps.invalid;
  var controlClasses = cx(genericSelectStyles.control, (_cx = {}, _defineProperty(_cx, genericSelectStyles.controlFocused, isFocused && !invalid), _defineProperty(_cx, "invalid", invalid), _defineProperty(_cx, genericSelectStyles.focusedInvalid, isFocused && invalid), _cx), className);
  return React.createElement("div", _extends({
    ref: innerRef,
    className: controlClasses
  }, innerProps), children);
};
GenericSelectControlControl.__docgenInfo = {
  componentName: "GenericSelectControlControl",
  packageName: "@jutro/components",
  description: "",
  displayName: "GenericSelectControlControl",
  methods: [],
  actualName: "GenericSelectControlControl"
};