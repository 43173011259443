import _objectWithoutProperties from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from "./Main.module.css";
import { Container } from '../container/Container';
var mainPropTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  fluid: PropTypes.bool,
  contentClassName: PropTypes.string
};
export var Main = function Main(_ref) {
  var children = _ref.children,
      className = _ref.className,
      contentClassName = _ref.contentClassName,
      fluid = _ref.fluid,
      dangerouslySetInnerHTML = _ref.dangerouslySetInnerHTML,
      other = _objectWithoutProperties(_ref, ["children", "className", "contentClassName", "fluid", "dangerouslySetInnerHTML"]);

  return React.createElement("main", _extends({
    tabIndex: "-1",
    className: cx(styles.main, className)
  }, other), React.createElement(Container, {
    className: cx(styles.container, contentClassName),
    fluid: fluid
  }, children));
};
Main.propTypes = mainPropTypes;
Main.__docgenInfo = {
  componentName: "Main",
  packageName: "@jutro/components",
  description: "Main content of the page.",
  displayName: "Main",
  methods: [],
  actualName: "Main",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component."
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "The container's content."
    },
    fluid: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Span the entire width."
    },
    contentClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for content"
    }
  }
};