var uniqueIdForInputFields = 0;

var getUniqueId = function getUniqueId() {
  var currentId = uniqueIdForInputFields;
  uniqueIdForInputFields += 1;
  return currentId;
};

export var getComponentId = function getComponentId(id) {
  return "".concat(id, "_").concat(getUniqueId());
};