import React from 'react';
import { InlineLoader } from '../../../loading/inlineLoader/InlineLoader';
export var GenericSelectControlLoadingIndicator = function GenericSelectControlLoadingIndicator() {
  return React.createElement(InlineLoader, {
    loading: true
  });
};
GenericSelectControlLoadingIndicator.__docgenInfo = {
  componentName: "GenericSelectControlLoadingIndicator",
  packageName: "@jutro/components",
  description: "",
  displayName: "GenericSelectControlLoadingIndicator",
  methods: [],
  actualName: "GenericSelectControlLoadingIndicator"
};