import _extends from "@babel/runtime-corejs3/helpers/extends";
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
import { AuthContext } from '@jutro/auth';
import { Avatar } from '@jutro/components';
import styles from "./DropdownMenuAvatar.module.css";
export var DropdownMenuAvatarHeader = function DropdownMenuAvatarHeader(_ref) {
  var title = _ref.title,
      subtitle = _ref.subtitle,
      username = _ref.username,
      icon = _ref.icon,
      useAuthInfo = _ref.useAuthInfo,
      className = _ref.className,
      imageSource = _ref.imageSource,
      messageProps = _ref.messageProps,
      showImageBorder = _ref.showImageBorder;
  var auth = useContext(AuthContext);
  var translator = useContext(TranslatorContext);

  var getImageSource = function getImageSource() {
    var _auth$userInfo;

    return useAuthInfo ? auth === null || auth === void 0 ? void 0 : (_auth$userInfo = auth.userInfo) === null || _auth$userInfo === void 0 ? void 0 : _auth$userInfo.pictureUrl : imageSource;
  };

  var avatarProps = {
    username: username,
    icon: icon,
    imageSource: getImageSource(),
    messageProps: messageProps,
    showImageBorder: showImageBorder,
    useAuthInfo: useAuthInfo
  };

  var getHeaderTitle = function getHeaderTitle() {
    var _auth$userInfo$name, _auth$userInfo2;

    var translatedTitle = translator(title);

    if (!useAuthInfo) {
      return translatedTitle;
    }

    return (_auth$userInfo$name = auth === null || auth === void 0 ? void 0 : (_auth$userInfo2 = auth.userInfo) === null || _auth$userInfo2 === void 0 ? void 0 : _auth$userInfo2.name) !== null && _auth$userInfo$name !== void 0 ? _auth$userInfo$name : translatedTitle;
  };

  var getHeaderSubtitle = function getHeaderSubtitle() {
    var _auth$userInfo3, _auth$userInfo$email, _auth$userInfo4;

    var translatedSubtitle = translator(subtitle);

    if (!useAuthInfo) {
      return translatedSubtitle;
    }

    return (auth === null || auth === void 0 ? void 0 : (_auth$userInfo3 = auth.userInfo) === null || _auth$userInfo3 === void 0 ? void 0 : _auth$userInfo3.preferred_username) || ((_auth$userInfo$email = auth === null || auth === void 0 ? void 0 : (_auth$userInfo4 = auth.userInfo) === null || _auth$userInfo4 === void 0 ? void 0 : _auth$userInfo4.email) !== null && _auth$userInfo$email !== void 0 ? _auth$userInfo$email : translatedSubtitle);
  };

  return React.createElement("div", {
    className: cx(styles.dropdownMenuAvatarHeader, className)
  }, React.createElement(Avatar, _extends({
    disabled: true
  }, avatarProps)), React.createElement("div", {
    className: styles.headerText
  }, React.createElement("div", {
    className: styles.headerTitle
  }, getHeaderTitle()), React.createElement("div", {
    className: styles.headerSubtitle
  }, getHeaderSubtitle())));
};
DropdownMenuAvatarHeader.propTypes = {
  title: intlMessageShape,
  subtitle: intlMessageShape,
  username: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  imageSource: PropTypes.string,
  messageProps: PropTypes.shape({
    userAvatar: Avatar.propTypes.messageProps.userAvatar
  }),
  useAuthInfo: PropTypes.bool,
  showImageBorder: PropTypes.bool,
  headerClassName: PropTypes.string
};
DropdownMenuAvatarHeader.defaultAvatarProps = {
  useAuthInfo: false
};
DropdownMenuAvatarHeader.__docgenInfo = {
  componentName: "DropdownMenuAvatarHeader",
  packageName: "@jutro/router",
  description: "",
  displayName: "DropdownMenuAvatarHeader",
  methods: [],
  actualName: "DropdownMenuAvatarHeader",
  props: {
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Header title text"
    },
    subtitle: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Menu header subtitle text"
    },
    username: {
      type: {
        name: "string"
      },
      required: false,
      description: "Users first and last names, used as component alt text as well\nas to display initials if no image selected"
    },
    icon: {
      type: {
        name: "string"
      },
      required: false,
      description: "Icon name from the Font Awesome or Material Icons icon list to be displayed if no image or initials present"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional component styling class name"
    },
    imageSource: {
      type: {
        name: "string"
      },
      required: false,
      description: "Path to user image, preferentially shown before user initials or icon"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          userAvatar: {
            name: "custom",
            raw: "Avatar.propTypes.messageProps.userAvatar",
            description: "userAvatar message",
            required: false
          }
        }
      },
      required: false,
      description: "Message props(error message/aria-label)"
    },
    useAuthInfo: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If `true`, display header information from Okta, if available, and Logout menu link"
    },
    showImageBorder: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Defines whether image border should be shown or not"
    },
    headerClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional css class to the dropdown menu avatar header"
    }
  }
};