import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, GridItem, gaps } from '@jutro/layout';

var getGridProps = function getGridProps(props) {
  return _.pick(props, ['repeat', 'gap', 'columns']);
};

var getGridItemProps = function getGridItemProps(props) {
  return _.pick(props, ['colStart', 'colSpan']);
};

export var Layout = function Layout(_ref2) {
  var id = _ref2.id,
      children = _ref2.children,
      className = _ref2.className,
      layout = _ref2.layout;

  var _ref = layout || {},
      desktop = _ref.desktop,
      tablet = _ref.tablet,
      phoneWide = _ref.phoneWide,
      phone = _ref.phone;

  return React.createElement(Grid, _extends({
    id: id,
    className: className
  }, getGridProps(desktop || {}), {
    tablet: getGridProps(tablet || {}),
    phonewide: getGridProps(phoneWide || {}),
    phone: getGridProps(phone || {})
  }), React.createElement(GridItem, _extends({}, getGridItemProps(desktop || {}), {
    tablet: getGridItemProps(tablet || {}),
    phoneWide: getGridItemProps(phoneWide || {}),
    phone: getGridItemProps(phone || {})
  }), children));
};
export var layoutShape = PropTypes.shape({
  id: PropTypes.string,
  repeat: PropTypes.oneOfType([PropTypes.oneOf(['auto-fit', 'auto-fill']), PropTypes.number, PropTypes.string]),
  gap: PropTypes.oneOf(_Object$keys(gaps)),
  columns: PropTypes.array,
  colSpan: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colStart: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
});
Layout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  layout: PropTypes.shape({
    desktop: layoutShape,
    tablet: layoutShape,
    phoneWide: layoutShape,
    phone: layoutShape
  })
};
Layout.__docgenInfo = {
  componentName: "Layout",
  packageName: "@jutro/components",
  description: "",
  displayName: "Layout",
  methods: [],
  actualName: "Layout",
  props: {
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "children; preferably 'GridItem'; works with any child"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Optional css class(es) to add to the grid tag"
    },
    layout: {
      type: {
        name: "shape",
        value: {
          desktop: {
            name: "custom",
            raw: "layoutShape",
            description: "Optional props to override default layout",
            required: false
          },
          tablet: {
            name: "custom",
            raw: "layoutShape",
            description: "Optional props to override layout on tablet",
            required: false
          },
          phoneWide: {
            name: "custom",
            raw: "layoutShape",
            description: "Optional props to override layout on phoneWide",
            required: false
          },
          phone: {
            name: "custom",
            raw: "layoutShape",
            description: "Optional props to override layout on phone",
            required: false
          }
        }
      },
      required: false,
      description: ""
    }
  }
};