import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";

function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();

  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
        result;

    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;

      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }

    return _possibleConstructorReturn(this, result);
  };
}

function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;

  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlMessageShape } from '@jutro/prop-types';
import { TabSetContext } from './TabSetContext';
import { getTabHeadingId } from './TabBar';
export var Tab = function (_Component) {
  _inherits(Tab, _Component);

  var _super = _createSuper(Tab);

  function Tab() {
    _classCallCheck(this, Tab);

    return _super.apply(this, arguments);
  }

  _createClass(Tab, [{
    key: "renderTab",
    value: function renderTab(activeTabId) {
      var _this$props = this.props,
          id = _this$props.id,
          children = _this$props.children,
          className = _this$props.className,
          disabled = _this$props.disabled,
          visible = _this$props.visible;
      var hidden = id !== activeTabId || disabled || !visible;

      var _getTabHeadingId = getTabHeadingId(id),
          headingElemId = _getTabHeadingId.headingElemId;

      return React.createElement("div", {
        id: id,
        className: className,
        "aria-labelledby": headingElemId,
        role: "tabpanel",
        "aria-hidden": hidden,
        tabIndex: hidden ? '-1' : '0'
      }, !hidden && children);
    }
  }, {
    key: "render",
    value: function render() {
      var _this = this;

      return React.createElement(TabSetContext.Consumer, null, function (activeTabId) {
        return _this.renderTab(activeTabId);
      });
    }
  }]);

  return Tab;
}(Component);

_defineProperty(Tab, "propTypes", {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  visible: PropTypes.bool,
  className: PropTypes.string,
  heading: PropTypes.oneOfType([PropTypes.node, intlMessageShape]),
  children: PropTypes.node
});

_defineProperty(Tab, "defaultProps", {
  disabled: false,
  visible: true
});

Tab.__docgenInfo = {
  componentName: "Tab",
  packageName: "@jutro/components",
  description: "The `Tab` component is designed to be used as the child of a `TabSet` component.  It will render its `children`\ninside the appropriate tab pane <div> only if its `id` matches the `activeTabId` from the context provided by the\n`TabSet` AND the component is not `disabled`.  Also, the `heading` prop will never be rendered internally by a `Tab`\nas this will be done by the `TabSet` container instead.",
  displayName: "Tab",
  methods: [{
    name: "renderTab",
    docblock: null,
    modifiers: [],
    params: [{
      name: "activeTabId",
      optional: undefined,
      type: null
    }],
    returns: null
  }],
  actualName: "Tab",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "The id for this Tab"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, this `Tab` is disabled.",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    visible: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, this `Tab` is visible.",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Optional class name to apply to this component"
    },
    heading: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: false
              },
              defaultMessage: {
                name: "string",
                required: false
              },
              args: {
                name: "shape",
                value: {},
                required: false
              }
            }
          }]
        }]
      },
      required: false,
      description: "The heading for the `Tab`. This prop is NOT rendered by the `Tab` component directly, but rather is extracted out by the `TabSet` component and rendered by it instead\nCan either be a simple string or renderer function (which should accept rendering props object as an argument)"
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "The content of this tab, child components to display"
    }
  }
};