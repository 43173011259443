import { defineMessages } from 'react-intl';
export var messages = defineMessages({
  dd: {
    id: 'jutro-components.widgets.inputs.DatePicker.dayFormat',
    defaultMessage: 'dd'
  },
  mm: {
    id: 'jutro-components.widgets.inputs.DatePicker.monthFormat',
    defaultMessage: 'MM'
  },
  yy: {
    id: 'jutro-components.widgets.inputs.DatePicker.yearFormat',
    defaultMessage: 'yyyy'
  },
  today: {
    id: 'jutro-components.widgets.inputs.DatePicker.today',
    defaultMessage: 'Today'
  },
  incorrectInput: {
    id: 'jutro-components.widgets.inputs.Incorrect input',
    defaultMessage: 'Incorrect input'
  },
  previous: {
    id: 'jutro-components.widgets.inputs.DatePicker.previous',
    defaultMessage: 'Previous'
  },
  next: {
    id: 'jutro-components.widgets.inputs.DatePicker.next',
    defaultMessage: 'Next'
  }
});