import _defineProperty2 from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

var _typeIconMap;

import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";

function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();

  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
        result;

    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;

      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }

    return _possibleConstructorReturn(this, result);
  };
}

function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;

  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}

import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
import { ContextConsumer, getMessageProp } from '@jutro/platform';
import styles from "./InlineNotification.module.css";
import { Icon } from '../Icon/Icon';
import { IconButton } from '../IconButton/IconButton';
import { messages } from './InlineNotification.messages';
import { getKeyPressHandler } from '../../accessibility/getKeyPressHandler';
var types = {
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success'
};
var typeIconMap = (_typeIconMap = {}, _defineProperty2(_typeIconMap, types.WARNING, 'mi-error-outline'), _defineProperty2(_typeIconMap, types.ERROR, 'mi-error-outline'), _defineProperty2(_typeIconMap, types.INFO, 'mi-info'), _defineProperty2(_typeIconMap, types.SUCCESS, 'mi-check-circle'), _typeIconMap);
export var InlineNotification = function (_Component) {
  _inherits(InlineNotification, _Component);

  var _super = _createSuper(InlineNotification);

  function InlineNotification() {
    var _this;

    _classCallCheck(this, InlineNotification);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "state", {
      open: true
    });

    _defineProperty(_assertThisInitialized(_this), "handleDismiss", function () {
      _this.setState({
        open: false
      });
    });

    _defineProperty(_assertThisInitialized(_this), "renderComponent", function (translator) {
      var _ref;

      var open = _this.state.open;
      var _this$props = _this.props,
          id = _this$props.id,
          className = _this$props.className,
          type = _this$props.type,
          isEmbeddedNotification = _this$props.isEmbeddedNotification;
      var classes = cx(styles.inlineNotification, open && (_ref = {}, _defineProperty2(_ref, styles.inlineNotificationSuccess, type === types.SUCCESS), _defineProperty2(_ref, styles.inlineNotificationInfo, type === types.INFO), _defineProperty2(_ref, styles.inlineNotificationWarning, type === types.WARNING), _defineProperty2(_ref, styles.inlineNotificationError, type === types.ERROR), _ref), className);
      var ariaProps = !isEmbeddedNotification ? {
        role: 'alert',
        'aria-hidden': !open
      } : {};
      return React.createElement("div", _extends({
        id: id,
        className: classes
      }, ariaProps), open && _this.renderContent(translator));
    });

    return _this;
  }

  _createClass(InlineNotification, [{
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {
      if (prevProps.type !== this.props.type || prevProps.message !== this.props.message) {
        this.setState({
          open: true
        });
      }
    }
  }, {
    key: "renderTypeIcon",
    value: function renderTypeIcon(type) {
      var icon = React.createElement(Icon, {
        icon: typeIconMap[type]
      });
      return React.createElement("span", {
        className: styles.iconWrapper,
        "aria-hidden": "true"
      }, icon);
    }
  }, {
    key: "renderDismissButton",
    value: function renderDismissButton(type) {
      if (!this.props.isDismissable) {
        return null;
      }

      var iconColor = type === 'warning' ? 'dark' : 'light';
      var dismissLabel = getMessageProp('dismiss', this.props.messageProps, messages);
      return React.createElement(IconButton, {
        icon: "mi-close",
        className: styles.dismissButton,
        iconClassName: styles.dismissButtonIcon,
        onClick: this.handleDismiss,
        ariaLabel: dismissLabel,
        iconColor: iconColor,
        onKeyPress: getKeyPressHandler(this.handleDismiss)
      });
    }
  }, {
    key: "renderContent",
    value: function renderContent(translator) {
      var _defaultText;

      var _this$props2 = this.props,
          message = _this$props2.message,
          type = _this$props2.type,
          isEmbeddedNotification = _this$props2.isEmbeddedNotification,
          messageProps = _this$props2.messageProps;
      var typeIcon = this.renderTypeIcon(type);
      var dismissButton = this.renderDismissButton(type);
      var defaultText = (_defaultText = {}, _defineProperty2(_defaultText, types.SUCCESS, getMessageProp('success', messageProps, messages)), _defineProperty2(_defaultText, types.INFO, getMessageProp('info', messageProps, messages)), _defineProperty2(_defaultText, types.WARNING, getMessageProp('warning', messageProps, messages)), _defineProperty2(_defaultText, types.ERROR, getMessageProp('error', messageProps, messages)), _defaultText);
      return React.createElement(React.Fragment, null, typeIcon, React.createElement("span", {
        className: styles.message
      }, React.createElement("span", {
        className: styles.messageDefault
      }, translator(defaultText[type])), React.createElement("span", null, translator(message))), !isEmbeddedNotification ? dismissButton : null);
    }
  }, {
    key: "render",
    value: function render() {
      return React.createElement(ContextConsumer, {
        contexts: [TranslatorContext]
      }, this.renderComponent);
    }
  }]);

  return InlineNotification;
}(Component);

_defineProperty(InlineNotification, "propTypes", {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  message: intlMessageShape,
  type: PropTypes.oneOf([types.SUCCESS, types.INFO, types.WARNING, types.ERROR]),
  isDismissable: PropTypes.bool,
  isEmbeddedNotification: PropTypes.bool,
  messageProps: PropTypes.shape({
    success: intlMessageShape,
    warning: intlMessageShape,
    info: intlMessageShape,
    error: intlMessageShape,
    dismissLabel: intlMessageShape
  })
});

_defineProperty(InlineNotification, "defaultProps", {
  isDismissable: true,
  type: types.INFO,
  isEmbeddedNotification: false
});

InlineNotification.__docgenInfo = {
  componentName: "InlineNotification",
  packageName: "@jutro/components",
  description: "Displays a message within the body of the page. You show it on event, and the user can dismiss it.",
  displayName: "InlineNotification",
  methods: [{
    name: "handleDismiss",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "renderTypeIcon",
    docblock: null,
    modifiers: [],
    params: [{
      name: "type",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "renderDismissButton",
    docblock: "Renders dismiss button part of components\n@param {object} type notification type\n@returns {React.ReactElement} - Dismiss button",
    modifiers: [],
    params: [{
      name: "type",
      description: "notification type",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "Dismiss button",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Renders dismiss button part of components"
  }, {
    name: "renderContent",
    docblock: null,
    modifiers: [],
    params: [{
      name: "translator",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "renderComponent",
    docblock: "Renders component content\n\n@param {Function} translator function\n@returns {React.ReactElement}",
    modifiers: [],
    params: [{
      name: "translator",
      description: "function",
      type: {
        name: "Function"
      },
      optional: false
    }],
    returns: {
      description: null,
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Renders component content"
  }],
  actualName: "InlineNotification",
  metadataType: "element",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Component unique identifier"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    message: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Text to display on notification"
    },
    type: {
      type: {
        name: "enum",
        value: [{
          value: "'success'",
          computed: false
        }, {
          value: "'info'",
          computed: false
        }, {
          value: "'warning'",
          computed: false
        }, {
          value: "'error'",
          computed: false
        }]
      },
      required: false,
      description: "Phrase allowing to set color and appearance of notification (success, warning, error, info)",
      defaultValue: {
        value: "'info'",
        computed: false
      }
    },
    isDismissable: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, the dismiss button appears with this component",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    isEmbeddedNotification: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, attributes 'aria-hidden' and 'role' will be added",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          success: {
            name: "union",
            description: "prefix text for success type message",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          },
          warning: {
            name: "union",
            description: "prefix text for warning type message",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          },
          info: {
            name: "union",
            description: "prefix text for info type message",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          },
          error: {
            name: "union",
            description: "prefix text for error type message",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          },
          dismissLabel: {
            name: "union",
            description: "dismiss button aria-label",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          }
        }
      },
      required: false,
      description: "Message props(error message/aria-label)"
    }
  }
};