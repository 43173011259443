import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";

var Options = function () {
  function Options(options) {
    _classCallCheck(this, Options);

    this.options = options;
  }

  _createClass(Options, [{
    key: "onOption",
    value: function onOption(optionName, callback) {
      var option = this.options[optionName];

      if (option !== undefined) {
        callback(option);
      }
    }
  }]);

  return Options;
}();

export { Options as default };