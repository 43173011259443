import { defineMessages } from 'react-intl';
export var messages = defineMessages({
  startLabel: {
    id: 'jutro-components.widgets.inputs.DateRange.startLabel',
    defaultMessage: 'Start Date'
  },
  endLabel: {
    id: 'jutro-components.widgets.inputs.DateRange.endLabel',
    defaultMessage: 'End Date'
  }
});