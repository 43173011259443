import _someInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/some";
import PropTypes from 'prop-types';
import { intlMessageShape } from './intlMessageShape';
export var location = {
  pathname: PropTypes.string,
  search: PropTypes.string,
  hash: PropTypes.string,
  state: PropTypes.shape({})
};
export var intlLocation = {
  pathname: intlMessageShape,
  search: PropTypes.string,
  hash: intlMessageShape,
  state: PropTypes.shape({})
};
export var toShape = PropTypes.oneOfType([PropTypes.string, PropTypes.shape(location)]);
export var intlToShape = PropTypes.oneOfType([intlMessageShape, PropTypes.shape(intlLocation)]);
export var isIntlToShapeLocation = function isIntlToShapeLocation(to) {
  var _context;

  return to !== undefined && typeof to !== 'string' && _someInstanceProperty(_context = ['pathname', 'search', 'hash', 'state']).call(_context, function (property) {
    return property in to;
  });
};