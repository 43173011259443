import _defineProperty2 from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _trimInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/trim";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";

function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();

  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
        result;

    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;

      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }

    return _possibleConstructorReturn(this, result);
  };
}

function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;

  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { intlMessageShape } from '@jutro/prop-types';
import { getMessageProp } from '@jutro/platform';
import styles from "./FieldLabel.module.css";
import { messages } from './FieldComponent.messages';
var requiredSymbol = '*';
export var FieldLabel = function (_PureComponent) {
  _inherits(FieldLabel, _PureComponent);

  var _super = _createSuper(FieldLabel);

  function FieldLabel() {
    _classCallCheck(this, FieldLabel);

    return _super.apply(this, arguments);
  }

  _createClass(FieldLabel, [{
    key: "createLabel",
    value: function createLabel(label, classes, translator) {
      var translatedLabel = label && translator(label);
      var labelElement = typeof translatedLabel === 'string' ? _trimInstanceProperty(translatedLabel).call(translatedLabel) : translatedLabel;
      return labelElement && React.createElement("span", {
        className: classes
      }, labelElement);
    }
  }, {
    key: "renderLabel",
    value: function renderLabel() {
      var _cx;

      var _this$props = this.props,
          translator = _this$props.translator,
          messageProps = _this$props.messageProps;
      var _this$props2 = this.props,
          id = _this$props2.id,
          label = _this$props2.label,
          required = _this$props2.required,
          readOnly = _this$props2.readOnly,
          disabled = _this$props2.disabled,
          hideLabel = _this$props2.hideLabel,
          showOptional = _this$props2.showOptional,
          showRequired = _this$props2.showRequired,
          className = _this$props2.className,
          labelClassName = _this$props2.labelClassName,
          secondaryLabelClassName = _this$props2.secondaryLabelClassName,
          tooltipIcon = _this$props2.tooltipIcon,
          secondaryLabel = _this$props2.secondaryLabel,
          htmlFor = _this$props2.htmlFor,
          labelPosition = _this$props2.labelPosition,
          showInlineLabel = _this$props2.showInlineLabel;
      var translatedLabel = this.createLabel(label, cx(styles.primaryLabel, labelClassName), translator);
      var translatedSecondaryLabel = this.createLabel(secondaryLabel, cx(styles.secondaryLabel, secondaryLabelClassName), translator);
      var optional = getMessageProp('optional', messageProps, messages);
      var optionalIndicator = showOptional && !required && !readOnly && this.createLabel(optional, styles.optional, translator);
      var requiredIndicator = showRequired && required && !readOnly && !disabled && React.createElement("span", {
        "aria-hidden": "true",
        className: cx(styles.requiredSymbol)
      }, requiredSymbol);
      var labelContainerClasses = cx(styles.fieldLabelContainer, (_cx = {}, _defineProperty2(_cx, styles[labelPosition], !showInlineLabel), _defineProperty2(_cx, styles.hidden, hideLabel), _cx));
      var labelClasses = cx(styles.fieldLabel, _defineProperty2({}, styles.readOnly, readOnly), className);
      return React.createElement("div", {
        className: labelContainerClasses
      }, React.createElement("label", {
        id: id,
        className: labelClasses,
        htmlFor: htmlFor
      }, translatedLabel, optionalIndicator, requiredIndicator, tooltipIcon), translatedSecondaryLabel);
    }
  }, {
    key: "render",
    value: function render() {
      return this.renderLabel();
    }
  }]);

  return FieldLabel;
}(PureComponent);

_defineProperty(FieldLabel, "propTypes", {
  id: PropTypes.string,
  htmlFor: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondaryLabel: intlMessageShape,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  hideLabel: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  secondaryLabelClassName: PropTypes.string,
  showOptional: PropTypes.bool,
  showRequired: PropTypes.bool,
  tooltipIcon: PropTypes.node,
  translator: PropTypes.func.isRequired,
  messageProps: PropTypes.shape({
    optional: intlMessageShape
  }),
  labelPosition: PropTypes.oneOf(['top', 'left', 'right'])
});

_defineProperty(FieldLabel, "defaultProps", {
  labelPosition: 'top'
});

FieldLabel.__docgenInfo = {
  componentName: "FieldLabel",
  packageName: "@jutro/components",
  description: "Class renders label for `FieldComponent`.",
  displayName: "FieldLabel",
  methods: [{
    name: "createLabel",
    docblock: null,
    modifiers: [],
    params: [{
      name: "label",
      optional: undefined,
      type: null
    }, {
      name: "classes",
      optional: undefined,
      type: null
    }, {
      name: "translator",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "renderLabel",
    docblock: "Renders component\n\n@returns {React.ReactElement}",
    modifiers: [],
    params: [],
    returns: {
      description: null,
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Renders component"
  }],
  actualName: "FieldLabel",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: false,
      description: "Used to identify the component. Significant for components with more complex structures, where aria-labelledby property needs to be used"
    },
    htmlFor: {
      type: {
        name: "string"
      },
      required: true,
      description: "Used to identify the component. Applied to control and referenced by label"
    },
    label: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "object"
        }]
      },
      required: false,
      description: "Text to display; if not provided, uses the value of the '[id]' prop"
    },
    secondaryLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Text for secondary label"
    },
    required: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, this field is required"
    },
    readOnly: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, this field is readonly?"
    },
    hideLabel: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, the label is not visible"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class, provides an additional style to apply to the component"
    },
    labelClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class, provides an additional style to apply to primary label"
    },
    secondaryLabelClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class, provides an additional style to apply to secondary label"
    },
    showOptional: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, displays the `Optional` span"
    },
    showRequired: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, displays the required asterisc next to the label"
    },
    tooltipIcon: {
      type: {
        name: "node"
      },
      required: false,
      description: "The icon to us ein the tooltip. Use Font Awesome keyword, like \"calendar\" without any prefix"
    },
    translator: {
      type: {
        name: "func"
      },
      required: true,
      description: "The function used to translate strings"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          optional: {
            name: "union",
            description: "Optional message",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          }
        }
      },
      required: false,
      description: "Message props(error message/aria-label)"
    },
    labelPosition: {
      type: {
        name: "enum",
        value: [{
          value: "'top'",
          computed: false
        }, {
          value: "'left'",
          computed: false
        }, {
          value: "'right'",
          computed: false
        }]
      },
      required: false,
      description: "Allows to select label position",
      defaultValue: {
        value: "'top'",
        computed: false
      }
    }
  }
};