import _objectWithoutProperties from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import React, { useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Avatar } from '@jutro/components';
import { intlMessageShape } from '@jutro/prop-types';
import { AuthContext } from '@jutro/auth';
import cx from 'classnames';
import { DropdownMenu } from './DropdownMenu';
import styles from "./DropdownMenuAvatar.module.css";
import { DropdownMenuAvatarContent } from './DropdownMenuAvatarContent';
export var DropdownMenuAvatar = function DropdownMenuAvatar(_ref) {
  var username = _ref.username,
      icon = _ref.icon,
      className = _ref.className,
      imageSource = _ref.imageSource,
      messageProps = _ref.messageProps,
      showImageBorder = _ref.showImageBorder,
      useAuthInfo = _ref.useAuthInfo,
      id = _ref.id,
      isOpenProp = _ref.isOpen,
      dropUp = _ref.dropUp,
      alignRight = _ref.alignRight,
      title = _ref.title,
      subtitle = _ref.subtitle,
      children = _ref.children,
      onBeforeLogout = _ref.onBeforeLogout,
      headerClassName = _ref.headerClassName,
      authLinksClassName = _ref.authLinksClassName,
      separatorClassName = _ref.separatorClassName;
  var auth = useContext(AuthContext);
  var dropdownMenuAvatarContentProps = {
    title: title,
    subtitle: subtitle,
    children: children,
    username: username,
    icon: icon,
    className: className,
    imageSource: imageSource,
    messageProps: messageProps,
    useAuthInfo: useAuthInfo,
    showImageBorder: showImageBorder,
    onBeforeLogout: onBeforeLogout,
    headerClassName: headerClassName,
    authLinksClassName: authLinksClassName,
    separatorClassName: separatorClassName
  };
  var menuProps = {
    id: id,
    isOpen: isOpenProp,
    dropUp: dropUp,
    alignRight: alignRight
  };

  var getImageSource = function getImageSource() {
    return useAuthInfo ? _.get(auth, 'userInfo.pictureUrl', null) : imageSource;
  };

  var avatarClassName = cx(className, 'dropdownMenuAvatarTrigger');
  var avatarProps = {
    username: username,
    icon: icon,
    className: avatarClassName,
    imageSource: getImageSource(),
    messageProps: messageProps,
    showImageBorder: showImageBorder,
    useAuthInfo: useAuthInfo
  };

  var handleRenderTrigger = function handleRenderTrigger(_ref2, toggleMenu) {
    var isOpen = _ref2.isOpen,
        menuId = _ref2.menuId,
        other = _objectWithoutProperties(_ref2, ["isOpen", "menuId"]);

    return React.createElement(Avatar, _extends({
      "aria-label": "Profile menu",
      "aria-controls": menuId
    }, other, avatarProps, {
      onClick: function onClick() {
        return toggleMenu(!isOpen);
      }
    }));
  };

  return React.createElement(DropdownMenu, _extends({}, menuProps, {
    className: styles.dropdownMenuAvatar,
    onRenderTrigger: handleRenderTrigger,
    menuClassName: styles.dropdownMenuAvatarMenu
  }), React.createElement(DropdownMenuAvatarContent, dropdownMenuAvatarContentProps));
};
DropdownMenuAvatar.propTypes = {
  title: intlMessageShape,
  subtitle: intlMessageShape,
  username: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  imageSource: PropTypes.string,
  messageProps: PropTypes.shape({
    userAvatar: intlMessageShape
  }),
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  dropUp: PropTypes.bool,
  alignRight: PropTypes.bool,
  useAuthInfo: PropTypes.bool,
  children: PropTypes.node,
  showImageBorder: PropTypes.bool,
  headerClassName: PropTypes.string,
  authLinksClassName: PropTypes.string,
  separatorClassName: PropTypes.string,
  onBeforeLogout: PropTypes.func
};
DropdownMenuAvatar.defaultAvatarProps = {
  alignRight: true,
  useAuthInfo: false
};
DropdownMenuAvatar.__docgenInfo = {
  componentName: "DropdownMenuAvatar",
  packageName: "@jutro/router",
  description: "DropdownMenuAvatar",
  displayName: "DropdownMenuAvatar",
  methods: [],
  actualName: "DropdownMenuAvatar",
  metadataType: "action",
  props: {
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Menu header title text"
    },
    subtitle: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Menu header subtitle text"
    },
    username: {
      type: {
        name: "string"
      },
      required: false,
      description: "Users first and last names, used as component alt text as well\nas to display initials if no image selected"
    },
    icon: {
      type: {
        name: "string"
      },
      required: false,
      description: "Icon name from the Font Awesome or Material Icons icon list to be displayed if no image or initials present"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional component styling class name"
    },
    imageSource: {
      type: {
        name: "string"
      },
      required: false,
      description: "Path to user image, preferentially shown before user initials or icon"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          userAvatar: {
            name: "union",
            description: "userAvatar message",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          }
        }
      },
      required: false,
      description: "Message props(error message/aria-label)"
    },
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Used to identify menu component."
    },
    isOpen: {
      type: {
        name: "bool"
      },
      required: false,
      description: "The prop that indicates if the menu is currently visible."
    },
    dropUp: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If `true`, the menu appears above the trigger component."
    },
    alignRight: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If `true`, items are aligned to the right edge of the menu."
    },
    useAuthInfo: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If `true`, display header information from Okta, if available, and Logout menu link"
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "The component children wrapped by the dropdown menu avatar component."
    },
    showImageBorder: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Defines whether image border should be shown or not"
    },
    headerClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional css class to the dropdown menu avatar header"
    },
    authLinksClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional css class to the dropdown menu avatar auth links"
    },
    separatorClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Optional css class to the dropdown menu separator"
    },
    onBeforeLogout: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback to be called before okta logout"
    }
  }
};