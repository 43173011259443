import _extends from "@babel/runtime-corejs3/helpers/extends";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import React, { useContext, useEffect, useCallback, useMemo } from 'react';
import { logDeprecationMessage } from '@jutro/platform';
import { ThemeContext } from '@jutro/theme';
import { DropdownSelectField } from '../../inputs/DropdownSelectField/DropdownSelectField';
import { themeChooserPropTypes, themeChooserDefaultProps } from './propTypes';

var isThemeContextValue = function isThemeContextValue(theme) {
  return 'switchTheme' in theme;
};

export var ThemeChooser = function ThemeChooser(_ref) {
  var id = _ref.id,
      availableThemes = _ref.availableThemes,
      themeInput = _ref.theme,
      defaultTheme = _ref.defaultTheme,
      onThemeChange = _ref.onThemeChange,
      className = _ref.className,
      label = _ref.label,
      storybookComponent = _ref.storybookComponent,
      isValueControlledOutside = _ref.skipPropagation,
      readOnly = _ref.readOnly,
      hideLabel = _ref.hideLabel,
      internalClassNames = _ref.internalClassNames,
      alwaysShowPlaceholder = _ref.alwaysShowPlaceholder,
      searchable = _ref.searchable,
      isInitiallyOpen = _ref.isInitiallyOpen,
      controlClassName = _ref.controlClassName,
      secondaryLabel = _ref.secondaryLabel,
      tooltip = _ref.tooltip,
      placeholder = _ref.placeholder,
      required = _ref.required,
      schemaRequired = _ref.schemaRequired,
      disabled = _ref.disabled,
      nullable = _ref.nullable,
      visible = _ref.visible,
      onValidationChange = _ref.onValidationChange,
      onBlur = _ref.onBlur,
      onFocus = _ref.onFocus,
      showErrors = _ref.showErrors,
      showRequired = _ref.showRequired,
      showOptional = _ref.showOptional,
      layout = _ref.layout,
      labelClassName = _ref.labelClassName,
      secondaryLabelClassName = _ref.secondaryLabelClassName,
      gwTest = _ref.gwTest,
      showValidationIcon = _ref.showValidationIcon,
      requiredFieldValidationMessage = _ref.requiredFieldValidationMessage,
      successMessage = _ref.successMessage,
      messageProps = _ref.messageProps,
      labelPosition = _ref.labelPosition,
      phone = _ref.phone,
      phoneWide = _ref.phoneWide,
      tablet = _ref.tablet;
  var themeContextConfig = useContext(ThemeContext);
  var themeInputConfig = useMemo(function () {
    return isValueControlledOutside && _findInstanceProperty(availableThemes).call(availableThemes, function (_ref2) {
      var name = _ref2.name;
      return name === (themeInput && themeInput.name);
    });
  }, [themeInput]);
  var theme = themeInputConfig || themeContextConfig;
  useEffect(function () {
    _forEachInstanceProperty(availableThemes).call(availableThemes, function (_ref3) {
      var dropTarget = _ref3.dropTarget,
          name = _ref3.name;

      if (!dropTarget) {
        return;
      }

      logDeprecationMessage("ThemeChooser: ".concat(name, " Theme"), undefined, undefined, dropTarget);
    });
  }, [availableThemes]);
  var availableThemesOptions = useMemo(function () {
    return _mapInstanceProperty(availableThemes).call(availableThemes, function (_ref4) {
      var name = _ref4.name;
      return {
        displayName: name,
        id: name
      };
    });
  }, [availableThemes]);
  var handleOnChange = useCallback(function (themeName) {
    var newTheme = _findInstanceProperty(availableThemes).call(availableThemes, function (themeItem) {
      return themeItem.name === themeName;
    });

    if (!newTheme) {
      return;
    }

    if (onThemeChange) {
      onThemeChange(newTheme);
    }

    if (!isValueControlledOutside && isThemeContextValue(theme)) {
      theme.switchTheme(newTheme);
    }
  }, [onThemeChange, availableThemes, theme]);

  if (storybookComponent) {
    var StorybookComponent = storybookComponent;
    return React.createElement(StorybookComponent, {
      id: id,
      className: className,
      onThemeChange: onThemeChange,
      theme: theme,
      availableThemes: availableThemes,
      label: label,
      skipPropagation: isValueControlledOutside
    });
  }

  var defaultValue = defaultTheme ? {
    id: defaultTheme.name,
    displayName: defaultTheme.name
  } : undefined;
  var dropdownSelectProps = {
    id: id,
    className: className,
    label: label,
    hideLabel: hideLabel,
    readOnly: readOnly,
    internalClassNames: internalClassNames,
    alwaysShowPlaceholder: alwaysShowPlaceholder,
    searchable: searchable,
    isInitiallyOpen: isInitiallyOpen,
    controlClassName: controlClassName,
    secondaryLabel: secondaryLabel,
    tooltip: tooltip,
    placeholder: placeholder,
    required: required,
    schemaRequired: schemaRequired,
    disabled: disabled,
    nullable: nullable,
    visible: visible,
    onValidationChange: onValidationChange,
    onBlur: onBlur,
    onFocus: onFocus,
    showErrors: showErrors,
    showRequired: showRequired,
    showOptional: showOptional,
    layout: layout,
    labelClassName: labelClassName,
    secondaryLabelClassName: secondaryLabelClassName,
    gwTest: gwTest,
    showValidationIcon: showValidationIcon,
    requiredFieldValidationMessage: requiredFieldValidationMessage,
    successMessage: successMessage,
    messageProps: messageProps,
    labelPosition: labelPosition,
    phone: phone,
    phoneWide: phoneWide,
    tablet: tablet,
    defaultValue: defaultValue
  };
  return React.createElement(DropdownSelectField, _extends({
    availableValues: availableThemesOptions,
    value: theme.name,
    onValueChange: handleOnChange
  }, dropdownSelectProps));
};
ThemeChooser.propTypes = themeChooserPropTypes;
ThemeChooser.defaultProps = themeChooserDefaultProps;
ThemeChooser.displayName = 'ThemeChooser';
ThemeChooser.__docgenInfo = {
  componentName: "ThemeChooser",
  packageName: "@jutro/components",
  description: "Component that allows the user to select the theme configuration",
  displayName: "ThemeChooser",
  methods: [],
  actualName: "ThemeChooser",
  metadataType: "element",
  composes: ["./propTypes"]
};