import { defineMessages } from 'react-intl';
export var messages = defineMessages({
  success: {
    id: 'jutro-components.widgets.InlineNotification.success',
    defaultMessage: 'Success:'
  },
  info: {
    id: 'jutro-components.widgets.InlineNotification.info',
    defaultMessage: 'Info:'
  },
  warning: {
    id: 'jutro-components.widgets.InlineNotification.warning',
    defaultMessage: 'Warning:'
  },
  error: {
    id: 'jutro-components.widgets.InlineNotification.error',
    defaultMessage: 'Error:'
  },
  dismiss: {
    id: 'jutro-components.widgets.InlineNotification.dismiss',
    defaultMessage: 'Dismiss'
  }
});