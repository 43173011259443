import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import get from "lodash/get";
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { prepareComponentFromMetadata, renderComponentFromMetadata } from '@jutro/uiconfig';
import { FieldValue } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { TableColumn } from '../table/TableColumn';
import { defaultColumnFilter } from '../helper';
import styles from "./FieldColumn.module.css";
import tableStyles from "../DataTable.module.css";
export var FieldColumn = function FieldColumn() {
  if (false) {
    throw new Error('Component <FieldColumn /> should never render');
  }

  return React.createElement(React.Fragment, null);
};
FieldColumn.propTypes = _objectSpread({}, TableColumn.propTypes, {
  model: PropTypes.object,
  basePath: PropTypes.string,
  uiMetadata: PropTypes.object,
  pageProps: PropTypes.object,
  readOnly: PropTypes.bool,
  onAccessor: PropTypes.func
});

var getOptionCode = function getOptionCode(option) {
  var _option$code;

  return (_option$code = option === null || option === void 0 ? void 0 : option.code) !== null && _option$code !== void 0 ? _option$code : option.id;
};

var getOptionName = function getOptionName(option) {
  var _option$name;

  return (_option$name = option === null || option === void 0 ? void 0 : option.name) !== null && _option$name !== void 0 ? _option$name : option.displayName;
};

export var DefaultCell = function DefaultCell(_ref) {
  var row = _ref.row,
      rowId = _ref.rowId,
      innerProps = _ref.innerProps;
  var translator = useContext(TranslatorContext);
  var path = innerProps.path,
      visible = innerProps.visible,
      fieldDatatype = innerProps.fieldDatatype,
      id = innerProps.id,
      _innerProps$available = innerProps.availableValues,
      availableValues = _innerProps$available === void 0 ? [] : _innerProps$available;

  if (visible === false) {
    return null;
  }

  var cellValue = get(row, path);

  var selectOption = _findInstanceProperty(availableValues).call(availableValues, function (el) {
    return getOptionCode(el) === (cellValue === null || cellValue === void 0 ? void 0 : cellValue.toString());
  });

  var value = selectOption ? getOptionName(selectOption) : cellValue;
  return React.createElement(FieldValue, {
    id: "".concat(id, "_").concat(rowId),
    datatype: fieldDatatype,
    value: translator(value)
  });
};

FieldColumn.defaultCell = function (row, rowId, props) {
  return React.createElement(DefaultCell, {
    row: row,
    rowId: rowId,
    innerProps: props
  });
};

FieldColumn.editCell = function (_ref2) {
  var props = _ref2.props,
      editProps = _ref2.editProps,
      editActions = _ref2.editActions;

  var id = props.id,
      basePath = props.basePath,
      path = props.path,
      visible = props.visible,
      type = props.fieldType,
      datatype = props.fieldDatatype,
      component = props.fieldComponent,
      rest = _objectWithoutProperties(props, ["id", "basePath", "path", "visible", "fieldType", "fieldDatatype", "fieldComponent"]);

  var row = editProps.row,
      rowId = editProps.rowId,
      index = editProps.index;
  var update = editActions.update;

  if (visible === false) {
    return null;
  }

  var results = prepareComponentFromMetadata({
    type: 'field',
    datatype: datatype,
    component: component,
    componentProps: _objectSpread({}, rest, {
      id: "".concat(id, "_").concat(rowId),
      hideLabel: true,
      className: styles.fullWidth,
      value: get(row, props.path),
      path: basePath ? "".concat(basePath, ".").concat(index, ".").concat(path) : path,
      onValueChange: function onValueChange(newValue) {
        return update(row, path, newValue);
      }
    })
  });
  return renderComponentFromMetadata(results);
};

FieldColumn.editCellClass = tableStyles.editedCell;
FieldColumn.displayName = 'FieldColumn';
FieldColumn.defaultProps = {
  renderCell: FieldColumn.defaultCell,
  renderEditCell: FieldColumn.editCell,
  onFilter: defaultColumnFilter,
  columnProportion: 1,
  fieldDatatype: 'string'
};
FieldColumn.__docgenInfo = {
  componentName: "FieldColumn",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "FieldColumn",
  methods: [],
  actualName: "FieldColumn",
  metadataType: "container",
  props: {
    model: {
      type: {
        name: "object"
      },
      required: false,
      description: ""
    },
    basePath: {
      type: {
        name: "string"
      },
      required: false,
      description: ""
    },
    uiMetadata: {
      type: {
        name: "object"
      },
      required: false,
      description: ""
    },
    pageProps: {
      type: {
        name: "object"
      },
      required: false,
      description: ""
    },
    readOnly: {
      type: {
        name: "bool"
      },
      required: false,
      description: ""
    },
    onAccessor: {
      type: {
        name: "func"
      },
      required: false,
      description: "Returns a value that will be used by the column to access the correct data of each row"
    },
    renderCell: {
      defaultValue: {
        value: "FieldColumn.defaultCell",
        computed: true
      },
      required: false
    },
    renderEditCell: {
      defaultValue: {
        value: "FieldColumn.editCell",
        computed: true
      },
      required: false
    },
    onFilter: {
      defaultValue: {
        value: "defaultColumnFilter",
        computed: true
      },
      required: false
    },
    columnProportion: {
      defaultValue: {
        value: "1",
        computed: false
      },
      required: false
    },
    fieldDatatype: {
      defaultValue: {
        value: "'string'",
        computed: false
      },
      required: false
    }
  },
  composes: ["../table/TableColumn"]
};
DefaultCell.__docgenInfo = {
  componentName: "DefaultCell",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "DefaultCell",
  methods: [],
  actualName: "DefaultCell"
};