import _defineProperty2 from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty2(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";

function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();

  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
        result;

    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;

      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }

    return _possibleConstructorReturn(this, result);
  };
}

function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;

  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button } from '@jutro/components';
import { intlMessageShape, intlToShape } from '@jutro/prop-types';
import styles from "./AsyncButtonLink.module.css";
import { withAsyncAction } from '../AsyncAction/AsyncAction';

var AsyncButtonLinkInternal = function (_Component) {
  _inherits(AsyncButtonLinkInternal, _Component);

  var _super = _createSuper(AsyncButtonLinkInternal);

  function AsyncButtonLinkInternal() {
    _classCallCheck(this, AsyncButtonLinkInternal);

    return _super.apply(this, arguments);
  }

  _createClass(AsyncButtonLinkInternal, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
          className = _this$props.className,
          onClick = _this$props.onClick,
          children = _this$props.children,
          innerRef = _this$props.innerRef,
          loading = _this$props.loading,
          onTrigger = _this$props.onTrigger,
          failTo = _this$props.failTo,
          props = _objectWithoutProperties(_this$props, ["className", "onClick", "children", "innerRef", "loading", "onTrigger", "failTo"]);

      var classes = cx(styles.asyncButtonLink, className);

      if (loading) {
        delete props.icon;
      }

      return React.createElement(Button, _extends({}, props, {
        onClick: onClick,
        ref: innerRef,
        className: classes
      }), children);
    }
  }]);

  return AsyncButtonLinkInternal;
}(Component);

_defineProperty(AsyncButtonLinkInternal, "propTypes", _objectSpread({}, Button.propTypes, {
  failTo: intlToShape,
  failToMessage: PropTypes.string,
  message: intlMessageShape,
  replace: PropTypes.bool,
  to: intlToShape,
  toMessage: PropTypes.string,
  onTrigger: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, intlMessageShape]),
  className: PropTypes.string,
  innerRef: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
}));

_defineProperty(AsyncButtonLinkInternal, "defaultProps", _objectSpread({}, Button.defaultProps));

_defineProperty(AsyncButtonLinkInternal, "displayName", 'AsyncButtonLink');

export var AsyncButtonLink = withAsyncAction(AsyncButtonLinkInternal);
AsyncButtonLinkInternal.__docgenInfo = {
  componentName: "AsyncButtonLink",
  packageName: "@jutro/router",
  description: "AsyncButtonLink is a Jutro component,\ndesigned following the principles of Link (React Router 4).\nAsyncButtonLink invokes the trigger and wait for promise to be resolved or rejected.\nMeanwhile the promise is in execution, the component updates its content within the message provided by property {message}\nCase promise has been resolved, AsyncButtonLink attempts to navigate to destination provided by property {to}\nCase promise has been rejected, AsyncButtonLink attempts to navigate to destination provided by property {failTo}",
  displayName: "AsyncButtonLink",
  methods: [],
  actualName: "AsyncButtonLinkInternal",
  metadataType: "action",
  props: {
    failTo: {
      type: {
        name: "custom",
        raw: "intlToShape"
      },
      required: false,
      description: "The destination path when promise is rejected; can be an object like <Link to>"
    },
    failToMessage: {
      type: {
        name: "string"
      },
      required: false,
      description: "The message shown when promise is rejected; shown if 'failTo' is not provided"
    },
    message: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "The message shown when executing trigger/promise"
    },
    replace: {
      type: {
        name: "bool"
      },
      required: false,
      description: "The replace prop will replace the current entry in the history stack"
    },
    to: {
      type: {
        name: "custom",
        raw: "intlToShape"
      },
      required: false,
      description: "The destination path when promise is resolved; can be an object like <Link to>"
    },
    toMessage: {
      type: {
        name: "string"
      },
      required: false,
      description: "The message shown when promise is resolved; shown if 'to' is not provided"
    },
    onTrigger: {
      type: {
        name: "func"
      },
      required: true,
      description: "The method used to trigger the promise"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: false,
      description: "The method to be called before (instead in case of event.preventDefault been called) built-in onClick handler"
    },
    children: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: false
              },
              defaultMessage: {
                name: "string",
                required: false
              },
              args: {
                name: "shape",
                value: {},
                required: false
              }
            }
          }]
        }]
      },
      required: false,
      description: "The children elements to render inside of the AsyncLink"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    innerRef: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "func"
        }]
      },
      required: false,
      description: "Get ref to the inner rendered <a> or <button>"
    }
  },
  composes: ["@jutro/components"]
};