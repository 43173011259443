import { DateTime } from 'luxon';
import isNil from "lodash/isNil";
import { DATA_TYPE_OBJECT } from '@jutro/prop-types';
import { parseDateShapeToDate, formatDateToDataType } from '@jutro/components';

var isDateObjectType = function isDateObjectType(dateShape) {
  return !isNil(dateShape) && !isNil(dateShape.year) && !isNil(dateShape.month) && !isNil(dateShape.day);
};

export var parseDateTimeZoneFromDataType = function parseDateTimeZoneFromDataType(value, defaultTimeZone, showTime) {
  var datetime = value.datetime,
      timezone = value.timezone;
  var timeZone = timezone || defaultTimeZone;
  if (isNil(datetime)) return {
    timezone: timeZone
  };

  if (!showTime) {
    var parsedDate = parseDateShapeToDate(datetime);
    return {
      datetime: {
        year: parsedDate.getFullYear(),
        month: parsedDate.getMonth(),
        day: parsedDate.getDate()
      },
      timezone: timeZone
    };
  }

  var result;

  if (isDateObjectType(datetime)) {
    var _year = datetime.year,
        _month = datetime.month,
        _day = datetime.day,
        _hour = datetime.hour,
        _minute = datetime.minute;
    result = DateTime.fromObject({
      year: _year,
      month: _month + 1,
      day: _day,
      hour: _hour,
      minute: _minute,
      zone: 'UTC'
    });
  } else {
    var date = new Date(datetime);
    result = DateTime.fromISO(date.toISOString(), {
      zone: 'UTC'
    });
  }

  var _result$setZone = result.setZone(timeZone),
      year = _result$setZone.year,
      month = _result$setZone.month,
      day = _result$setZone.day,
      hour = _result$setZone.hour,
      minute = _result$setZone.minute;

  return {
    datetime: {
      year: year,
      month: month - 1,
      day: day,
      hour: hour,
      minute: minute
    },
    timezone: timeZone
  };
};
export var formatDateTimeZoneToDataType = function formatDateTimeZoneToDataType(value, dataType, showTime) {
  var datetime = value.datetime,
      timezone = value.timezone;
  if (!datetime) return value;

  if (!showTime) {
    var date = new Date(datetime.year, datetime.month, datetime.day);
    return {
      datetime: formatDateToDataType(date, dataType),
      timezone: timezone
    };
  }

  var year = datetime.year,
      month = datetime.month,
      day = datetime.day,
      hour = datetime.hour,
      minute = datetime.minute;
  var dateTimeZone = DateTime.fromObject({
    year: year,
    month: month + 1,
    day: day,
    hour: hour,
    minute: minute,
    zone: timezone
  }).setZone('UTC');

  if (dataType === DATA_TYPE_OBJECT) {
    var _year2 = dateTimeZone.year,
        _month2 = dateTimeZone.month,
        _day2 = dateTimeZone.day,
        _hour2 = dateTimeZone.hour,
        _minute2 = dateTimeZone.minute;
    return {
      datetime: {
        year: _year2,
        month: _month2 - 1,
        day: _day2,
        hour: _hour2,
        minute: _minute2
      },
      timezone: timezone
    };
  }

  return {
    datetime: dateTimeZone.toISO(),
    timezone: timezone
  };
};