import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button } from '@jutro/components';
import { intlMessageShape } from '@jutro/prop-types';
import { DropdownMenu } from './DropdownMenu';
import styles from "./DropdownMenuButton.module.css";
export var DropdownMenuButton = function DropdownMenuButton(_ref) {
  var id = _ref.id,
      buttonText = _ref.buttonText,
      isOpenProp = _ref.isOpen,
      dropUp = _ref.dropUp,
      alignRight = _ref.alignRight,
      menuClassName = _ref.menuClassName,
      children = _ref.children,
      buttonProps = _objectWithoutProperties(_ref, ["id", "buttonText", "isOpen", "dropUp", "alignRight", "menuClassName", "children"]);

  var menuProps = {
    isOpen: isOpenProp,
    dropUp: dropUp,
    alignRight: alignRight,
    menuClassName: menuClassName
  };

  var handleRenderTrigger = function handleRenderTrigger(_ref2, toggleMenu) {
    var buttonId = _ref2.id,
        menuId = _ref2.menuId,
        isOpen = _ref2.isOpen,
        other = _objectWithoutProperties(_ref2, ["id", "menuId", "isOpen"]);

    var handleClick = function handleClick() {
      return toggleMenu(!isOpen);
    };

    return React.createElement(Button, _extends({
      id: buttonId
    }, other, buttonProps, {
      onClick: handleClick,
      "aria-controls": menuId,
      "aria-haspopup": "true"
    }), buttonText);
  };

  var classes = cx(_defineProperty({}, styles.fullWidth, buttonProps && buttonProps.fullWidth));
  return React.createElement(DropdownMenu, _extends({
    id: id,
    className: classes
  }, menuProps, {
    onRenderTrigger: handleRenderTrigger
  }), children);
};
DropdownMenuButton.propTypes = {
  id: PropTypes.string.isRequired,
  buttonText: PropTypes.oneOfType([PropTypes.node, intlMessageShape]),
  isOpen: PropTypes.bool,
  dropUp: PropTypes.bool,
  alignRight: PropTypes.bool,
  menuClassName: PropTypes.string,
  children: PropTypes.node
};
DropdownMenuButton.__docgenInfo = {
  componentName: "DropdownMenuButton",
  packageName: "@jutro/router",
  description: "DropdownMenuButton",
  displayName: "DropdownMenuButton",
  methods: [],
  actualName: "DropdownMenuButton",
  metadataType: "action",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Used to identify menu component."
    },
    buttonText: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: false
              },
              defaultMessage: {
                name: "string",
                required: false
              },
              args: {
                name: "shape",
                value: {},
                required: false
              }
            }
          }]
        }]
      },
      required: false,
      description: "Content to be rendered as the 'children' for the 'Button' component."
    },
    isOpen: {
      type: {
        name: "bool"
      },
      required: false,
      description: "The prop that indicates if the menu is currently visible."
    },
    dropUp: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If `true`, the menu appears above the trigger component."
    },
    alignRight: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If `true`, menu items are aligned to the right edge of the menu."
    },
    menuClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Override class for the inner menu of the dropdown menu"
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "The component children wrapped by the dropdown menu button component."
    }
  }
};