import _defineProperty2 from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

var _cardFactoryMap;

import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";

function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();

  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
        result;

    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;

      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }

    return _possibleConstructorReturn(this, result);
  };
}

function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;

  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}

import React, { Component } from 'react';
import cx from 'classnames';
import { uniqueInnerId } from '@jutro/platform';
import PropTypes from 'prop-types';
import { intlMessageShape } from '@jutro/prop-types';
import { getKeyPressHandler } from '../../../accessibility/getKeyPressHandler';
import { Icon } from '../../Icon/Icon';
import { Money } from '../../values/Money';
export var PORTRAIT_LAYOUT = 'portrait';
export var LANDSCAPE_LAYOUT = 'landscape';
export var LAYOUT_PROP_TYPE = PropTypes.oneOf([PORTRAIT_LAYOUT, LANDSCAPE_LAYOUT]);

var renderCardIcon = function renderCardIcon(props) {
  var styles = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var iconName = props.iconName,
      iconSrc = props.iconSrc,
      iconContainerClasses = props.iconContainerClasses,
      iconContentClasses = props.iconContentClasses;
  return React.createElement("div", {
    className: iconContainerClasses
  }, iconSrc ? React.createElement("img", {
    src: iconSrc,
    alt: iconName,
    className: cx(styles.imageIcon, iconContentClasses)
  }) : React.createElement(Icon, {
    icon: iconName,
    className: iconContentClasses
  }));
};

var cardFactoryMap = (_cardFactoryMap = {}, _defineProperty2(_cardFactoryMap, PORTRAIT_LAYOUT, {
  renderContent: function renderContent(radioButton, _ref2, _ref3, _ref4) {
    var name = _ref2.name,
        moneyAmount = _ref2.moneyAmount,
        secondaryLabel = _ref2.secondaryLabel,
        iconName = _ref2.iconName,
        currency = _ref2.currency,
        iconSrc = _ref2.iconSrc;
    var styles = _ref3.styles,
        iconContentClasses = _ref3.iconContentClasses,
        iconContainerClasses = _ref3.iconContainerClasses;
    var nameId = _ref4.nameId,
        secondaryLabelId = _ref4.secondaryLabelId,
        moneyId = _ref4.moneyId;
    return React.createElement("div", {
      className: styles.cardWrapperPortrait
    }, renderCardIcon({
      iconName: iconName,
      iconSrc: iconSrc,
      iconContainerClasses: iconContainerClasses,
      iconContentClasses: iconContentClasses
    }, styles), React.createElement(Money, {
      id: moneyId,
      amount: moneyAmount,
      currency: currency,
      showFractions: true,
      className: styles.moneyAmount
    }), React.createElement("div", {
      id: nameId,
      className: styles.namePortrait
    }, name), React.createElement("div", {
      id: secondaryLabelId,
      className: styles.secondaryLabelPortrait
    }, secondaryLabel), radioButton);
  },
  getIconContentClasses: function getIconContentClasses(styles) {
    return cx(styles.iconContentPortrait);
  },
  getIconWrapperClasses: function getIconWrapperClasses(styles, checked) {
    return cx(styles.radioWrapper, styles.radioWrapperPortrait, _defineProperty2({}, styles.radioWrapperChecked, checked));
  },
  getCardClasses: function getCardClasses(styles, checked, disabled, _ref5) {
    var focused = _ref5.focused,
        active = _ref5.active;
    return cx(styles.cardPortrait, _defineProperty2({}, styles.cardChecked, checked), _defineProperty2({}, styles.disabled, disabled), _defineProperty2({}, styles.cardFocused, focused), _defineProperty2({}, styles.cardActive, active));
  }
}), _defineProperty2(_cardFactoryMap, LANDSCAPE_LAYOUT, {
  renderContent: function renderContent(radioButton, _ref6, _ref7, _ref8) {
    var name = _ref6.name,
        moneyAmount = _ref6.moneyAmount,
        secondaryLabel = _ref6.secondaryLabel,
        iconName = _ref6.iconName,
        currency = _ref6.currency,
        iconSrc = _ref6.iconSrc;
    var styles = _ref7.styles,
        iconContentClasses = _ref7.iconContentClasses,
        iconContainerClasses = _ref7.iconContainerClasses;
    var nameId = _ref8.nameId,
        secondaryLabelId = _ref8.secondaryLabelId;
    return React.createElement("div", {
      className: styles.cardWrapperLandscape
    }, radioButton, renderCardIcon({
      iconName: iconName,
      iconSrc: iconSrc,
      iconContainerClasses: iconContainerClasses,
      iconContentClasses: iconContentClasses
    }, styles), React.createElement("div", {
      className: styles.contentWrapperLandscape
    }, React.createElement(Money, {
      amount: moneyAmount,
      currency: currency,
      showFractions: true,
      className: styles.moneyAmount
    }), React.createElement("div", {
      id: nameId,
      className: styles.nameLandscape
    }, name), React.createElement("div", {
      id: secondaryLabelId,
      className: styles.secondaryLabelLandscape
    }, secondaryLabel)));
  },
  getIconContentClasses: function getIconContentClasses(styles) {
    return cx(styles.iconContentLandscape);
  },
  getIconWrapperClasses: function getIconWrapperClasses(styles, checked) {
    return cx(styles.radioWrapper, styles.radioWrapperLandscape, _defineProperty2({}, styles.radioWrapperChecked, checked));
  },
  getCardClasses: function getCardClasses(styles, checked, disabled, _ref9) {
    var focused = _ref9.focused,
        active = _ref9.active;
    return cx(styles.cardLandscape, _defineProperty2({}, styles.cardChecked, checked), _defineProperty2({}, styles.disabled, disabled), _defineProperty2({}, styles.cardFocused, focused), _defineProperty2({}, styles.cardActive, active));
  }
}), _cardFactoryMap);
export var RadioButtonCard = function (_Component) {
  _inherits(RadioButtonCard, _Component);

  var _super = _createSuper(RadioButtonCard);

  function RadioButtonCard(props) {
    var _this;

    _classCallCheck(this, RadioButtonCard);

    _this = _super.call(this, props);

    _defineProperty(_assertThisInitialized(_this), "state", {
      focused: false
    });

    _defineProperty(_assertThisInitialized(_this), "handleClick", function () {
      var _this$props = _this.props,
          disabled = _this$props.disabled,
          code = _this$props.code,
          onValueSelect = _this$props.onValueSelect;

      if (disabled) {
        return;
      }

      _this.radioRef.current.focus();

      onValueSelect(code);
    });

    _defineProperty(_assertThisInitialized(_this), "handleKeyPress", getKeyPressHandler(_this.handleClick));

    _defineProperty(_assertThisInitialized(_this), "handleBlur", function () {
      _this.setState({
        focused: false
      });
    });

    _defineProperty(_assertThisInitialized(_this), "activateCard", function (e) {
      if (e.key !== ' ') {
        return;
      }

      _this.setActive(true);
    });

    _defineProperty(_assertThisInitialized(_this), "deactivateCard", function (e) {
      if (e.key !== ' ') {
        return;
      }

      _this.setActive(false);
    });

    _this.radioRef = React.createRef();
    return _this;
  }

  _createClass(RadioButtonCard, [{
    key: "setActive",
    value: function setActive(active) {
      this.setState({
        active: active
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props2 = this.props,
          id = _this$props2.id,
          code = _this$props2.code,
          name = _this$props2.name,
          iconName = _this$props2.iconName,
          secondaryLabel = _this$props2.secondaryLabel,
          moneyAmount = _this$props2.moneyAmount,
          currency = _this$props2.currency,
          disabled = _this$props2.disabled,
          checked = _this$props2.checked,
          onValueSelect = _this$props2.onValueSelect,
          layout = _this$props2.layout,
          styles = _this$props2.styles,
          iconSrc = _this$props2.iconSrc,
          iconClassName = _this$props2.iconClassName,
          tabIndex = _this$props2.tabIndex,
          iconContainerClassName = _this$props2.iconContainerClassName,
          other = _objectWithoutProperties(_this$props2, ["id", "code", "name", "iconName", "secondaryLabel", "moneyAmount", "currency", "disabled", "checked", "onValueSelect", "layout", "styles", "iconSrc", "iconClassName", "tabIndex", "iconContainerClassName"]);

      var _ref = cardFactoryMap[layout] || cardFactoryMap[PORTRAIT_LAYOUT],
          getIconWrapperClasses = _ref.getIconWrapperClasses,
          getCardClasses = _ref.getCardClasses,
          renderContent = _ref.renderContent,
          getIconContentClasses = _ref.getIconContentClasses;

      var currentTabIndex = disabled ? undefined : tabIndex;
      var iconWrapperClasses = getIconWrapperClasses(styles, checked);
      var cardClasses = getCardClasses(styles, checked, disabled, this.state);
      var iconContentClasses = cx(getIconContentClasses(styles), iconClassName);

      var _uniqueInnerId = uniqueInnerId(id, 'valueId', 'nameId', 'secondaryLabelId', 'moneyId'),
          valueId = _uniqueInnerId.valueId,
          nameId = _uniqueInnerId.nameId,
          secondaryLabelId = _uniqueInnerId.secondaryLabelId,
          moneyId = _uniqueInnerId.moneyId;

      var radioButton = React.createElement("div", {
        className: iconWrapperClasses,
        ref: this.radioRef,
        tabIndex: currentTabIndex,
        "aria-disabled": disabled,
        "aria-checked": checked
      }, React.createElement("input", _extends({
        type: "radio",
        id: valueId,
        className: styles.radioInput,
        value: code,
        checked: checked,
        readOnly: true,
        disabled: disabled
      }, other)));
      return React.createElement("div", {
        key: valueId,
        "aria-disabled": disabled,
        className: cardClasses,
        "data-value": code,
        role: "radio",
        "aria-checked": checked,
        "aria-labelledby": "".concat(nameId, " ").concat(secondaryLabelId, " ").concat(moneyId),
        onClick: this.handleClick,
        onKeyPress: this.handleKeyPress,
        onKeyDown: this.activateCard,
        onKeyUp: this.deactivateCard,
        onBlur: this.handleBlur
      }, renderContent(radioButton, {
        name: name,
        secondaryLabel: secondaryLabel,
        moneyAmount: moneyAmount,
        iconName: iconName,
        currency: currency,
        iconSrc: iconSrc
      }, {
        styles: styles,
        iconContentClasses: iconContentClasses,
        iconContainerClasses: iconContainerClassName
      }, {
        nameId: nameId,
        secondaryLabelId: secondaryLabelId,
        moneyId: moneyId
      }));
    }
  }]);

  return RadioButtonCard;
}(Component);

_defineProperty(RadioButtonCard, "propTypes", {
  code: intlMessageShape.isRequired,
  name: intlMessageShape.isRequired,
  secondaryLabel: intlMessageShape.isRequired,
  moneyAmount: PropTypes.number,
  tabIndex: PropTypes.number,
  currency: PropTypes.string,
  layout: LAYOUT_PROP_TYPE
});

_defineProperty(RadioButtonCard, "defaultProps", {
  layout: PORTRAIT_LAYOUT
});

renderCardIcon.__docgenInfo = {
  componentName: "renderCardIcon",
  packageName: "@jutro/components",
  description: "",
  displayName: "renderCardIcon",
  methods: [],
  actualName: "renderCardIcon"
};
RadioButtonCard.__docgenInfo = {
  componentName: "RadioButtonCard",
  packageName: "@jutro/components",
  description: "Renders a radio button displayed as a card.",
  displayName: "RadioButtonCard",
  methods: [{
    name: "handleClick",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "handleBlur",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "setActive",
    docblock: null,
    modifiers: [],
    params: [{
      name: "active",
      optional: false,
      type: null
    }],
    returns: null
  }, {
    name: "activateCard",
    docblock: null,
    modifiers: [],
    params: [{
      name: "e",
      optional: false,
      type: null
    }],
    returns: null
  }, {
    name: "deactivateCard",
    docblock: null,
    modifiers: [],
    params: [{
      name: "e",
      optional: false,
      type: null
    }],
    returns: null
  }],
  actualName: "RadioButtonCard",
  props: {
    code: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: true,
      description: "Radio button code."
    },
    name: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: true,
      description: "Name displayed below the radio button."
    },
    secondaryLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: true,
      description: "Secondary label displayed below the name."
    },
    moneyAmount: {
      type: {
        name: "number"
      },
      required: false,
      description: "Money amount"
    },
    tabIndex: {
      type: {
        name: "number"
      },
      required: false,
      description: "TabIndex of the Radio Button"
    },
    currency: {
      type: {
        name: "string"
      },
      required: false,
      description: "Currency code"
    },
    layout: {
      type: {
        name: "custom",
        raw: "LAYOUT_PROP_TYPE"
      },
      required: false,
      description: "Cards layout",
      defaultValue: {
        value: "'portrait'",
        computed: false
      }
    }
  }
};