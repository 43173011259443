import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import React, { useState } from 'react';
import { warning } from '@jutro/logger';
import { intlMessageShape } from '@jutro/prop-types';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import styles from "./IFrame.module.css";
import { Loader } from '../loading/loader/Loader';
var aspectRatios = ['4/3', '16/9', '17/9', '2/1'];

var aspectRatioStyle = _reduceInstanceProperty(aspectRatios).call(aspectRatios, function (reduced, ratio) {
  var suffix = _.replace(ratio, '/', 'x');

  reduced[ratio] = "aspect-ratio-".concat(suffix);
  return reduced;
}, {});

var validateHeight = function validateHeight(height) {
  var validHeightExp = new RegExp('^[0-9]+(.[0-9]+)?(px|%)$');
  var isValid = validHeightExp.test(height);

  if (!isValid) {
    warning("Height prop : ".concat(height, " is not valid. Please provide a number suffixed with px or %"));
  }

  return isValid;
};

var iframePropTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  title: intlMessageShape.isRequired,
  aspectRatio: PropTypes.oneOf(aspectRatios),
  height: PropTypes.string,
  className: PropTypes.string,
  onLoad: PropTypes.func,
  showLoader: PropTypes.bool,
  noBorder: PropTypes.bool
};
export var IFrame = function IFrame(_ref) {
  var id = _ref.id,
      src = _ref.src,
      height = _ref.height,
      title = _ref.title,
      aspectRatio = _ref.aspectRatio,
      className = _ref.className,
      loaderClassName = _ref.loaderClassName,
      onLoad = _ref.onLoad,
      showLoader = _ref.showLoader,
      noBorder = _ref.noBorder,
      rest = _objectWithoutProperties(_ref, ["id", "src", "height", "title", "aspectRatio", "className", "loaderClassName", "onLoad", "showLoader", "noBorder"]);

  var _useState = useState(showLoader),
      _useState2 = _slicedToArray(_useState, 2),
      iframeLoading = _useState2[0],
      setIframeLoading = _useState2[1];

  var _useState3 = useState(src),
      _useState4 = _slicedToArray(_useState3, 2),
      prevSrc = _useState4[0],
      setPrevSrc = _useState4[1];

  if (src !== prevSrc) {
    setPrevSrc(src);

    if (showLoader) {
      setIframeLoading(true);
    }
  }

  var aspectRatioClass = styles[aspectRatioStyle[aspectRatio]];
  var containerClasses = aspectRatioClass ? cx(styles.iframeContainer, aspectRatioClass) : styles.iframeContainer;
  var iframeClasses = cx(styles.iframe, _defineProperty({}, styles.noBorder, noBorder), className);

  var onLoadHandler = function onLoadHandler() {
    if (onLoad) {
      onLoad();
    }

    if (showLoader) {
      setIframeLoading(false);
    }
  };

  var frameContainerStyle = {};

  if (!aspectRatio || !_includesInstanceProperty(aspectRatios).call(aspectRatios, aspectRatio)) {
    if (!validateHeight(height)) {
      height = IFrame.defaultProps.height;
    }

    frameContainerStyle = {
      paddingBottom: height
    };
  }

  return React.createElement("div", {
    className: containerClasses,
    style: frameContainerStyle
  }, iframeLoading && React.createElement(Loader, {
    className: cx(styles.loader, loaderClassName)
  }), React.createElement("iframe", _extends({
    id: id,
    src: src,
    title: title,
    onLoad: onLoadHandler,
    className: iframeClasses
  }, rest)));
};
IFrame.propTypes = iframePropTypes;
IFrame.defaultProps = {
  height: '50%',
  showLoader: true
};
IFrame.__docgenInfo = {
  componentName: "IFrame",
  packageName: "@jutro/components",
  description: "IFrame",
  displayName: "IFrame",
  methods: [],
  actualName: "IFrame",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "unique ID of iframe container"
    },
    src: {
      type: {
        name: "string"
      },
      required: true,
      description: "src for iframe"
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: true,
      description: "title for iframe"
    },
    aspectRatio: {
      type: {
        name: "enum",
        value: [{
          value: "'4/3'",
          computed: false
        }, {
          value: "'16/9'",
          computed: false
        }, {
          value: "'17/9'",
          computed: false
        }, {
          value: "'2/1'",
          computed: false
        }]
      },
      required: false,
      description: "aspectRatio for iframe"
    },
    height: {
      type: {
        name: "string"
      },
      required: false,
      description: "height for iframe container in pixel or percentage format",
      defaultValue: {
        value: "'50%'",
        computed: false
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "className for iframe"
    },
    onLoad: {
      type: {
        name: "func"
      },
      required: false,
      description: "function for onload event"
    },
    showLoader: {
      type: {
        name: "bool"
      },
      required: false,
      description: "show loader when iframe still loading",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    noBorder: {
      type: {
        name: "bool"
      },
      required: false,
      description: "hide the iframe border"
    }
  }
};