import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';
import { useBreakpoint } from '@jutro/layout';
var responsiveElementPropTypes = {
  tag: PropTypes.string.isRequired,
  parentTag: PropTypes.string,
  children: PropTypes.node
};
export var ResponsiveElement = function ResponsiveElement(props) {
  var _useBreakpoint = useBreakpoint(props),
      breakpointProps = _useBreakpoint.breakpointProps;

  var Tag = breakpointProps.tag,
      ParentTag = breakpointProps.parentTag,
      tablet = breakpointProps.tablet,
      phoneWide = breakpointProps.phoneWide,
      phone = breakpointProps.phone,
      content = breakpointProps.content,
      children = breakpointProps.children,
      dangerouslySetInnerHTML = breakpointProps.dangerouslySetInnerHTML,
      others = _objectWithoutProperties(breakpointProps, ["tag", "parentTag", "tablet", "phoneWide", "phone", "content", "children", "dangerouslySetInnerHTML"]);

  if (ParentTag) {
    return React.createElement(ParentTag, _extends({
      tag: Tag
    }, others), content || children);
  }

  return React.createElement(Tag, others, content || children);
};
ResponsiveElement.propTypes = responsiveElementPropTypes;
ResponsiveElement.__docgenInfo = {
  componentName: "ResponsiveElement",
  packageName: "@jutro/components",
  description: "ResponsiveElement",
  displayName: "ResponsiveElement",
  methods: [],
  actualName: "ResponsiveElement",
  metadataType: "element",
  props: {
    tag: {
      type: {
        name: "string"
      },
      required: true,
      description: "The html tag to use when rendering the translated message"
    },
    parentTag: {
      type: {
        name: "string"
      },
      required: false,
      description: "The html tag to use when rendering the translated message"
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: ""
    }
  }
};