import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import range from "lodash/range";
var languageRE = /^([a-z]{2,3})([-_]|$)/;
var regionRE = /[-_]([A-Z]{2})([-_]|$)/;

var getLanguageSubtag = function getLanguageSubtag(locale) {
  var caseInsensitiveLocale = locale.toLowerCase();
  var r = languageRE.exec(caseInsensitiveLocale);
  return r ? r[1] : undefined;
};

function getRegionSubtag(locale) {
  var caseInsensitiveLocale = locale.toUpperCase();
  var r = regionRE.exec(caseInsensitiveLocale);
  return r ? r[1] : undefined;
}

export var getCountryCodeFromLocale = function getCountryCodeFromLocale(locale) {
  return getRegionSubtag(locale);
};
export var getLanguageFromLocale = function getLanguageFromLocale(locale) {
  return getLanguageSubtag(locale);
};
export var getBrowserLocale = function getBrowserLocale() {
  return window.navigator.userLanguage || window.navigator.language;
};
export var normalizeToUnderscoreLocale = function normalizeToUnderscoreLocale(locale) {
  return locale.replace('-', '_');
};
export var isIntlShape = function isIntlShape(content) {
  return content && content.id !== undefined && content.defaultMessage !== undefined;
};
export var getFormattedMonthsForLocale = function getFormattedMonthsForLocale(locale) {
  var _context;

  return _mapInstanceProperty(_context = range(12)).call(_context, function (month) {
    return locale.localize.month(month);
  });
};