import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";

function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();

  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
        result;

    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;

      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }

    return _possibleConstructorReturn(this, result);
  };
}

function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;

  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { formatShortDate, formatLongDate, getBrowserLocale } from '@jutro/locale';
import { logDeprecationMessage } from '@jutro/platform';
export var LocalizedDateRange = function (_PureComponent) {
  _inherits(LocalizedDateRange, _PureComponent);

  var _super = _createSuper(LocalizedDateRange);

  function LocalizedDateRange() {
    _classCallCheck(this, LocalizedDateRange);

    return _super.apply(this, arguments);
  }

  _createClass(LocalizedDateRange, [{
    key: "formatDate",
    value: function formatDate(date) {
      var _this$props = this.props,
          format = _this$props.format,
          locale = _this$props.locale;
      return format === 'short' ? formatShortDate(date, locale) : formatLongDate(date, locale);
    }
  }, {
    key: "render",
    value: function render() {
      logDeprecationMessage('LocalizedDateRange', 'FormattedDateRange');
      var _this$props2 = this.props,
          className = _this$props2.className,
          end = _this$props2.end,
          start = _this$props2.start,
          separator = _this$props2.separator,
          tag = _this$props2.tag;
      var Tag = tag;
      return React.createElement(Tag, {
        className: className
      }, this.formatDate(start), separator, this.formatDate(end));
    }
  }]);

  return LocalizedDateRange;
}(PureComponent);

_defineProperty(LocalizedDateRange, "propTypes", {
  start: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,
  format: PropTypes.oneOf(['short', 'long']),
  separator: PropTypes.string,
  locale: PropTypes.string,
  tag: PropTypes.string,
  className: PropTypes.string
});

_defineProperty(LocalizedDateRange, "defaultProps", {
  separator: ' - ',
  locale: getBrowserLocale(),
  format: 'short',
  tag: 'span'
});

LocalizedDateRange.__docgenInfo = {
  componentName: "LocalizedDateRange",
  packageName: "@jutro/components",
  description: "The `LocalizedDateRange` component is used to render `Date` values\nlocalized and in specific preset formats, e.g. short or long.",
  displayName: "LocalizedDateRange",
  methods: [{
    name: "formatDate",
    docblock: "Formats the date using the `format` property.\n@param {Date} date - The date to format\n@returns {string} - The formatted date",
    modifiers: [],
    params: [{
      name: "date",
      description: "The date to format",
      type: {
        name: "Date"
      },
      optional: false
    }],
    returns: {
      description: "The formatted date",
      type: {
        name: "string"
      }
    },
    description: "Formats the date using the `format` property."
  }],
  actualName: "LocalizedDateRange",
  metadataType: "element",
  props: {
    start: {
      type: {
        name: "instanceOf",
        value: "Date"
      },
      required: true,
      description: "The date range start"
    },
    end: {
      type: {
        name: "instanceOf",
        value: "Date"
      },
      required: true,
      description: "The date range end"
    },
    format: {
      type: {
        name: "enum",
        value: [{
          value: "'short'",
          computed: false
        }, {
          value: "'long'",
          computed: false
        }]
      },
      required: false,
      description: "The date format: 'short' or 'long'",
      defaultValue: {
        value: "'short'",
        computed: false
      }
    },
    separator: {
      type: {
        name: "string"
      },
      required: false,
      description: "The range separator (between start and end)",
      defaultValue: {
        value: "' - '",
        computed: false
      }
    },
    locale: {
      type: {
        name: "string"
      },
      required: false,
      description: "The locale to use for formatting (default is browser)",
      defaultValue: {
        value: "getBrowserLocale()",
        computed: true
      }
    },
    tag: {
      type: {
        name: "string"
      },
      required: false,
      description: "The html tag to use when rendering the outermost element of this component",
      defaultValue: {
        value: "'span'",
        computed: false
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    }
  }
};