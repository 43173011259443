import { defineMessages } from 'react-intl';
export var messages = defineMessages({
  'Africa/Abidjan': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Abidjan',
    defaultMessage: 'Africa/Abidjan'
  },
  'Africa/Accra': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Accra',
    defaultMessage: 'Africa/Accra'
  },
  'Africa/Addis_Ababa': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Addis_Ababa',
    defaultMessage: 'Africa/Addis Ababa'
  },
  'Africa/Algiers': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Algiers',
    defaultMessage: 'Africa/Algiers'
  },
  'Africa/Asmara': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Asmara',
    defaultMessage: 'Africa/Asmara'
  },
  'Africa/Bamako': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Bamako',
    defaultMessage: 'Africa/Bamako'
  },
  'Africa/Bangui': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Bangui',
    defaultMessage: 'Africa/Bangui'
  },
  'Africa/Banjul': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Banjul',
    defaultMessage: 'Africa/Banjul'
  },
  'Africa/Bissau': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Bissau',
    defaultMessage: 'Africa/Bissau'
  },
  'Africa/Blantyre': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Blantyre',
    defaultMessage: 'Africa/Blantyre'
  },
  'Africa/Brazzaville': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Brazzaville',
    defaultMessage: 'Africa/Brazzaville'
  },
  'Africa/Bujumbura': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Bujumbura',
    defaultMessage: 'Africa/Bujumbura'
  },
  'Africa/Cairo': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Cairo',
    defaultMessage: 'Africa/Cairo'
  },
  'Africa/Casablanca': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Casablanca',
    defaultMessage: 'Africa/Casablanca'
  },
  'Africa/Conakry': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Conakry',
    defaultMessage: 'Africa/Conakry'
  },
  'Africa/Dakar': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Dakar',
    defaultMessage: 'Africa/Dakar'
  },
  'Africa/Dar_es_Salaam': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Dar_es_Salaam',
    defaultMessage: 'Africa/Dar es Salaam'
  },
  'Africa/Djibouti': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Djibouti',
    defaultMessage: 'Africa/Djibouti'
  },
  'Africa/Douala': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Douala',
    defaultMessage: 'Africa/Douala'
  },
  'Africa/El_Aaiun': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/El_Aaiun',
    defaultMessage: 'Africa/El Aaiun'
  },
  'Africa/Freetown': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Freetown',
    defaultMessage: 'Africa/Freetown'
  },
  'Africa/Gaborone': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Gaborone',
    defaultMessage: 'Africa/Gaborone'
  },
  'Africa/Harare': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Harare',
    defaultMessage: 'Africa/Harare'
  },
  'Africa/Johannesburg': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Johannesburg',
    defaultMessage: 'Africa/Johannesburg'
  },
  'Africa/Juba': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Juba',
    defaultMessage: 'Africa/Juba'
  },
  'Africa/Kampala': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Kampala',
    defaultMessage: 'Africa/Kampala'
  },
  'Africa/Khartoum': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Khartoum',
    defaultMessage: 'Africa/Khartoum'
  },
  'Africa/Kigali': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Kigali',
    defaultMessage: 'Africa/Kigali'
  },
  'Africa/Kinshasa': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Kinshasa',
    defaultMessage: 'Africa/Kinshasa'
  },
  'Africa/Lagos': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Lagos',
    defaultMessage: 'Africa/Lagos'
  },
  'Africa/Libreville': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Libreville',
    defaultMessage: 'Africa/Libreville'
  },
  'Africa/Lome': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Lome',
    defaultMessage: 'Africa/Lome'
  },
  'Africa/Luanda': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Luanda',
    defaultMessage: 'Africa/Luanda'
  },
  'Africa/Lubumbashi': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Lubumbashi',
    defaultMessage: 'Africa/Lubumbashi'
  },
  'Africa/Lusaka': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Lusaka',
    defaultMessage: 'Africa/Lusaka'
  },
  'Africa/Malabo': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Malabo',
    defaultMessage: 'Africa/Malabo'
  },
  'Africa/Maputo': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Maputo',
    defaultMessage: 'Africa/Maputo'
  },
  'Africa/Maseru': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Maseru',
    defaultMessage: 'Africa/Maseru'
  },
  'Africa/Mbabane': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Mbabane',
    defaultMessage: 'Africa/Mbabane'
  },
  'Africa/Mogadishu': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Mogadishu',
    defaultMessage: 'Africa/Mogadishu'
  },
  'Africa/Monrovia': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Monrovia',
    defaultMessage: 'Africa/Monrovia'
  },
  'Africa/Nairobi': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Nairobi',
    defaultMessage: 'Africa/Nairobi'
  },
  'Africa/Ndjamena': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Ndjamena',
    defaultMessage: 'Africa/Ndjamena'
  },
  'Africa/Niamey': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Niamey',
    defaultMessage: 'Africa/Niamey'
  },
  'Africa/Nouakchott': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Nouakchott',
    defaultMessage: 'Africa/Nouakchott'
  },
  'Africa/Ouagadougou': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Ouagadougou',
    defaultMessage: 'Africa/Ouagadougou'
  },
  'Africa/Porto-Novo': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Porto-Novo',
    defaultMessage: 'Africa/Porto-Novo'
  },
  'Africa/Sao_Tome': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Sao_Tome',
    defaultMessage: 'Africa/Sao Tome'
  },
  'Africa/Tripoli': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Tripoli',
    defaultMessage: 'Africa/Tripoli'
  },
  'Africa/Tunis': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Tunis',
    defaultMessage: 'Africa/Tunis'
  },
  'Africa/Windhoek': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Africa/Windhoek',
    defaultMessage: 'Africa/Windhoek'
  },
  'America/Adak': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Adak',
    defaultMessage: 'America/Adak'
  },
  'America/Anchorage': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Anchorage',
    defaultMessage: 'America/Anchorage'
  },
  'America/Anguilla': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Anguilla',
    defaultMessage: 'America/Anguilla'
  },
  'America/Antigua': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Antigua',
    defaultMessage: 'America/Antigua'
  },
  'America/Argentina/Buenos_Aires': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Argentina/Buenos_Aires',
    defaultMessage: 'America/Argentina/Buenos Aires'
  },
  'America/Aruba': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Aruba',
    defaultMessage: 'America/Aruba'
  },
  'America/Asuncion': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Asuncion',
    defaultMessage: 'America/Asuncion'
  },
  'America/Atikokan': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Atikokan',
    defaultMessage: 'America/Atikokan'
  },
  'America/Barbados': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Barbados',
    defaultMessage: 'America/Barbados'
  },
  'America/Belize': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Belize',
    defaultMessage: 'America/Belize'
  },
  'America/Blanc-Sablon': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Blanc-Sablon',
    defaultMessage: 'America/Blanc-Sablon'
  },
  'America/Bogota': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Bogota',
    defaultMessage: 'America/Bogota'
  },
  'America/Cancun': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Cancun',
    defaultMessage: 'America/Cancun'
  },
  'America/Caracas': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Caracas',
    defaultMessage: 'America/Caracas'
  },
  'America/Cayenne': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Cayenne',
    defaultMessage: 'America/Cayenne'
  },
  'America/Cayman': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Cayman',
    defaultMessage: 'America/Cayman'
  },
  'America/Chicago': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Chicago',
    defaultMessage: 'America/Chicago'
  },
  'America/Costa_Rica': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Costa_Rica',
    defaultMessage: 'America/Costa Rica'
  },
  'America/Curacao': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Curacao',
    defaultMessage: 'America/Curacao'
  },
  'America/Danmarkshavn': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Danmarkshavn',
    defaultMessage: 'America/Danmarkshavn'
  },
  'America/Dawson_Creek': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Dawson_Creek',
    defaultMessage: 'America/Dawson Creek'
  },
  'America/Denver': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Denver',
    defaultMessage: 'America/Denver'
  },
  'America/Dominica': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Dominica',
    defaultMessage: 'America/Dominica'
  },
  'America/Edmonton': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Edmonton',
    defaultMessage: 'America/Edmonton'
  },
  'America/El_Salvador': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/El_Salvador',
    defaultMessage: 'America/El Salvador'
  },
  'America/Godthab': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Godthab',
    defaultMessage: 'America/Godthab'
  },
  'America/Grand_Turk': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Grand_Turk',
    defaultMessage: 'America/Grand Turk'
  },
  'America/Grenada': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Grenada',
    defaultMessage: 'America/Grenada'
  },
  'America/Guadeloupe': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Guadeloupe',
    defaultMessage: 'America/Guadeloupe'
  },
  'America/Guatemala': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Guatemala',
    defaultMessage: 'America/Guatemala'
  },
  'America/Guayaquil': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Guayaquil',
    defaultMessage: 'America/Guayaquil'
  },
  'America/Guyana': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Guyana',
    defaultMessage: 'America/Guyana'
  },
  'America/Halifax': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Halifax',
    defaultMessage: 'America/Halifax'
  },
  'America/Havana': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Havana',
    defaultMessage: 'America/Havana'
  },
  'America/Hermosillo': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Hermosillo',
    defaultMessage: 'America/Hermosillo'
  },
  'America/Jamaica': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Jamaica',
    defaultMessage: 'America/Jamaica'
  },
  'America/Kralendijk': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Kralendijk',
    defaultMessage: 'America/Kralendijk'
  },
  'America/La_Paz': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/La_Paz',
    defaultMessage: 'America/La Paz'
  },
  'America/Lima': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Lima',
    defaultMessage: 'America/Lima'
  },
  'America/Los_Angeles': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Los_Angeles',
    defaultMessage: 'America/Los Angeles'
  },
  'America/Lower_Princes': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Lower_Princes',
    defaultMessage: 'America/Lower Princes'
  },
  'America/Managua': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Managua',
    defaultMessage: 'America/Managua'
  },
  'America/Manaus': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Manaus',
    defaultMessage: 'America/Manaus'
  },
  'America/Marigot': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Marigot',
    defaultMessage: 'America/Marigot'
  },
  'America/Martinique': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Martinique',
    defaultMessage: 'America/Martinique'
  },
  'America/Mexico_City': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Mexico_City',
    defaultMessage: 'America/Mexico City'
  },
  'America/Miquelon': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Miquelon',
    defaultMessage: 'America/Miquelon'
  },
  'America/Montevideo': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Montevideo',
    defaultMessage: 'America/Montevideo'
  },
  'America/Montserrat': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Montserrat',
    defaultMessage: 'America/Montserrat'
  },
  'America/Nassau': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Nassau',
    defaultMessage: 'America/Nassau'
  },
  'America/New_York': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/New_York',
    defaultMessage: 'America/New York'
  },
  'America/Noronha': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Noronha',
    defaultMessage: 'America/Noronha'
  },
  'America/Ojinaga': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Ojinaga',
    defaultMessage: 'America/Ojinaga'
  },
  'America/Panama': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Panama',
    defaultMessage: 'America/Panama'
  },
  'America/Paramaribo': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Paramaribo',
    defaultMessage: 'America/Paramaribo'
  },
  'America/Phoenix': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Phoenix',
    defaultMessage: 'America/Phoenix'
  },
  'America/Port-au-Prince': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Port-au-Prince',
    defaultMessage: 'America/Port-au-Prince'
  },
  'America/Port_of_Spain': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Port_of_Spain',
    defaultMessage: 'America/Port of Spain'
  },
  'America/Puerto_Rico': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Puerto_Rico',
    defaultMessage: 'America/Puerto Rico'
  },
  'America/Punta_Arenas': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Punta_Arenas',
    defaultMessage: 'America/Punta Arenas'
  },
  'America/Regina': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Regina',
    defaultMessage: 'America/Regina'
  },
  'America/Rio_Branco': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Rio_Branco',
    defaultMessage: 'America/Rio Branco'
  },
  'America/Santiago': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Santiago',
    defaultMessage: 'America/Santiago'
  },
  'America/Santo_Domingo': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Santo_Domingo',
    defaultMessage: 'America/Santo Domingo'
  },
  'America/Sao_Paulo': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Sao_Paulo',
    defaultMessage: 'America/Sao Paulo'
  },
  'America/Scoresbysund': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Scoresbysund',
    defaultMessage: 'America/Scoresbysund'
  },
  'America/St_Barthelemy': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/St_Barthelemy',
    defaultMessage: 'America/St Barthelemy'
  },
  'America/St_Johns': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/St_Johns',
    defaultMessage: 'America/St Johns'
  },
  'America/St_Kitts': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/St_Kitts',
    defaultMessage: 'America/St Kitts'
  },
  'America/St_Lucia': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/St_Lucia',
    defaultMessage: 'America/St Lucia'
  },
  'America/St_Thomas': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/St_Thomas',
    defaultMessage: 'America/St Thomas'
  },
  'America/St_Vincent': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/St_Vincent',
    defaultMessage: 'America/St Vincent'
  },
  'America/Tegucigalpa': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Tegucigalpa',
    defaultMessage: 'America/Tegucigalpa'
  },
  'America/Thule': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Thule',
    defaultMessage: 'America/Thule'
  },
  'America/Tijuana': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Tijuana',
    defaultMessage: 'America/Tijuana'
  },
  'America/Toronto': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Toronto',
    defaultMessage: 'America/Toronto'
  },
  'America/Tortola': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Tortola',
    defaultMessage: 'America/Tortola'
  },
  'America/Vancouver': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Vancouver',
    defaultMessage: 'America/Vancouver'
  },
  'America/Whitehorse': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Whitehorse',
    defaultMessage: 'America/Whitehorse'
  },
  'America/Winnipeg': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.America/Winnipeg',
    defaultMessage: 'America/Winnipeg'
  },
  'Antarctica/Casey': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Antarctica/Casey',
    defaultMessage: 'Antarctica/Casey'
  },
  'Antarctica/Davis': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Antarctica/Davis',
    defaultMessage: 'Antarctica/Davis'
  },
  'Antarctica/DumontDUrville': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Antarctica/DumontDUrville',
    defaultMessage: 'Antarctica/DumontDUrville'
  },
  'Antarctica/Mawson': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Antarctica/Mawson',
    defaultMessage: 'Antarctica/Mawson'
  },
  'Antarctica/McMurdo': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Antarctica/McMurdo',
    defaultMessage: 'Antarctica/McMurdo'
  },
  'Antarctica/Palmer': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Antarctica/Palmer',
    defaultMessage: 'Antarctica/Palmer'
  },
  'Antarctica/Syowa': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Antarctica/Syowa',
    defaultMessage: 'Antarctica/Syowa'
  },
  'Antarctica/Troll': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Antarctica/Troll',
    defaultMessage: 'Antarctica/Troll'
  },
  'Antarctica/Vostok': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Antarctica/Vostok',
    defaultMessage: 'Antarctica/Vostok'
  },
  'Arctic/Longyearbyen': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Arctic/Longyearbyen',
    defaultMessage: 'Arctic/Longyearbyen'
  },
  'Asia/Aden': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Aden',
    defaultMessage: 'Asia/Aden'
  },
  'Asia/Almaty': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Almaty',
    defaultMessage: 'Asia/Almaty'
  },
  'Asia/Amman': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Amman',
    defaultMessage: 'Asia/Amman'
  },
  'Asia/Ashgabat': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Ashgabat',
    defaultMessage: 'Asia/Ashgabat'
  },
  'Asia/Baghdad': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Baghdad',
    defaultMessage: 'Asia/Baghdad'
  },
  'Asia/Bahrain': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Bahrain',
    defaultMessage: 'Asia/Bahrain'
  },
  'Asia/Baku': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Baku',
    defaultMessage: 'Asia/Baku'
  },
  'Asia/Bangkok': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Bangkok',
    defaultMessage: 'Asia/Bangkok'
  },
  'Asia/Beirut': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Beirut',
    defaultMessage: 'Asia/Beirut'
  },
  'Asia/Bishkek': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Bishkek',
    defaultMessage: 'Asia/Bishkek'
  },
  'Asia/Brunei': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Brunei',
    defaultMessage: 'Asia/Brunei'
  },
  'Asia/Chita': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Chita',
    defaultMessage: 'Asia/Chita'
  },
  'Asia/Colombo': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Colombo',
    defaultMessage: 'Asia/Colombo'
  },
  'Asia/Damascus': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Damascus',
    defaultMessage: 'Asia/Damascus'
  },
  'Asia/Dhaka': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Dhaka',
    defaultMessage: 'Asia/Dhaka'
  },
  'Asia/Dili': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Dili',
    defaultMessage: 'Asia/Dili'
  },
  'Asia/Dubai': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Dubai',
    defaultMessage: 'Asia/Dubai'
  },
  'Asia/Dushanbe': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Dushanbe',
    defaultMessage: 'Asia/Dushanbe'
  },
  'Asia/Hebron': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Hebron',
    defaultMessage: 'Asia/Hebron'
  },
  'Asia/Ho_Chi_Minh': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Ho_Chi_Minh',
    defaultMessage: 'Asia/Ho Chi Minh'
  },
  'Asia/Hong_Kong': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Hong_Kong',
    defaultMessage: 'Asia/Hong Kong'
  },
  'Asia/Hovd': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Hovd',
    defaultMessage: 'Asia/Hovd'
  },
  'Asia/Irkutsk': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Irkutsk',
    defaultMessage: 'Asia/Irkutsk'
  },
  'Asia/Jakarta': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Jakarta',
    defaultMessage: 'Asia/Jakarta'
  },
  'Asia/Jayapura': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Jayapura',
    defaultMessage: 'Asia/Jayapura'
  },
  'Asia/Jerusalem': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Jerusalem',
    defaultMessage: 'Asia/Jerusalem'
  },
  'Asia/Kabul': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Kabul',
    defaultMessage: 'Asia/Kabul'
  },
  'Asia/Kamchatka': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Kamchatka',
    defaultMessage: 'Asia/Kamchatka'
  },
  'Asia/Karachi': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Karachi',
    defaultMessage: 'Asia/Karachi'
  },
  'Asia/Kathmandu': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Kathmandu',
    defaultMessage: 'Asia/Kathmandu'
  },
  'Asia/Kolkata': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Kolkata',
    defaultMessage: 'Asia/Kolkata'
  },
  'Asia/Kuala_Lumpur': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Kuala_Lumpur',
    defaultMessage: 'Asia/Kuala Lumpur'
  },
  'Asia/Kuwait': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Kuwait',
    defaultMessage: 'Asia/Kuwait'
  },
  'Asia/Macau': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Macau',
    defaultMessage: 'Asia/Macau'
  },
  'Asia/Makassar': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Makassar',
    defaultMessage: 'Asia/Makassar'
  },
  'Asia/Manila': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Manila',
    defaultMessage: 'Asia/Manila'
  },
  'Asia/Muscat': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Muscat',
    defaultMessage: 'Asia/Muscat'
  },
  'Asia/Nicosia': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Nicosia',
    defaultMessage: 'Asia/Nicosia'
  },
  'Asia/Novosibirsk': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Novosibirsk',
    defaultMessage: 'Asia/Novosibirsk'
  },
  'Asia/Omsk': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Omsk',
    defaultMessage: 'Asia/Omsk'
  },
  'Asia/Phnom_Penh': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Phnom_Penh',
    defaultMessage: 'Asia/Phnom Penh'
  },
  'Asia/Pyongyang': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Pyongyang',
    defaultMessage: 'Asia/Pyongyang'
  },
  'Asia/Qatar': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Qatar',
    defaultMessage: 'Asia/Qatar'
  },
  'Asia/Qyzylorda': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Qyzylorda',
    defaultMessage: 'Asia/Qyzylorda'
  },
  'Asia/Riyadh': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Riyadh',
    defaultMessage: 'Asia/Riyadh'
  },
  'Asia/Sakhalin': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Sakhalin',
    defaultMessage: 'Asia/Sakhalin'
  },
  'Asia/Seoul': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Seoul',
    defaultMessage: 'Asia/Seoul'
  },
  'Asia/Shanghai': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Shanghai',
    defaultMessage: 'Asia/Shanghai'
  },
  'Asia/Singapore': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Singapore',
    defaultMessage: 'Asia/Singapore'
  },
  'Asia/Taipei': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Taipei',
    defaultMessage: 'Asia/Taipei'
  },
  'Asia/Tashkent': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Tashkent',
    defaultMessage: 'Asia/Tashkent'
  },
  'Asia/Tbilisi': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Tbilisi',
    defaultMessage: 'Asia/Tbilisi'
  },
  'Asia/Tehran': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Tehran',
    defaultMessage: 'Asia/Tehran'
  },
  'Asia/Thimphu': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Thimphu',
    defaultMessage: 'Asia/Thimphu'
  },
  'Asia/Tokyo': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Tokyo',
    defaultMessage: 'Asia/Tokyo'
  },
  'Asia/Ulaanbaatar': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Ulaanbaatar',
    defaultMessage: 'Asia/Ulaanbaatar'
  },
  'Asia/Urumqi': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Urumqi',
    defaultMessage: 'Asia/Urumqi'
  },
  'Asia/Vientiane': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Vientiane',
    defaultMessage: 'Asia/Vientiane'
  },
  'Asia/Vladivostok': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Vladivostok',
    defaultMessage: 'Asia/Vladivostok'
  },
  'Asia/Yangon': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Yangon',
    defaultMessage: 'Asia/Yangon'
  },
  'Asia/Yekaterinburg': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Yekaterinburg',
    defaultMessage: 'Asia/Yekaterinburg'
  },
  'Asia/Yerevan': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Asia/Yerevan',
    defaultMessage: 'Asia/Yerevan'
  },
  'Atlantic/Azores': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Atlantic/Azores',
    defaultMessage: 'Atlantic/Azores'
  },
  'Atlantic/Bermuda': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Atlantic/Bermuda',
    defaultMessage: 'Atlantic/Bermuda'
  },
  'Atlantic/Canary': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Atlantic/Canary',
    defaultMessage: 'Atlantic/Canary'
  },
  'Atlantic/Cape_Verde': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Atlantic/Cape_Verde',
    defaultMessage: 'Atlantic/Cape Verde'
  },
  'Atlantic/Faroe': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Atlantic/Faroe',
    defaultMessage: 'Atlantic/Faroe'
  },
  'Atlantic/Reykjavik': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Atlantic/Reykjavik',
    defaultMessage: 'Atlantic/Reykjavik'
  },
  'Atlantic/South_Georgia': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Atlantic/South_Georgia',
    defaultMessage: 'Atlantic/South Georgia'
  },
  'Atlantic/St_Helena': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Atlantic/St_Helena',
    defaultMessage: 'Atlantic/St Helena'
  },
  'Atlantic/Stanley': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Atlantic/Stanley',
    defaultMessage: 'Atlantic/Stanley'
  },
  'Australia/Adelaide': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Australia/Adelaide',
    defaultMessage: 'Australia/Adelaide'
  },
  'Australia/Brisbane': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Australia/Brisbane',
    defaultMessage: 'Australia/Brisbane'
  },
  'Australia/Darwin': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Australia/Darwin',
    defaultMessage: 'Australia/Darwin'
  },
  'Australia/Eucla': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Australia/Eucla',
    defaultMessage: 'Australia/Eucla'
  },
  'Australia/Lord_Howe': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Australia/Lord_Howe',
    defaultMessage: 'Australia/Lord Howe'
  },
  'Australia/Perth': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Australia/Perth',
    defaultMessage: 'Australia/Perth'
  },
  'Australia/Sydney': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Australia/Sydney',
    defaultMessage: 'Australia/Sydney'
  },
  'Europe/Amsterdam': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Amsterdam',
    defaultMessage: 'Europe/Amsterdam'
  },
  'Europe/Andorra': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Andorra',
    defaultMessage: 'Europe/Andorra'
  },
  'Europe/Athens': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Athens',
    defaultMessage: 'Europe/Athens'
  },
  'Europe/Belgrade': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Belgrade',
    defaultMessage: 'Europe/Belgrade'
  },
  'Europe/Berlin': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Berlin',
    defaultMessage: 'Europe/Berlin'
  },
  'Europe/Bratislava': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Bratislava',
    defaultMessage: 'Europe/Bratislava'
  },
  'Europe/Brussels': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Brussels',
    defaultMessage: 'Europe/Brussels'
  },
  'Europe/Bucharest': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Bucharest',
    defaultMessage: 'Europe/Bucharest'
  },
  'Europe/Budapest': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Budapest',
    defaultMessage: 'Europe/Budapest'
  },
  'Europe/Chisinau': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Chisinau',
    defaultMessage: 'Europe/Chisinau'
  },
  'Europe/Copenhagen': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Copenhagen',
    defaultMessage: 'Europe/Copenhagen'
  },
  'Europe/Dublin': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Dublin',
    defaultMessage: 'Europe/Dublin'
  },
  'Europe/Gibraltar': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Gibraltar',
    defaultMessage: 'Europe/Gibraltar'
  },
  'Europe/Guernsey': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Guernsey',
    defaultMessage: 'Europe/Guernsey'
  },
  'Europe/Helsinki': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Helsinki',
    defaultMessage: 'Europe/Helsinki'
  },
  'Europe/Isle_of_Man': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Isle_of_Man',
    defaultMessage: 'Europe/Isle of Man'
  },
  'Europe/Istanbul': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Istanbul',
    defaultMessage: 'Europe/Istanbul'
  },
  'Europe/Jersey': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Jersey',
    defaultMessage: 'Europe/Jersey'
  },
  'Europe/Kaliningrad': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Kaliningrad',
    defaultMessage: 'Europe/Kaliningrad'
  },
  'Europe/Kiev': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Kiev',
    defaultMessage: 'Europe/Kiev'
  },
  'Europe/Lisbon': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Lisbon',
    defaultMessage: 'Europe/Lisbon'
  },
  'Europe/Ljubljana': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Ljubljana',
    defaultMessage: 'Europe/Ljubljana'
  },
  'Europe/London': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/London',
    defaultMessage: 'Europe/London'
  },
  'Europe/Luxembourg': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Luxembourg',
    defaultMessage: 'Europe/Luxembourg'
  },
  'Europe/Madrid': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Madrid',
    defaultMessage: 'Europe/Madrid'
  },
  'Europe/Malta': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Malta',
    defaultMessage: 'Europe/Malta'
  },
  'Europe/Mariehamn': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Mariehamn',
    defaultMessage: 'Europe/Mariehamn'
  },
  'Europe/Minsk': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Minsk',
    defaultMessage: 'Europe/Minsk'
  },
  'Europe/Monaco': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Monaco',
    defaultMessage: 'Europe/Monaco'
  },
  'Europe/Moscow': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Moscow',
    defaultMessage: 'Europe/Moscow'
  },
  'Europe/Oslo': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Oslo',
    defaultMessage: 'Europe/Oslo'
  },
  'Europe/Paris': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Paris',
    defaultMessage: 'Europe/Paris'
  },
  'Europe/Podgorica': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Podgorica',
    defaultMessage: 'Europe/Podgorica'
  },
  'Europe/Prague': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Prague',
    defaultMessage: 'Europe/Prague'
  },
  'Europe/Riga': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Riga',
    defaultMessage: 'Europe/Riga'
  },
  'Europe/Rome': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Rome',
    defaultMessage: 'Europe/Rome'
  },
  'Europe/Samara': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Samara',
    defaultMessage: 'Europe/Samara'
  },
  'Europe/San_Marino': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/San_Marino',
    defaultMessage: 'Europe/San Marino'
  },
  'Europe/Sarajevo': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Sarajevo',
    defaultMessage: 'Europe/Sarajevo'
  },
  'Europe/Skopje': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Skopje',
    defaultMessage: 'Europe/Skopje'
  },
  'Europe/Sofia': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Sofia',
    defaultMessage: 'Europe/Sofia'
  },
  'Europe/Stockholm': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Stockholm',
    defaultMessage: 'Europe/Stockholm'
  },
  'Europe/Tallinn': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Tallinn',
    defaultMessage: 'Europe/Tallinn'
  },
  'Europe/Tirane': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Tirane',
    defaultMessage: 'Europe/Tirane'
  },
  'Europe/Vaduz': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Vaduz',
    defaultMessage: 'Europe/Vaduz'
  },
  'Europe/Vatican': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Vatican',
    defaultMessage: 'Europe/Vatican'
  },
  'Europe/Vienna': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Vienna',
    defaultMessage: 'Europe/Vienna'
  },
  'Europe/Vilnius': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Vilnius',
    defaultMessage: 'Europe/Vilnius'
  },
  'Europe/Warsaw': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Warsaw',
    defaultMessage: 'Europe/Warsaw'
  },
  'Europe/Zagreb': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Zagreb',
    defaultMessage: 'Europe/Zagreb'
  },
  'Europe/Zurich': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Europe/Zurich',
    defaultMessage: 'Europe/Zurich'
  },
  'Indian/Antananarivo': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Indian/Antananarivo',
    defaultMessage: 'Indian/Antananarivo'
  },
  'Indian/Chagos': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Indian/Chagos',
    defaultMessage: 'Indian/Chagos'
  },
  'Indian/Christmas': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Indian/Christmas',
    defaultMessage: 'Indian/Christmas'
  },
  'Indian/Cocos': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Indian/Cocos',
    defaultMessage: 'Indian/Cocos'
  },
  'Indian/Comoro': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Indian/Comoro',
    defaultMessage: 'Indian/Comoro'
  },
  'Indian/Kerguelen': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Indian/Kerguelen',
    defaultMessage: 'Indian/Kerguelen'
  },
  'Indian/Mahe': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Indian/Mahe',
    defaultMessage: 'Indian/Mahe'
  },
  'Indian/Maldives': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Indian/Maldives',
    defaultMessage: 'Indian/Maldives'
  },
  'Indian/Mauritius': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Indian/Mauritius',
    defaultMessage: 'Indian/Mauritius'
  },
  'Indian/Mayotte': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Indian/Mayotte',
    defaultMessage: 'Indian/Mayotte'
  },
  'Indian/Reunion': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Indian/Reunion',
    defaultMessage: 'Indian/Reunion'
  },
  'Pacific/Apia': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Apia',
    defaultMessage: 'Pacific/Apia'
  },
  'Pacific/Auckland': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Auckland',
    defaultMessage: 'Pacific/Auckland'
  },
  'Pacific/Bougainville': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Bougainville',
    defaultMessage: 'Pacific/Bougainville'
  },
  'Pacific/Chatham': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Chatham',
    defaultMessage: 'Pacific/Chatham'
  },
  'Pacific/Chuuk': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Chuuk',
    defaultMessage: 'Pacific/Chuuk'
  },
  'Pacific/Easter': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Easter',
    defaultMessage: 'Pacific/Easter'
  },
  'Pacific/Efate': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Efate',
    defaultMessage: 'Pacific/Efate'
  },
  'Pacific/Enderbury': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Enderbury',
    defaultMessage: 'Pacific/Enderbury'
  },
  'Pacific/Fakaofo': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Fakaofo',
    defaultMessage: 'Pacific/Fakaofo'
  },
  'Pacific/Fiji': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Fiji',
    defaultMessage: 'Pacific/Fiji'
  },
  'Pacific/Funafuti': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Funafuti',
    defaultMessage: 'Pacific/Funafuti'
  },
  'Pacific/Galapagos': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Galapagos',
    defaultMessage: 'Pacific/Galapagos'
  },
  'Pacific/Gambier': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Gambier',
    defaultMessage: 'Pacific/Gambier'
  },
  'Pacific/Guadalcanal': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Guadalcanal',
    defaultMessage: 'Pacific/Guadalcanal'
  },
  'Pacific/Guam': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Guam',
    defaultMessage: 'Pacific/Guam'
  },
  'Pacific/Honolulu': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Honolulu',
    defaultMessage: 'Pacific/Honolulu'
  },
  'Pacific/Kiritimati': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Kiritimati',
    defaultMessage: 'Pacific/Kiritimati'
  },
  'Pacific/Kosrae': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Kosrae',
    defaultMessage: 'Pacific/Kosrae'
  },
  'Pacific/Majuro': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Majuro',
    defaultMessage: 'Pacific/Majuro'
  },
  'Pacific/Marquesas': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Marquesas',
    defaultMessage: 'Pacific/Marquesas'
  },
  'Pacific/Midway': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Midway',
    defaultMessage: 'Pacific/Midway'
  },
  'Pacific/Nauru': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Nauru',
    defaultMessage: 'Pacific/Nauru'
  },
  'Pacific/Niue': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Niue',
    defaultMessage: 'Pacific/Niue'
  },
  'Pacific/Norfolk': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Norfolk',
    defaultMessage: 'Pacific/Norfolk'
  },
  'Pacific/Noumea': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Noumea',
    defaultMessage: 'Pacific/Noumea'
  },
  'Pacific/Pago_Pago': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Pago_Pago',
    defaultMessage: 'Pacific/Pago Pago'
  },
  'Pacific/Palau': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Palau',
    defaultMessage: 'Pacific/Palau'
  },
  'Pacific/Pitcairn': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Pitcairn',
    defaultMessage: 'Pacific/Pitcairn'
  },
  'Pacific/Port_Moresby': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Port_Moresby',
    defaultMessage: 'Pacific/Port Moresby'
  },
  'Pacific/Rarotonga': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Rarotonga',
    defaultMessage: 'Pacific/Rarotonga'
  },
  'Pacific/Saipan': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Saipan',
    defaultMessage: 'Pacific/Saipan'
  },
  'Pacific/Tahiti': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Tahiti',
    defaultMessage: 'Pacific/Tahiti'
  },
  'Pacific/Tarawa': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Tarawa',
    defaultMessage: 'Pacific/Tarawa'
  },
  'Pacific/Tongatapu': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Tongatapu',
    defaultMessage: 'Pacific/Tongatapu'
  },
  'Pacific/Wake': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Wake',
    defaultMessage: 'Pacific/Wake'
  },
  'Pacific/Wallis': {
    id: 'jutro-components.widgets.inputs.TimeZoneField.Pacific/Wallis',
    defaultMessage: 'Pacific/Wallis'
  }
});