import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
import styles from "./ColorSwatch.module.css";
import { hexColorElements } from './hexColorElements';
import { getKeyPressHandler } from '../../../accessibility/getKeyPressHandler';
import { isBright } from './utils';
var solidColorPropTypes = PropTypes.shape({
  hexColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
});
var gradientColorPropTypes = PropTypes.shape({
  startingColor: PropTypes.string.isRequired,
  startingHexColor: PropTypes.string.isRequired,
  finishingColor: PropTypes.string.isRequired,
  finishingHexColor: PropTypes.string.isRequired
});
var brightSwatchBorderColor = 'var(--GW-BORDER-COLOR)';

function solidBoxStyle(_ref) {
  var color = _ref.color,
      hexColor = _ref.hexColor;
  return {
    backgroundColor: color,
    borderColor: isBright(hexColor) ? brightSwatchBorderColor : color
  };
}

function gradientBoxStyle(_ref2) {
  var startingColor = _ref2.startingColor,
      startingHexColor = _ref2.startingHexColor,
      finishingColor = _ref2.finishingColor,
      finishingHexColor = _ref2.finishingHexColor;
  var shouldHaveBorder = isBright(startingHexColor) || isBright(finishingHexColor);
  var gradientColorValue = "linear-gradient(to right, ".concat(startingColor, ", ").concat(finishingColor, ")");
  var borderStyle = shouldHaveBorder ? {
    borderColor: brightSwatchBorderColor
  } : {
    borderImage: gradientColorValue,
    borderImageSlice: 1
  };
  return _objectSpread({
    backgroundImage: gradientColorValue
  }, borderStyle);
}

var boxStyleByType = {
  solid: solidBoxStyle,
  gradient: gradientBoxStyle
};
var colorSwatchPropTypes = {
  title: intlMessageShape.isRequired,
  colorProperties: PropTypes.oneOfType([solidColorPropTypes, gradientColorPropTypes]).isRequired,
  type: PropTypes.oneOf(['gradient', 'solid']).isRequired,
  onClick: PropTypes.func
};
export var ColorSwatch = function ColorSwatch(props) {
  var translator = useContext(TranslatorContext);
  var title = props.title,
      colorProperties = props.colorProperties,
      type = props.type,
      className = props.className,
      onClick = props.onClick;

  function handleClick(event) {
    if (onClick) {
      onClick(event);
    }
  }

  var swatchClasses = cx(styles.colorSwatch, className);
  var colorElements = hexColorElements(type, colorProperties, styles.color);
  return React.createElement("div", {
    tabIndex: "0",
    role: "button",
    className: swatchClasses,
    onClick: handleClick,
    onKeyPress: getKeyPressHandler(handleClick)
  }, React.createElement("div", {
    className: styles.box,
    style: boxStyleByType[type](colorProperties)
  }), React.createElement("div", {
    className: styles.title
  }, translator(title)), colorElements);
};
ColorSwatch.propTypes = colorSwatchPropTypes;
ColorSwatch.defaultProps = {
  type: 'solid'
};
ColorSwatch.__docgenInfo = {
  componentName: "ColorSwatch",
  packageName: "@jutro/components",
  description: "ColorSwatch",
  displayName: "ColorSwatch",
  methods: [],
  actualName: "ColorSwatch",
  metadataType: "action",
  props: {
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: true,
      description: "Title of the color"
    },
    colorProperties: {
      type: {
        name: "union",
        value: [{
          name: "custom",
          raw: "solidColorPropTypes"
        }, {
          name: "custom",
          raw: "gradientColorPropTypes"
        }]
      },
      required: true,
      description: "Color properties containing either color and hex color for solid colors or starting and finishing colors and hexColors of a gradient"
    },
    type: {
      type: {
        name: "enum",
        value: [{
          value: "'gradient'",
          computed: false
        }, {
          value: "'solid'",
          computed: false
        }]
      },
      required: false,
      description: "Type of the color. Either gradient or solid",
      defaultValue: {
        value: "'solid'",
        computed: false
      }
    },
    onClick: {
      type: {
        name: "func"
      },
      required: false,
      description: "Custom onClick handler"
    }
  }
};