import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _reverseInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reverse";
import _startsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/starts-with";
import _ from 'lodash';
import { log } from '@jutro/logger';
import { refTypeMap, REF_PROPERTY, REF_PROPERTY_SHORTCUT } from '../common/refs';
export function resolveContentReference(item, rootMetadata) {
  if (_.isString(item) && _startsWithInstanceProperty(item).call(item, REF_PROPERTY_SHORTCUT)) {
    return extendMetadata(undefined, item.substr(REF_PROPERTY_SHORTCUT.length), rootMetadata);
  }

  if (item[REF_PROPERTY]) {
    return extendMetadata(item, item[REF_PROPERTY], rootMetadata);
  }

  return item;
}
export function extendMetadata(targetItem, refKey, rootMetadata) {
  if (!refKey) {
    return targetItem;
  }

  var parts = refKey.split('/');

  var baseItem = _.get(rootMetadata, parts);

  if (!baseItem) {
    log.warning("unable to resolve @ref: \"".concat(refKey, "\""));
    return targetItem;
  }

  var extendedItem = baseItem;

  if (targetItem) {
    extendedItem = _objectSpread({}, baseItem, {}, targetItem);
    delete extendedItem[REF_PROPERTY];

    if (baseItem.componentProps && targetItem.componentProps) {
      extendedItem.componentProps = _objectSpread({}, baseItem.componentProps, {}, targetItem.componentProps);
    }
  }

  fillInMissingValues(extendedItem, _reverseInstanceProperty(parts).call(parts), refKey);
  return extendedItem;
}

function fillInMissingValues(extendedItem, parts, refKey) {
  if (!extendedItem.id || !extendedItem.type) {
    var _parts = _slicedToArray(parts, 2),
        idFromRefKey = _parts[0],
        typeFromRefKey = _parts[1];

    if (!extendedItem.id) {
      if (idFromRefKey) {
        extendedItem.id = idFromRefKey;
      } else {
        log.warning("unable to resolve 'id' from: \"".concat(refKey, "\""));
      }
    }

    if (!extendedItem.type) {
      if (typeFromRefKey) {
        extendedItem.type = refTypeMap[typeFromRefKey];
      } else {
        log.warning("unable to resolve 'type' from: \"".concat(refKey, "\""));
      }
    }
  }
}