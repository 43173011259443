import { warning } from '@jutro/logger';
export default function loadGtag(measurementId) {
  if (!measurementId) {
    warning('To enable gtag.js provide measurement id');
    return null;
  }

  var scriptElement = document.createElement('script');
  scriptElement.src = "https://www.googletagmanager.com/gtag/js?id=".concat(measurementId);
  var headElement = document.getElementsByTagName('head')[0];
  headElement.appendChild(scriptElement);
  window.dataLayer = window.dataLayer || [];

  function gtag() {
    window.dataLayer.push(arguments);
  }

  gtag('js', new Date());
  gtag('config', measurementId);
  return gtag;
}