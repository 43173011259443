import React from 'react';
import PropTypes from 'prop-types';
import { intlMessageShape } from '@jutro/prop-types';
import { HelpElement } from './HelpElement';
var helpHeadingPropTypes = {
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, intlMessageShape])
};
export var HelpHeading = function HelpHeading(_ref) {
  var className = _ref.className,
      content = _ref.content;
  return React.createElement(HelpElement, {
    className: className,
    tag: "h5",
    content: content
  });
};
HelpHeading.propTypes = helpHeadingPropTypes;
HelpHeading.__docgenInfo = {
  componentName: "HelpHeading",
  packageName: "@jutro/components",
  description: "The `HelpHeading` component is designed as a simple component presenting heading inside the `HelpPopover` container.",
  displayName: "HelpHeading",
  methods: [],
  actualName: "HelpHeading",
  metadataType: "element",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component."
    },
    content: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: false
              },
              defaultMessage: {
                name: "string",
                required: false
              },
              args: {
                name: "shape",
                value: {},
                required: false
              }
            }
          }]
        }]
      },
      required: false,
      description: "Content to render"
    }
  }
};