import _defineProperty2 from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty2(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";

function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();

  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
        result;

    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;

      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }

    return _possibleConstructorReturn(this, result);
  };
}

function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;

  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}

import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { isNilValue } from '@jutro/data';
import cx from 'classnames';
import { FieldComponent } from '../FieldComponent/FieldComponent';
import styles from "./SwitchField.module.css";

function isChecked(value) {
  return !isNilValue(value) && value.toString() === 'true';
}

export var SwitchField = function (_FieldComponent) {
  _inherits(SwitchField, _FieldComponent);

  var _super = _createSuper(SwitchField);

  function SwitchField(props) {
    var _this;

    _classCallCheck(this, SwitchField);

    _this = _super.call(this, props);

    _defineProperty(_assertThisInitialized(_this), "handleCheckChange", function (event) {
      if (!_this.isValueChangeAllowed()) {
        return;
      }

      var value = event.target.checked;

      _this.notifyChange(value);
    });

    _defineProperty(_assertThisInitialized(_this), "handleKeyPress", function (event) {
      if (event.key === 'Enter') {
        _this.toggleValue(event);
      }
    });

    _defineProperty(_assertThisInitialized(_this), "handleClick", function (event) {
      _this.inputRef.current.focus();

      _this.toggleValue(event);
    });

    _defineProperty(_assertThisInitialized(_this), "isValueChangeAllowed", function () {
      return !(_this.props.disabled || _this.props.readOnly);
    });

    _defineProperty(_assertThisInitialized(_this), "toggleValue", function (event) {
      if (!_this.isValueChangeAllowed()) {
        return;
      }

      event.preventDefault();
      var value = _this.props.value;
      var checked = isChecked(value);

      _this.notifyChange(!checked);
    });

    _this.inputRef = createRef();
    return _this;
  }

  _createClass(SwitchField, [{
    key: "render",
    value: function render() {
      return _get(_getPrototypeOf(SwitchField.prototype), "render", this).call(this);
    }
  }, {
    key: "shouldRenderTooltipIcon",
    value: function shouldRenderTooltipIcon() {
      return false;
    }
  }, {
    key: "renderControlReadOnly",
    value: function renderControlReadOnly(breakpointProps, theme) {
      return this.renderControl(breakpointProps, theme);
    }
  }, {
    key: "renderLabel",
    value: function renderLabel() {
      return null;
    }
  }, {
    key: "renderControl",
    value: function renderControl(breakpointProps) {
      var _cx;

      var id = breakpointProps.id,
          value = breakpointProps.value,
          disabled = breakpointProps.disabled,
          label = breakpointProps.label,
          required = breakpointProps.required,
          controlClassName = breakpointProps.controlClassName,
          hideLabel = breakpointProps.hideLabel,
          children = breakpointProps.children,
          detailElement = breakpointProps.detailElement,
          labelPosition = breakpointProps.labelPosition,
          readOnly = breakpointProps.readOnly,
          fullWidth = breakpointProps.fullWidth;
      var translator = this.translator;
      var checked = isChecked(value);
      var isRight = labelPosition === 'right';
      var classes = cx(styles.switch, (_cx = {}, _defineProperty2(_cx, styles.switchLabelRight, isRight), _defineProperty2(_cx, styles.fullWidth, fullWidth), _cx), controlClassName);
      var detailVisible = checked && (children || detailElement);
      var detail = React.createElement("div", {
        className: cx(_defineProperty2({}, styles.detailVisible, detailVisible)),
        role: "region",
        "aria-live": "assertive",
        "aria-hidden": !detailVisible
      }, detailVisible && (children || detailElement));
      var switchInput = React.createElement("input", _extends({
        id: id,
        type: "checkbox",
        ref: this.inputRef,
        value: id,
        checked: checked,
        onKeyPress: this.handleKeyPress,
        onChange: this.handleCheckChange,
        onBlur: this.handleBlur,
        onFocus: this.handleFocus,
        disabled: disabled || readOnly,
        required: required,
        role: "switch",
        "aria-checked": checked,
        "aria-label": hideLabel ? translator(label) : null
      }, this.generateDataPathProperty()));

      var labelNode = _get(_getPrototypeOf(SwitchField.prototype), "renderLabel", this).call(this, breakpointProps, styles.label);

      var control = React.createElement(React.Fragment, null, React.createElement("div", {
        className: classes
      }, !hideLabel && React.createElement("div", {
        className: styles.labelSection
      }, !isRight && labelNode), React.createElement("div", {
        className: styles.controlSection
      }, switchInput, React.createElement("span", {
        onClick: this.handleClick,
        className: isRight ? undefined : styles.inputLabel
      }), isRight && !hideLabel && labelNode)), detail);
      return control;
    }
  }]);

  return SwitchField;
}(FieldComponent);

_defineProperty(SwitchField, "propTypes", _objectSpread({}, FieldComponent.propTypes, {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  fullWidth: PropTypes.bool,
  children: PropTypes.node,
  detailElement: PropTypes.node,
  labelPosition: PropTypes.oneOf(['right', 'left'])
}));

_defineProperty(SwitchField, "defaultProps", _objectSpread({}, FieldComponent.defaultProps, {
  labelPosition: 'left',
  phone: {
    labelPosition: 'left'
  }
}));

_defineProperty(SwitchField, "contextType", FieldComponent.contextType);

SwitchField.__docgenInfo = {
  componentName: "SwitchField",
  packageName: "@jutro/components",
  description: "Renders a \"switch\" with a label. Holds a value of `true` or `false`.",
  displayName: "SwitchField",
  methods: [{
    name: "handleCheckChange",
    docblock: "Custom change handler for `checkbox` element. Uses `notifyChange` to invoke onValueChange callback\n\n@param {object} event",
    modifiers: [],
    params: [{
      name: "event",
      description: null,
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Custom change handler for `checkbox` element. Uses `notifyChange` to invoke onValueChange callback"
  }, {
    name: "handleKeyPress",
    docblock: "Custom change handler for `checkbox` element. Uses `notifyChange` to invoke onValueChange callback\n\n@param {object} event",
    modifiers: [],
    params: [{
      name: "event",
      description: null,
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Custom change handler for `checkbox` element. Uses `notifyChange` to invoke onValueChange callback"
  }, {
    name: "handleClick",
    docblock: "Handles the click on the switch button and focuses the invisible input element\n\n@param {Event} event",
    modifiers: [],
    params: [{
      name: "event",
      description: null,
      type: {
        name: "Event"
      },
      optional: false
    }],
    returns: null,
    description: "Handles the click on the switch button and focuses the invisible input element"
  }, {
    name: "isValueChangeAllowed",
    docblock: "Checks if component state allows for a change to be propagated ei. disabled and readOnly are set to false\n\n@returns {boolean|boolean}",
    modifiers: [],
    params: [],
    returns: {
      description: null,
      type: {
        name: "union",
        elements: [{
          name: "boolean"
        }, {
          name: "boolean"
        }]
      }
    },
    description: "Checks if component state allows for a change to be propagated ei. disabled and readOnly are set to false"
  }, {
    name: "toggleValue",
    docblock: "Controls toggling values of the switch button\n\n@param {Event} event",
    modifiers: [],
    params: [{
      name: "event",
      description: null,
      type: {
        name: "Event"
      },
      optional: false
    }],
    returns: null,
    description: "Controls toggling values of the switch button"
  }, {
    name: "shouldRenderTooltipIcon",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "renderControlReadOnly",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpointProps",
      optional: undefined,
      type: null
    }, {
      name: "theme",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "renderLabel",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  actualName: "SwitchField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }]
      },
      required: false,
      description: "Boolean or string value"
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }]
      },
      required: false,
      description: "Default value"
    },
    fullWidth: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true splits the label and control as far apart as possible."
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "Node to render when checkbox is selected, as processed by `renderContentFromMetadata` method (metadata 3.0)"
    },
    detailElement: {
      type: {
        name: "node"
      },
      required: false,
      description: "Children to render when checkbox is selected (metadata 3.0)"
    },
    labelPosition: {
      type: {
        name: "enum",
        value: [{
          value: "'right'",
          computed: false
        }, {
          value: "'left'",
          computed: false
        }]
      },
      required: false,
      description: "Allows to select label position",
      defaultValue: {
        value: "'left'",
        computed: false
      }
    },
    phone: {
      defaultValue: {
        value: "{ labelPosition: 'left' }",
        computed: false
      },
      required: false
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};