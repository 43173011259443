import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import omit from "lodash/omit";
import findKey from "lodash/findKey";
import { COLLAPSE, TOGGLE, COLLAPSE_ALL } from './types';
export function reducer(state, _ref) {
  var type = _ref.type,
      payload = _ref.payload;

  switch (type) {
    case COLLAPSE:
      {
        var viewIndex = findKey(state, function (_ref2) {
          var rowId = _ref2.rowId;
          return rowId === payload;
        });

        if (viewIndex === undefined) {
          return state;
        }

        return omit(state, [viewIndex]);
      }

    case TOGGLE:
      {
        var rowId = payload.rowId,
            _viewIndex = payload.viewIndex,
            allowMultiple = payload.allowMultiple;
        var oldExpanded = allowMultiple ? state : {};
        return state[_viewIndex] ? omit(state, [_viewIndex]) : _objectSpread({}, oldExpanded, _defineProperty({}, _viewIndex, {
          rowId: rowId
        }));
      }

    case COLLAPSE_ALL:
      return {};

    default:
      return state;
  }
}