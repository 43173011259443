import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { Layout, layoutShape } from './Layout';
export var PanelLayout = function PanelLayout(_ref2) {
  var id = _ref2.id,
      children = _ref2.children,
      className = _ref2.className,
      layout = _ref2.layout;

  var _ref = layout || {},
      desktop = _ref.desktop,
      tablet = _ref.tablet,
      phoneWide = _ref.phoneWide,
      phone = _ref.phone;

  return React.createElement(Layout, {
    id: id,
    layout: {
      desktop: _objectSpread({
        columns: ['1fr'],
        repeat: '8',
        gap: 'large',
        colStart: '2',
        colSpan: '6'
      }, desktop),
      tablet: _objectSpread({
        repeat: '6',
        gap: 'medium',
        colStart: '2',
        colSpan: '4'
      }, tablet),
      phoneWide: _objectSpread({
        repeat: '6',
        gap: 'medium',
        colStart: '2',
        colSpan: '4'
      }, phoneWide),
      phone: _objectSpread({
        repeat: '4',
        gap: 'medium',
        colStart: '1',
        colSpan: '4'
      }, phone)
    },
    className: className
  }, children);
};
PanelLayout.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  layout: PropTypes.shape({
    desktop: layoutShape,
    tablet: layoutShape,
    phoneWide: layoutShape,
    phone: layoutShape
  })
};
PanelLayout.__docgenInfo = {
  componentName: "PanelLayout",
  packageName: "@jutro/components",
  description: "",
  displayName: "PanelLayout",
  methods: [],
  actualName: "PanelLayout",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: false,
      description: "Used to identify the component"
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "children; preferably 'GridItem'; works with any child"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Optional css class(es) to add to the grid tag"
    },
    layout: {
      type: {
        name: "shape",
        value: {
          desktop: {
            name: "shape",
            description: "Optional props to override default layout",
            required: false,
            value: {
              id: {
                name: "string",
                description: "Used to identify the component",
                required: false
              },
              repeat: {
                name: "union",
                value: [{
                  name: "enum",
                  value: [{
                    value: "'auto-fit'",
                    computed: false
                  }, {
                    value: "'auto-fill'",
                    computed: false
                  }]
                }, {
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "repeat columns: 'auto-fit' or 'auto-fill' or number",
                required: false
              },
              gap: {
                name: "enum",
                computed: true,
                value: "Object.keys(gaps)",
                description: "gap between rows and columns: 'none', 'small', 'medium', 'large'",
                required: false
              },
              columns: {
                name: "array",
                description: "define explicit columns",
                required: false
              },
              colSpan: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                required: false
              },
              colStart: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                required: false
              }
            }
          },
          tablet: {
            name: "shape",
            description: "Optional props to override layout on tablet",
            required: false,
            value: {
              id: {
                name: "string",
                description: "Used to identify the component",
                required: false
              },
              repeat: {
                name: "union",
                value: [{
                  name: "enum",
                  value: [{
                    value: "'auto-fit'",
                    computed: false
                  }, {
                    value: "'auto-fill'",
                    computed: false
                  }]
                }, {
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "repeat columns: 'auto-fit' or 'auto-fill' or number",
                required: false
              },
              gap: {
                name: "enum",
                computed: true,
                value: "Object.keys(gaps)",
                description: "gap between rows and columns: 'none', 'small', 'medium', 'large'",
                required: false
              },
              columns: {
                name: "array",
                description: "define explicit columns",
                required: false
              },
              colSpan: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                required: false
              },
              colStart: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                required: false
              }
            }
          },
          phoneWide: {
            name: "shape",
            description: "Optional props to override layout on phoneWide",
            required: false,
            value: {
              id: {
                name: "string",
                description: "Used to identify the component",
                required: false
              },
              repeat: {
                name: "union",
                value: [{
                  name: "enum",
                  value: [{
                    value: "'auto-fit'",
                    computed: false
                  }, {
                    value: "'auto-fill'",
                    computed: false
                  }]
                }, {
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "repeat columns: 'auto-fit' or 'auto-fill' or number",
                required: false
              },
              gap: {
                name: "enum",
                computed: true,
                value: "Object.keys(gaps)",
                description: "gap between rows and columns: 'none', 'small', 'medium', 'large'",
                required: false
              },
              columns: {
                name: "array",
                description: "define explicit columns",
                required: false
              },
              colSpan: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                required: false
              },
              colStart: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                required: false
              }
            }
          },
          phone: {
            name: "shape",
            description: "Optional props to override layout on phone",
            required: false,
            value: {
              id: {
                name: "string",
                description: "Used to identify the component",
                required: false
              },
              repeat: {
                name: "union",
                value: [{
                  name: "enum",
                  value: [{
                    value: "'auto-fit'",
                    computed: false
                  }, {
                    value: "'auto-fill'",
                    computed: false
                  }]
                }, {
                  name: "number"
                }, {
                  name: "string"
                }],
                description: "repeat columns: 'auto-fit' or 'auto-fill' or number",
                required: false
              },
              gap: {
                name: "enum",
                computed: true,
                value: "Object.keys(gaps)",
                description: "gap between rows and columns: 'none', 'small', 'medium', 'large'",
                required: false
              },
              columns: {
                name: "array",
                description: "define explicit columns",
                required: false
              },
              colSpan: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                required: false
              },
              colStart: {
                name: "union",
                value: [{
                  name: "number"
                }, {
                  name: "string"
                }],
                required: false
              }
            }
          }
        }
      },
      required: false,
      description: ""
    }
  }
};