import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { createContext, useContext } from 'react';
import format from 'string-format';
import { createContextConsumerHOC } from '@jutro/platform';
import { IntlContext } from './IntlProvider';

var createTranslator = function createTranslator(intl) {
  return function (key, args) {
    if (!key) {
      return key;
    }

    if (typeof key !== 'string') {
      var mergedArgs = key.args ? _objectSpread({}, args, {}, key.args) : args;

      if (intl && key.id) {
        var formattedMessage = intl.formatMessage && intl.formatMessage(key, mergedArgs);
        return formattedMessage === key.id && key.defaultMessage === '' ? '' : formattedMessage;
      }

      if (typeof key.defaultMessage === 'string') {
        return mergedArgs !== undefined && mergedArgs !== null ? format(key.defaultMessage, mergedArgs) : key.defaultMessage;
      }
    }

    return typeof key === 'string' && args !== undefined && args !== null ? format(key, args) : key;
  };
};

export var TranslatorContext = createContext(createTranslator());
export var TranslatorProvider = function TranslatorProvider(_ref) {
  var children = _ref.children;
  var intl = useContext(IntlContext);
  return React.createElement(TranslatorContext.Provider, {
    value: createTranslator(intl)
  }, children);
};
export var withTranslator = function withTranslator(ComponentToWrap) {
  return createContextConsumerHOC({
    component: ComponentToWrap,
    context: TranslatorContext,
    displayName: "WithTranslator".concat(ComponentToWrap.displayName || ComponentToWrap.name),
    mapContextToProps: function mapContextToProps(props, translator) {
      return {
        translator: translator
      };
    }
  });
};
TranslatorProvider.__docgenInfo = {
  componentName: "TranslatorProvider",
  packageName: "@jutro/locale",
  description: "",
  displayName: "TranslatorProvider",
  methods: [],
  actualName: "TranslatorProvider"
};