import _defineProperty2 from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty2(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import * as logger from 'loglevel';
import prefix from 'loglevel-plugin-prefix';
import { getConfigValue } from '@jutro/config';
var DEFAULT_LOGGER_CONFIG = {
  level: getConfigValue('JUTRO_LOGGER_LEVEL', 'WARN'),
  prefixTemplate: '%l:',
  loggerName: 'jutro-logger'
};

var Logger = function () {
  function Logger(loggerConfig) {
    _classCallCheck(this, Logger);

    _defineProperty(this, "log", void 0);

    var config = loggerConfig || DEFAULT_LOGGER_CONFIG;
    this.setConfig(config);
  }

  _createClass(Logger, [{
    key: "setConfig",
    value: function setConfig(config, replace) {
      var configs = replace ? config : _objectSpread({}, DEFAULT_LOGGER_CONFIG, {}, config);
      var level = configs.level,
          prefixTemplate = configs.prefixTemplate,
          loggerName = configs.loggerName;
      prefix.reg(logger);
      prefix.apply(logger, {
        template: prefixTemplate
      });
      this.log = logger.getLogger(loggerName);
      this.log.setLevel(level);
    }
  }, {
    key: "resetConfig",
    value: function resetConfig() {
      this.setConfig(DEFAULT_LOGGER_CONFIG);
    }
  }, {
    key: "trace",
    value: function trace(message) {
      this.log.trace(message);
    }
  }, {
    key: "debug",
    value: function debug(message) {
      this.log.debug(message);
    }
  }, {
    key: "info",
    value: function info(message) {
      this.log.info(message);
    }
  }, {
    key: "warning",
    value: function warning(message, options) {
      if (options && options.bold) {
        this.log.warn("%c".concat(message), 'font-weight: bold');
      } else {
        this.log.warn(message);
      }
    }
  }, {
    key: "error",
    value: function error(message) {
      this.log.error(message);
    }
  }]);

  return Logger;
}();

export { Logger as default };
export var log = new Logger();
export var trace = function trace(message) {
  log.trace(message);
};
export var debug = function debug(message) {
  log.debug(message);
};
export var info = function info(message) {
  log.info(message);
};
export var warning = function warning(message, options) {
  log.warning(message, options);
};
export var error = function error(message) {
  log.error(message);
};

var logOnce = function logOnce(messageFunction) {
  var called = false;
  return function (message) {
    if (!called) {
      called = true;
      messageFunction(message);
    }
  };
};

export var makeUniqueWarningLog = function makeUniqueWarningLog(message) {
  return logOnce(function () {
    return warning(message);
  });
};