import { datadogLogs } from '@datadog/browser-logs';
export default function loadDataDog(dataDogClientToken, dataDogservice, environment) {
  var jutroversion = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "5.3.1";
  var dataDogSite = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 'datadoghq.com';
  datadogLogs.init({
    clientToken: dataDogClientToken,
    site: dataDogSite,
    env: "atmos-".concat(environment),
    forwardErrorsToLogs: true,
    sampleRate: 100,
    version: jutroversion,
    service: dataDogservice
  });
}