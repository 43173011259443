import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { intlMessageShape } from '@jutro/prop-types';
import styles from "./LogoTitle.module.css";
export var translateTitle = function translateTitle(title, translator) {
  if (_.isFunction(title)) {
    title = title(translator);
  }

  if (translator) {
    title = translator(title);
  }

  return title;
};
var logoTitlePropTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, intlMessageShape, PropTypes.func]),
  renderTitle: PropTypes.func,
  className: PropTypes.string,
  logoClassName: PropTypes.string,
  titleClassName: PropTypes.string
};
export var LogoTitle = function LogoTitle(_ref) {
  var src = _ref.src,
      titleText = _ref.title,
      renderTitle = _ref.renderTitle,
      alt = _ref.alt,
      className = _ref.className,
      titleClassName = _ref.titleClassName,
      logoClassName = _ref.logoClassName;
  var translator = useContext(TranslatorContext);
  var classes = cx(styles.logoTitle, className);
  var logoClasses = cx(styles.logo, logoClassName);
  var titleClasses = cx(styles.title, titleClassName);
  var title = titleText || renderTitle;
  return React.createElement("span", {
    className: classes
  }, src && React.createElement("img", {
    className: logoClasses,
    src: src,
    alt: alt
  }), title && React.createElement("span", {
    className: titleClasses
  }, translateTitle(title, translator)));
};
LogoTitle.propTypes = logoTitlePropTypes;
LogoTitle.__docgenInfo = {
  componentName: "LogoTitle",
  packageName: "@jutro/components",
  description: "LogoTitle",
  displayName: "LogoTitle",
  methods: [],
  actualName: "LogoTitle",
  metadataType: "element",
  props: {
    src: {
      type: {
        name: "string"
      },
      required: false,
      description: "Src for logo image."
    },
    alt: {
      type: {
        name: "string"
      },
      required: false,
      description: "Alternative text for logo if image is not exists."
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: false
              },
              defaultMessage: {
                name: "string",
                required: false
              },
              args: {
                name: "shape",
                value: {},
                required: false
              }
            }
          }]
        }, {
          name: "func"
        }]
      },
      required: false,
      description: "Title as string, intl message or func."
    },
    renderTitle: {
      type: {
        name: "func"
      },
      required: false,
      description: "Title rendered by a callback"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Custom class names."
    },
    logoClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Custom class name for the logo"
    },
    titleClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Custom class name for the title"
    }
  }
};