import isString from "lodash/isString";
export var sortWithLocale = function sortWithLocale(language) {
  return function (a, b) {
    if (isString(a) && isString(b)) {
      return new Intl.Collator(language).compare(a, b);
    }

    if (a > b) {
      return 1;
    }

    if (a < b) {
      return -1;
    }

    return 0;
  };
};