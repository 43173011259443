import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";

function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();

  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
        result;

    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;

      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }

    return _possibleConstructorReturn(this, result);
  };
}

function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;

  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}

import React from 'react';
import Tippy from '@tippyjs/react';
import { followCursor, sticky } from 'tippy.js';
import { placementOptions } from '@jutro/prop-types';
import { uniqueInnerId } from '@jutro/platform';
import PropTypes from 'prop-types';
import 'tippy.js/dist/tippy.css';
import styles from "./Tooltip.module.css";
export var Tooltip = function (_React$Component) {
  _inherits(Tooltip, _React$Component);

  var _super = _createSuper(Tooltip);

  function Tooltip() {
    var _this;

    _classCallCheck(this, Tooltip);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "onMount", function (instance) {
      instance.reference.setAttribute('aria-describedby', "tippy-".concat(instance.id));
      instance.reference.setAttribute('aria-controls', "tippy-".concat(instance.id));
      instance.reference.setAttribute('aria-owns', "tippy-".concat(instance.id));
    });

    _defineProperty(_assertThisInitialized(_this), "onHide", function (instance) {
      instance.reference.removeAttribute('aria-describedby');
      instance.reference.removeAttribute('aria-controls');
      instance.reference.removeAttribute('aria-owns');
    });

    _defineProperty(_assertThisInitialized(_this), "renderTooltip", function () {
      var _this$props = _this.props,
          id = _this$props.id,
          children = _this$props.children,
          showOnInit = _this$props.showOnInit,
          flipBehavior = _this$props.flipBehavior,
          tippyProps = _objectWithoutProperties(_this$props, ["id", "children", "showOnInit", "flipBehavior"]);

      if (!tippyProps.content) {
        return null;
      }

      var tooltipWrapperId = id || 'tooltip';

      var _uniqueInnerId = uniqueInnerId(tooltipWrapperId, 'tooltipId'),
          tooltipId = _uniqueInnerId.tooltipId;

      return React.createElement(Tippy, _extends({}, tippyProps, Tooltip.nonConfigurableTippyProps, {
        interactive: true,
        className: styles.tooltip,
        onMount: _this.onMount,
        onHide: _this.onHide,
        aria: tooltipId,
        showOnCreate: showOnInit,
        popperOptions: {
          modifiers: [{
            name: 'flip',
            options: {
              flipBehavior: flipBehavior
            }
          }]
        },
        plugins: [sticky, followCursor]
      }), children);
    });

    return _this;
  }

  _createClass(Tooltip, [{
    key: "render",
    value: function render() {
      return this.renderTooltip();
    }
  }]);

  return Tooltip;
}(React.Component);

_defineProperty(Tooltip, "propTypes", {
  id: PropTypes.string.isRequired,
  followCursor: PropTypes.bool,
  placement: PropTypes.oneOf(placementOptions),
  trigger: PropTypes.string,
  duration: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  hideOnClick: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  animation: PropTypes.string,
  delay: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  sticky: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  flipBehavior: PropTypes.array,
  showOnInit: PropTypes.bool
});

_defineProperty(Tooltip, "defaultProps", {
  followCursor: false,
  placement: 'right',
  trigger: 'mouseenter focus',
  duration: [300, 300],
  hideOnClick: true,
  animation: 'fade',
  delay: [0, 20],
  sticky: true,
  flipBehavior: ['right', 'bottom', 'top', 'left', 'right'],
  showOnInit: false
});

_defineProperty(Tooltip, "nonConfigurableTippyProps", {
  animation: 'fade',
  arrow: true,
  inertia: false,
  theme: 'dark'
});

Tooltip.__docgenInfo = {
  componentName: "Tooltip",
  packageName: "@jutro/components",
  description: "Adds a tooltip to the parent component.",
  displayName: "Tooltip",
  methods: [{
    name: "onMount",
    docblock: null,
    modifiers: [],
    params: [{
      name: "instance",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "onHide",
    docblock: null,
    modifiers: [],
    params: [{
      name: "instance",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "renderTooltip",
    docblock: "Renders Tooltip\n@returns {React.ReactElement}",
    modifiers: [],
    params: [],
    returns: {
      description: null,
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Renders Tooltip"
  }],
  actualName: "Tooltip",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Used to identify the component. referenced to find it in the document tree"
    },
    followCursor: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Determines if follows the user's mouse cursor",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    placement: {
      type: {
        name: "enum",
        computed: true,
        value: "placementOptions"
      },
      required: false,
      description: "Positions of the tooltip relative to its reference element",
      defaultValue: {
        value: "'right'",
        computed: false
      }
    },
    trigger: {
      type: {
        name: "string"
      },
      required: false,
      description: "The events (each separated by a space) which cause tooltip to show",
      defaultValue: {
        value: "'mouseenter focus'",
        computed: false
      }
    },
    duration: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "array"
        }]
      },
      required: false,
      description: "Duration of the CSS transition animation in ms",
      defaultValue: {
        value: "[300, 300]",
        computed: false
      }
    },
    hideOnClick: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }]
      },
      required: false,
      description: "Determines if the tooltip should hide if a mousedown event was fired outside of it",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    animation: {
      type: {
        name: "string"
      },
      required: false,
      description: "The type of transition animation",
      defaultValue: {
        value: "'fade'",
        computed: false
      }
    },
    delay: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "array"
        }]
      },
      required: false,
      description: "Delay in ms once a trigger event is fired before a tooltip shows or hides",
      defaultValue: {
        value: "[0, 20]",
        computed: false
      }
    },
    sticky: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }]
      },
      required: false,
      description: "Ensures the tooltip stays stuck to its reference element if it moves around or changes size while showing",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    flipBehavior: {
      type: {
        name: "array"
      },
      required: false,
      description: "Determines the order of flipping, i.e. which placements to prefer if a certain placement cannot be used",
      defaultValue: {
        value: "['right', 'bottom', 'top', 'left', 'right']",
        computed: false
      }
    },
    showOnInit: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Show tooltip when page is loaded",
      defaultValue: {
        value: "false",
        computed: false
      }
    }
  }
};