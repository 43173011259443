import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxField } from '@jutro/components';
import styles from "./ColumnEntry.module.css";
export function ColumnEntry(_ref) {
  var id = _ref.id,
      header = _ref.header,
      enabled = _ref.enabled,
      onToggle = _ref.onToggle;
  return !!header && React.createElement("div", {
    className: styles.columnEntry
  }, React.createElement(CheckboxField, {
    id: id,
    label: header,
    controlClassName: styles.columnEntryCheckbox,
    labelClassName: styles.columnEntryLabel,
    value: enabled,
    showInlineLabel: true,
    onValueChange: onToggle
  }));
}
ColumnEntry.propTypes = {
  id: PropTypes.string.isRequired,
  header: PropTypes.string,
  enabled: PropTypes.bool,
  onToggle: PropTypes.func
};
ColumnEntry.__docgenInfo = {
  componentName: "ColumnEntry",
  packageName: "@jutro/datatable",
  description: "ColumnEntry",
  displayName: "ColumnEntry",
  methods: [],
  actualName: "ColumnEntry",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Used to identify the component"
    },
    header: {
      type: {
        name: "string"
      },
      required: false,
      description: "Label text to be displayed beside the checkbox"
    },
    enabled: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, the checkbox marked as checked"
    },
    onToggle: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback to toggle the checkbox enabled state"
    }
  }
};