import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
import _Array$isArray from "@babel/runtime-corejs3/core-js-stable/array/is-array";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _ from 'lodash';
import { log } from '@jutro/logger';
import Options from './Options';

var Registration = function () {
  function Registration(id, Service, lazy) {
    _classCallCheck(this, Registration);

    this.id = id;
    this.name = Service.name || Service.constructor.name;
    this.attributes = {};

    if (!lazy) {
      this.service = new Service();
    } else {
      this.init = Service;
    }
  }

  _createClass(Registration, [{
    key: "associate",
    value: function associate(attributes) {
      this.attributes = _.merge(this.attributes, attributes);
    }
  }, {
    key: "getMetadata",
    value: function getMetadata() {
      return _.merge({
        id: this.id,
        name: this.name
      }, this.attributes);
    }
  }, {
    key: "isLazy",
    value: function isLazy() {
      return !!this.init;
    }
  }, {
    key: "initializeService",
    value: function initializeService() {
      if (!this.init) {
        log.warning('Service is already instantiated');
      } else {
        var Service = this.init;
        this.service = new Service();
        this.init = null;
      }
    }
  }, {
    key: "getService",
    value: function getService() {
      if (this.isLazy()) {
        this.initializeService();
      }

      return this.service;
    }
  }]);

  return Registration;
}();

var ServiceRegistry = function () {
  function ServiceRegistry() {
    _classCallCheck(this, ServiceRegistry);

    this.registrations = {};
    this.implementations = {};
  }

  _createClass(ServiceRegistry, [{
    key: "register",
    value: function register(id, Service) {
      var _this = this;

      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (this.registrations[id]) {
        log.warning("Service with id '".concat(id, "' has already been registered"));
      } else {
        var registration = new Registration(id, Service, options.lazy);
        var opts = new Options(options);
        opts.onOption('implements', function (implemented) {
          var contracts = _Array$isArray(implemented) ? implemented : [implemented];

          _forEachInstanceProperty(contracts).call(contracts, function (c) {
            if (!c.implementedBy(Service)) {
              throw new Error("Service ".concat(Service.name || Service.constructor.name, " does not implement: ").concat(c));
            }

            var key = c.hash();

            if (!_this.implementations[key]) {
              _this.implementations[key] = [];
            }

            _this.implementations[key].push(registration);
          });
        });
        opts.onOption('attributes', function (attributes) {
          return registration.associate(attributes);
        });
        this.registrations[id] = registration;
      }
    }
  }, {
    key: "hasRegistration",
    value: function hasRegistration(id) {
      return !!this.registrations[id];
    }
  }, {
    key: "getRegistration",
    value: function getRegistration(id) {
      return this.registrations[id];
    }
  }, {
    key: "getRegistrationIds",
    value: function getRegistrationIds() {
      return _Object$keys(this.registrations);
    }
  }, {
    key: "getImplementorsOf",
    value: function getImplementorsOf(contract) {
      var implementors = this.implementations[contract.hash()];

      if (!implementors || implementors.length <= 0) {
        log.warning("No implementors found for: ".concat(contract));
      }

      return implementors || [];
    }
  }]);

  return ServiceRegistry;
}();

export { ServiceRegistry as default };