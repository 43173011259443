export var dateFormatMap = {
  vshort: 'P',
  short: 'PP',
  long: 'PPP',
  abbreviated: 'PP',
  full: 'PPPP'
};
export var timeFormat = 'p';
export function getDateFormat(format) {
  return dateFormatMap[format] || dateFormatMap.long;
}
export function getTimeFormat() {
  return timeFormat;
}
export var minDefaultYear = 1900;
export var maxDefaultYear = 2100;