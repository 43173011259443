import PropTypes from 'prop-types';
import { intlMessageShape } from './intlMessageShape';
export var contextPropType = {
  path: PropTypes.string,
  title: intlMessageShape,
  exact: PropTypes.bool,
  match: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)])
};
export var contextShape = PropTypes.shape(contextPropType);
export var contextSwitcherPropTypes = {
  defaultLabel: intlMessageShape,
  values: PropTypes.arrayOf(contextShape.isRequired).isRequired
};
export var contextSwitcherShape = PropTypes.shape(contextSwitcherPropTypes);