import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";

function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();

  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
        result;

    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;

      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }

    return _possibleConstructorReturn(this, result);
  };
}

function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;

  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from "./AddressDisplay.module.css";
export var AddressDisplay = function (_PureComponent) {
  _inherits(AddressDisplay, _PureComponent);

  var _super = _createSuper(AddressDisplay);

  function AddressDisplay() {
    _classCallCheck(this, AddressDisplay);

    return _super.apply(this, arguments);
  }

  _createClass(AddressDisplay, [{
    key: "renderFields",
    value: function renderFields() {
      var _context;

      var _this$props = this.props,
          address = _this$props.address,
          fields = _this$props.fields;
      return _mapInstanceProperty(_context = _filterInstanceProperty(fields).call(fields, function (field) {
        return !!address[field];
      })).call(_context, function (existingField) {
        return React.createElement("div", null, address[existingField]);
      });
    }
  }, {
    key: "render",
    value: function render() {
      var className = this.props.className;
      var classes = cx(styles.addressDisplay, className);
      return React.createElement("div", {
        className: classes
      }, this.renderFields());
    }
  }]);

  return AddressDisplay;
}(PureComponent);

_defineProperty(AddressDisplay, "propTypes", {
  address: PropTypes.object.isRequired,
  fields: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string
});

_defineProperty(AddressDisplay, "defaultProps", {
  fields: ['addressLine1', 'addressLine2', 'addressLine3', 'city', 'state', 'postalCode']
});

AddressDisplay.__docgenInfo = {
  componentName: "AddressDisplay",
  packageName: "@jutro/components",
  description: "Displays an address from a data object in read-only mode.",
  displayName: "AddressDisplay",
  methods: [{
    name: "renderFields",
    docblock: "Renders the list of fields.\n\n@returns {Array<React.ReactElement>} - The list of fields",
    modifiers: [],
    params: [],
    returns: {
      description: "The list of fields",
      type: {
        name: "Array",
        elements: [{
          name: "React.ReactElement"
        }]
      }
    },
    description: "Renders the list of fields."
  }],
  actualName: "AddressDisplay",
  metadataType: "element",
  props: {
    address: {
      type: {
        name: "object"
      },
      required: true,
      description: "The address to display"
    },
    fields: {
      type: {
        name: "arrayOf",
        value: {
          name: "string"
        }
      },
      required: false,
      description: "The array of address fields to display, for example ['addressLine1', 'addressLine2',\n'addressLine3', 'city', 'state', 'postalCode']",
      defaultValue: {
        value: "[\n    'addressLine1',\n    'addressLine2',\n    'addressLine3',\n    'city',\n    'state',\n    'postalCode',\n]",
        computed: false
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    }
  }
};