import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _slicedToArray2 from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import React, { useRef, useCallback, useState } from 'react';
import isFunction from "lodash/isFunction";
export function bindActionCreators(dispatch) {
  var actions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _context;

  return _reduceInstanceProperty(_context = _Object$entries(actions)).call(_context, function (acc, _ref) {
    var _ref2 = _slicedToArray2(_ref, 2),
        name = _ref2[0],
        action = _ref2[1];

    return _objectSpread({}, acc, _defineProperty({}, name, function () {
      return dispatch(action.apply(void 0, arguments));
    }));
  }, {});
}
export function useControlledReducer(_ref3) {
  var reducer = _ref3.reducer,
      initialState = _ref3.initialState,
      controlledState = _ref3.controlledState,
      onStateChange = _ref3.onStateChange,
      _ref3$updateInControl = _ref3.updateInControlledMode,
      updateInControlledMode = _ref3$updateInControl === void 0 ? false : _ref3$updateInControl;
  var store = useRef({
    state: initialState
  }).current;

  var _useState = useState(0),
      _useState2 = _slicedToArray(_useState, 2),
      setState = _useState2[1];

  var update = useCallback(function () {
    return setState(function (state) {
      return state + 1;
    });
  }, []);

  if (controlledState !== undefined) {
    store.state = isFunction(controlledState) ? controlledState(store.state) : controlledState;
  }

  store.reducer = reducer;

  store.dispatch = function (action) {
    var oldState = store.state;
    var newState = store.reducer(oldState, action);

    if (newState === oldState) {
      return;
    }

    if (onStateChange) {
      onStateChange(newState);
    }

    if (!controlledState || updateInControlledMode) {
      store.state = newState;
      update();
    }
  };

  var dispatch = useCallback(function (action) {
    return store.dispatch(action);
  }, [store]);
  return [store.state, dispatch];
}