import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { createHOC } from '@jutro/platform';
import { intlMessageShape } from '@jutro/prop-types';
import { ValueComponentWrapper } from './ValueComponentWrapper';
import { FormattedDate, formattedDatePropTypes } from './FormattedDate';
var DateValueInternal = createHOC({
  component: FormattedDate,
  wrapper: ValueComponentWrapper,
  displayName: 'DateValue'
});

var dateValuePropTypes = _objectSpread({
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  path: PropTypes.string,
  tag: PropTypes.string,
  prefix: intlMessageShape,
  suffix: intlMessageShape
}, formattedDatePropTypes);

export var DateValue = function DateValue(props) {
  return React.createElement(DateValueInternal, props);
};
DateValue.propTypes = dateValuePropTypes;
DateValue.defaultProps = _objectSpread({
  tag: 'div'
}, FormattedDate.defaultProps);
DateValue.__docgenInfo = {
  componentName: "DateValue",
  packageName: "@jutro/components",
  description: "Renders a formatted date using the `tag` property to wrap\nthe value.",
  displayName: "DateValue",
  methods: [],
  actualName: "DateValue",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Used to identify the component"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    path: {
      type: {
        name: "string"
      },
      required: false,
      description: "Hint path to value"
    },
    tag: {
      type: {
        name: "string"
      },
      required: false,
      description: "The html tag to use when rendering the outermost element of this component",
      defaultValue: {
        value: "'div'",
        computed: false
      }
    },
    prefix: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Optional prefix message to be attached in front of the value"
    },
    suffix: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Optional suffix message to be attached at the end the value"
    }
  },
  composes: ["./FormattedDate"]
};