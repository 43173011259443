import _JSON$stringify from "@babel/runtime-corejs3/core-js-stable/json/stringify";
import _URLSearchParams from "@babel/runtime-corejs3/core-js-stable/url-search-params";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import { JSON_CONTENT_TYPE, CONTENT_TYPE } from '../common';

function isJsonPayload(headers) {
  var _headers$CONTENT_TYPE;

  return (_headers$CONTENT_TYPE = headers[CONTENT_TYPE]) === null || _headers$CONTENT_TYPE === void 0 ? void 0 : _includesInstanceProperty(_headers$CONTENT_TYPE).call(_headers$CONTENT_TYPE, JSON_CONTENT_TYPE);
}

function isBodyInit(data) {
  return typeof data === 'string' || data instanceof Blob || data instanceof FormData || data instanceof ArrayBuffer || data instanceof _URLSearchParams;
}

export function defaultEncoder(data, options) {
  if (options !== null && options !== void 0 && options.headers && isJsonPayload(options.headers)) {
    return isBodyInit(data) ? data : _JSON$stringify(data);
  }

  return data;
}