import { defineMessages } from 'react-intl';
export var messages = defineMessages({
  noDataText: {
    id: 'jutro-datatable.DataTable.Pagination.NoDataText',
    defaultMessage: 'No rows found'
  },
  loadingText: {
    id: 'jutro-datatable.DataTable.Pagination.LoadingText',
    defaultMessage: 'Loading...'
  },
  expandedRowLabel: {
    id: 'jutro-datatable.DataTable.Expander.ExpandedRowLabel',
    defaultMessage: 'Collapse row'
  },
  collapsedRowLabel: {
    id: 'jutro-datatable.DataTable.Expander.CollapsedRowLabel',
    defaultMessage: 'Expand row'
  },
  tableLabel: {
    id: 'jutro-datatable.DataTable.TableLabel',
    defaultMessage: 'Data Table'
  },
  searchPlaceholder: {
    id: 'jutro-datatable.DataTable.SearchPlaceholder',
    defaultMessage: 'Search...'
  }
});