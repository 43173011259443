export var consumerTypography = {
  '--GW-FONT-SIZE-H1': '2.5rem',
  '--GW-FONT-SIZE-H2': '2rem',
  '--GW-FONT-SIZE-H3': '1.5rem',
  '--GW-FONT-SIZE-H4': '1.25rem',
  '--GW-FONT-SIZE-H5': '1rem',
  '--GW-FONT-SIZE-BODY': '1rem',
  '--GW-FONT-SIZE-SUBTEXT': '0.875rem',
  '--GW-FONT-SIZE-LABEL': '1rem',
  '--GW-FONT-SIZE-SECONDARY-LABEL': '0.875rem',
  '--GW-FONT-SIZE-INPUT': '1rem',
  '--GW-FONT-SIZE-BUTTON': '1rem',
  '--GW-ICON-FONT-SIZE-1': '1rem',
  '--GW-ICON-FONT-SIZE-2': '1.25rem',
  '--GW-ICON-FONT-SIZE-3': '1.5rem'
};