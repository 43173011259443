import { defineMessages } from 'react-intl';
export var messages = defineMessages({
  actionWarning: {
    id: 'jutro-router.alert.Action Warning',
    defaultMessage: 'Action Warning'
  },
  ok: {
    id: 'jutro-router.alert.OK',
    defaultMessage: 'OK'
  }
});