import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
import React from 'react';
export var childrenToArray = function childrenToArray(children) {
  var _context;

  var newFirstChildren = [];

  _forEachInstanceProperty(_context = React.Children).call(_context, children, function (child) {
    newFirstChildren.push(child);
  });

  return newFirstChildren;
};