import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import styles from "./Flex.module.css";
import { useBreakpoint } from '../breakpoint/useBreakpoint';
var alignSelfMapping = {
  top: styles.gwAlignSelfStart,
  middle: styles.gwAlignSelfCenter,
  bottom: styles.gwAlignSelfEnd,
  left: styles.gwAlignSelfStart,
  center: styles.gwAlignSelfCenter,
  right: styles.gwAlignSelfEnd,
  baseline: styles.gwAlignSelfBaseline,
  stretch: styles.gwAlignSelfStretch
};
var textAlignMapping = {
  left: styles.gwTextLeft,
  center: styles.gwTextCenter,
  right: styles.gwTextRight
};
var styleMappings = null;

if (window.__TEST__) {
  styleMappings = {
    alignSelf: alignSelfMapping,
    textAlign: textAlignMapping
  };
}

export { styleMappings };
export var FlexItem = function FlexItem(props) {
  var _useBreakpoint = useBreakpoint(props),
      componentProps = _useBreakpoint.breakpointProps;

  var visible = componentProps.visible,
      grow = componentProps.grow,
      shrink = componentProps.shrink,
      alignSelf = componentProps.alignSelf,
      textAlign = componentProps.textAlign,
      tag = componentProps.tag,
      children = componentProps.children,
      className = componentProps.className,
      phone = componentProps.phone,
      phoneWide = componentProps.phoneWide,
      tablet = componentProps.tablet,
      dangerouslySetInnerHTML = componentProps.dangerouslySetInnerHTML,
      other = _objectWithoutProperties(componentProps, ["visible", "grow", "shrink", "alignSelf", "textAlign", "tag", "children", "className", "phone", "phoneWide", "tablet", "dangerouslySetInnerHTML"]);

  var Tag = tag;
  var inlineStyles = useMemo(function () {
    if (!grow && !shrink) {
      return undefined;
    }

    return pickBy({
      flexGrow: grow,
      flexShrink: shrink
    }, identity);
  }, [grow, shrink]);

  if (visible === false) {
    return null;
  }

  var textAlignClass = textAlign && textAlignMapping[textAlign];
  var alignSelfClass = alignSelf && alignSelfMapping[alignSelf];
  var classes = cx(styles.gwFlexItem, textAlignClass, alignSelfClass, className);
  return React.createElement(Tag, _extends({
    className: classes,
    style: inlineStyles
  }, other), children);
};
var flexItemPropTypes = {
  visible: PropTypes.bool,
  grow: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  shrink: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  textAlign: PropTypes.oneOf(_Object$keys(textAlignMapping)),
  alignSelf: PropTypes.oneOf(_Object$keys(alignSelfMapping)),
  tag: PropTypes.elementType,
  className: PropTypes.string,
  children: PropTypes.node,
  phone: PropTypes.object,
  phoneWide: PropTypes.object,
  tablet: PropTypes.object
};
FlexItem.propTypes = flexItemPropTypes;
FlexItem.defaultProps = {
  tag: 'div'
};
FlexItem.displayName = 'FlexItem';
FlexItem.__docgenInfo = {
  componentName: "FlexItem",
  packageName: "@jutro/layout",
  description: "Defines a css grid 'FlexItem'. This is used in conjunction with 'Grid' parent.",
  displayName: "FlexItem",
  methods: [],
  actualName: "FlexItem",
  metadataType: "layout",
  props: {
    visible: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Define whether FlexItem is visible"
    },
    grow: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: false,
      description: "Whether flex item can grow"
    },
    shrink: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: false,
      description: "Whether flex item can shrink"
    },
    textAlign: {
      type: {
        name: "enum",
        value: [{
          value: "\"left\"",
          computed: false
        }, {
          value: "\"center\"",
          computed: false
        }, {
          value: "\"right\"",
          computed: false
        }]
      },
      required: false,
      description: "Horizontally align the contents of the column"
    },
    alignSelf: {
      type: {
        name: "enum",
        value: [{
          value: "\"top\"",
          computed: false
        }, {
          value: "\"middle\"",
          computed: false
        }, {
          value: "\"bottom\"",
          computed: false
        }, {
          value: "\"left\"",
          computed: false
        }, {
          value: "\"center\"",
          computed: false
        }, {
          value: "\"right\"",
          computed: false
        }, {
          value: "\"baseline\"",
          computed: false
        }, {
          value: "\"stretch\"",
          computed: false
        }]
      },
      required: false,
      description: "Align the flex item according to the cross axis of the flex direction."
    },
    tag: {
      type: {
        name: "elementType"
      },
      required: false,
      description: "Dom tag to use",
      defaultValue: {
        value: "'div'",
        computed: false
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Css class(es) to append to tag"
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "Children for this item"
    },
    phone: {
      type: {
        name: "object"
      },
      required: false,
      description: "Include any FlexItem property for use at 'phone' breakpoint"
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: false,
      description: "Include any FlexItem property for use at 'phoneWide' breakpoint;"
    },
    tablet: {
      type: {
        name: "object"
      },
      required: false,
      description: "Include any FlexItem property for use at 'tablet' breakpoint;"
    }
  }
};