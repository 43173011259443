import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
export var PageHead = function PageHead(props) {
  var currentLanguage = props.currentLanguage,
      appName = props.appName,
      appDescription = props.appDescription;
  return React.createElement(Helmet, {
    htmlAttributes: {
      lang: currentLanguage
    },
    titleTemplate: "%s - ".concat(appName),
    defaultTitle: appName
  }, React.createElement("meta", {
    name: "description",
    content: appDescription
  }));
};
PageHead.propTypes = {
  currentLanguage: PropTypes.string,
  appName: PropTypes.string,
  appDescription: PropTypes.string
};
PageHead.__docgenInfo = {
  componentName: "PageHead",
  packageName: "@jutro/router",
  description: "PageHead component provides an api to configure and manipulate\nthe head tag on the page",
  displayName: "PageHead",
  methods: [],
  actualName: "PageHead",
  props: {
    currentLanguage: {
      type: {
        name: "string"
      },
      required: false,
      description: "Page current language"
    },
    appName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Application name to display in title"
    },
    appDescription: {
      type: {
        name: "string"
      },
      required: false,
      description: "Application description"
    }
  }
};