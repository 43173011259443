import { COLLAPSE, TOGGLE, COLLAPSE_ALL } from './types';
export var collapse = function collapse(payload) {
  return {
    type: COLLAPSE,
    payload: payload
  };
};
export var toggle = function toggle(rowId, viewIndex, allowMultiple) {
  return {
    type: TOGGLE,
    payload: {
      rowId: rowId,
      viewIndex: viewIndex,
      allowMultiple: allowMultiple
    }
  };
};
export var collapseAll = function collapseAll() {
  return {
    type: COLLAPSE_ALL
  };
};