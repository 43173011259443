import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _bindInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/bind";
import _findIndexInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find-index";
import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _Array$isArray from "@babel/runtime-corejs3/core-js-stable/array/is-array";
import _everyInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/every";
import _Object$getPrototypeOf from "@babel/runtime-corejs3/core-js-stable/object/get-prototype-of";
import { Children, Component, Fragment, isValidElement } from 'react';
import { error } from '@jutro/logger';
import isNil from "lodash/isNil";
export var getDisplayName = function getDisplayName(AComponent) {
  var _AComponent$construct;

  if (!AComponent) {
    return undefined;
  }

  return AComponent.displayName || AComponent.name || ((_AComponent$construct = AComponent.constructor) === null || _AComponent$construct === void 0 ? void 0 : _AComponent$construct.name);
};

function childIsOfType(child, componentType) {
  var childDisplayName = getDisplayName(child.type);
  var componentTypeDisplayName = getDisplayName(componentType);

  if (child.type === componentType || childDisplayName === componentTypeDisplayName) {
    return true;
  }

  if (typeof child.type !== 'string' && Object.prototype.isPrototypeOf.call(Component, child.type)) {
    var prototype = _Object$getPrototypeOf(child.type);

    while (prototype !== Component) {
      if (prototype === componentType) {
        return true;
      }

      prototype = _Object$getPrototypeOf(prototype);
    }
  }

  return false;
}

function createChildComponentTypeChecker(componentType) {
  var componentDisplayName = getDisplayName(componentType);

  var checkType = function checkType(props, propName, componentName, location, propFullName) {
    var _context;

    var componentNameSafe = componentName || '<<anonymous>>';
    var propFullNameSafe = propFullName || propName;
    var children = props[propName];

    if (typeof children === 'object' && childIsOfType(children, Fragment)) {
      children = children.props[propName];
    }

    if (!_everyInstanceProperty(_context = Children.toArray(children)).call(_context, function (child) {
      return childIsOfType(child, componentType);
    })) {
      return new Error("Invalid ".concat(location, " `").concat(propFullNameSafe, "` supplied to `").concat(componentNameSafe, "`. ") + "".concat(componentNameSafe, " accepts only ").concat(componentDisplayName, " components as children."));
    }

    return null;
  };

  return wrapWithIsRequired(checkType);
}

function createOneOfChildComponentTypeChecker(componentTypes) {
  if (!_Array$isArray(componentTypes)) {
    throw new TypeError("Expected an array for the 'componentTypes' parameter, got ".concat(typeof componentTypes, " instead"));
  }

  var displayNames = _mapInstanceProperty(componentTypes).call(componentTypes, function (componentType) {
    return getDisplayName(componentType);
  });

  function checkType(props, propName, componentName) {
    var prop = props[propName];

    _forEachInstanceProperty(Children).call(Children, prop, function (child) {
      if (!child) {
        return;
      }

      var matchedIndex = _findIndexInstanceProperty(componentTypes).call(componentTypes, function (componentType) {
        return childIsOfType(child, componentType);
      });

      if (matchedIndex < 0) {
        error("".concat(componentName, " child is a '").concat(getDisplayName(child.type), "', but should be one of: ").concat(displayNames, "."));
      }
    });
  }

  return checkType;
}

function createAltTextWithImageChecker() {
  function checkAltTextDefinedWithImage(props, propName) {
    if (props.image && !props.title && !props[propName]) {
      return new Error('imageAltText or title must be defined with image');
    }

    return undefined;
  }

  return checkAltTextDefinedWithImage;
}

export var wrapWithIsRequired = function wrapWithIsRequired(propType) {
  var wrappedPropType = _bindInstanceProperty(propType).call(propType, null);

  wrappedPropType.isRequired = function (props, propName, componentName) {
    var value = props[propName];

    if (isNil(value)) {
      return new Error("Failed value type: The value `".concat(propName, "` is marked as required in `").concat(componentName, "`, but its value is `").concat(value, "`."));
    }

    for (var _len = arguments.length, rest = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
      rest[_key - 3] = arguments[_key];
    }

    return propType.apply(void 0, [props, propName, componentName].concat(rest));
  };

  return wrappedPropType;
};

var and = function and() {
  for (var _len2 = arguments.length, propTypes = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    propTypes[_key2] = arguments[_key2];
  }

  return wrapWithIsRequired(function () {
    for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      args[_key3] = arguments[_key3];
    }

    return _reduceInstanceProperty(propTypes).call(propTypes, function (propTypeError, propType) {
      return propTypeError || propType.apply(void 0, args);
    }, null);
  });
};

var enabledOn = function enabledOn(propType, anotherProp, anotherPropValue) {
  return wrapWithIsRequired(function (props) {
    for (var _len4 = arguments.length, rest = new Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) {
      rest[_key4 - 1] = arguments[_key4];
    }

    return props[anotherProp] === anotherPropValue ? propType.apply(void 0, [props].concat(rest)) : null;
  });
};

var withLength = function withLength(expectedLengthExpression) {
  return wrapWithIsRequired(function (props, propName, componentName) {
    var value = props[propName];

    if (isNil(value)) {
      return null;
    }

    if (value.length === undefined) {
      return new Error("Invalid prop '".concat(propName, "' with no length field supplied to '").concat(componentName, "', expected value with length field"));
    }

    var length = value.length;
    var expectedLength = typeof expectedLengthExpression === 'number' ? expectedLengthExpression : expectedLengthExpression(props);

    if (length === expectedLength) {
      return null;
    }

    return new Error("Invalid prop '".concat(propName, "' with length '").concat(length, "' supplied to '").concat(componentName, "', expected value with length '").concat(expectedLength, "'"));
  });
};

var elementsWithId = function elementsWithId(props, propName, componentName, location, propFullName) {
  var _context2;

  var prop = props[propName];
  var componentNameSafe = componentName || '<<anonymous>>';
  var propFullNameSafe = propFullName || propName;

  if (!_everyInstanceProperty(_context2 = Children.toArray(prop)).call(_context2, function (child) {
    return isValidElement(child) && child.props.id;
  })) {
    return new Error("Invalid ".concat(location, " `").concat(propFullNameSafe, "` supplied to `").concat(componentNameSafe, "`. ") + "Provide an array/children of React elements with `id` prop on each.");
  }

  return null;
};

export var ComponentPropTypes = {
  childOfComponentType: createChildComponentTypeChecker,
  oneOfChildOfComponentTypes: createOneOfChildComponentTypeChecker,
  altTextDefinedWithImage: createAltTextWithImageChecker,
  all: and,
  enabledOn: enabledOn,
  withLength: withLength,
  elementsWithId: wrapWithIsRequired(elementsWithId)
};