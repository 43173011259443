import _extends from "@babel/runtime-corejs3/helpers/extends";
import _JSON$stringify from "@babel/runtime-corejs3/core-js-stable/json/stringify";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import isObject from "lodash/isObject";
import { isEmptyValue } from '@jutro/data';
import { intlMessageShape } from '@jutro/prop-types';
import { TranslatorContext } from '@jutro/locale';
import { DateValue } from './DateValue';
import { CurrencyValue } from './CurrencyValue';
import { NumberValue } from './NumberValue';
import { FieldComponent } from '../inputs/FieldComponent/FieldComponent';
export var fieldValueComponentMap = {
  currency: {
    component: CurrencyValue
  },
  date: {
    component: DateValue
  },
  'date-time': {
    component: DateValue
  },
  decimal: {
    component: NumberValue,
    componentProps: {
      format: 'decimal',
      showFractions: true
    }
  },
  integer: {
    component: NumberValue,
    componentProps: {
      format: 'decimal',
      showFractions: false
    }
  },
  number: {
    component: NumberValue,
    componentProps: {
      format: 'decimal',
      showFractions: true
    }
  },
  percent: {
    component: NumberValue,
    componentProps: {
      format: 'percent',
      showFractions: false
    }
  },
  year: {
    component: NumberValue
  }
};
var fieldValuePropTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  datatype: PropTypes.string,
  path: PropTypes.string,
  placeholder: intlMessageShape,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.bool]),
  prefix: intlMessageShape,
  suffix: intlMessageShape,
  tag: PropTypes.string
};
export var FieldValue = function FieldValue(props) {
  var id = props.id,
      className = props.className,
      Tag = props.tag,
      datatype = props.datatype,
      path = props.path,
      placeholder = props.placeholder,
      value = props.value,
      onValueChange = props.onValueChange,
      prefix = props.prefix,
      suffix = props.suffix,
      other = _objectWithoutProperties(props, ["id", "className", "tag", "datatype", "path", "placeholder", "value", "onValueChange", "prefix", "suffix"]);

  var translator = useContext(TranslatorContext);
  var componentInfo = fieldValueComponentMap[datatype];

  if (!componentInfo) {
    var getValue = function getValue() {
      if (isEmptyValue(value)) {
        return translator(placeholder);
      }

      return isObject(value) ? _JSON$stringify(value) : value.toString();
    };

    return React.createElement(Tag, {
      id: id,
      title: value,
      className: className,
      "data-read-only": true
    }, prefix && translator(prefix), getValue(), suffix && translator(suffix));
  }

  var ValueComponent = componentInfo.component,
      componentProps = componentInfo.componentProps;
  return React.createElement(ValueComponent, _extends({}, componentProps, {
    id: id,
    className: className,
    tag: Tag,
    placeholder: placeholder,
    value: value,
    prefix: prefix,
    suffix: suffix
  }, other));
};
FieldValue.propTypes = fieldValuePropTypes;
FieldValue.defaultProps = {
  tag: 'span',
  placeholder: FieldComponent.defaultROEmptyValue
};
FieldValue.__docgenInfo = {
  componentName: "FieldValue",
  packageName: "@jutro/components",
  description: "Renders a formatted value using the `tag` and `datatype` property to wrap\nthe value.",
  displayName: "FieldValue",
  methods: [],
  actualName: "FieldValue",
  metadataType: "element",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Used to identify the component"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    datatype: {
      type: {
        name: "string"
      },
      required: false,
      description: "Hint for value's datatype"
    },
    path: {
      type: {
        name: "string"
      },
      required: false,
      description: "Hint path to value"
    },
    placeholder: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "The string to display if 'value' is undefined/null",
      defaultValue: {
        value: "FieldComponent.defaultROEmptyValue",
        computed: true
      }
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }, {
          name: "object"
        }, {
          name: "bool"
        }]
      },
      required: false,
      description: "The value to display"
    },
    prefix: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Optional prefix message to be attached in front of the value"
    },
    suffix: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Optional suffix message to be attached at the end the value"
    },
    tag: {
      type: {
        name: "string"
      },
      required: false,
      description: "The html tag to use when rendering the outermost element of this component",
      defaultValue: {
        value: "'span'",
        computed: false
      }
    }
  }
};