import { defineMessages } from 'react-intl';
export var messages = defineMessages({
  requiredField: {
    id: 'jutro-components.widgets.inputs.This is a required field',
    defaultMessage: 'This is a required field'
  },
  optional: {
    id: 'jutro-components.widgets.inputs.Optional',
    defaultMessage: '(Optional)'
  }
});