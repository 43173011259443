import _ from 'lodash';

function isCurrentPath(instance, path) {
  var loc = instance.props.location;

  if (typeof path === 'object') {
    var pathname = path.pathname,
        _path$search = path.search,
        search = _path$search === void 0 ? '' : _path$search,
        _path$hash = path.hash,
        hash = _path$hash === void 0 ? '' : _path$hash;
    return pathname === loc.pathname && search === loc.search && hash === loc.hash;
  }

  return path === loc.pathname;
}

export function navigateTo(instance, path, result) {
  if (!instance) {
    return;
  }

  if (path && !isCurrentPath(instance, path)) {
    var location = path;

    if (_.isString(location)) {
      location = {
        pathname: path,
        state: {
          action: 'redirect',
          result: result
        }
      };
    } else if (result) {
      _.set(location, 'state.result', result);
    }

    var _instance$props = instance.props,
        history = _instance$props.history,
        replace = _instance$props.replace;

    if (replace) {
      history.replace(location);
    } else {
      history.push(location);
    }
  }
}