import _defineProperty2 from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty2(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";

function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();

  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
        result;

    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;

      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }

    return _possibleConstructorReturn(this, result);
  };
}

function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;

  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { mergeStyles, uniqueInnerId } from '@jutro/platform';
import { defaultAvailableValuePropType, dataTypeShape, DATA_TYPE_STRING, availableValueObjectShape } from '@jutro/prop-types';
import { FieldComponent } from '../FieldComponent/FieldComponent';
import { AnimationGroup } from '../../AnimationGroup/AnimationGroup';
import styles from "./ToggleField.module.css";
import buttonStyles from "../../button/Button.module.css";
import { messages } from './ToggleField.messages';
import { animationDelayDefault } from '../../config/animationMap';
import { ToggleButton } from './ToggleButton';
var defaultAvailableValues = [{
  code: 'true',
  name: messages.yes
}, {
  code: 'false',
  name: messages.no
}];
export var ToggleField = function (_FieldComponent) {
  _inherits(ToggleField, _FieldComponent);

  var _super = _createSuper(ToggleField);

  function ToggleField() {
    var _this;

    _classCallCheck(this, ToggleField);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "handleButtonClick", function (evt) {
      evt.preventDefault();
      var targetValue = evt.target.dataset.value;

      _this.handleAvailableValuesValueChange(targetValue);
    });

    return _this;
  }

  _createClass(ToggleField, [{
    key: "render",
    value: function render() {
      return _get(_getPrototypeOf(ToggleField.prototype), "render", this).call(this);
    }
  }, {
    key: "renderButton",
    value: function renderButton(option, styleClasses, activeStyle, activeKeypress, uniqueId) {
      var _option$name;

      var selectedValue = this.getDataTypeAwareSelectedValue();
      return React.createElement(ToggleButton, _extends({
        key: (option === null || option === void 0 ? void 0 : option.id) || (option === null || option === void 0 ? void 0 : (_option$name = option.name) === null || _option$name === void 0 ? void 0 : _option$name.id) || (option === null || option === void 0 ? void 0 : option.name) || (option === null || option === void 0 ? void 0 : option.code),
        uniqueId: uniqueId,
        active: activeStyle,
        activeKeypress: activeKeypress,
        option: option,
        styleClasses: styleClasses,
        handleButtonClick: this.handleButtonClick
      }, this.props, {
        value: selectedValue
      }));
    }
  }, {
    key: "generateAccessibilityProperties",
    value: function generateAccessibilityProperties() {
      var accessibilityProperties = _get(_getPrototypeOf(ToggleField.prototype), "generateAccessibilityProperties", this).call(this);

      var required = accessibilityProperties['aria-required'];
      accessibilityProperties.required = required;
      delete accessibilityProperties['aria-required'];
      return accessibilityProperties;
    }
  }, {
    key: "renderControl",
    value: function renderControl(breakpointProps) {
      var _this2 = this;

      var id = breakpointProps.id,
          availableValues = breakpointProps.availableValues,
          label = breakpointProps.label,
          controlClassName = breakpointProps.controlClassName,
          required = breakpointProps.required,
          disabled = breakpointProps.disabled,
          children = breakpointProps.children,
          animation = breakpointProps.animation,
          detailElement = breakpointProps.detailElement;

      var _uniqueInnerId = uniqueInnerId(id, 'uniqueId'),
          uniqueId = _uniqueInnerId.uniqueId;

      var value = this.getDataTypeAwareSelectedValue();
      var detailVisible = value && value.toString() === 'true' && (detailElement || children);
      var translator = this.translator;
      var values = availableValues;
      var buttons = [];
      var optionCount = values === null || values === void 0 ? void 0 : values.length;
      var combinedStyles = mergeStyles(buttonStyles, styles);

      _forEachInstanceProperty(values).call(values, function (option, index) {
        var _cx;

        var isFirstElement = index === 0;
        var isLastElement = index === optionCount - 1;
        var isNotFirstOrLast = !isFirstElement && !isLastElement;
        var buttonClasses = cx(combinedStyles.button, combinedStyles.primary, combinedStyles.toggleButton, (_cx = {
          disabled: disabled
        }, _defineProperty2(_cx, combinedStyles.left, isFirstElement), _defineProperty2(_cx, combinedStyles.right, isLastElement), _defineProperty2(_cx, combinedStyles.middle, isNotFirstOrLast), _cx));

        var button = _this2.renderButton(option, buttonClasses, styles.active, styles.activeKeypress, uniqueId, value);

        buttons.push(button);
      });

      var outerClasses = cx(styles.toggle, controlClassName);
      var groupLabel = required ? "".concat(translator(label), ", ").concat(translator(messages.required)) : translator(label);
      var control = React.createElement("div", _extends({
        className: outerClasses,
        role: "group"
      }, this.generateAccessibilityProperties(), {
        "aria-label": groupLabel
      }), React.createElement("div", {
        id: uniqueId,
        className: styles.content
      }, buttons));
      var otherContent = React.createElement("div", {
        role: "region",
        "aria-live": "assertive",
        "aria-hidden": !detailVisible
      }, detailVisible && React.createElement(AnimationGroup, {
        animation: animation || 'insert',
        appear: true,
        timeout: {
          appear: animationDelayDefault
        }
      }, detailElement || children));
      return React.createElement(React.Fragment, null, control, otherContent);
    }
  }]);

  return ToggleField;
}(FieldComponent);

_defineProperty(ToggleField, "propTypes", _objectSpread({}, FieldComponent.propTypes, {
  value: defaultAvailableValuePropType,
  defaultValue: defaultAvailableValuePropType,
  dataType: dataTypeShape,
  availableValues: PropTypes.arrayOf(availableValueObjectShape),
  detailElement: PropTypes.node,
  animation: PropTypes.string,
  children: PropTypes.node
}));

_defineProperty(ToggleField, "defaultProps", _objectSpread({}, FieldComponent.defaultProps, {
  availableValues: defaultAvailableValues,
  dataType: DATA_TYPE_STRING
}));

_defineProperty(ToggleField, "contextType", FieldComponent.contextType);

ToggleField.__docgenInfo = {
  componentName: "ToggleField",
  packageName: "@jutro/components",
  description: "Renders buttons that allow you to toggle between the available values.",
  displayName: "ToggleField",
  methods: [{
    name: "handleButtonClick",
    docblock: "Custom change handler for `button` element. Uses `notifyChange` to invoke onValueChange callback\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Custom change handler for `button` element. Uses `notifyChange` to invoke onValueChange callback"
  }, {
    name: "renderButton",
    docblock: "Creates a button element\n\n@param {object} option - object that contains the value for the button\n@param {object} styleClasses - class name object of button styles\n@param {object} activeStyle - class name object of button active styles\n@returns {Element} - a button element",
    modifiers: [],
    params: [{
      name: "option",
      description: "object that contains the value for the button",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "styleClasses",
      description: "class name object of button styles",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "activeStyle",
      description: "class name object of button active styles",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "activeKeypress"
    }, {
      name: "uniqueId"
    }],
    returns: {
      description: "a button element",
      type: {
        name: "Element"
      }
    },
    description: "Creates a button element"
  }, {
    name: "generateAccessibilityProperties",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  actualName: "ToggleField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }, {
          name: "number"
        }, {
          name: "custom",
          raw: "availableValueObjectShape"
        }]
      },
      required: false,
      description: "Boolean, object, or string value"
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }, {
          name: "number"
        }, {
          name: "custom",
          raw: "availableValueObjectShape"
        }]
      },
      required: false,
      description: "Default value"
    },
    dataType: {
      type: {
        name: "enum",
        value: [{
          value: "'object'",
          computed: false
        }, {
          value: "'string'",
          computed: false
        }]
      },
      required: false,
      description: "Format of the value",
      defaultValue: {
        value: "'string'",
        computed: false
      }
    },
    availableValues: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "shape",
            value: {
              code: {
                name: "union",
                value: [{
                  name: "string"
                }, {
                  name: "number"
                }],
                required: false
              },
              name: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              },
              subtitle: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              },
              secondaryLabel: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              }
            }
          }, {
            name: "shape",
            value: {
              id: {
                name: "union",
                value: [{
                  name: "string"
                }, {
                  name: "number"
                }],
                required: false
              },
              displayName: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              },
              subtitle: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              },
              secondaryLabel: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              }
            }
          }]
        }
      },
      required: false,
      description: "Array of choice objects to display; choice objects contains `code` and `name`; if not provided, default 'Yes'/'No' is used",
      defaultValue: {
        value: "[\n    {\n        code: 'true',\n        name: messages.yes,\n    },\n    {\n        code: 'false',\n        name: messages.no,\n    },\n]",
        computed: false
      }
    },
    detailElement: {
      type: {
        name: "node"
      },
      required: false,
      description: "Node to render when the \"other\" value is selected"
    },
    animation: {
      type: {
        name: "string"
      },
      required: false,
      description: "Animation passed to AnimationGroup"
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "Wrapped children to be rendered when the value prop is set to true"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};