import { defineMessages } from 'react-intl';
export var messages = defineMessages({
  title: {
    id: 'jutro-datatable.filterBar.title',
    defaultMessage: 'Filters'
  },
  searchInput: {
    id: 'jutro-datatable.filterBar.searchInput',
    defaultMessage: 'Search'
  },
  clearFilters: {
    id: 'jutro-datatable.filterBar.clearFilters',
    defaultMessage: 'Clear Filters'
  },
  applyFilters: {
    id: 'jutro-datatable.filterBar.applyFilters',
    defaultMessage: 'Apply'
  },
  manageFilters: {
    id: 'jutro-datatable.filterBar.manageFilters',
    defaultMessage: 'Filters'
  }
});