import _JSON$stringify from "@babel/runtime-corejs3/core-js-stable/json/stringify";
import { getConfigValue } from '@jutro/config';
import loadSumoLogic from '../sumoLogic';
import { subscribe } from '../EventProvider';
import JUTRO_TOPICS from '../jutroTopics';
export default (function () {
  var sumoLogicUrl = getConfigValue('JUTRO_SUMO_LOGIC_URL');
  var sumoLogicSourceName = getConfigValue('JUTRO_SUMO_LOGIC_SOURCE_NAME', 'Jutro App');

  if (!sumoLogicUrl) {
    return;
  }

  var defaultLogger = loadSumoLogic(sumoLogicUrl, sumoLogicSourceName, 'fetch');
  var errorLogger = loadSumoLogic(sumoLogicUrl, sumoLogicSourceName, 'error');
  var UNKNOWN_ERROR = JUTRO_TOPICS.UNKNOWN_ERROR,
      FETCH_ERROR = JUTRO_TOPICS.FETCH_ERROR,
      FETCH_REQUEST = JUTRO_TOPICS.FETCH_REQUEST,
      FETCH_RESPONSE = JUTRO_TOPICS.FETCH_RESPONSE;
  subscribe([UNKNOWN_ERROR, FETCH_ERROR], function (event, topic) {
    errorLogger.log(_JSON$stringify(event), {
      sourceCategory: topic
    });
  });
  subscribe([FETCH_REQUEST, FETCH_RESPONSE], function (event, topic) {
    defaultLogger.log(_JSON$stringify(event), {
      sourceCategory: topic
    });
  });
});