import PropTypes from 'prop-types';
import { intlMessageShape } from './intlMessageShape';
export var DATA_TYPE_OBJECT = 'object';
export var DATA_TYPE_STRING = 'string';
export var DATA_TYPE_NUMBER = 'number';
export var DATA_TYPE_DATE_TIME = 'date-time';
export var dataTypeShape = PropTypes.oneOf([DATA_TYPE_OBJECT, DATA_TYPE_STRING]);
export var dataTypeShapeWithNumber = PropTypes.oneOf([DATA_TYPE_OBJECT, DATA_TYPE_STRING, DATA_TYPE_NUMBER]);
export var dataTypeShapeWithDateTime = PropTypes.oneOf([DATA_TYPE_OBJECT, DATA_TYPE_STRING, DATA_TYPE_DATE_TIME]);
export var availableValuesIdDisplayNameObject = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  displayName: intlMessageShape,
  subtitle: intlMessageShape,
  secondaryLabel: intlMessageShape
};
export var availableValuesCodeNameObject = {
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: intlMessageShape,
  subtitle: intlMessageShape,
  secondaryLabel: intlMessageShape
};
export var availableValueObjectShape = PropTypes.oneOfType([PropTypes.shape(availableValuesCodeNameObject), PropTypes.shape(availableValuesIdDisplayNameObject)]);
export var defaultAvailableValuePropType = PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, availableValueObjectShape]);