import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _someInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/some";
import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _Array$isArray from "@babel/runtime-corejs3/core-js-stable/array/is-array";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { BreakpointTracker, BreakpointTrackerContext } from '@jutro/layout';
import { log } from '@jutro/logger';
import { TranslatorContext } from '@jutro/locale';
import { metadataTypes } from '@jutro/uimetadata';
import { intlMessageShape } from '@jutro/prop-types';
import styles from "./Table.module.css";
import { TableColumn } from './TableColumn';
import { TableTitleBar } from './TableTitleBar';
var tablePropTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  renderTitle: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node),
  phone: PropTypes.object,
  phoneWide: PropTypes.object,
  tablet: PropTypes.object,
  renderTitleAction: PropTypes.func,
  columnsProportion: PropTypes.arrayOf(PropTypes.number),
  placeholder: intlMessageShape,
  renderPhoneCardComponent: PropTypes.func,
  titleId: PropTypes.string,
  titlePosition: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string
  }))
};
export var Table = function Table(props) {
  var translator = useContext(TranslatorContext);
  return React.createElement(BreakpointTrackerContext.Consumer, null, function (breakpoint) {
    return renderTableComponent(props, breakpoint, translator);
  });
};

function renderHeader(headerProps) {
  var componentProps = headerProps.componentProps,
      breakpoint = headerProps.breakpoint,
      translator = headerProps.translator;

  var renderProp = function renderProp(_ref) {
    var headerClassName = _ref.headerClassName,
        rest = _objectWithoutProperties(_ref, ["headerClassName"]);

    var columnProps = _objectSpread({
      headerClassName: cx(styles.tableHeader, headerClassName)
    }, rest);

    return TableColumn.renderHeader(columnProps, translator);
  };

  var keyMapper = function keyMapper() {
    return 'header';
  };

  return renderLine({
    componentProps: componentProps,
    breakpoint: breakpoint,
    renderProp: renderProp,
    keyMapper: keyMapper
  });
}

function renderRow(props) {
  var componentProps = props.componentProps,
      row = props.row,
      index = props.index,
      breakpoint = props.breakpoint,
      translator = props.translator;

  var renderProp = function renderProp(columnProps) {
    return TableColumn.renderCell(row, index, _objectSpread({}, columnProps, {
      basePath: componentProps.path
    }), translator);
  };

  var keyMapper = function keyMapper() {
    return row.id || "".concat(index);
  };

  return renderLine({
    componentProps: componentProps,
    breakpoint: breakpoint,
    renderProp: renderProp,
    keyMapper: keyMapper,
    rowIndex: index
  });
}

var ignoreRowClicks = ['INPUT', 'BUTTON', 'A', 'LABEL', 'SELECT'];

function handleRowEvent(evt, columnCallback) {
  var currentTarget = evt.target;

  if (currentTarget && _includesInstanceProperty(ignoreRowClicks).call(ignoreRowClicks, currentTarget.tagName)) {
    return;
  }

  columnCallback(evt);
}

function renderLine(props) {
  var _context;

  var componentProps = props.componentProps,
      breakpoint = props.breakpoint,
      renderProp = props.renderProp,
      keyMapper = props.keyMapper,
      rowIndex = props.rowIndex;
  var children = componentProps.children,
      columnsProportion = componentProps.columnsProportion;
  var onRowClick;
  var onKeyDown;
  var tabIndex;

  var columns = _mapInstanceProperty(_context = React.Children.toArray(children)).call(_context, function (column, index) {
    var columnProps = BreakpointTracker.applyBreakpointOverrides(column.props, breakpoint);

    if (columnProps.visible === false || columnsProportion && !columnsProportion[index]) {
      return null;
    }

    if (column.type.onRowClick) {
      onRowClick = function onRowClick(evt) {
        return handleRowEvent(evt, column.type.onRowClick);
      };
    }

    if (column.type.getTabIndex) {
      tabIndex = column.type.getTabIndex(rowIndex);
    }

    if (column.type.onKeyDown) {
      onKeyDown = function onKeyDown(evt) {
        return handleRowEvent(evt, column.type.onKeyDown);
      };
    }

    return renderProp(_objectSpread({}, columnProps, {
      scope: 'col'
    }));
  });

  return React.createElement("tr", {
    className: styles.row,
    key: keyMapper(),
    onClick: onRowClick,
    onKeyDown: onKeyDown,
    tabIndex: tabIndex
  }, columns);
}

function renderTableTitleBar(props) {
  var title = props.title,
      renderTitleAction = props.renderTitleAction,
      theme = props.theme,
      titleId = props.titleId,
      titlePosition = props.titlePosition;
  return React.createElement(TableTitleBar, {
    title: title,
    titleAction: renderTitleAction,
    theme: theme,
    titleId: titleId,
    titlePosition: titlePosition
  });
}

function renderColGroup(columnsProportion, children) {
  if (children && columnsProportion) {
    var visibleCildrenCount = _Array$isArray(children) ? _filterInstanceProperty(children).call(children, function (col) {
      return col && col.props.visible !== false;
    }).length : 1;

    if (visibleCildrenCount !== columnsProportion.length) {
      log.warning('Number of table columns and columns proportion does not match');
    }
  }

  if (!columnsProportion || columnsProportion.length === 0) {
    return undefined;
  }

  columnsProportion = _filterInstanceProperty(columnsProportion).call(columnsProportion, function (x) {
    return !!x;
  });

  var proportionTotal = _reduceInstanceProperty(columnsProportion).call(columnsProportion, function (total, x) {
    return total + x;
  });

  var cols = _mapInstanceProperty(columnsProportion).call(columnsProportion, function (x, index) {
    return React.createElement("col", {
      key: index,
      style: {
        width: "".concat(x / proportionTotal * 100, "%")
      }
    });
  });

  return React.createElement("colgroup", null, cols);
}

function renderPlaceHolder(placeholder, translator) {
  if (!placeholder) {
    return null;
  }

  var placeholderText = translator ? translator(placeholder) : placeholder;
  return React.createElement("div", {
    className: styles.placeholder
  }, placeholderText);
}

var renderDesktopTable = function renderDesktopTable(componentProps, breakpoint, translator, titleId) {
  var data = componentProps.data,
      children = componentProps.children,
      columnsProportion = componentProps.columnsProportion;
  var colGroup = renderColGroup(columnsProportion, children);
  var header = renderHeader({
    componentProps: componentProps,
    breakpoint: breakpoint,
    translator: translator
  });

  var body = _mapInstanceProperty(data).call(data, function (row, index) {
    return renderRow({
      componentProps: componentProps,
      row: row,
      index: index,
      breakpoint: breakpoint,
      translator: translator
    });
  });

  return React.createElement("table", {
    className: styles.tableElement,
    "aria-labelledby": titleId
  }, colGroup, React.createElement("thead", null, header), React.createElement("tbody", null, body));
};

var renderPhoneCardRow = function renderPhoneCardRow(rowProps, row, index, translator) {
  var isVisible = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
  var classes = cx(styles.tableCardRow, _defineProperty({}, styles.radioRow, rowProps === null || rowProps === void 0 ? void 0 : rowProps.option));

  var cardProps = _objectSpread({}, rowProps, {
    headerClassName: styles.tableCardElementHeader,
    cellClassName: styles.tableCardElementContent,
    scope: 'row',
    textAlign: undefined,
    visible: isVisible
  });

  return React.createElement("tr", {
    key: rowProps.id + index,
    className: classes
  }, TableColumn.renderHeader(cardProps, translator), TableColumn.renderCell(row, index, cardProps, translator));
};

var renderPhoneTable = function renderPhoneTable(componentProps, breakpoint, translator, titleId) {
  var _context2, _context3;

  var data = componentProps.data,
      children = componentProps.children,
      renderPhoneCardComponent = componentProps.renderPhoneCardComponent,
      columnsProportion = componentProps.columnsProportion;
  var Card = renderPhoneCardComponent || 'div';
  var clickableColumn;

  _forEachInstanceProperty(_context2 = _filterInstanceProperty(_context3 = React.Children.toArray(children)).call(_context3, Boolean)).call(_context2, function (child) {
    if (child.type.onRowClick) {
      clickableColumn = child;
    }
  });

  var onCardClick = clickableColumn ? function (evt) {
    return handleRowEvent(evt, clickableColumn.type.onRowClick);
  } : undefined;

  var cards = _mapInstanceProperty(data).call(data, function (row, index) {
    var _context4;

    var key = row.id || "".concat(index);

    var card = _mapInstanceProperty(_context4 = React.Children.toArray(children)).call(_context4, function (child, childIndex) {
      return renderPhoneCardRow(child.props, row, index, translator, (columnsProportion === null || columnsProportion === void 0 ? void 0 : columnsProportion[childIndex]) !== 0);
    });

    return React.createElement(Card, {
      key: key,
      className: styles.card,
      onClick: onCardClick,
      id: key
    }, React.createElement("div", {
      onClick: onCardClick
    }, React.createElement("table", {
      className: styles.tableCard,
      "aria-describedby": titleId
    }, React.createElement("tbody", null, card))));
  });

  return React.createElement(React.Fragment, null, cards);
};

var renderDesktopOrPhoneTable = function renderDesktopOrPhoneTable(props) {
  var componentProps = props.componentProps,
      breakpoint = props.breakpoint,
      translator = props.translator,
      titleId = props.titleId;

  if (!breakpoint || breakpoint !== 'phone' && breakpoint !== 'phoneWide') {
    return renderDesktopTable(componentProps, breakpoint, translator, titleId);
  }

  return renderPhoneTable(componentProps, breakpoint, translator, titleId);
};

var isClickable = function isClickable(child) {
  var _child$type;

  return child === null || child === void 0 ? void 0 : (_child$type = child.type) === null || _child$type === void 0 ? void 0 : _child$type.onRowClick;
};

var isHoverable = function isHoverable(props) {
  var children = props.children;

  if (!children) {
    return false;
  }

  if (!_Array$isArray(children) && children.type === React.Fragment) {
    children = children.props.children;
  }

  if (!_Array$isArray(children)) {
    return children.type.onRowClick;
  }

  return _someInstanceProperty(children).call(children, isClickable);
};

var renderTableComponent = function renderTableComponent(props, breakpoint, translator) {
  var componentProps = BreakpointTracker.applyBreakpointOverrides(props, breakpoint);
  var data = componentProps.data,
      className = componentProps.className,
      title = componentProps.title,
      renderTitle = componentProps.renderTitle,
      titleId = componentProps.titleId,
      renderTitleAction = componentProps.renderTitleAction,
      titlePosition = componentProps.titlePosition,
      placeholder = componentProps.placeholder;
  var tableClasses = cx(styles.table, _defineProperty({}, styles.hoverable, isHoverable(props)), className);
  var titleBar = renderTableTitleBar({
    title: title || renderTitle,
    renderTitleAction: renderTitleAction,
    titleId: titleId,
    titlePosition: titlePosition
  });
  var hasData = !_.isEmpty(data);
  var placeholderElement = !hasData ? renderPlaceHolder(placeholder, translator) : null;
  return React.createElement("div", {
    className: tableClasses
  }, titleBar, hasData && renderDesktopOrPhoneTable({
    componentProps: componentProps,
    breakpoint: breakpoint,
    translator: translator,
    titleId: titleId
  }), placeholderElement);
};

Table.propTypes = tablePropTypes;
Table.metadataType = metadataTypes.CONTAINER;
Table.__docgenInfo = {
  componentName: "Table",
  packageName: "@jutro/datatable",
  description: "Renders a table. You can use it to display data from a model.",
  displayName: "Table",
  methods: [],
  actualName: "Table",
  metadataType: "container",
  props: {
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "func"
        }]
      },
      required: false,
      description: "Title which is placed in title bar"
    },
    renderTitle: {
      type: {
        name: "func"
      },
      required: false,
      description: "Function that renders custom title"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Optional css class(es) to add to the grid tag"
    },
    children: {
      type: {
        name: "arrayOf",
        value: {
          name: "node"
        }
      },
      required: false,
      description: "Children; preferably 'GridItem'; works with any child"
    },
    phone: {
      type: {
        name: "object"
      },
      required: false,
      description: "Include any Table property for use at 'phone' breakpoint"
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: false,
      description: "Include any Table property for use at 'phoneWide' and 'phone' breakpoint"
    },
    tablet: {
      type: {
        name: "object"
      },
      required: false,
      description: "Include any Table property for use at 'tablet' and 'phone' breakpoint"
    },
    renderTitleAction: {
      type: {
        name: "func"
      },
      required: false,
      description: "Render prop for customizing action in the title area"
    },
    columnsProportion: {
      type: {
        name: "arrayOf",
        value: {
          name: "number"
        }
      },
      required: false,
      description: "Proportion of columns (e.g. [2, 2, 1])"
    },
    placeholder: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Placeholder to show when data is empty"
    },
    renderPhoneCardComponent: {
      type: {
        name: "func"
      },
      required: false,
      description: "Component to wrap mobile Radio Table"
    },
    titleId: {
      type: {
        name: "string"
      },
      required: false,
      description: "The id used for the tables title for use in the aria-describedby"
    },
    titlePosition: {
      type: {
        name: "string"
      },
      required: false,
      description: "The position of the title in the title bar (left, right, center)"
    },
    data: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            id: {
              name: "string",
              description: "Optional string for generating keys for rows",
              required: false
            }
          }
        }
      },
      required: false,
      description: "Cells data"
    }
  }
};
renderHeader.__docgenInfo = {
  componentName: "renderHeader",
  packageName: "@jutro/datatable",
  description: "Renders header props",
  displayName: "renderHeader",
  methods: [],
  actualName: "renderHeader"
};
renderRow.__docgenInfo = {
  componentName: "renderRow",
  packageName: "@jutro/datatable",
  description: "Renders row",
  displayName: "renderRow",
  methods: [],
  actualName: "renderRow"
};
renderLine.__docgenInfo = {
  componentName: "renderLine",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "renderLine",
  methods: [],
  actualName: "renderLine"
};
renderTableTitleBar.__docgenInfo = {
  componentName: "renderTableTitleBar",
  packageName: "@jutro/datatable",
  description: "Renders TableTitleBar",
  displayName: "renderTableTitleBar",
  methods: [],
  actualName: "renderTableTitleBar"
};
renderColGroup.__docgenInfo = {
  componentName: "renderColGroup",
  packageName: "@jutro/datatable",
  description: "Renders colgroup",
  displayName: "renderColGroup",
  methods: [],
  actualName: "renderColGroup"
};
renderPlaceHolder.__docgenInfo = {
  componentName: "renderPlaceHolder",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "renderPlaceHolder",
  methods: [],
  actualName: "renderPlaceHolder"
};
renderDesktopTable.__docgenInfo = {
  componentName: "renderDesktopTable",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "renderDesktopTable",
  methods: [],
  actualName: "renderDesktopTable"
};
renderPhoneCardRow.__docgenInfo = {
  componentName: "renderPhoneCardRow",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "renderPhoneCardRow",
  methods: [],
  actualName: "renderPhoneCardRow"
};
renderPhoneTable.__docgenInfo = {
  componentName: "renderPhoneTable",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "renderPhoneTable",
  methods: [],
  actualName: "renderPhoneTable"
};
renderDesktopOrPhoneTable.__docgenInfo = {
  componentName: "renderDesktopOrPhoneTable",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "renderDesktopOrPhoneTable",
  methods: [],
  actualName: "renderDesktopOrPhoneTable"
};
renderTableComponent.__docgenInfo = {
  componentName: "renderTableComponent",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "renderTableComponent",
  methods: [],
  actualName: "renderTableComponent"
};