import _extends from "@babel/runtime-corejs3/helpers/extends";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useState, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ModalNext, ModalBody, ModalFooter, ModalHeader, Button } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import isNil from "lodash/isNil";
import cloneDeep from "lodash/cloneDeep";
import { messages } from './ColumnsConfigModal.messages';
import { ColumnEntry } from './ColumnEntry';
export function ColumnsConfigModal(_ref) {
  var isOpen = _ref.isOpen,
      onResolve = _ref.onResolve,
      onReject = _ref.onReject,
      parentSelector = _ref.parentSelector,
      initialColumns = _ref.initialColumns;
  var translator = useContext(TranslatorContext);

  var _useState = useState(initialColumns),
      _useState2 = _slicedToArray(_useState, 2),
      columns = _useState2[0],
      setColumns = _useState2[1];

  var handleConfirm = useCallback(function () {
    return onResolve(columns);
  }, [onResolve, columns]);
  var columnEntries = useMemo(function () {
    var onColumnToggle = function onColumnToggle(index) {
      return function (enabled) {
        var _context;

        var updateVisibility = function updateVisibility(currentlyEnabled) {
          return isNil(enabled) ? !currentlyEnabled : enabled;
        };

        var newColumns = _mapInstanceProperty(_context = cloneDeep(columns)).call(_context, function (column, columnIndex) {
          if (columnIndex === index) {
            column.enabled = updateVisibility();
          }

          return column;
        });

        setColumns(newColumns);
      };
    };

    return _mapInstanceProperty(columns).call(columns, function (column, index) {
      return React.createElement(ColumnEntry, _extends({
        key: column.id
      }, column, {
        onToggle: onColumnToggle(index)
      }));
    });
  }, [columns]);
  return React.createElement(ModalNext, {
    isOpen: isOpen,
    onRequestClose: onReject,
    parentSelector: parentSelector
  }, React.createElement(ModalHeader, {
    title: translator(messages.title),
    subtitle: translator(messages.subtitle),
    onClose: onReject
  }), React.createElement(ModalBody, {
    contentLayout: {
      component: 'Grid',
      componentProps: {
        columns: ['1fr'],
        gap: 'none'
      }
    }
  }, columnEntries), React.createElement(ModalFooter, null, React.createElement(Button, {
    type: "text",
    onClick: onReject
  }, translator(messages.cancel)), React.createElement(Button, {
    onClick: handleConfirm
  }, translator(messages.applyChanges))));
}
var columnPropTypes = {
  id: PropTypes.string.isRequired,
  header: PropTypes.string,
  enabled: PropTypes.bool
};
ColumnsConfigModal.propTypes = {
  isOpen: PropTypes.bool,
  onResolve: PropTypes.func,
  onReject: PropTypes.func,
  parentSelector: PropTypes.func,
  initialColumns: PropTypes.arrayOf(PropTypes.shape(columnPropTypes))
};
ColumnsConfigModal.__docgenInfo = {
  componentName: "ColumnsConfigModal",
  packageName: "@jutro/datatable",
  description: "ColumnsConfigModal",
  displayName: "ColumnsConfigModal",
  methods: [],
  actualName: "ColumnsConfigModal",
  props: {
    isOpen: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Flag to indicate if the Modal is currently open"
    },
    onResolve: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback to be called when the Modal has been requested to be resolve"
    },
    onReject: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback to be called when the Modal has been requested to be closed"
    },
    parentSelector: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback to find the Modal's parent selector"
    },
    initialColumns: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            id: {
              name: "string",
              description: "Used to identify the component",
              required: true
            },
            header: {
              name: "string",
              description: "Label text to be displayed beside the checkbox",
              required: false
            },
            enabled: {
              name: "bool",
              description: "If true, the checkbox marked as checked",
              required: false
            }
          }
        }
      },
      required: false,
      description: "Array of columns"
    }
  }
};