import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import React from 'react';
import PropTypes from 'prop-types';
import { FieldValue } from '@jutro/components';
import { simpleDatatypeComponentMap } from '@jutro/uiconfig';
import get from "lodash/get";
import { TableColumn } from '../table/TableColumn';
import { FieldColumn } from './FieldColumn';
import styles from "../DataTable.module.css";
import { defaultColumnFilter } from '../helper';
export var DisplayColumn = function DisplayColumn() {
  if (false) {
    throw new Error('Component <DisplayColumn /> should never render');
  }

  return React.createElement(React.Fragment, null);
};
DisplayColumn.propTypes = _objectSpread({}, TableColumn.propTypes, {
  fieldDatatype: PropTypes.oneOf(_Object$keys(simpleDatatypeComponentMap)),
  onAccessor: PropTypes.func
});

DisplayColumn.defaultCell = function (row, rowId, _ref) {
  var fieldDatatype = _ref.fieldDatatype,
      path = _ref.path,
      visible = _ref.visible,
      id = _ref.id;

  if (visible === false) {
    return null;
  }

  return React.createElement(FieldValue, {
    id: "".concat(id, "_").concat(rowId),
    datatype: fieldDatatype,
    value: get(row, path)
  });
};

DisplayColumn.editCell = function (props) {
  return FieldColumn.editCell(props);
};

DisplayColumn.editCellClass = styles.editedCell;
DisplayColumn.displayName = 'DisplayColumn';
DisplayColumn.defaultProps = {
  renderCell: DisplayColumn.defaultCell,
  renderEditCell: DisplayColumn.editCell,
  editCellClass: DisplayColumn.editCellClass,
  onFilter: defaultColumnFilter,
  columnProportion: 1,
  fieldDatatype: 'string'
};
DisplayColumn.__docgenInfo = {
  componentName: "DisplayColumn",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "DisplayColumn",
  methods: [],
  actualName: "DisplayColumn",
  metadataType: "container",
  props: {
    fieldDatatype: {
      type: {
        name: "enum",
        computed: true,
        value: "Object.keys(simpleDatatypeComponentMap)"
      },
      required: false,
      description: "",
      defaultValue: {
        value: "'string'",
        computed: false
      }
    },
    onAccessor: {
      type: {
        name: "func"
      },
      required: false,
      description: "Returns a value that will be used by the column to access the correct data of each row"
    },
    renderCell: {
      defaultValue: {
        value: "DisplayColumn.defaultCell",
        computed: true
      },
      required: false
    },
    renderEditCell: {
      defaultValue: {
        value: "DisplayColumn.editCell",
        computed: true
      },
      required: false
    },
    editCellClass: {
      defaultValue: {
        value: "DisplayColumn.editCellClass",
        computed: true
      },
      required: false
    },
    onFilter: {
      defaultValue: {
        value: "defaultColumnFilter",
        computed: true
      },
      required: false
    },
    columnProportion: {
      defaultValue: {
        value: "1",
        computed: false
      },
      required: false
    }
  },
  composes: ["../table/TableColumn"]
};