import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React from 'react';
import cx from 'classnames';
import { intlMessageShape } from '@jutro/prop-types';
import PropTypes from 'prop-types';
import styles from "./StepProgressBar.module.css";
import { ProgressStep } from './ProgressStep';
export var progressStepPropType = {
  title: intlMessageShape.isRequired,
  visited: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};
export var stepProgressBarPropTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape(progressStepPropType).isRequired).isRequired,
  className: PropTypes.string,
  isVertical: PropTypes.bool
};
export var StepProgressBar = function StepProgressBar(_ref) {
  var steps = _ref.steps,
      className = _ref.className,
      isVertical = _ref.isVertical,
      rest = _objectWithoutProperties(_ref, ["steps", "className", "isVertical"]);

  var classes = cx(styles.stepProgressBar, className, _defineProperty({}, styles.vertical, isVertical));
  return React.createElement("nav", _extends({
    className: classes
  }, rest), _mapInstanceProperty(steps).call(steps, function (stepProps, index) {
    var id = "".concat(stepProps.title).concat(index);
    return React.createElement(ProgressStep, {
      step: stepProps,
      id: id,
      key: id,
      isVertical: isVertical
    });
  }));
};
StepProgressBar.propTypes = stepProgressBarPropTypes;
StepProgressBar.displayName = 'StepProgressBar';
StepProgressBar.__docgenInfo = {
  componentName: "StepProgressBar",
  packageName: "@jutro/components",
  description: "",
  displayName: "StepProgressBar",
  methods: [],
  actualName: "StepProgressBar",
  props: {
    steps: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            title: {
              name: "union",
              description: "Text part for labeling the step",
              required: true,
              value: [{
                name: "string"
              }, {
                name: "shape",
                value: {
                  id: {
                    name: "string",
                    required: false
                  },
                  defaultMessage: {
                    name: "string",
                    required: false
                  },
                  args: {
                    name: "shape",
                    value: {},
                    required: false
                  }
                }
              }]
            },
            visited: {
              name: "bool",
              description: "Indicates if step marker should be displayed as visited.",
              required: false
            },
            active: {
              name: "bool",
              description: "Indicates if step marker should be displayed as currently active step.",
              required: false
            },
            disabled: {
              name: "bool",
              description: "Indicates if step marker should be displayed as disabled.",
              required: false
            },
            onClick: {
              name: "func",
              description: "Handler for click event on progress marker.",
              required: false
            }
          }
        }
      },
      required: true,
      description: "Steps data"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Class to override progress bar styles"
    },
    isVertical: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true component will be vertically oriented"
    }
  }
};