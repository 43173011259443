import { defineMessages } from 'react-intl';
export var messages = defineMessages({
  noOptionsMessage: {
    id: 'jutro-components.widgets.inputs.GenericSelectControl.noOptionsMessage',
    defaultMessage: 'No options'
  },
  createNewMessage: {
    id: 'jutro-components.widgets.inputs.GenericSelectControl.createNewMessage',
    defaultMessage: 'Add: {message}'
  }
});