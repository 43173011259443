import PropTypes from 'prop-types';
export var dateObjectValueShape = {
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  day: PropTypes.number.isRequired,
  hour: PropTypes.number,
  minute: PropTypes.number
};
export var dateValueShape = PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(Date), PropTypes.shape(dateObjectValueShape)]);
var dateRangeProps = {
  startDate: dateValueShape,
  endDate: dateValueShape
};
export var dateRangeValueShape = PropTypes.shape(dateRangeProps);
export var dateTimeZoneValueProps = {
  datetime: dateValueShape,
  timezone: PropTypes.string
};
export var dateTimeZoneValueShape = PropTypes.shape(dateTimeZoneValueProps);