import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import assignWith from "lodash/assignWith";
import { TranslatorContext } from '@jutro/locale';
import { intlMessageShape, linkShape } from '@jutro/prop-types';
import { Link } from '../Link/Link';
import styles from "./PopoverContainer.module.css";
import { messages } from './PopoverContainer.messages';
var popoverContainerPropTypes = {
  title: intlMessageShape,
  headerLink: linkShape,
  footerLink: linkShape,
  className: PropTypes.string,
  children: PropTypes.node,
  internalClassNames: PropTypes.shape({
    header: PropTypes.string,
    title: PropTypes.string,
    headerLink: PropTypes.string,
    body: PropTypes.string,
    footer: PropTypes.string,
    footerLink: PropTypes.string
  }),
  hideHeader: PropTypes.bool,
  hideFooter: PropTypes.bool
};
export var PopoverContainer = function PopoverContainer(_ref) {
  var title = _ref.title,
      headerLink = _ref.headerLink,
      footerLink = _ref.footerLink,
      children = _ref.children,
      className = _ref.className,
      internalClassNames = _ref.internalClassNames,
      hideHeader = _ref.hideHeader,
      hideFooter = _ref.hideFooter;
  var translator = useContext(TranslatorContext);
  var mergedStyles = useMemo(function () {
    return internalClassNames ? assignWith(_objectSpread({}, styles), internalClassNames !== null && internalClassNames !== void 0 ? internalClassNames : {}, function (popoverContainerStyle, additionalStyle) {
      return additionalStyle ? cx(popoverContainerStyle, additionalStyle) : popoverContainerStyle;
    }) : styles;
  }, [internalClassNames]);
  return React.createElement("div", {
    className: cx(mergedStyles.popoverContainer, className)
  }, !hideHeader && React.createElement("div", {
    className: mergedStyles.header
  }, React.createElement("span", {
    className: mergedStyles.title
  }, translator(title)), renderLink(_objectSpread({}, headerLink, {
    className: mergedStyles.headerLink
  }), translator)), React.createElement("div", {
    className: mergedStyles.body
  }, children), !hideFooter && React.createElement("div", {
    className: mergedStyles.footer
  }, React.createElement("span", {
    className: mergedStyles.footerLinkContainer
  }, renderLink(_objectSpread({}, footerLink, {
    className: mergedStyles.footerLink,
    textClassName: mergedStyles.footerText
  }), translator))));
};
PopoverContainer.propTypes = popoverContainerPropTypes;
PopoverContainer.defaultProps = {
  title: messages.title,
  hideHeader: false,
  hideFooter: false
};

function renderLink(_ref2, translator) {
  var label = _ref2.label,
      onClick = _ref2.onClick,
      href = _ref2.href,
      className = _ref2.className,
      textClassName = _ref2.textClassName;

  if (!label || !(onClick || href)) {
    return null;
  }

  var children = translator(label);
  var props = {
    children: children,
    href: href,
    onClick: onClick,
    className: className,
    textClassName: textClassName
  };
  return React.createElement(Link, props);
}

PopoverContainer.__docgenInfo = {
  componentName: "PopoverContainer",
  packageName: "@jutro/components",
  description: "PopoverContainer",
  displayName: "PopoverContainer",
  methods: [],
  actualName: "PopoverContainer",
  props: {
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Popover Container title",
      defaultValue: {
        value: "messages.title",
        computed: true
      }
    },
    headerLink: {
      type: {
        name: "shape",
        value: {
          label: {
            name: "custom",
            raw: "intlMessageShape.isRequired",
            required: true
          },
          onClick: {
            name: "func",
            required: false
          },
          href: {
            name: "custom",
            raw: "intlMessageShape",
            required: false
          }
        }
      },
      required: false,
      description: "Description for header link"
    },
    footerLink: {
      type: {
        name: "shape",
        value: {
          label: {
            name: "custom",
            raw: "intlMessageShape.isRequired",
            required: true
          },
          onClick: {
            name: "func",
            required: false
          },
          href: {
            name: "custom",
            raw: "intlMessageShape",
            required: false
          }
        }
      },
      required: false,
      description: "Description for footer link"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "Popover Container content"
    },
    internalClassNames: {
      type: {
        name: "shape",
        value: {
          header: {
            name: "string",
            description: "CSS class name for popover header",
            required: false
          },
          title: {
            name: "string",
            description: "CSS class name for popover title",
            required: false
          },
          headerLink: {
            name: "string",
            description: "CSS class name for popover header link",
            required: false
          },
          body: {
            name: "string",
            description: "CSS class name for popover body",
            required: false
          },
          footer: {
            name: "string",
            description: "CSS class name for popover footer",
            required: false
          },
          footerLink: {
            name: "string",
            description: "CSS class name for popover footer link",
            required: false
          }
        }
      },
      required: false,
      description: "Map of CSS class names for overriding individual parts of component's styles"
    },
    hideHeader: {
      type: {
        name: "bool"
      },
      required: false,
      description: "When set to true hides header",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    hideFooter: {
      type: {
        name: "bool"
      },
      required: false,
      description: "When set to true hides footer",
      defaultValue: {
        value: "false",
        computed: false
      }
    }
  }
};
renderLink.__docgenInfo = {
  componentName: "renderLink",
  packageName: "@jutro/components",
  description: "",
  displayName: "renderLink",
  methods: [],
  actualName: "renderLink"
};