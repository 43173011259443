import _objectWithoutProperties from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useContext, useState, useEffect, useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useBreakpoint } from '@jutro/layout';
import { IconButton } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { contextSwitcherShape, routesShape } from '@jutro/prop-types';
import { shouldShowIcons, removeNavLinkIcon } from '../navBarHelper';
import styles from "./SideNavigation.module.css";
import { messages } from './SideNavigation.messages';
import { NavigationContent } from '../NavigationContent';
import { SideNavigationContext } from './SideNavigationContext';
import { NavBarAccordion } from './NavBarAccordion/NavBarAccordion';
export var SideNavigation = function SideNavigation(_ref) {
  var className = _ref.className,
      routes = _ref.routes,
      contextSwitcher = _ref.contextSwitcher,
      collapsibleProp = _ref.collapsible,
      collapsed = _ref.collapsed;

  var _useState = useState(''),
      _useState2 = _slicedToArray(_useState, 2),
      activeRoute = _useState2[0],
      setActiveRoute = _useState2[1];

  var _useState3 = useState(collapsed),
      _useState4 = _slicedToArray(_useState3, 2),
      isCollapsed = _useState4[0],
      setIsCollapsed = _useState4[1];

  var _useBreakpoint = useBreakpoint(),
      breakpoint = _useBreakpoint.breakpoint;

  var isCollapsible = !contextSwitcher && collapsibleProp;
  useEffect(function () {
    setIsCollapsed(collapsed);
  }, [collapsed]);
  var classes = cx(styles.sideNavigation, _defineProperty({}, styles.collapsed, isCollapsible && isCollapsed), className);
  var addSideNavStyles = useCallback(function (_ref2) {
    var navLink = _ref2.navLink,
        rest = _objectWithoutProperties(_ref2, ["navLink"]);

    return _objectSpread({}, rest, {
      navLink: _objectSpread({}, navLink, {
        className: cx(navLink && navLink.className, styles.navBarLink)
      })
    });
  }, [styles.navBarLink]);
  var sideRoutes = shouldShowIcons(routes) ? routes : _mapInstanceProperty(routes).call(routes, removeNavLinkIcon);
  var translator = useContext(TranslatorContext);
  var footer = isCollapsible && React.createElement("div", {
    className: styles.footer
  }, React.createElement(IconButton, {
    icon: isCollapsed ? 'mi-chevron-right' : 'mi-chevron-left',
    className: styles.chevronWrapper,
    iconClassName: styles.chevronIcon,
    onClick: function onClick() {
      setIsCollapsed(!isCollapsed);
    },
    ariaLabel: translator(isCollapsed ? messages.collapsedButtonLabel : messages.expandedButtonLabel)
  }));
  var showContextSwitcher = contextSwitcher && breakpoint !== 'phone';
  return React.createElement("div", {
    className: classes
  }, React.createElement("div", {
    className: styles.content
  }, React.createElement("div", {
    className: styles.layout
  }, React.createElement(SideNavigationContext.Provider, {
    value: {
      activeRoute: activeRoute,
      setActiveRoute: setActiveRoute,
      isCollapsed: isCollapsed,
      expand: function expand() {
        return setIsCollapsed(false);
      },
      collapse: function collapse() {
        return setIsCollapsed(true);
      }
    }
  }, React.createElement(NavigationContent, {
    routes: _mapInstanceProperty(sideRoutes).call(sideRoutes, addSideNavStyles),
    contextSwitcher: showContextSwitcher ? contextSwitcher : undefined,
    alignment: "left",
    className: styles.navigationContent,
    nestedRoutesComponent: NavBarAccordion
  })), footer)));
};
SideNavigation.propTypes = {
  className: PropTypes.string,
  routes: routesShape.isRequired,
  contextSwitcher: contextSwitcherShape,
  collapsible: PropTypes.bool,
  collapsed: PropTypes.bool
};
SideNavigation.defaultProps = {
  collapsible: false,
  collapsed: false
};
SideNavigation.__docgenInfo = {
  componentName: "SideNavigation",
  packageName: "@jutro/router",
  description: "The `SideNavigation` component is designed as a container for navigation links and dropdowns",
  displayName: "SideNavigation",
  methods: [],
  actualName: "SideNavigation",
  metadataType: "container",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component."
    },
    routes: {
      type: {
        name: "arrayOf",
        value: {
          name: "custom",
          raw: "routeShape"
        }
      },
      required: true,
      description: "Routing metadata object"
    },
    contextSwitcher: {
      type: {
        name: "shape",
        value: {
          defaultLabel: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Default button label when no context is active",
            required: false
          },
          values: {
            name: "arrayOf",
            value: {
              name: "custom",
              raw: "contextShape.isRequired"
            },
            description: "Array of values for the contexts",
            required: true
          }
        }
      },
      required: false,
      description: "Context switcher object"
    },
    collapsible: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Defines whether component can be collapsed",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    collapsed: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Default state for SideNavigation",
      defaultValue: {
        value: "false",
        computed: false
      }
    }
  }
};