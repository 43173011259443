import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from "./HeaderActions.module.css";
var headerActionsPropTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};
export var HeaderActions = function HeaderActions(_ref) {
  var children = _ref.children,
      className = _ref.className;
  var classes = cx(styles.headerActions, className);
  return React.createElement("div", {
    className: classes
  }, children);
};
HeaderActions.propTypes = headerActionsPropTypes;
HeaderActions.__docgenInfo = {
  componentName: "HeaderActions",
  packageName: "@jutro/components",
  description: "HeaderActions",
  displayName: "HeaderActions",
  methods: [],
  actualName: "HeaderActions",
  metadataType: "container",
  props: {
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: ""
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: ""
    }
  }
};