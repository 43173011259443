import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _extends from "@babel/runtime-corejs3/helpers/extends";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { FieldComponent, DateField } from '@jutro/components';
import { publish, JUTRO_TOPICS } from '@jutro/events';
import { Grid } from '@jutro/layout';
import { DATA_TYPE_OBJECT, dateTimeZoneValueShape, intlMessageShape, nestedTooltipShape } from '@jutro/prop-types';
import styles from "./DateTimeZoneField.module.css";
import { DateTimeField } from '../DateTimeField/DateTimeField';
import { TimeZoneField } from './TimeZoneField';
import { useTimeZoneDropdownValues } from './useTimeZoneDropdownValues';
import { parseDateTimeZoneFromDataType, formatDateTimeZoneToDataType } from './helpers';

var dateTimeZoneFieldPropTypes = _objectSpread({}, DateTimeField.propTypes, {
  value: dateTimeZoneValueShape,
  defaultValue: dateTimeZoneValueShape,
  showTimeZone: PropTypes.bool,
  timeZoneLabel: intlMessageShape,
  timeZoneSecondaryLabel: intlMessageShape,
  timeZoneTooltip: PropTypes.oneOfType([PropTypes.string, nestedTooltipShape]),
  timeZoneHideLabel: PropTypes.bool,
  timeZones: PropTypes.arrayOf(PropTypes.string.isRequired),
  defaultTimeZone: PropTypes.string
});

export var DateTimeZoneField = function DateTimeZoneField(props) {
  var className = props.className,
      isClearable = props.isClearable,
      showTime = props.showTime,
      tooltipTime = props.tooltipTime,
      placeholderTime = props.placeholderTime,
      labelTime = props.labelTime,
      hideLabelTime = props.hideLabelTime,
      secondaryLabelTime = props.secondaryLabelTime,
      showTimeZone = props.showTimeZone,
      timeZoneLabel = props.timeZoneLabel,
      timeZoneSecondaryLabel = props.timeZoneSecondaryLabel,
      timeZoneTooltip = props.timeZoneTooltip,
      timeZoneHideLabel = props.timeZoneHideLabel,
      timeZones = props.timeZones,
      defaultTimeZone = props.defaultTimeZone,
      id = props.id,
      _props$dataType = props.dataType,
      dataType = _props$dataType === void 0 ? DATA_TYPE_OBJECT : _props$dataType,
      _props$value = props.value,
      valueProp = _props$value === void 0 ? {} : _props$value,
      _props$defaultValue = props.defaultValue,
      defaultValue = _props$defaultValue === void 0 ? {} : _props$defaultValue,
      path = props.path,
      model = props.model,
      label = props.label,
      hideLabel = props.hideLabel,
      dataPath = props.dataPath,
      onValueChange = props.onValueChange,
      commonFieldProps = _objectWithoutProperties(props, ["className", "isClearable", "showTime", "tooltipTime", "placeholderTime", "labelTime", "hideLabelTime", "secondaryLabelTime", "showTimeZone", "timeZoneLabel", "timeZoneSecondaryLabel", "timeZoneTooltip", "timeZoneHideLabel", "timeZones", "defaultTimeZone", "id", "dataType", "value", "defaultValue", "path", "model", "label", "hideLabel", "dataPath", "onValueChange"]);

  var allLabelsHidden = (!!hideLabel || !label) && (timeZoneHideLabel || !timeZoneLabel) && (hideLabelTime || !labelTime);

  var _useTimeZoneDropdownV = useTimeZoneDropdownValues(timeZones),
      _useTimeZoneDropdownV2 = _slicedToArray(_useTimeZoneDropdownV, 2),
      currentTimeZone = _useTimeZoneDropdownV2[0],
      timeZoneValues = _useTimeZoneDropdownV2[1];

  var parsedValue = parseDateTimeZoneFromDataType(valueProp, defaultTimeZone || currentTimeZone, showTime);

  var handleChange = function handleChange(value) {
    var newValue = _objectSpread({}, parsedValue, {}, value);

    var formattedValue = formatDateTimeZoneToDataType(newValue, dataType, showTime);

    if (!onValueChange) {
      return;
    }

    onValueChange(formattedValue, model || path, {
      id: id,
      valueProp: valueProp,
      dataPath: dataPath
    });
    publish(JUTRO_TOPICS.VALUE_CHANGED, FieldComponent.fieldEventFormatter(_objectSpread({}, props, {
      value: formattedValue
    })));
  };

  var renderDateTime = function renderDateTime() {
    var handleDateTimeChange = function handleDateTimeChange(datetime) {
      return handleChange(_objectSpread({}, parsedValue, {
        datetime: datetime
      }));
    };

    var dateTimeHideLabel = allLabelsHidden;
    var labelClassName = cx(_defineProperty({}, styles.hideVisibility, !allLabelsHidden && hideLabel));
    return React.createElement(DateTimeField, _extends({}, commonFieldProps, {
      id: "".concat(id, "-datetime"),
      isClearable: isClearable,
      label: label,
      hideLabel: dateTimeHideLabel,
      labelClassName: labelClassName,
      showTime: showTime,
      dataType: DATA_TYPE_OBJECT,
      onValueChange: handleDateTimeChange,
      value: parsedValue.datetime,
      defaultValue: defaultValue.datetime,
      tooltipTime: tooltipTime,
      placeholderTime: placeholderTime,
      labelTime: labelTime,
      hideLabelTime: hideLabelTime,
      secondaryLabelTime: secondaryLabelTime
    }));
  };

  var renderTimeZone = function renderTimeZone() {
    var handleTimeZoneChange = function handleTimeZoneChange(timezone) {
      return handleChange(_objectSpread({}, parsedValue, {
        timezone: timezone
      }));
    };

    var timeZoneOverrideHideLabel = allLabelsHidden;
    var labelClassName = cx(_defineProperty({}, styles.hideVisibility, !allLabelsHidden && timeZoneHideLabel));
    return React.createElement(TimeZoneField, _extends({}, commonFieldProps, {
      id: "".concat(id, "-timezone"),
      className: styles.timeZoneField,
      labelClassName: labelClassName,
      availableValues: timeZoneValues,
      value: parsedValue.timezone,
      defaultValue: defaultValue.timezone,
      hideLabel: timeZoneOverrideHideLabel,
      label: timeZoneLabel,
      secondaryLabel: timeZoneSecondaryLabel,
      tooltip: timeZoneTooltip,
      onValueChange: handleTimeZoneChange
    }));
  };

  return React.createElement(Grid, {
    columns: commonFieldProps.labelPosition === 'top' ? ['2fr', 'auto'] : ['1fr'],
    rows: ['1fr'],
    phone: {
      columns: ['1fr']
    },
    className: className
  }, renderDateTime(), showTimeZone && renderTimeZone());
};
DateTimeZoneField.displayName = 'DateTimeZoneField';
DateTimeZoneField.propTypes = dateTimeZoneFieldPropTypes;
DateTimeZoneField.defaultProps = _objectSpread({}, DateField.defaultProps, {
  dataType: DATA_TYPE_OBJECT,
  showTime: true,
  showTimeZone: true
});
DateTimeZoneField.__docgenInfo = {
  componentName: "DateTimeZoneField",
  packageName: "@jutro/lab-preview-date",
  description: "",
  displayName: "DateTimeZoneField",
  methods: [],
  actualName: "DateTimeZoneField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "custom",
        raw: "dateTimeZoneValueShape"
      },
      required: false,
      description: "Value to display"
    },
    defaultValue: {
      type: {
        name: "custom",
        raw: "dateTimeZoneValueShape"
      },
      required: false,
      description: "Set the default field value on render if there is a default value; needs onValueChange to work"
    },
    showTimeZone: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Show timezone picker",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    timeZoneLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Intl message for time zone field label"
    },
    timeZoneSecondaryLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Intl message for time zone field secondary label (same as secondaryLabel prop by default)"
    },
    timeZoneTooltip: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "custom",
          raw: "nestedTooltipShape"
        }]
      },
      required: false,
      description: "Tooltip for time zone field (same as tooltip prop by default)"
    },
    timeZoneHideLabel: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Hide time zone label (same as hideLabel by default)"
    },
    timeZones: {
      type: {
        name: "arrayOf",
        value: {
          name: "string"
        }
      },
      required: false,
      description: "Time zones to show in the dropdown"
    },
    defaultTimeZone: {
      type: {
        name: "string"
      },
      required: false,
      description: "Default time zone to be used (local by default)"
    },
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_OBJECT",
        computed: true
      },
      required: false
    },
    showTime: {
      defaultValue: {
        value: "true",
        computed: false
      },
      required: false
    }
  },
  composes: ["../DateTimeField/DateTimeField"]
};