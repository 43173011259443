import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import isArray from "lodash/isArray";
import { isSupportedHTMLElement } from './checkSupportedHTMLElements';
import { isSupportedHTMLContainer } from './checkSupportedHTMLContainers';
export var metadataTypes = {
  ACTION: 'action',
  ELEMENT: 'element',
  CONTAINER: 'container',
  FIELD: 'field',
  ITERABLE: 'iterable',
  LAYOUT: 'layout',
  COMPONENT: 'component'
};
var metadataTypePattern = /^@metadataType\s([a-z]+)$/;
export var getComponentMetadataType = function getComponentMetadataType(component, content) {
  var _component$__docgenIn, _context, _component$__docgenIn2, _component$__docgenIn3;

  var metadataTypeFilteredFromDescription = component === null || component === void 0 ? void 0 : (_component$__docgenIn = component.__docgenInfo) === null || _component$__docgenIn === void 0 ? void 0 : _filterInstanceProperty(_context = _component$__docgenIn.description.split('\n')).call(_context, function (line) {
    return line.match(metadataTypePattern);
  });

  if (metadataTypeFilteredFromDescription !== null && metadataTypeFilteredFromDescription !== void 0 && metadataTypeFilteredFromDescription.length) {
    return metadataTypeFilteredFromDescription[0].replace(metadataTypePattern, '$1');
  }

  if (!isArray(content) && isSupportedHTMLElement(component)) {
    return metadataTypes.ELEMENT;
  }

  if (isArray(content) && isSupportedHTMLContainer(component)) {
    return metadataTypes.CONTAINER;
  }

  return (component === null || component === void 0 ? void 0 : component.metadataType) || (component === null || component === void 0 ? void 0 : (_component$__docgenIn2 = component.__docgenInfo) === null || _component$__docgenIn2 === void 0 ? void 0 : _component$__docgenIn2.metadataType) || (component === null || component === void 0 ? void 0 : (_component$__docgenIn3 = component.__docgenInfo) === null || _component$__docgenIn3 === void 0 ? void 0 : _component$__docgenIn3.jutroMetadataType);
};