import { defineMessages } from 'react-intl';
export var messages = defineMessages({
  validationMinValue: {
    id: 'jutro-components.widgets.inputs.The minimum allowed value is',
    defaultMessage: 'The minimum allowed value is {value}'
  },
  validationMaxValue: {
    id: 'jutro-components.widgets.inputs.The maximum allowed value is',
    defaultMessage: 'The maximum allowed value is {value}'
  }
});