import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useContext, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { LocaleContext, LanguageContext, LocaleService, getNativeLocaleLanguage, TranslatorContext } from '@jutro/locale';
import { Grid } from '@jutro/layout';
import { intlMessageShape } from '@jutro/prop-types';
import { getConfigValue } from '@jutro/config';
import { warning } from '@jutro/logger';
import styles from "./GlobalizationChooser.module.css";
import { messages } from './GlobalizationChooser.messages.js';
import { GlobalizationChooserStorybook } from './GlobalizationChooserStorybook';
import { DropdownSelectField } from '../inputs/DropdownSelectField/DropdownSelectField';
var defaultLocaleId = 'localeSelect';
var defaultLanguageId = 'languageSelect';
var defaultLocaleLabel = messages.localeLabel;
var defaultLanguageLabel = messages.languageLabel;
var globalizationChooserPropTypes = {
  className: PropTypes.string,
  containerStyle: PropTypes.string,
  innerStyle: PropTypes.string,
  localeId: PropTypes.string,
  languageId: PropTypes.string,
  localeValue: PropTypes.string,
  languageValue: PropTypes.string,
  languageLabelText: intlMessageShape,
  localeLabelText: intlMessageShape,
  availableLanguageValues: PropTypes.array,
  availableLocaleValues: PropTypes.array,
  onLocaleValueChange: PropTypes.func,
  onLanguageValueChange: PropTypes.func,
  renderLocaleLabel: PropTypes.func,
  renderLanguageLabel: PropTypes.func,
  showLocaleLabel: PropTypes.bool,
  showLanguageLabel: PropTypes.bool,
  showLocaleSelect: PropTypes.bool,
  showLanguageSelect: PropTypes.bool,
  style: PropTypes.object,
  storybookMode: PropTypes.bool,
  readOnly: PropTypes.bool,
  skipPropagation: PropTypes.bool
};
export var GlobalizationChooser = function GlobalizationChooser(_ref) {
  var onLocaleValueChange = _ref.onLocaleValueChange,
      onLanguageValueChange = _ref.onLanguageValueChange,
      localeId = _ref.localeId,
      languageId = _ref.languageId,
      localeLabelText = _ref.localeLabelText,
      languageLabelText = _ref.languageLabelText,
      _ref$localeValue = _ref.localeValue,
      localeValue = _ref$localeValue === void 0 ? getDefaultLocale() : _ref$localeValue,
      _ref$languageValue = _ref.languageValue,
      languageValue = _ref$languageValue === void 0 ? getDefaultLanguage() : _ref$languageValue,
      _ref$availableLanguag = _ref.availableLanguageValues,
      availableLanguageValues = _ref$availableLanguag === void 0 ? getDefaultLanguages() : _ref$availableLanguag,
      _ref$availableLocaleV = _ref.availableLocaleValues,
      availableLocaleValues = _ref$availableLocaleV === void 0 ? getDefaultLocales() : _ref$availableLocaleV,
      _ref$renderLocaleLabe = _ref.renderLocaleLabel,
      renderLocaleLabel = _ref$renderLocaleLabe === void 0 ? getNativeLocaleLanguage : _ref$renderLocaleLabe,
      _ref$renderLanguageLa = _ref.renderLanguageLabel,
      renderLanguageLabel = _ref$renderLanguageLa === void 0 ? getNativeLocaleLanguage : _ref$renderLanguageLa,
      showLocaleLabel = _ref.showLocaleLabel,
      showLanguageLabel = _ref.showLanguageLabel,
      showLocaleSelect = _ref.showLocaleSelect,
      showLanguageSelect = _ref.showLanguageSelect,
      className = _ref.className,
      containerStyle = _ref.containerStyle,
      innerStyle = _ref.innerStyle,
      _ref$style = _ref.style,
      style = _ref$style === void 0 ? {} : _ref$style,
      storybookMode = _ref.storybookMode,
      readOnly = _ref.readOnly,
      skipPropagation = _ref.skipPropagation;
  var translator = useContext(TranslatorContext);
  var availableLanguages = availableLanguageValues;

  if (getConfigValue('JUTRO_AUTH_ENABLED', false) && getConfigValue('JUTRO_TSM_ENABLED', false)) {
    availableLanguages = getDefaultLanguages();
    warning("Reading language list from String Manager service. Ignoring \"availableLanguageValues\" property in app config");
  }

  var languageContext = useContext(LanguageContext);
  var localeContext = useContext(LocaleContext);

  var _useState = useState(LocaleService.getStoredLocale()),
      _useState2 = _slicedToArray(_useState, 2),
      activeLocale = _useState2[0],
      setActiveLocale = _useState2[1];

  var resolvedLocale = useMemo(function () {
    if (skipPropagation) {
      return localeValue;
    }

    return activeLocale || localeValue;
  }, [activeLocale, localeValue, skipPropagation]);

  var _useState3 = useState(LocaleService.getStoredLanguage()),
      _useState4 = _slicedToArray(_useState3, 2),
      activeLanguage = _useState4[0],
      setActiveLanguage = _useState4[1];

  var resolvedLanguage = useMemo(function () {
    if (skipPropagation) {
      return languageValue;
    }

    return activeLanguage || languageValue;
  }, [activeLanguage, languageValue, skipPropagation]);
  var updateActiveLocale = useCallback(function (locale) {
    LocaleService.setCurrentLocale(locale);

    if (localeContext !== null && localeContext !== void 0 && localeContext.localeOnChangeCallback) {
      localeContext.localeOnChangeCallback(locale);
    }
  }, []);
  var handleOnLocaleChange = useCallback(function (newLocale) {
    if (onLocaleValueChange) {
      onLocaleValueChange(newLocale);
    }

    setActiveLocale(newLocale);

    if (!skipPropagation) {
      updateActiveLocale(newLocale);
    }
  }, [localeContext, onLocaleValueChange, updateActiveLocale, skipPropagation]);
  var updateActiveLanguage = useCallback(function (language) {
    LocaleService.setCurrentLanguage(language);

    if (languageContext !== null && languageContext !== void 0 && languageContext.languageOnChangeCallback) {
      languageContext.languageOnChangeCallback(language);
    }
  }, []);
  var handleOnLanguageChange = useCallback(function (newLanguage) {
    if (onLanguageValueChange) {
      onLanguageValueChange(newLanguage);
    }

    setActiveLanguage(newLanguage);

    if (!skipPropagation) {
      updateActiveLanguage(newLanguage);
    }
  }, [languageContext, onLanguageValueChange, updateActiveLanguage, skipPropagation]);
  var availableLanguageOptions = useMemo(function () {
    var _context;

    return _filterInstanceProperty(_context = _mapInstanceProperty(availableLanguages).call(availableLanguages, function (value) {
      var label = renderLanguageLabel(value.toLowerCase());
      return label ? {
        value: value,
        label: label
      } : null;
    })).call(_context, function (x) {
      return x;
    });
  }, [availableLanguages, renderLanguageLabel]);
  var availableLocaleOptions = useMemo(function () {
    var _context2;

    return _filterInstanceProperty(_context2 = _mapInstanceProperty(availableLocaleValues).call(availableLocaleValues, function (value) {
      var label = renderLocaleLabel(value.toLowerCase());
      return label ? {
        value: value,
        label: label
      } : null;
    })).call(_context2, function (x) {
      return x;
    });
  }, [availableLocaleValues, renderLocaleLabel]);
  var dropdownLanguageOptions = useMemo(function () {
    return _mapInstanceProperty(availableLanguageOptions).call(availableLanguageOptions, function (_ref2) {
      var value = _ref2.value,
          label = _ref2.label;
      return {
        displayName: label,
        id: value
      };
    });
  }, [availableLanguageOptions]);
  var dropdownLocaleOptions = useMemo(function () {
    return _mapInstanceProperty(availableLocaleOptions).call(availableLocaleOptions, function (_ref3) {
      var value = _ref3.value,
          label = _ref3.label;
      return {
        displayName: label,
        id: value
      };
    });
  }, [availableLocaleOptions]);
  var localeLabel = translator(localeLabelText);
  var languageLabel = translator(languageLabelText);

  if (storybookMode) {
    return React.createElement(GlobalizationChooserStorybook, {
      languageId: languageId,
      languageLabel: languageLabel,
      showLanguageLabel: showLanguageLabel,
      showLanguageSelect: showLanguageSelect,
      availableLanguageOptions: availableLanguageOptions,
      resolvedLanguage: resolvedLanguage,
      onLanguageChange: handleOnLanguageChange,
      localeId: localeId,
      localeLabel: localeLabel,
      showLocaleLabel: showLocaleLabel,
      showLocaleSelect: showLocaleSelect,
      availableLocaleOptions: availableLocaleOptions,
      resolvedLocale: resolvedLocale,
      onLocaleChange: handleOnLocaleChange,
      containerStyle: containerStyle,
      innerStyle: innerStyle,
      style: style
    });
  }

  return React.createElement(Grid, {
    gap: "large",
    className: containerStyle
  }, showLanguageSelect && React.createElement(DropdownSelectField, {
    label: languageLabel,
    hideLabel: !showLanguageLabel,
    availableValues: dropdownLanguageOptions,
    value: resolvedLanguage,
    onValueChange: handleOnLanguageChange,
    className: className,
    controlClassName: styles.globalizationChooser,
    id: languageId,
    readOnly: readOnly
  }), showLocaleSelect && React.createElement(DropdownSelectField, {
    label: localeLabel,
    hideLabel: !showLocaleLabel,
    availableValues: dropdownLocaleOptions,
    value: resolvedLocale,
    onValueChange: handleOnLocaleChange,
    className: className,
    controlClassName: styles.globalizationChooser,
    id: localeId,
    readOnly: readOnly
  }));
};
GlobalizationChooser.propTypes = globalizationChooserPropTypes;
GlobalizationChooser.defaultProps = {
  showLocaleSelect: true,
  showLanguageSelect: true,
  languageId: defaultLanguageId,
  localeId: defaultLocaleId,
  localeLabelText: defaultLocaleLabel,
  languageLabelText: defaultLanguageLabel,
  showLocaleLabel: true,
  showLanguageLabel: true,
  storybookMode: false,
  readOnly: false,
  skipPropagation: false
};

var getDefaultLocales = function getDefaultLocales() {
  var availableLocales = LocaleService.getAvailableLocales();
  var defaultLocales = LocaleService.settings ? LocaleService.settings.getAvailableLocales() : [LocaleService.locale];
  return availableLocales || defaultLocales;
};

var getDefaultLanguages = function getDefaultLanguages() {
  var availableLanguages = LocaleService.getAvailableLanguages();
  var defaultLanguages = LocaleService.settings ? LocaleService.settings.getAvailableLanguages() : [LocaleService.locale];
  return availableLanguages || defaultLanguages;
};

var getDefaultLocale = function getDefaultLocale() {
  return LocaleService.getCurrentLocale();
};

var getDefaultLanguage = function getDefaultLanguage() {
  return LocaleService.getCurrentLanguage();
};

GlobalizationChooser.__docgenInfo = {
  componentName: "GlobalizationChooser",
  packageName: "@jutro/components",
  description: "Component that allows the user to select the preferred application language and locale.",
  displayName: "GlobalizationChooser",
  methods: [],
  actualName: "GlobalizationChooser",
  metadataType: "element",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component."
    },
    containerStyle: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component container."
    },
    innerStyle: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component inner container. Used only in Storybook mode."
    },
    localeId: {
      type: {
        name: "string"
      },
      required: false,
      description: "Id of the locale select element.",
      defaultValue: {
        value: "'localeSelect'",
        computed: false
      }
    },
    languageId: {
      type: {
        name: "string"
      },
      required: false,
      description: "Id of the language select element.",
      defaultValue: {
        value: "'languageSelect'",
        computed: false
      }
    },
    localeValue: {
      type: {
        name: "string"
      },
      required: false,
      description: "Selected locale.",
      defaultValue: {
        value: "getDefaultLocale()",
        computed: true
      }
    },
    languageValue: {
      type: {
        name: "string"
      },
      required: false,
      description: "Selected language.",
      defaultValue: {
        value: "getDefaultLanguage()",
        computed: true
      }
    },
    languageLabelText: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Message key for the language label.",
      defaultValue: {
        value: "messages.languageLabel",
        computed: true
      }
    },
    localeLabelText: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Message key for the locale label.",
      defaultValue: {
        value: "messages.localeLabel",
        computed: true
      }
    },
    availableLanguageValues: {
      type: {
        name: "array"
      },
      required: false,
      description: "Languages available for selection.",
      defaultValue: {
        value: "getDefaultLanguages()",
        computed: true
      }
    },
    availableLocaleValues: {
      type: {
        name: "array"
      },
      required: false,
      description: "Locales available for selection.",
      defaultValue: {
        value: "getDefaultLocales()",
        computed: true
      }
    },
    onLocaleValueChange: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback invoked on locale change."
    },
    onLanguageValueChange: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback invoked on language change."
    },
    renderLocaleLabel: {
      type: {
        name: "func"
      },
      required: false,
      description: "Render prop to display locale in options.",
      defaultValue: {
        value: "getNativeLocaleLanguage",
        computed: true
      }
    },
    renderLanguageLabel: {
      type: {
        name: "func"
      },
      required: false,
      description: "Render prop to display language in options.",
      defaultValue: {
        value: "getNativeLocaleLanguage",
        computed: true
      }
    },
    showLocaleLabel: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Flag for showing or hiding locale label",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    showLanguageLabel: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Flag for showing or hiding language label",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    showLocaleSelect: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Flag for showing/hiding the locale select",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    showLanguageSelect: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Flag for showing/hiding the language select",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    style: {
      type: {
        name: "object"
      },
      required: false,
      description: "Object containing the style overrides for component.  Used only in Storybook mode.",
      defaultValue: {
        value: "{}",
        computed: false
      }
    },
    storybookMode: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Option for rendering using native HTML tags instead of Jutro components.",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    readOnly: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If set to true the dropdowns are readonly. Ignored in the storybook mode.",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    skipPropagation: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If set to true the config is not updated on value change and GlobalizationChooser becomes a controlled component.",
      defaultValue: {
        value: "false",
        computed: false
      }
    }
  }
};