import { cortinaBadge } from './Guidewire_Cortina_Badge.min.js';
export var cortinaFloorplans = {
  '--GW-HEADER-BACKGROUND-COLOR': '#131e2e',
  '--GW-HEADER-SEARCH-BACKGROUND-COLOR': 'rgb(255, 255, 255, 0.1)',
  '--GW-TOP-NAV-BACKGROUND-COLOR': '#324c76',
  '--GW-TOP-NAV-ITEM-BACKGROUND-COLOR': '#324c76',
  '--GW-TOP-NAV-ITEM-COLOR': '#fff',
  '--GW-TOP-NAV-ITEM-BOX-SHADOW-FOCUS-COLOR-AFTER': '#65c6c4',
  '--GW-TOP-NAV-ITEM-BACKGROUND-COLOR-HOVER': 'rgb(255, 255, 255, 0.2)',
  '--GW-TOP-NAV-ITEM-COLOR-HOVER': '#fff',
  '--GW-TOP-NAV-ITEM-BACKGROUND-COLOR-ACTIVE': 'rgba(255, 255, 255, 0.4)',
  '--GW-TOP-NAV-ITEM-COLOR-ACTIVE': '#fff',
  '--GW-SIDE-NAV-ACCORDION-BACKGROUND-COLOR': '#f8f9fa',
  '--GW-SIDE-NAV-CHEVRON-COLOR': '#fff',
  '--GW-SIDE-NAV-CHEVRON-COLOR-ACTIVE': '#65c6c4',
  '--GW-SIDE-NAV-CONTENT-BACKGROUND-COLOR': '#131e2e',
  '--GW-SIDE-NAV-ITEM-COLOR': '#fff',
  '--GW-SIDE-NAV-ITEM-COLOR-HOVER': '#fff',
  '--GW-SIDE-NAV-ITEM-COLOR-ACTIVE': '#fff',
  '--GW-SIDE-NAV-ITEM-BACKGROUND-COLOR-HOVER': '#294164',
  '--GW-SIDE-NAV-ITEM-BACKGROUND-COLOR-ACTIVE': '#d3dded',
  '--GW-SIDE-NAV-SUB-ITEM-BACKGROUND-COLOR-HOVER': '#d3dded',
  '--GW-RIGHT-SIDEBAR-SIDE-BACKGROUND-COLOR': '#e1e6ec',
  '--GW-RIGHT-SIDEBAR-BAR-BACKGROUND-COLOR': '#e1e6ec',
  '--GW-RIGHT-SIDEBAR-ITEM-BACKGROUND-COLOR-ACTIVE': 'rgba(40,51,63,0.1)',
  '--GW-FOOTER-BACKGROUND-COLOR': '#131e2e',
  '--GW-RELEASE-BADGE-URL': "url(\"".concat(cortinaBadge, "\")"),
  '--GW-RELEASE-BADGE-DATA': cortinaBadge
};