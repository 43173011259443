import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import convert from 'color-convert';
export function isBright(hexColor) {
  if (!hexColor && hexColor.length !== 7 && hexColor.length !== 9) {
    return false;
  }

  if (hexColor.length === 9) {
    return true;
  }

  var hexRawColor = hexColor.length === 7 ? hexColor : hexColor.substr(0, 7);

  var _convert$hex$rgb = convert.hex.rgb(hexRawColor),
      _convert$hex$rgb2 = _slicedToArray(_convert$hex$rgb, 3),
      r = _convert$hex$rgb2[0],
      g = _convert$hex$rgb2[1],
      b = _convert$hex$rgb2[2];

  var brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 130;
}