import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';
import isNil from "lodash/isNil";
import { dateValueShape, intlMessageShape } from '@jutro/prop-types';
import { FormattedDate, formatDate } from './FormattedDate';
import { FieldComponent } from '../inputs/FieldComponent/FieldComponent';
var formattedDateRangePropTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  startValue: dateValueShape,
  endValue: dateValueShape,
  placeholder: intlMessageShape,
  format: PropTypes.oneOf(['short', 'long', 'abbreviated', 'full']),
  separator: PropTypes.string,
  showTime: PropTypes.bool
};
export var FormattedDateRange = function FormattedDateRange(props) {
  var startDate = props.startDate,
      startValue = props.startValue,
      separator = props.separator,
      endDate = props.endDate,
      endValue = props.endValue,
      other = _objectWithoutProperties(props, ["startDate", "startValue", "separator", "endDate", "endValue"]);

  return React.createElement(React.Fragment, null, React.createElement(FormattedDate, _extends({
    date: startDate,
    value: startValue
  }, other)), separator, React.createElement(FormattedDate, _extends({
    date: endDate,
    value: endValue
  }, other)));
};
FormattedDateRange.propTypes = formattedDateRangePropTypes;
FormattedDateRange.defaultProps = {
  format: 'long',
  placeholder: FieldComponent.defaultROEmptyValue,
  separator: ' - ',
  showTime: false
};
export function formatDateRange(intl, props, dateRangeObject) {
  var _ref = isNil(dateRangeObject) ? {} : dateRangeObject,
      startDate = _ref.startDate,
      endDate = _ref.endDate,
      _ref$separator = _ref.separator,
      separator = _ref$separator === void 0 ? FormattedDateRange.defaultProps.separator : _ref$separator;

  var startDateProp = props.startDate,
      endDateProp = props.endDate,
      startValue = props.startValue,
      endValue = props.endValue,
      separatorProp = props.separator,
      otherProps = _objectWithoutProperties(props, ["startDate", "endDate", "startValue", "endValue", "separator"]);

  var formattedStartDate = formatDate(intl, _objectSpread({}, otherProps, {
    date: startDateProp,
    value: startValue
  }), startDate);
  var formattedEndDate = formatDate(intl, _objectSpread({}, otherProps, {
    date: endDateProp,
    value: endValue
  }), endDate);
  return "".concat(formattedStartDate).concat(separator || separatorProp).concat(formattedEndDate);
}
FormattedDateRange.__docgenInfo = {
  componentName: "FormattedDateRange",
  packageName: "@jutro/components",
  description: "The `FormattedDateRange` component is used to render `Date` values\nlocalized and in specific preset formats",
  displayName: "FormattedDateRange",
  methods: [],
  actualName: "FormattedDateRange",
  metadataType: "element",
  props: {
    startDate: {
      type: {
        name: "instanceOf",
        value: "Date"
      },
      required: false,
      description: "The start date to display"
    },
    endDate: {
      type: {
        name: "instanceOf",
        value: "Date"
      },
      required: false,
      description: "The end date to display"
    },
    startValue: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }, {
          name: "instanceOf",
          value: "Date"
        }, {
          name: "shape",
          value: {
            year: {
              name: "number",
              required: true
            },
            month: {
              name: "number",
              required: true
            },
            day: {
              name: "number",
              required: true
            },
            hour: {
              name: "number",
              required: false
            },
            minute: {
              name: "number",
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Start value to display in the format of string|number|object"
    },
    endValue: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }, {
          name: "instanceOf",
          value: "Date"
        }, {
          name: "shape",
          value: {
            year: {
              name: "number",
              required: true
            },
            month: {
              name: "number",
              required: true
            },
            day: {
              name: "number",
              required: true
            },
            hour: {
              name: "number",
              required: false
            },
            minute: {
              name: "number",
              required: false
            }
          }
        }]
      },
      required: false,
      description: "End value to display in the format of string|number|object"
    },
    placeholder: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "The string to display if start or end 'date' is undefined/null",
      defaultValue: {
        value: "FieldComponent.defaultROEmptyValue",
        computed: true
      }
    },
    format: {
      type: {
        name: "enum",
        value: [{
          value: "'short'",
          computed: false
        }, {
          value: "'long'",
          computed: false
        }, {
          value: "'abbreviated'",
          computed: false
        }, {
          value: "'full'",
          computed: false
        }]
      },
      required: false,
      description: "The date format: 'short' or 'long'",
      defaultValue: {
        value: "'long'",
        computed: false
      }
    },
    separator: {
      type: {
        name: "string"
      },
      required: false,
      description: "The range separator (between start and end date)",
      defaultValue: {
        value: "' - '",
        computed: false
      }
    },
    showTime: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, displays the time with the date",
      defaultValue: {
        value: "false",
        computed: false
      }
    }
  }
};