import _objectWithoutProperties from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { AuthContext } from './AuthContextProvider';

var secureRoutePropTypes = _objectSpread({}, Route.propTypes, {
  children: PropTypes.func,
  component: PropTypes.elementType,
  render: PropTypes.func
});

export var SecureRoute = function SecureRoute(_ref) {
  var children = _ref.children,
      Component = _ref.component,
      render = _ref.render,
      props = _objectWithoutProperties(_ref, ["children", "component", "render"]);

  var auth = useContext(AuthContext);

  if (auth && !auth.authenticated && !auth.isPending) {
    auth.login();
  }

  return React.createElement(Route, props, function (routeProps) {
    if (children) {
      return children(_objectSpread({}, routeProps, {
        auth: auth
      }));
    }

    if (Component) {
      if (auth) {
        return (auth === null || auth === void 0 ? void 0 : auth.authenticated) && React.createElement(Component, routeProps);
      }

      return React.createElement(Component, routeProps);
    }

    if (render) {
      return render(_objectSpread({}, routeProps, {
        auth: auth
      }));
    }

    return null;
  });
};
SecureRoute.propTypes = secureRoutePropTypes;
SecureRoute.displayName = 'SecureRoute';
SecureRoute.__docgenInfo = {
  componentName: "SecureRoute",
  packageName: "@jutro/auth",
  description: "Route which requires authentication\nInspired by Octa's SecureRoute\nhttps://github.com/okta/okta-oidc-js/blob/master/packages/okta-react/src/SecureRoute.js\nhttps://github.com/okta/okta-oidc-js/blob/8fe896a383fc5e02999d63068175e72bec538115/packages/okta-react/src/SecureRoute.js",
  displayName: "SecureRoute",
  methods: [],
  actualName: "SecureRoute",
  props: {
    children: {
      type: {
        name: "func"
      },
      required: false,
      description: "Functions called when rendering route. Takes precedence over component and render\n({ auth: Auth, ...routeProps }) => React.ReactNode"
    },
    component: {
      type: {
        name: "elementType"
      },
      required: false,
      description: "Component to be render when authenticated. Takes precedence over render"
    },
    render: {
      type: {
        name: "func"
      },
      required: false,
      description: "Functions called when rendering route\n({ auth: Auth, ...routeProps }) => React.ReactNode"
    }
  },
  composes: ["react-router-dom"]
};