import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Flex, FlexItem, useBreakpoint } from '@jutro/layout';
import styles from "./ActionTitleBar.module.css";
import { TitleElement } from './TitleElement';
export var ActionTitleBar = function ActionTitleBar(props) {
  var _useBreakpoint = useBreakpoint(props),
      breakpointProps = _useBreakpoint.breakpointProps;

  var className = breakpointProps.className;
  var childrenArray = React.Children.toArray(breakpointProps.children);

  var isTitleElement = function isTitleElement(child) {
    return child.type === TitleElement;
  };

  var titleElements = _filterInstanceProperty(childrenArray).call(childrenArray, isTitleElement);

  var actions = _filterInstanceProperty(childrenArray).call(childrenArray, function (child) {
    return !isTitleElement(child);
  });

  return React.createElement(Flex, {
    className: cx(styles.actionTitleBar, styles.actionTitleBarContainer, className),
    alignItems: "stretch"
  }, React.createElement(FlexItem, {
    grow: "1"
  }, titleElements), React.createElement(FlexItem, {
    textAlign: "right"
  }, actions));
};
export var actionTitleBarBasePropTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};
ActionTitleBar.propTypes = _objectSpread({}, actionTitleBarBasePropTypes, {
  phone: PropTypes.shape(actionTitleBarBasePropTypes),
  phoneWide: PropTypes.shape(actionTitleBarBasePropTypes),
  tablet: PropTypes.shape(actionTitleBarBasePropTypes)
});
ActionTitleBar.displayName = 'ActionTitleBar';
ActionTitleBar.__docgenInfo = {
  componentName: "ActionTitleBar",
  packageName: "@jutro/lab-preview-title-bars",
  description: "",
  displayName: "ActionTitleBar",
  methods: [],
  actualName: "ActionTitleBar",
  metadataType: "container",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    children: {
      type: {
        name: "union",
        value: [{
          name: "arrayOf",
          value: {
            name: "node"
          }
        }, {
          name: "node"
        }]
      },
      required: true,
      description: "Children to be rendered inside this component"
    },
    phone: {
      type: {
        name: "shape",
        value: {
          className: {
            name: "string",
            description: "CSS class name for this component",
            required: false
          },
          children: {
            name: "union",
            value: [{
              name: "arrayOf",
              value: {
                name: "node"
              }
            }, {
              name: "node"
            }],
            description: "Children to be rendered inside this component",
            required: true
          }
        }
      },
      required: false,
      description: ""
    },
    phoneWide: {
      type: {
        name: "shape",
        value: {
          className: {
            name: "string",
            description: "CSS class name for this component",
            required: false
          },
          children: {
            name: "union",
            value: [{
              name: "arrayOf",
              value: {
                name: "node"
              }
            }, {
              name: "node"
            }],
            description: "Children to be rendered inside this component",
            required: true
          }
        }
      },
      required: false,
      description: ""
    },
    tablet: {
      type: {
        name: "shape",
        value: {
          className: {
            name: "string",
            description: "CSS class name for this component",
            required: false
          },
          children: {
            name: "union",
            value: [{
              name: "arrayOf",
              value: {
                name: "node"
              }
            }, {
              name: "node"
            }],
            description: "Children to be rendered inside this component",
            required: true
          }
        }
      },
      required: false,
      description: ""
    }
  }
};