import React, { useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
var outsideClickDetectorPropTypes = {
  onClickOutside: PropTypes.func,
  onWindowBlur: PropTypes.func
};
export var OutsideClickDetector = function OutsideClickDetector(_ref) {
  var renderProp = _ref.children,
      onClickOutside = _ref.onClickOutside,
      onWindowBlur = _ref.onWindowBlur;
  var node = useRef();
  var handleClick = useCallback(function (event) {
    if (node.current.contains(event.target)) {
      return;
    }

    if (onClickOutside) {
      onClickOutside(event);
    }
  }, [onClickOutside]);
  var handleWindowBlur = useCallback(function (event) {
    return onWindowBlur && onWindowBlur(event);
  }, [onWindowBlur]);
  useEffect(function () {
    window.addEventListener('blur', handleWindowBlur);
    document.addEventListener('mousedown', handleClick);
    return function () {
      window.removeEventListener('blur', handleWindowBlur);
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick, handleWindowBlur]);
  return renderProp(function (ref) {
    node.current = ref;
  });
};
OutsideClickDetector.propTypes = outsideClickDetectorPropTypes;