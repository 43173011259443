import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';
import { metadataTypes } from '@jutro/uimetadata';
import styles from "./Table.module.css";
export var TableColumn = function TableColumn() {
  if (false) {
    throw new Error('Component <TableColumn /> should never render');
  }

  return React.createElement(React.Fragment, null);
};
TableColumn.propTypes = {
  id: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.object]),
  renderHeader: PropTypes.func,
  headerClassName: PropTypes.string,
  cell: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  renderCell: PropTypes.func,
  cellClassName: PropTypes.string,
  visible: PropTypes.bool,
  phone: PropTypes.object,
  phoneWide: PropTypes.object,
  tablet: PropTypes.object,
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  width: PropTypes.number,
  columnProportion: PropTypes.number,
  sortable: PropTypes.bool,
  renderEditCell: PropTypes.func,
  editCellClass: PropTypes.string,
  columnClassName: PropTypes.string,
  onFilter: PropTypes.func,
  path: PropTypes.string,
  onSort: PropTypes.func
};
TableColumn.defaultProps = {
  columnProportion: 1
};
TableColumn.displayName = 'TableColumn';
var textAlignMapping = {
  left: styles.textLeft,
  right: styles.textRight,
  center: styles.textCenter
};

var getHeaderContent = function getHeaderContent(props, translator) {
  var header = props.header,
      renderHeader = props.renderHeader,
      other = _objectWithoutProperties(props, ["header", "renderHeader"]);

  var content = header || renderHeader;

  if (_.isFunction(content)) {
    other.translator = translator;
    content = content(other);
  }

  if (translator) {
    content = translator(content);
  }

  return content;
};

var getCellContent = function getCellContent(row, index, props, translator) {
  var cell = props.cell,
      renderCell = props.renderCell,
      other = _objectWithoutProperties(props, ["cell", "renderCell"]);

  var content = cell || renderCell;

  if (_.isFunction(content)) {
    other.translator = translator;
    content = content(row, index, other);
  }

  if (translator) {
    content = translator(content);
  }

  return content;
};

TableColumn.renderHeader = function (props, translator) {
  var headerClassName = props.headerClassName,
      textAlign = props.textAlign,
      id = props.id,
      scope = props.scope;

  if (props.visible === false) {
    return null;
  }

  var content = getHeaderContent(props, translator);
  var headerClasses = cx(headerClassName, getTextAlignClass(textAlign));
  return React.createElement("th", {
    key: id,
    className: headerClasses,
    scope: scope
  }, content);
};

TableColumn.renderCell = function (row, index, props, translator) {
  var cellClassName = props.cellClassName,
      textAlign = props.textAlign,
      id = props.id;

  if (props.visible === false) {
    return null;
  }

  var content = getCellContent(row, index, props, translator);
  var cellClasses = cx(styles.tableCell, getTextAlignClass(textAlign), cellClassName);
  return React.createElement("td", {
    key: id + index,
    className: cellClasses
  }, content);
};

var getTextAlignClass = function getTextAlignClass(textAlign) {
  return textAlignMapping[textAlign];
};

TableColumn.metadataType = metadataTypes.CONTAINER;
TableColumn.__docgenInfo = {
  componentName: "TableColumn",
  packageName: "@jutro/datatable",
  description: "TableColumn",
  displayName: "TableColumn",
  methods: [],
  actualName: "TableColumn",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: false,
      description: "Identifier"
    },
    header: {
      type: {
        name: "union",
        value: [{
          name: "func"
        }, {
          name: "string"
        }, {
          name: "object"
        }]
      },
      required: false,
      description: "String or callback(props) to render the column header in the table"
    },
    renderHeader: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback(props) to render the column header in the table"
    },
    headerClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Classname for header <th>"
    },
    cell: {
      type: {
        name: "union",
        value: [{
          name: "func"
        }, {
          name: "string"
        }]
      },
      required: false,
      description: "String or callback(row, index, props) to render the column cell for a row in the table"
    },
    renderCell: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback(row, index, props) to render the column cell for a row in the table"
    },
    cellClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional classname for cell (in order to use it in DataTable, specificity of the selector should be higher than 5 classnames)"
    },
    visible: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Is this column shown in the table"
    },
    phone: {
      type: {
        name: "object"
      },
      required: false,
      description: "Include any TableColumn property for use at 'phone' breakpoint"
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: false,
      description: "Include any TableColumn property for use at 'phoneWide' and 'phone' breakpoint"
    },
    tablet: {
      type: {
        name: "object"
      },
      required: false,
      description: "Include any TableColumn property for use at 'tablet', 'phoneWide' and 'phone' breakpoint"
    },
    textAlign: {
      type: {
        name: "enum",
        value: [{
          value: "'left'",
          computed: false
        }, {
          value: "'center'",
          computed: false
        }, {
          value: "'right'",
          computed: false
        }]
      },
      required: false,
      description: "Text-align css property value (default 'center')"
    },
    width: {
      type: {
        name: "number"
      },
      required: false,
      description: "The width of current column, when `undefined` columns will share the table accordingly to columns columnProportion prop"
    },
    columnProportion: {
      type: {
        name: "number"
      },
      required: false,
      description: "Proportion of the column. Doesn't work when width is defined",
      defaultValue: {
        value: "1",
        computed: false
      }
    },
    sortable: {
      type: {
        name: "bool"
      },
      required: false,
      description: "To specify if the column is sortable, when `undefined` the column is sortable"
    },
    renderEditCell: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback(row, index, props) to render the column cell for a row in the table in the edit mode"
    },
    editCellClass: {
      type: {
        name: "string"
      },
      required: false,
      description: "Custom classname for column cell in the edit mode"
    },
    columnClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Classname for column"
    },
    onFilter: {
      type: {
        name: "func"
      },
      required: false,
      description: "Column filter function"
    },
    path: {
      type: {
        name: "string"
      },
      required: false,
      description: "The full path of view model, it also acts as an accessor for row's data"
    },
    onSort: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback to be invoked on column sort click"
    }
  }
};