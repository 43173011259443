import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _startsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/starts-with";
import _someInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/some";
import mapValues from "lodash/mapValues";
import omit from "lodash/omit";
import { warning } from '@jutro/logger';
var SAFE_PREFIXES = ['/', 'http', 'https', 'mailto:', 'tel:'];
var DANGEROUS_PROPS = ['href', 'to', 'action'];
export function isSafeUrl(url) {
  if (!url) {
    return true;
  }

  return _someInstanceProperty(SAFE_PREFIXES).call(SAFE_PREFIXES, function (prefix) {
    return _startsWithInstanceProperty(url).call(url, prefix);
  });
}
export function sanitizeUrl(url) {
  var allowNoLeadingSlash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (!isSafeUrl(url) && !allowNoLeadingSlash) {
    warning("'".concat(url, "' is not a safe url value. The only allowed urls are the ones starting with: ").concat(SAFE_PREFIXES.join(', ')));
    return '';
  }

  return url;
}
export function sanitizeRouterTo(to) {
  if (typeof to === 'string') {
    return sanitizeUrl(to);
  }

  if (!to) {
    return to;
  }

  return _objectSpread({}, to, {
    pathname: to.pathname && sanitizeUrl(to.pathname)
  });
}
export function sanitizeProps(props) {
  return mapValues(props, sanitizeProp);
}

function sanitizeProp(value, key) {
  return _includesInstanceProperty(DANGEROUS_PROPS).call(DANGEROUS_PROPS, key) && typeof value === 'string' ? sanitizeUrl(value) : value;
}

export function removeDangerousProps(props) {
  return omit(props, 'dangerouslySetInnerHTML');
}