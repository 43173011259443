import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { intlMessageShape } from '@jutro/prop-types';
import { useSafeTranslatedUrls } from '@jutro/locale';
import { Link } from '../Link/Link';
import { HelpElement } from './HelpElement';
import styles from "./HelpPopover.module.css";
var helpLinkPropTypes = {
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, intlMessageShape]),
  onClick: PropTypes.func,
  href: intlMessageShape
};
export var HelpLink = function HelpLink(_ref) {
  var content = _ref.content,
      onClick = _ref.onClick,
      href = _ref.href,
      className = _ref.className;
  var urlTranslatorAndSanitizer = useSafeTranslatedUrls();

  if (!content || !(onClick || href)) {
    return null;
  }

  var classes = cx(styles.helpLink, className);
  return React.createElement(HelpElement, {
    className: classes,
    content: content,
    tag: Link,
    onClick: onClick,
    href: urlTranslatorAndSanitizer(href),
    target: "_blank",
    rel: "noopener noreferrer"
  });
};
HelpLink.propTypes = helpLinkPropTypes;
HelpLink.__docgenInfo = {
  componentName: "HelpLink",
  packageName: "@jutro/components",
  description: "The `HelpLink` component is designed as a simple component presenting link inside the Help dropdown on Application Header.",
  displayName: "HelpLink",
  methods: [],
  actualName: "HelpLink",
  metadataType: "element",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component."
    },
    content: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: false
              },
              defaultMessage: {
                name: "string",
                required: false
              },
              args: {
                name: "shape",
                value: {},
                required: false
              }
            }
          }]
        }]
      },
      required: false,
      description: "Content of link"
    },
    onClick: {
      type: {
        name: "func"
      },
      required: false,
      description: "Click callback"
    },
    href: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "External location"
    }
  }
};