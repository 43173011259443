import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import { Tracer, ExplicitContext, ConsoleRecorder, BatchRecorder, jsonEncoder } from 'zipkin';
import wrapFetch from 'zipkin-instrumentation-fetch';
import { HttpLogger } from 'zipkin-transport-http';
import { getConfigValue } from '@jutro/config';
import { REQUEST_HANDLER_TYPE } from '../HttpRequestBuilder';
var wrapWindowFetch = wrapFetch;
var JSON_V2 = jsonEncoder.JSON_V2;
export function zipkinTraceHandler(remoteServiceName, localServiceName, zipkinUrl, debug) {
  var fetchCall = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : globalThis.fetch;
  var recorder;
  var zipkinDebugConfigValue = getConfigValue('zipkinDebug');
  var zipkinUrlConfigValue = getConfigValue('JUTRO_ZIPKIN_URL');

  if (debug || zipkinDebugConfigValue) {
    recorder = new ConsoleRecorder();
  } else if (zipkinUrl || zipkinUrlConfigValue) {
    recorder = new BatchRecorder({
      logger: new HttpLogger({
        endpoint: zipkinUrl || (zipkinUrlConfigValue === null || zipkinUrlConfigValue === void 0 ? void 0 : zipkinUrlConfigValue.toString()),
        jsonEncoder: JSON_V2
      })
    });
  } else {
    recorder = {
      record: function record() {}
    };
  }

  var zipkinLocalServiceNameConfigValue = getConfigValue('JUTRO_ZIPKIN_LOCAL_SERVICE_NAME');
  var tracer = new Tracer({
    ctxImpl: new ExplicitContext(),
    recorder: recorder,
    localServiceName: localServiceName || (zipkinLocalServiceNameConfigValue === null || zipkinLocalServiceNameConfigValue === void 0 ? void 0 : zipkinLocalServiceNameConfigValue.toString())
  });

  var traceFetch = function traceFetch(url, options) {
    var theFetch = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : fetchCall;
    return wrapWindowFetch(theFetch, {
      tracer: tracer,
      remoteServiceName: remoteServiceName
    })(url, options);
  };

  return _defineProperty({}, REQUEST_HANDLER_TYPE.FETCH, traceFetch);
}