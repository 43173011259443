import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";

function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();

  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
        result;

    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;

      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }

    return _possibleConstructorReturn(this, result);
  };
}

function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;

  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ContextConsumer } from '@jutro/platform';
import { TranslatorContext } from '@jutro/locale';
import { isEmptyValue, isNilValue } from '@jutro/data';
import styles from "./RadioButton.module.css";
import { getOptionCode, getOptionName } from '../availableValues';
import { getComponentId } from '../uniqueIds';
var contexts = [TranslatorContext];
export var RadioButton = function (_Component) {
  _inherits(RadioButton, _Component);

  var _super = _createSuper(RadioButton);

  function RadioButton(props, context) {
    var _this;

    _classCallCheck(this, RadioButton);

    _this = _super.call(this, props, context);

    _defineProperty(_assertThisInitialized(_this), "inputRef", React.createRef());

    _defineProperty(_assertThisInitialized(_this), "handleValueChange", function (evt) {
      var _this$props = _this.props,
          onValueChange = _this$props.onValueChange,
          model = _this$props.model,
          path = _this$props.path;

      if (onValueChange) {
        onValueChange(evt.target.value, model || path);
      }
    });

    _defineProperty(_assertThisInitialized(_this), "handleKeyDown", function (evt) {
      var _this$props2 = _this.props,
          onValueChange = _this$props2.onValueChange,
          model = _this$props2.model,
          path = _this$props2.path;

      if (evt.key === ' ' || evt.key === 'Enter') {
        if (onValueChange) {
          onValueChange(_this.inputRef.current.value, model || path);
        }

        evt.preventDefault();
      }
    });

    _defineProperty(_assertThisInitialized(_this), "renderComponent", function (translator) {
      var _code;

      var _this$props3 = _this.props,
          id = _this$props3.id,
          option = _this$props3.option,
          value = _this$props3.value,
          disabled = _this$props3.disabled,
          required = _this$props3.required,
          onValueChange = _this$props3.onValueChange,
          textAlign = _this$props3.textAlign,
          className = _this$props3.className,
          labelClassName = _this$props3.labelClassName,
          dangerouslySetInnerHTML = _this$props3.dangerouslySetInnerHTML,
          tabIndex = _this$props3.tabIndex,
          other = _objectWithoutProperties(_this$props3, ["id", "option", "value", "disabled", "required", "onValueChange", "textAlign", "className", "labelClassName", "dangerouslySetInnerHTML", "tabIndex"]);

      var classes = cx(styles.radioButton, className);
      var labelClasses = cx(styles.label, labelClassName);
      var code = getOptionCode(option);
      var checked = (value === null || value === void 0 ? void 0 : value.toString()) === ((_code = code) === null || _code === void 0 ? void 0 : _code.toString());

      if (isNilValue(code)) {
        code = 'empty';
        checked = isEmptyValue(value);
      }

      var _assertThisInitialize = _assertThisInitialized(_this),
          controlUniqueId = _assertThisInitialize.controlUniqueId;

      var valueId = "".concat(controlUniqueId, "_").concat(code);
      return React.createElement("div", {
        className: classes,
        "aria-checked": checked
      }, React.createElement("input", _extends({
        type: "radio",
        id: valueId,
        value: code || '',
        onChange: _this.handleValueChange,
        checked: checked,
        disabled: disabled,
        required: required,
        ref: _this.inputRef,
        onKeyDown: _this.handleKeyDown
      }, other)), React.createElement("label", {
        htmlFor: valueId,
        className: labelClasses
      }, _this.renderPrimaryText(translator), _this.renderSecondaryText(translator)));
    });

    var id = props.id;
    _this.controlUniqueId = getComponentId(id);
    return _this;
  }

  _createClass(RadioButton, [{
    key: "renderPrimaryText",
    value: function renderPrimaryText(translator) {
      var option = this.props.option;
      var name = getOptionName(option);

      if (!name) {
        return null;
      }

      return React.createElement("span", {
        className: styles.primaryText
      }, translator(name));
    }
  }, {
    key: "renderSecondaryText",
    value: function renderSecondaryText(translator) {
      var secondaryLabel = this.props.option.secondaryLabel;

      if (!secondaryLabel) {
        return null;
      }

      return React.createElement("span", {
        className: styles.secondaryText
      }, translator(secondaryLabel));
    }
  }, {
    key: "render",
    value: function render() {
      return React.createElement(ContextConsumer, {
        contexts: contexts
      }, this.renderComponent);
    }
  }]);

  return RadioButton;
}(Component);

_defineProperty(RadioButton, "propTypes", {
  option: PropTypes.object,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  required: PropTypes.bool,
  id: PropTypes.string,
  onValueChange: PropTypes.func,
  tabIndex: PropTypes.number,
  className: PropTypes.string,
  labelClassName: PropTypes.string
});

RadioButton.__docgenInfo = {
  componentName: "RadioButton",
  packageName: "@jutro/components",
  description: "",
  displayName: "RadioButton",
  methods: [{
    name: "handleValueChange",
    docblock: null,
    modifiers: [],
    params: [{
      name: "evt",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "handleKeyDown",
    docblock: null,
    modifiers: [],
    params: [{
      name: "evt",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "renderPrimaryText",
    docblock: null,
    modifiers: [],
    params: [{
      name: "translator",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "renderSecondaryText",
    docblock: null,
    modifiers: [],
    params: [{
      name: "translator",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "renderComponent",
    docblock: null,
    modifiers: [],
    params: [{
      name: "translator",
      optional: undefined,
      type: null
    }],
    returns: null
  }],
  actualName: "RadioButton",
  props: {
    option: {
      type: {
        name: "object"
      },
      required: false,
      description: "Radio Button Options"
    },
    disabled: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Is this field disabled?"
    },
    value: {
      type: {
        name: "string"
      },
      required: false,
      description: "Value to determine is the button checked ot unchecked"
    },
    required: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Is this field required?"
    },
    id: {
      type: {
        name: "string"
      },
      required: false,
      description: "Id of the radio button"
    },
    onValueChange: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback when the value is changed"
    },
    tabIndex: {
      type: {
        name: "number"
      },
      required: false,
      description: "TabIndex of the Radio Button"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "className passed to the top element of RadioButton"
    },
    labelClassName: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional className passed to the label"
    }
  }
};