import _toConsumableArray from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/toConsumableArray";
import _sortInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/sort";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import { useMemo, useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { getTimeZones } from '@vvo/tzdb';
import { messages } from './DateTimeZoneField.messages';
var dateFormat = new Intl.DateTimeFormat();
export var currentIanaTimeZone = dateFormat.resolvedOptions().timeZone;
export var isCurrentIanaInTimeZone = function isCurrentIanaInTimeZone(timeZone) {
  var _context;

  return timeZone.name === currentIanaTimeZone || _includesInstanceProperty(_context = timeZone.group).call(_context, currentIanaTimeZone);
};

var sortTimeZoneValues = function sortTimeZoneValues(a, b) {
  if (a.code < b.code) return -1;
  if (a.code > b.code) return 1;
  return 0;
};

export var useTimeZoneDropdownValues = function useTimeZoneDropdownValues(timeZoneList) {
  var translator = useContext(TranslatorContext);
  var filteredTimeZones = useMemo(function () {
    var _context2;

    return timeZoneList && timeZoneList.length ? _filterInstanceProperty(_context2 = getTimeZones()).call(_context2, function (timeZone) {
      return _includesInstanceProperty(timeZoneList).call(timeZoneList, timeZone.name);
    }) : getTimeZones();
  }, [timeZoneList]);
  return useMemo(function () {
    var _filteredTimeZones$fi, _context3;

    var timeZoneValue = (_filteredTimeZones$fi = _findInstanceProperty(filteredTimeZones).call(filteredTimeZones, isCurrentIanaInTimeZone)) === null || _filteredTimeZones$fi === void 0 ? void 0 : _filteredTimeZones$fi.name;

    var timeZoneValues = _mapInstanceProperty(filteredTimeZones).call(filteredTimeZones, function (timeZone) {
      return {
        code: timeZone.name,
        name: translator(messages[timeZone.name])
      };
    });

    if (timeZoneValue) {
      return [timeZoneValue, _sortInstanceProperty(timeZoneValues).call(timeZoneValues, sortTimeZoneValues)];
    }

    return [currentIanaTimeZone, _sortInstanceProperty(_context3 = [].concat(_toConsumableArray(timeZoneValues), [{
      code: currentIanaTimeZone,
      name: messages[currentIanaTimeZone]
    }])).call(_context3, sortTimeZoneValues)];
  }, [filteredTimeZones, translator]);
};