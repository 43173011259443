import { defineMessages } from 'react-intl';
export var messages = defineMessages({
  validatePhone: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.PhoneNumberIncorrect',
    defaultMessage: 'Phone number is incorrect.'
  },
  validatePhoneError0: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.validatePhoneError.IsPossible',
    defaultMessage: 'Most likely the country does not allow phone numbers with these digits.'
  },
  validatePhoneError1: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.validatePhoneError.InvalidCountryCode',
    defaultMessage: 'Country code does not match any country.'
  },
  validatePhoneError2: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.validatePhoneError.TooShort',
    defaultMessage: 'The phone number is too short.'
  },
  validatePhoneError3: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.validatePhoneError.TooLong',
    defaultMessage: 'The phone number is too long.'
  },
  validatePhoneError4: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.validatePhoneError.IsPossibleLocalOnly',
    defaultMessage: 'Enter a number with characters specific to the country.'
  },
  validatePhoneError5: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.validatePhoneError.InvalidLength',
    defaultMessage: 'Make sure you have a proper phone number for the selected country.'
  },
  'validatePhoneError-99': {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.validatePhoneError.UnknownError',
    defaultMessage: 'Unknown error.'
  }
});
export var countryMessages = defineMessages({
  af: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.af',
    defaultMessage: 'Afghanistan'
  },
  al: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.al',
    defaultMessage: 'Albania'
  },
  dz: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.dz',
    defaultMessage: 'Algeria'
  },
  as: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.as',
    defaultMessage: 'American Samoa'
  },
  ad: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ad',
    defaultMessage: 'Andorra'
  },
  ao: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ao',
    defaultMessage: 'Angola'
  },
  ai: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ai',
    defaultMessage: 'Anguilla'
  },
  ag: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ag',
    defaultMessage: 'Antigua and Barbuda'
  },
  ar: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ar',
    defaultMessage: 'Argentina'
  },
  am: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.am',
    defaultMessage: 'Armenia'
  },
  aw: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.aw',
    defaultMessage: 'Aruba'
  },
  au: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.au',
    defaultMessage: 'Australia'
  },
  at: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.at',
    defaultMessage: 'Austria'
  },
  az: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.az',
    defaultMessage: 'Azerbaijan'
  },
  bs: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.bs',
    defaultMessage: 'Bahamas'
  },
  bh: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.bh',
    defaultMessage: 'Bahrain'
  },
  bd: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.bd',
    defaultMessage: 'Bangladesh'
  },
  bb: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.bb',
    defaultMessage: 'Barbados'
  },
  by: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.by',
    defaultMessage: 'Belarus'
  },
  be: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.be',
    defaultMessage: 'Belgium'
  },
  bz: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.bz',
    defaultMessage: 'Belize'
  },
  bj: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.bj',
    defaultMessage: 'Benin'
  },
  bm: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.bm',
    defaultMessage: 'Bermuda'
  },
  bt: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.bt',
    defaultMessage: 'Bhutan'
  },
  bo: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.bo',
    defaultMessage: 'Bolivia'
  },
  ba: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ba',
    defaultMessage: 'Bosnia and Herzegovina'
  },
  bw: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.bw',
    defaultMessage: 'Botswana'
  },
  br: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.br',
    defaultMessage: 'Brazil'
  },
  io: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.io',
    defaultMessage: 'British Indian Ocean Territory'
  },
  vg: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.vg',
    defaultMessage: 'British Virgin Islands'
  },
  bn: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.bn',
    defaultMessage: 'Brunei'
  },
  bg: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.bg',
    defaultMessage: 'Bulgaria'
  },
  bf: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.bf',
    defaultMessage: 'Burkina Faso'
  },
  bi: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.bi',
    defaultMessage: 'Burundi'
  },
  kh: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.kh',
    defaultMessage: 'Cambodia'
  },
  cm: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.cm',
    defaultMessage: 'Cameroon'
  },
  ca: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ca',
    defaultMessage: 'Canada'
  },
  cv: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.cv',
    defaultMessage: 'Cape Verde'
  },
  bq: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.bq',
    defaultMessage: 'Caribbean Netherlands'
  },
  ky: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ky',
    defaultMessage: 'Cayman Islands'
  },
  cf: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.cf',
    defaultMessage: 'Central African Republic'
  },
  td: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.td',
    defaultMessage: 'Chad'
  },
  cl: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.cl',
    defaultMessage: 'Chile'
  },
  cn: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.cn',
    defaultMessage: 'China'
  },
  cx: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.cx',
    defaultMessage: 'Christmas Island'
  },
  cc: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.cc',
    defaultMessage: 'Cocos'
  },
  co: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.co',
    defaultMessage: 'Colombia'
  },
  km: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.km',
    defaultMessage: 'Comoros'
  },
  cd: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.cd',
    defaultMessage: 'Congo'
  },
  cg: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.cg',
    defaultMessage: 'Congo'
  },
  ck: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ck',
    defaultMessage: 'Cook Islands'
  },
  cr: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.cr',
    defaultMessage: 'Costa Rica'
  },
  ci: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ci',
    defaultMessage: 'Côte d’Ivoire'
  },
  hr: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.hr',
    defaultMessage: 'Croatia'
  },
  cu: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.cu',
    defaultMessage: 'Cuba'
  },
  cw: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.cw',
    defaultMessage: 'Curaçao'
  },
  cy: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.cy',
    defaultMessage: 'Cyprus'
  },
  cz: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.cz',
    defaultMessage: 'Czech Republic'
  },
  dk: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.dk',
    defaultMessage: 'Denmark'
  },
  dj: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.dj',
    defaultMessage: 'Djibouti'
  },
  dm: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.dm',
    defaultMessage: 'Dominica'
  },
  do: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.do',
    defaultMessage: 'Dominican Republic'
  },
  ec: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ec',
    defaultMessage: 'Ecuador'
  },
  eg: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.eg',
    defaultMessage: 'Egypt'
  },
  sv: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.sv',
    defaultMessage: 'El Salvador'
  },
  gq: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.gq',
    defaultMessage: 'Equatorial Guinea'
  },
  er: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.er',
    defaultMessage: 'Eritrea'
  },
  ee: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ee',
    defaultMessage: 'Estonia'
  },
  et: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.et',
    defaultMessage: 'Ethiopia'
  },
  fk: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.fk',
    defaultMessage: 'Falkland Islands'
  },
  fo: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.fo',
    defaultMessage: 'Faroe Islands'
  },
  fj: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.fj',
    defaultMessage: 'Fiji'
  },
  fi: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.fi',
    defaultMessage: 'Finland'
  },
  fr: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.fr',
    defaultMessage: 'France'
  },
  gf: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.gf',
    defaultMessage: 'French Guiana'
  },
  pf: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.pf',
    defaultMessage: 'French Polynesia'
  },
  ga: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ga',
    defaultMessage: 'Gabon'
  },
  gm: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.gm',
    defaultMessage: 'Gambia'
  },
  ge: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ge',
    defaultMessage: 'Georgia'
  },
  de: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.de',
    defaultMessage: 'Germany'
  },
  gh: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.gh',
    defaultMessage: 'Ghana'
  },
  gi: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.gi',
    defaultMessage: 'Gibraltar'
  },
  gr: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.gr',
    defaultMessage: 'Greece'
  },
  gl: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.gl',
    defaultMessage: 'Greenland'
  },
  gd: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.gd',
    defaultMessage: 'Grenada'
  },
  gp: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.gp',
    defaultMessage: 'Guadeloupe'
  },
  gu: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.gu',
    defaultMessage: 'Guam'
  },
  gt: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.gt',
    defaultMessage: 'Guatemala'
  },
  gg: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.gg',
    defaultMessage: 'Guernsey'
  },
  gn: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.gn',
    defaultMessage: 'Guinea'
  },
  gw: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.gw',
    defaultMessage: 'Guinea-Bissau'
  },
  gy: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.gy',
    defaultMessage: 'Guyana'
  },
  ht: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ht',
    defaultMessage: 'Haiti'
  },
  hn: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.hn',
    defaultMessage: 'Honduras'
  },
  hk: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.hk',
    defaultMessage: 'Hong Kong'
  },
  hu: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.hu',
    defaultMessage: 'Hungary'
  },
  is: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.is',
    defaultMessage: 'Iceland'
  },
  in: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.in',
    defaultMessage: 'India'
  },
  id: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.id',
    defaultMessage: 'Indonesia'
  },
  ir: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ir',
    defaultMessage: 'Iran'
  },
  iq: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.iq',
    defaultMessage: 'Iraq'
  },
  ie: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ie',
    defaultMessage: 'Ireland'
  },
  im: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.im',
    defaultMessage: 'Isle of Man'
  },
  il: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.il',
    defaultMessage: 'Israel'
  },
  it: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.it',
    defaultMessage: 'Italy'
  },
  jm: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.jm',
    defaultMessage: 'Jamaica'
  },
  jp: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.jp',
    defaultMessage: 'Japan'
  },
  je: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.je',
    defaultMessage: 'Jersey'
  },
  jo: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.jo',
    defaultMessage: 'Jordan'
  },
  kz: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.kz',
    defaultMessage: 'Kazakhstan'
  },
  ke: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ke',
    defaultMessage: 'Kenya'
  },
  ki: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ki',
    defaultMessage: 'Kiribati'
  },
  xk: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.xk',
    defaultMessage: 'Kosovo'
  },
  kw: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.kw',
    defaultMessage: 'Kuwait'
  },
  kg: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.kg',
    defaultMessage: 'Kyrgyzstan'
  },
  la: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.la',
    defaultMessage: 'Laos'
  },
  lv: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.lv',
    defaultMessage: 'Latvia'
  },
  lb: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.lb',
    defaultMessage: 'Lebanon'
  },
  ls: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ls',
    defaultMessage: 'Lesotho'
  },
  lr: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.lr',
    defaultMessage: 'Liberia'
  },
  ly: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ly',
    defaultMessage: 'Libya'
  },
  li: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.li',
    defaultMessage: 'Liechtenstein'
  },
  lt: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.lt',
    defaultMessage: 'Lithuania'
  },
  lu: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.lu',
    defaultMessage: 'Luxembourg'
  },
  mo: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.mo',
    defaultMessage: 'Macau'
  },
  mk: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.mk',
    defaultMessage: 'Macedonia'
  },
  mg: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.mg',
    defaultMessage: 'Madagascar'
  },
  mw: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.mw',
    defaultMessage: 'Malawi'
  },
  my: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.my',
    defaultMessage: 'Malaysia'
  },
  mv: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.mv',
    defaultMessage: 'Maldives'
  },
  ml: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ml',
    defaultMessage: 'Mali'
  },
  mt: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.mt',
    defaultMessage: 'Malta'
  },
  mh: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.mh',
    defaultMessage: 'Marshall Islands'
  },
  mq: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.mq',
    defaultMessage: 'Martinique'
  },
  mr: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.mr',
    defaultMessage: 'Mauritania'
  },
  mu: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.mu',
    defaultMessage: 'Mauritius'
  },
  yt: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.yt',
    defaultMessage: 'Mayotte'
  },
  mx: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.mx',
    defaultMessage: 'Mexico'
  },
  fm: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.fm',
    defaultMessage: 'Micronesia'
  },
  md: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.md',
    defaultMessage: 'Moldova'
  },
  mc: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.mc',
    defaultMessage: 'Monaco'
  },
  mn: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.mn',
    defaultMessage: 'Mongolia'
  },
  me: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.me',
    defaultMessage: 'Montenegro'
  },
  ms: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ms',
    defaultMessage: 'Montserrat'
  },
  ma: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ma',
    defaultMessage: 'Morocco'
  },
  mz: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.mz',
    defaultMessage: 'Mozambique'
  },
  mm: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.mm',
    defaultMessage: 'Myanmar'
  },
  na: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.na',
    defaultMessage: 'Namibia'
  },
  nr: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.nr',
    defaultMessage: 'Nauru'
  },
  np: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.np',
    defaultMessage: 'Nepal'
  },
  nl: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.nl',
    defaultMessage: 'Netherlands'
  },
  nc: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.nc',
    defaultMessage: 'New Caledonia'
  },
  nz: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.nz',
    defaultMessage: 'New Zealand'
  },
  ni: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ni',
    defaultMessage: 'Nicaragua'
  },
  ne: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ne',
    defaultMessage: 'Niger'
  },
  ng: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ng',
    defaultMessage: 'Nigeria'
  },
  nu: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.nu',
    defaultMessage: 'Niue'
  },
  nf: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.nf',
    defaultMessage: 'Norfolk Island'
  },
  kp: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.kp',
    defaultMessage: 'North Korea'
  },
  mp: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.mp',
    defaultMessage: 'Northern Mariana Islands'
  },
  no: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.no',
    defaultMessage: 'Norway'
  },
  om: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.om',
    defaultMessage: 'Oman'
  },
  pk: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.pk',
    defaultMessage: 'Pakistan'
  },
  pw: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.pw',
    defaultMessage: 'Palau'
  },
  ps: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ps',
    defaultMessage: 'Palestine'
  },
  pa: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.pa',
    defaultMessage: 'Panama'
  },
  pg: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.pg',
    defaultMessage: 'Papua New Guinea'
  },
  py: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.py',
    defaultMessage: 'Paraguay'
  },
  pe: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.pe',
    defaultMessage: 'Peru'
  },
  ph: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ph',
    defaultMessage: 'Philippines'
  },
  pl: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.pl',
    defaultMessage: 'Poland'
  },
  pt: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.pt',
    defaultMessage: 'Portugal'
  },
  pr: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.pr',
    defaultMessage: 'Puerto Rico'
  },
  qa: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.qa',
    defaultMessage: 'Qatar'
  },
  re: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.re',
    defaultMessage: 'Réunion'
  },
  ro: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ro',
    defaultMessage: 'Romania'
  },
  ru: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ru',
    defaultMessage: 'Russia'
  },
  rw: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.rw',
    defaultMessage: 'Rwanda'
  },
  bl: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.bl',
    defaultMessage: 'Saint Barthélemy'
  },
  sh: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.sh',
    defaultMessage: 'Saint Helena'
  },
  kn: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.kn',
    defaultMessage: 'Saint Kitts and Nevis'
  },
  lc: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.lc',
    defaultMessage: 'Saint Lucia'
  },
  mf: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.mf',
    defaultMessage: 'Saint Martin'
  },
  pm: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.pm',
    defaultMessage: 'Saint Pierre and Miquelon'
  },
  vc: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.vc',
    defaultMessage: 'Saint Vincent and the Grenadines'
  },
  ws: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ws',
    defaultMessage: 'Samoa'
  },
  sm: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.sm',
    defaultMessage: 'San Marino'
  },
  st: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.st',
    defaultMessage: 'São Tomé and Príncipe'
  },
  sa: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.sa',
    defaultMessage: 'Saudi Arabia'
  },
  sn: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.sn',
    defaultMessage: 'Senegal'
  },
  rs: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.rs',
    defaultMessage: 'Serbia'
  },
  sc: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.sc',
    defaultMessage: 'Seychelles'
  },
  sl: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.sl',
    defaultMessage: 'Sierra Leone'
  },
  sg: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.sg',
    defaultMessage: 'Singapore'
  },
  sx: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.sx',
    defaultMessage: 'Sint Maarten'
  },
  sk: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.sk',
    defaultMessage: 'Slovakia'
  },
  si: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.si',
    defaultMessage: 'Slovenia'
  },
  sb: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.sb',
    defaultMessage: 'Solomon Islands'
  },
  so: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.so',
    defaultMessage: 'Somalia'
  },
  za: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.za',
    defaultMessage: 'South Africa'
  },
  kr: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.kr',
    defaultMessage: 'South Korea'
  },
  ss: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ss',
    defaultMessage: 'South Sudan'
  },
  es: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.es',
    defaultMessage: 'Spain'
  },
  lk: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.lk',
    defaultMessage: 'Sri Lanka'
  },
  sd: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.sd',
    defaultMessage: 'Sudan'
  },
  sr: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.sr',
    defaultMessage: 'Suriname'
  },
  sj: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.sj',
    defaultMessage: 'Svalbard and Jan Mayen'
  },
  sz: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.sz',
    defaultMessage: 'Swaziland'
  },
  se: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.se',
    defaultMessage: 'Sweden'
  },
  ch: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ch',
    defaultMessage: 'Switzerland'
  },
  sy: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.sy',
    defaultMessage: 'Syria'
  },
  tw: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.tw',
    defaultMessage: 'Taiwan'
  },
  tj: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.tj',
    defaultMessage: 'Tajikistan'
  },
  tz: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.tz',
    defaultMessage: 'Tanzania'
  },
  th: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.th',
    defaultMessage: 'Thailand'
  },
  tl: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.tl',
    defaultMessage: 'Timor-Leste'
  },
  tg: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.tg',
    defaultMessage: 'Togo'
  },
  tk: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.tk',
    defaultMessage: 'Tokelau'
  },
  to: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.to',
    defaultMessage: 'Tonga'
  },
  tt: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.tt',
    defaultMessage: 'Trinidad and Tobago'
  },
  tn: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.tn',
    defaultMessage: 'Tunisia'
  },
  tr: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.tr',
    defaultMessage: 'Turkey'
  },
  tm: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.tm',
    defaultMessage: 'Turkmenistan'
  },
  tc: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.tc',
    defaultMessage: 'Turks and Caicos Islands'
  },
  tv: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.tv',
    defaultMessage: 'Tuvalu'
  },
  vi: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.vi',
    defaultMessage: 'U.S. Virgin Islands'
  },
  ug: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ug',
    defaultMessage: 'Uganda'
  },
  ua: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ua',
    defaultMessage: 'Ukraine'
  },
  ae: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ae',
    defaultMessage: 'United Arab Emirates'
  },
  gb: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.gb',
    defaultMessage: 'United Kingdom'
  },
  us: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.us',
    defaultMessage: 'United States'
  },
  uy: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.uy',
    defaultMessage: 'Uruguay'
  },
  uz: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.uz',
    defaultMessage: 'Uzbekistan'
  },
  vu: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.vu',
    defaultMessage: 'Vanuatu'
  },
  va: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.va',
    defaultMessage: 'Vatican City'
  },
  ve: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ve',
    defaultMessage: 'Venezuela'
  },
  vn: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.vn',
    defaultMessage: 'Vietnam'
  },
  wf: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.wf',
    defaultMessage: 'Wallis and Futuna'
  },
  eh: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.eh',
    defaultMessage: 'Western Sahara'
  },
  ye: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ye',
    defaultMessage: 'Yemen'
  },
  zm: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.zm',
    defaultMessage: 'Zambia'
  },
  zw: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.zw',
    defaultMessage: 'Zimbabwe'
  },
  ax: {
    id: 'jutro-lab-preview-intl-phone-number.IntlPhoneNumberField.country.ax',
    defaultMessage: 'Åland Islands'
  }
});