import _startsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/starts-with";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import cx from 'classnames';
export var getIconClasses = function getIconClasses(icon, isFixedWidth) {
  var parts = icon.replace(/_/g, '-').split(' ');

  if (parts.length === 1) {
    parts.unshift(materialIconsDefaultType);
  }

  var iconClasses = _mapInstanceProperty(parts).call(parts, function (part, index) {
    return index === 0 || isMiIcon(part) ? part : "".concat(materialIconsIconPrefix).concat(part);
  }).join(' ');

  if (isFixedWidth) return cx(iconClasses, fixedWidthMaterialClassName);
  return iconClasses;
};

function isMiIcon(icon) {
  return _startsWithInstanceProperty(icon).call(icon, materialIconsIconPrefix);
}

var materialIconsDefaultType = 'mir';
var fixedWidthMaterialClassName = 'mi-fw';
var materialIconsIconPrefix = 'mi-';