import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _URLSearchParams from "@babel/runtime-corejs3/core-js-stable/url-search-params";
import _Symbol from "@babel/runtime-corejs3/core-js-stable/symbol";
import get from "lodash/get";
import mergeWith from "lodash/mergeWith";
import merge from "lodash/merge";
import isArray from "lodash/isArray";
import substituteParametersValues from './load-config';
import defaultConfig from './defaults/defaultConfig.json';
export function loadAndMergeConfiguration(config) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return loadConfiguration(config, params, defaultConfig);
}
export function loadConfiguration() {
  var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var baseConfig = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : defaultConfig;

  if (baseConfig) {
    config = mergeWith(baseConfig, config, function (target, src) {
      if (isArray(target) && isArray(src)) {
        return src;
      }

      return undefined;
    });
  }

  config = substituteParametersValues(config, params);
  setConfiguration(config);
  return config;
}

var CONFIG_DATA_KEY = _Symbol('jutro.config.data');

export default function getConfiguration() {
  return global[CONFIG_DATA_KEY];
}
export function setConfiguration(config) {
  global[CONFIG_DATA_KEY] = _objectSpread({}, config);
}
export function resolveValue(value) {
  var parsedValue = parseBooleanValue(value);
  return parsedValue;
}

function parseBooleanValue(value) {
  if (value === 'true') return true;
  if (value === 'false') return false;
  return value;
}

export function getConfigValue(path, defaultValue) {
  var _envVariable$;

  var value = null;

  if (!true) {
    var _window, _window$location;

    var urlSearchParams = global.window ? (_window = window) === null || _window === void 0 ? void 0 : (_window$location = _window.location) === null || _window$location === void 0 ? void 0 : _window$location.search : undefined;
    var urlParams = new _URLSearchParams(urlSearchParams);
    value = urlParams.get(path) || __FULL_ENV__[path];

    if (value) {
      return resolveValue(value);
    }
  }

  var envVariable = __FULL_ENV__;
  value = (_envVariable$ = envVariable["REACT_APP_".concat(path.replace(/\./g, '_'))]) !== null && _envVariable$ !== void 0 ? _envVariable$ : __FULL_ENV__[path];

  if (value) {
    return resolveValue(value);
  }

  var config = getConfiguration();
  value = get(config, path);

  if (value === undefined) {
    return defaultValue;
  }

  return resolveValue(value);
}
export function getAndExtendConfigValue(path, extension) {
  var configValue = getConfigValue(path);
  return merge(configValue, extension);
}
export function isFeatureEnabled(path) {
  var allEnabled = getConfigValue('feature.all', true);
  return getConfigValue("feature.".concat(path), allEnabled);
}