import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _everyInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/every";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _ from 'lodash';
export var removeNavLinkIcon = function removeNavLinkIcon(_ref) {
  var navLink = _ref.navLink,
      rest = _objectWithoutProperties(_ref, ["navLink"]);

  if (!navLink) return rest;
  return _objectSpread({}, rest, {
    navLink: _.omit(navLink, 'icon')
  });
};
export var shouldShowIcons = function shouldShowIcons(routes) {
  var showOnNavBarRoutes = _filterInstanceProperty(routes).call(routes, function (route) {
    return _.get(route, 'showOnNavBar', true);
  });

  var hasNavLinkRoutes = _everyInstanceProperty(showOnNavBarRoutes).call(showOnNavBarRoutes, function (_ref2) {
    var navLink = _ref2.navLink;
    return navLink;
  });

  return hasNavLinkRoutes && _everyInstanceProperty(showOnNavBarRoutes).call(showOnNavBarRoutes, function (_ref3) {
    var icon = _ref3.navLink.icon;
    return icon;
  });
};