import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import PropTypes from 'prop-types';
import { intlMessageShape } from './intlMessageShape';
export var placementOptions = ['top', 'top-start', 'top-end', 'right', 'right-start', 'right-end', 'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end', 'auto', 'auto-start', 'auto-end'];
export var tooltipBaseProps = {
  text: intlMessageShape,
  title: intlMessageShape,
  link: intlMessageShape,
  href: intlMessageShape,
  renderContent: PropTypes.func
};

var tooltipShapeProps = _objectSpread({}, tooltipBaseProps, {
  placement: PropTypes.oneOf(placementOptions)
});

export var tooltipShape = PropTypes.shape(tooltipShapeProps);
export var nestedTooltipShape = PropTypes.shape(_objectSpread({}, tooltipShapeProps, {
  id: PropTypes.string,
  icon: PropTypes.string
}));