import _findIndexInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find-index";
import isEqual from "lodash/isEqual";
export var onFocusHandler = function onFocusHandler(focused, setFocused, rtState, rowIndex, columnId) {
  return function () {
    var _context;

    var newFocused = {
      row: rowIndex,
      column: _findIndexInstanceProperty(_context = rtState.allVisibleColumns).call(_context, function (c) {
        return c.id === columnId;
      }),
      columnId: columnId
    };

    if (!isEqual(focused, newFocused)) {
      setFocused(newFocused);
    }
  };
};
export var isFocused = function isFocused(_ref, rtState, row, columnId) {
  var focusedRow = _ref.row,
      focusedColumn = _ref.column;

  var _rtState$allVisibleCo;

  return focusedRow === row && ((_rtState$allVisibleCo = rtState.allVisibleColumns[focusedColumn]) === null || _rtState$allVisibleCo === void 0 ? void 0 : _rtState$allVisibleCo.id) === columnId;
};