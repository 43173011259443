import _toConsumableArray from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/toConsumableArray";
import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import _findIndexInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find-index";
import _Object$values from "@babel/runtime-corejs3/core-js-stable/object/values";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import React, { useMemo, useRef, useEffect, useState, useLayoutEffect } from 'react';
import isNumber from "lodash/isNumber";
import isArray from "lodash/isArray";
import isPlainObject from "lodash/isPlainObject";
import isNull from "lodash/isNull";
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Tag } from './Tag';
import styles from "./TagCollection.module.css";

var findMinimumWidth = function findMinimumWidth(children, max) {
  var _context, _context2;

  var minimum = _reduceInstanceProperty(_context = _sliceInstanceProperty(_context2 = _Object$values(children)).call(_context2, 0, children.length - 1)).call(_context, function (min, _ref) {
    var clientWidth = _ref.clientWidth;
    return clientWidth < min ? clientWidth : min;
  }, max);

  return minimum === max ? 0 : minimum;
};

var getTypeOfChild = function getTypeOfChild(child) {
  if (isArray(child)) {
    return 'array';
  }

  if (isPlainObject(child)) {
    return 'object';
  }

  if (isNull(child)) {
    return 'null';
  }

  return 'other';
};

var getChildrenTags = function getChildrenTags(children) {
  var childrenArray = React.Children.toArray(children);
  var optionsMenu = childrenArray[childrenArray.length - 1];

  var initialTags = _sliceInstanceProperty(childrenArray).call(childrenArray, 0, childrenArray.length - 1);

  return [initialTags, optionsMenu];
};

function getDivider(tags, currentRef, maxWidth, minimumTagWidth, maxBoundary) {
  var remainingWidth = maxWidth;

  var divider = _findIndexInstanceProperty(tags).call(tags, function (tag, elementIndex) {
    var _currentRef$children;

    var indexOfTag = _indexOfInstanceProperty(tags).call(tags, tag);

    var childWidth = (_currentRef$children = currentRef.children[tags.length - indexOfTag - 1]) === null || _currentRef$children === void 0 ? void 0 : _currentRef$children.clientWidth;

    if (remainingWidth - childWidth - minimumTagWidth.current < 0 || isNumber(maxBoundary) && elementIndex >= maxBoundary) {
      return true;
    }

    remainingWidth -= childWidth;
    return false;
  });

  return divider === -1 ? tags.length : divider;
}

var getStyleProps = function getStyleProps(child) {
  return {
    className: cx(child.className, styles.collectionTag),
    iconWrapperClassName: cx(child.iconWrapperClassName, styles.tagButton),
    labelClassName: cx(child.labelClassName, styles.tagLabel)
  };
};

export var TagCollection = function TagCollection(_ref2) {
  var id = _ref2.id,
      defaultGroupThreshold = _ref2.groupThreshold,
      defaultExpanded = _ref2.expanded,
      className = _ref2.className,
      children = _ref2.children;

  var _getChildrenTags = getChildrenTags(children),
      _getChildrenTags2 = _slicedToArray(_getChildrenTags, 2),
      initialTags = _getChildrenTags2[0],
      optionsMenu = _getChildrenTags2[1];

  var initialTagsLength = getTypeOfChild(children) === 'array' ? initialTags.length : 0;
  var ref = useRef();
  var minimumTagWidth = useRef(0);

  var _useState = useState(defaultExpanded),
      _useState2 = _slicedToArray(_useState, 2),
      expanded = _useState2[0],
      setExpanded = _useState2[1];

  var _useState3 = useState(null),
      _useState4 = _slicedToArray(_useState3, 2),
      maxWidth = _useState4[0],
      setMaxWidth = _useState4[1];

  var _useState5 = useState(0),
      _useState6 = _slicedToArray(_useState5, 2),
      tagsLength = _useState6[0],
      setTagsLength = _useState6[1];

  var _useState7 = useState(0),
      _useState8 = _slicedToArray(_useState7, 2),
      groupThreshold = _useState8[0],
      setGroupThreshold = _useState8[1];

  var _useState9 = useState(getTypeOfChild(children[0])),
      _useState10 = _slicedToArray(_useState9, 2),
      typeOfFirstChild = _useState10[0],
      setTypeOfFirstChild = _useState10[1];

  var isMaxWidthNull = useMemo(function () {
    return maxWidth === null;
  }, [maxWidth]);
  useEffect(function () {
    if (!isMaxWidthNull) {
      var _ref$current;

      if (initialTagsLength !== tagsLength) {
        setTagsLength(initialTagsLength);
        setGroupThreshold(0);
      }

      if (getTypeOfChild(children[0]) !== typeOfFirstChild) {
        setTypeOfFirstChild(getTypeOfChild(children[0]));
      }

      if ((_ref$current = ref.current) !== null && _ref$current !== void 0 && _ref$current.clientWidth && ref.current.clientWidth < maxWidth) {
        setMaxWidth(ref.current.clientWidth);
      }

      return;
    }

    if (isMaxWidthNull) {
      setTagsLength(initialTagsLength);
      setMaxWidth(ref.current.clientWidth);
    }
  }, [isMaxWidthNull, children, typeOfFirstChild]);
  useLayoutEffect(function () {
    var _ref$current2, _ref$current2$childre;

    if ((_ref$current2 = ref.current) !== null && _ref$current2 !== void 0 && (_ref$current2$childre = _ref$current2.children) !== null && _ref$current2$childre !== void 0 && _ref$current2$childre.length && !isMaxWidthNull && tagsLength === initialTagsLength) {
      minimumTagWidth.current = minimumTagWidth.current || findMinimumWidth(ref.current.children, maxWidth);
      var divider = initialTagsLength;

      if (!expanded) {
        divider = getDivider(initialTags, ref.current, maxWidth, minimumTagWidth, defaultGroupThreshold);
      }

      setTagsLength(divider);
      setGroupThreshold(initialTagsLength - divider);
    }
  }, [children, expanded, maxWidth, tagsLength, typeOfFirstChild, initialTagsLength]);

  var _useMemo = useMemo(function () {
    var _initialTags$, _initialTags$$props, _context3;

    if (isMaxWidthNull) {
      return [[], false];
    }

    var tagsToClone = _sliceInstanceProperty(initialTags).call(initialTags, groupThreshold, initialTagsLength);

    var childrenToClone = initialTagsLength > 0 ? tagsToClone : initialTags;
    var disabledCheck = (_initialTags$ = initialTags[0]) === null || _initialTags$ === void 0 ? void 0 : (_initialTags$$props = _initialTags$.props) === null || _initialTags$$props === void 0 ? void 0 : _initialTags$$props.isDisabled;

    var listOfTags = _mapInstanceProperty(_context3 = React.Children).call(_context3, childrenToClone, function (child, childIndex) {
      var flippedIndex = childrenToClone.length - childIndex - 1;

      var newProps = _objectSpread({}, child.props, {
        id: "".concat(id, "_").concat(flippedIndex)
      }, getStyleProps(child));

      return React.cloneElement(child, newProps);
    });

    var updatedOptionsMenu = React.cloneElement(optionsMenu, _objectSpread({}, optionsMenu.props, {}, getStyleProps(optionsMenu)));
    return [[].concat(_toConsumableArray(listOfTags), [updatedOptionsMenu]), disabledCheck];
  }, [styles.collectionTag, tagsLength, isMaxWidthNull, typeOfFirstChild, children, groupThreshold, id]),
      _useMemo2 = _slicedToArray(_useMemo, 2),
      tagsList = _useMemo2[0],
      isDisabled = _useMemo2[1];

  return React.createElement("span", {
    ref: ref,
    className: cx(styles.collection, _defineProperty({}, styles.expanded, expanded), className)
  }, groupThreshold > 0 ? React.createElement(Tag, {
    id: "".concat(id, "_expandButton"),
    disabled: isDisabled,
    label: "+".concat(groupThreshold),
    className: cx(styles.collectionTag),
    icon: "chevron-right",
    onClick: function onClick() {
      return setExpanded(true);
    },
    iconWrapperClassName: styles.tagButton,
    labelClassName: styles.tagLabel
  }) : null, tagsList);
};
TagCollection.propTypes = {
  id: PropTypes.string,
  expanded: PropTypes.bool,
  groupThreshold: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.node
};
TagCollection.__docgenInfo = {
  componentName: "TagCollection",
  packageName: "@jutro/components",
  description: "TagCollection",
  displayName: "TagCollection",
  methods: [],
  actualName: "TagCollection",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: false,
      description: "id of the wrapping component."
    },
    expanded: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Render expanded collection on initial component load"
    },
    groupThreshold: {
      type: {
        name: "number"
      },
      required: false,
      description: "Default state for number of visible tags, additional items will be represented as single tag (like `+12`) at the begining"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Custom class name for tag collection"
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "Tag components"
    }
  }
};