import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import { useMemo } from 'react';
import { bindActionCreators, useControlledReducer } from '../helper';
import { reducer } from './reducer';
import { setFilterValue, setPage, setPageSize, setSorted, setColumns, setEditedRow } from './actions';
export var useConfig = function useConfig(initialState, controlledState, onStateChange) {
  var _useControlledReducer = useControlledReducer({
    reducer: reducer,
    initialState: initialState,
    controlledState: controlledState,
    onStateChange: onStateChange
  }),
      _useControlledReducer2 = _slicedToArray(_useControlledReducer, 2),
      state = _useControlledReducer2[0],
      dispatch = _useControlledReducer2[1];

  var boundActions = useMemo(function () {
    return bindActionCreators(dispatch, {
      setFilterValue: setFilterValue,
      setPage: setPage,
      setPageSize: setPageSize,
      setSorted: setSorted,
      setColumns: setColumns,
      setEditedRow: setEditedRow
    });
  }, [dispatch]);
  return [state, boundActions];
};