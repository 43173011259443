import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _flatMapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/flat-map";

var _context;

import React, { useState, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Manager, Popper } from 'react-popper';
import { OutsideClickDetector } from './OutsideClickDetector';
var modifiers = {
  offset: {
    offset: '4,4'
  }
};
export var availableAlignments = _flatMapInstanceProperty(_context = ['auto', 'top', 'right', 'bottom', 'left']).call(_context, function (align) {
  var _context2;

  return _mapInstanceProperty(_context2 = ['', '-start', '-end']).call(_context2, function (modificator) {
    return "".concat(align).concat(modificator);
  });
});
var defaultAlign = 'bottom-end';
var popoverPropTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  renderTrigger: PropTypes.func.isRequired,
  align: PropTypes.oneOf(availableAlignments),
  isFlipEnabled: PropTypes.bool
};
export var Popover = function Popover(_ref) {
  var renderTrigger = _ref.renderTrigger,
      align = _ref.align,
      children = _ref.children,
      className = _ref.className,
      id = _ref.id,
      isFlipEnabled = _ref.isFlipEnabled;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isShown = _useState2[0],
      setShown = _useState2[1];

  var triggerRef = useRef();
  var outsideClickHandler = useCallback(function (_ref2) {
    var target = _ref2.target;
    var isTriggerClicked = triggerRef.current.contains(target);

    if (!isTriggerClicked) {
      setShown(false);
    }
  }, [triggerRef]);
  var close = useCallback(function () {
    return setShown(false);
  }, []);
  var toggle = useCallback(function () {
    return setShown(!isShown);
  }, [isShown]);
  var content = useMemo(function () {
    return typeof children === 'function' ? children(close) : children;
  }, [children, close]);

  if (!renderTrigger) {
    return null;
  }

  var placement = _includesInstanceProperty(availableAlignments).call(availableAlignments, align) ? align : defaultAlign;
  modifiers.flip = {
    enabled: isFlipEnabled
  };
  return React.createElement(Manager, null, renderTrigger(toggle, triggerRef, isShown), isShown && React.createElement(Popper, {
    referenceElement: triggerRef.current,
    placement: placement,
    modifiers: modifiers
  }, function (_ref3) {
    var popperRef = _ref3.ref,
        style = _ref3.style;
    return React.createElement(OutsideClickDetector, {
      onClickOutside: outsideClickHandler,
      onWindowBlur: close
    }, function (detectorRef) {
      var combinedRef = function combinedRef(ref) {
        popperRef(ref);
        detectorRef(ref);
      };

      return React.createElement("div", {
        ref: combinedRef,
        style: style,
        className: className,
        role: "menu",
        "aria-labelledby": id
      }, content);
    });
  }));
};
Popover.propTypes = popoverPropTypes;
Popover.defaultProps = {
  align: defaultAlign,
  isFlipEnabled: true
};
Popover.__docgenInfo = {
  componentName: "Popover",
  packageName: "@jutro/components",
  description: "Popover",
  displayName: "Popover",
  methods: [],
  actualName: "Popover",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Used to identify this component."
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    children: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "func"
        }]
      },
      required: false,
      description: "Notification popover content or renderProp to render this content"
    },
    renderTrigger: {
      type: {
        name: "func"
      },
      required: true,
      description: "Function to render trigger element"
    },
    align: {
      type: {
        name: "enum",
        computed: true,
        value: "availableAlignments"
      },
      required: false,
      description: "Popover alignment (relative to trigger element)",
      defaultValue: {
        value: "'bottom-end'",
        computed: false
      }
    },
    isFlipEnabled: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Should popover flip when it is about to overflow the visible area",
      defaultValue: {
        value: "true",
        computed: false
      }
    }
  }
};