import { useEffect } from 'react';
import { tsmLanguagesLoader } from './tsm/tsmLanguagesLoader';
import { isTSMEnabled } from './tsm/tsmHelpers';
export var LanguagesLoader = function LanguagesLoader(_ref) {
  var onLoad = _ref.onLoad;
  useEffect(function () {
    if (!isTSMEnabled()) {
      onLoad();
      return undefined;
    }

    var hasAppUnmounted = false;
    tsmLanguagesLoader().then(function () {
      return !hasAppUnmounted && onLoad();
    });
    return function () {
      hasAppUnmounted = true;
    };
  }, [onLoad]);
  return null;
};
LanguagesLoader.displayName = 'LanguagesLoader';