import _defineProperty2 from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty2(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
import _URL from "@babel/runtime-corejs3/core-js-stable/url";
import { HttpTransport } from './HttpTransport';
import { defaultEncoder } from './defaults/defaultEncoder';
import { defaultResponseHandler } from './defaults/defaultResponseHandler';
import { defaultErrorResponseHandler } from './defaults/defaultErrorResponseHandler';
import { defaultExceptionHandler } from './defaults/defaultExceptionHandler';
import { fastOptionsMerge } from './helper';

function buildUrl(url, baseUrl) {
  return baseUrl ? new _URL(url, baseUrl).toString() : url;
}

function buildSearchString(params) {
  var _context;

  if (!params) {
    return '';
  }

  var fakeUrl = new _URL('http://www.gw.com');

  _forEachInstanceProperty(_context = _Object$keys(params)).call(_context, function (key) {
    return fakeUrl.searchParams.append(key, params[key]);
  });

  return fakeUrl.search;
}

export var HttpRequest = function HttpRequest(baseUrl, defaultOptions, handlerOverrides) {
  var _this = this;

  _classCallCheck(this, HttpRequest);

  _defineProperty(this, "baseUrl", void 0);

  _defineProperty(this, "defaultOptions", void 0);

  _defineProperty(this, "transport", void 0);

  _defineProperty(this, "handlers", void 0);

  _defineProperty(this, "onEncode", void 0);

  _defineProperty(this, "get", function (url, params, optionOverrides) {
    var requestOptions = fastOptionsMerge(_this.defaultOptions, optionOverrides);
    var requestUrl = "".concat(buildUrl(url, _this.baseUrl)).concat(buildSearchString(params));
    return _this.transport.fetch(requestUrl, 'GET', requestOptions);
  });

  _defineProperty(this, "post", function (url, data, optionOverrides) {
    return _this.request(url, 'POST', data, optionOverrides);
  });

  _defineProperty(this, "put", function (url, data, optionOverrides) {
    return _this.request(url, 'PUT', data, optionOverrides);
  });

  _defineProperty(this, "patch", function (url, data, optionOverrides) {
    return _this.request(url, 'PATCH', data, optionOverrides);
  });

  _defineProperty(this, "delete", function (url, data, optionOverrides) {
    return _this.request(url, 'DELETE', data, optionOverrides);
  });

  _defineProperty(this, "request", function (url, method, data, optionOverrides) {
    var requestOptions = fastOptionsMerge(_this.defaultOptions, optionOverrides);

    if (data) {
      requestOptions.body = _this.onEncode(data, requestOptions);
    }

    return _this.transport.fetch(buildUrl(url, _this.baseUrl), method, requestOptions);
  });

  this.baseUrl = baseUrl;
  this.defaultOptions = defaultOptions;
  this.onEncode = (handlerOverrides === null || handlerOverrides === void 0 ? void 0 : handlerOverrides.onEncode) || defaultEncoder;
  this.handlers = _objectSpread({
    onErrorResponse: defaultErrorResponseHandler,
    onException: defaultExceptionHandler,
    onResponse: defaultResponseHandler
  }, handlerOverrides);
  this.transport = new HttpTransport(this.handlers);
};