import { useContext } from 'react';
import { BreakpointTrackerContext } from './BreakpointTracker';
import { extendWithBreakpointValues } from './breakpoint.utils';
export var useBreakpoint = function useBreakpoint(props) {
  var breakpoint = useContext(BreakpointTrackerContext);
  var breakpointProps = props && extendWithBreakpointValues(props, breakpoint);
  return {
    breakpointProps: breakpointProps,
    applyBreakpoint: function applyBreakpoint(componentProps) {
      return extendWithBreakpointValues(componentProps, breakpoint);
    },
    breakpoint: breakpoint
  };
};