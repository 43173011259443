import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";

function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();

  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
        result;

    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;

      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }

    return _possibleConstructorReturn(this, result);
  };
}

function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;

  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { publish, JUTRO_TOPICS } from '@jutro/events';

var RouteTrackerInternal = function (_Component) {
  _inherits(RouteTrackerInternal, _Component);

  var _super = _createSuper(RouteTrackerInternal);

  function RouteTrackerInternal() {
    var _this;

    _classCallCheck(this, RouteTrackerInternal);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "unlisten", null);

    _defineProperty(_assertThisInitialized(_this), "handleChange", function (params, state) {
      var track = _this.props.track;

      if (track) {
        track(params, state);
      }

      publish(JUTRO_TOPICS.ROUTE_CHANGED, {
        pathname: params.pathname,
        state: state
      });
    });

    return _this;
  }

  _createClass(RouteTrackerInternal, [{
    key: "componentWillMount",
    value: function componentWillMount() {
      this.listen(this.handleChange);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      this.unlisten();
    }
  }, {
    key: "listen",
    value: function listen(track) {
      if (!track) {
        return;
      }

      var history = this.props.history;
      this.unlisten = history.listen(track);
    }
  }, {
    key: "unlisten",
    value: function unlisten() {
      if (this.unlisten) {
        this.unlisten();
        this.unlisten = null;
      }
    }
  }, {
    key: "render",
    value: function render() {
      return null;
    }
  }]);

  return RouteTrackerInternal;
}(Component);

_defineProperty(RouteTrackerInternal, "propTypes", {
  track: PropTypes.func
});

_defineProperty(RouteTrackerInternal, "displayName", 'RouteTracker');

export var RouteTracker = withRouter(RouteTrackerInternal);
RouteTrackerInternal.__docgenInfo = {
  componentName: "RouteTracker",
  packageName: "@jutro/router",
  description: "RouteTracker is a component that will register a listener with route history.\nDrop into any component render that is contained in a <Router>.",
  displayName: "RouteTracker",
  methods: [{
    name: "handleChange",
    docblock: "Callback on route changed\n@param {object} params - params\n@param {object} state - state",
    modifiers: [],
    params: [{
      name: "params",
      description: "params",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "state",
      description: "state",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Callback on route changed"
  }, {
    name: "listen",
    docblock: "Register a callback with history listener\n\n@param {(location:Location, action:string) => void} track - callback to register with history listener",
    modifiers: [],
    params: [{
      name: "track"
    }],
    returns: null,
    description: "Register a callback with history listener"
  }, {
    name: "unlisten",
    docblock: "Unregister a callback with history listener",
    modifiers: [],
    params: [],
    returns: null,
    description: "Unregister a callback with history listener"
  }],
  actualName: "RouteTrackerInternal",
  props: {
    track: {
      type: {
        name: "func"
      },
      required: false,
      description: ""
    }
  }
};