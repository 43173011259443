import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import { useAccessibility } from '../state/accessibility/useAccessibility';
import { getA11yTrGroupProps, getA11yTrProps, getA11yTableProps, getA11yTheadThPropsSortableHeader, getA11yTheadThPropsHeader, getA11yTdProps } from './accessibilityProps.js';

var getInitialColumn = function getInitialColumn(selectionType, isExpandable) {
  var initialColumn = 0;

  if (selectionType === 'none' && isExpandable || selectionType === 'single' && !!isExpandable) {
    initialColumn = 1;
  } else if (selectionType === 'single' && isExpandable) {
    initialColumn = 2;
  }

  return initialColumn;
};

export var useAccessibilityProps = function useAccessibilityProps(_ref) {
  var selectionType = _ref.selectionType,
      isExpandable = _ref.isExpandable,
      tableId = _ref.tableId,
      sorted = _ref.sorted,
      tableLabel = _ref.tableLabel;

  var _useAccessibility = useAccessibility({
    focused: {
      row: 0,
      column: getInitialColumn(selectionType, isExpandable),
      columnId: undefined
    }
  }),
      _useAccessibility2 = _slicedToArray(_useAccessibility, 2),
      focused = _useAccessibility2[0].focused,
      setFocused = _useAccessibility2[1].setFocused;

  return {
    getA11yTrGroupProps: getA11yTrGroupProps,
    getA11yTrProps: getA11yTrProps,
    getA11yTableProps: getA11yTableProps(tableLabel),
    getA11yTheadThPropsSortableHeader: getA11yTheadThPropsSortableHeader(tableId, sorted, focused, setFocused),
    getA11yTheadThPropsHeader: getA11yTheadThPropsHeader(tableId, focused, setFocused),
    getA11yTdProps: getA11yTdProps(tableId, focused, setFocused)
  };
};