import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _Promise from "@babel/runtime-corejs3/core-js-stable/promise";
import { BLOB_CONTENT_TYPE, JSON_CONTENT_TYPE, CONTENT_TYPE } from '../common';
export function defaultResponseHandler(response) {
  if (response.status === 204) {
    return _Promise.resolve(null);
  }

  var contentType = response.headers.get(CONTENT_TYPE);

  if (contentType && _includesInstanceProperty(contentType).call(contentType, JSON_CONTENT_TYPE)) {
    return response.json();
  }

  if (contentType && _includesInstanceProperty(contentType).call(contentType, BLOB_CONTENT_TYPE)) {
    return response.blob();
  }

  return response.text();
}