import { defineMessages } from 'react-intl';
export var messages = defineMessages({
  localeLabel: {
    id: 'jutro-components.widgets.GlobalizationChooser.localelabel',
    defaultMessage: 'Locale: '
  },
  languageLabel: {
    id: 'jutro-components.widgets.GlobalizationChooser.languagelabel',
    defaultMessage: 'Language: '
  }
});