import _defineProperty2 from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty2(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";

function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();

  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
        result;

    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;

      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }

    return _possibleConstructorReturn(this, result);
  };
}

function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;

  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { defaultAvailableValuePropType, dataTypeShape, DATA_TYPE_STRING, availableValueObjectShape } from '@jutro/prop-types';
import { FieldComponent } from '../FieldComponent/FieldComponent';
import { getOptionCode } from '../availableValues';
import { RadioButton } from './RadioButton';
import { messages } from './RadioButtonField.messages';
import { makeHandleRadioButtonKeyDown, getTabIndex } from '../../../radiobuttonUtils';
var defaultAvailableValues = [{
  code: 'true',
  name: messages.yes
}, {
  code: 'false',
  name: messages.no
}];
export var RadioButtonField = function (_FieldComponent) {
  _inherits(RadioButtonField, _FieldComponent);

  var _super = _createSuper(RadioButtonField);

  function RadioButtonField() {
    _classCallCheck(this, RadioButtonField);

    return _super.apply(this, arguments);
  }

  _createClass(RadioButtonField, [{
    key: "render",
    value: function render() {
      return _get(_getPrototypeOf(RadioButtonField.prototype), "render", this).call(this);
    }
  }, {
    key: "renderRadioOptions",
    value: function renderRadioOptions(options) {
      var _this = this;

      var _this$props = this.props,
          id = _this$props.id,
          disabled = _this$props.disabled,
          required = _this$props.required;
      var selectedValue = this.getDataTypeAwareSelectedValue();
      return _mapInstanceProperty(options).call(options, function (option, i) {
        var optionCode = getOptionCode(option);
        return React.createElement(RadioButton, _extends({
          key: optionCode,
          option: option,
          id: id,
          tabIndex: getTabIndex(i),
          value: selectedValue,
          onValueChange: _this.handleAvailableValuesValueChange,
          disabled: disabled,
          required: required
        }, _this.generateDataPathProperty()));
      });
    }
  }, {
    key: "generateAccessibilityProperties",
    value: function generateAccessibilityProperties() {
      var translator = this.translator;
      var label = this.props.label;
      return _objectSpread({}, _get(_getPrototypeOf(RadioButtonField.prototype), "generateAccessibilityProperties", this).call(this), {
        'aria-label': translator(label)
      });
    }
  }, {
    key: "renderControl",
    value: function renderControl(breakpointProps) {
      var _this2 = this;

      var _this$props2 = this.props,
          id = _this$props2.id,
          availableValues = _this$props2.availableValues,
          controlClassName = _this$props2.controlClassName;

      var triggerFocus = function triggerFocus(el, value) {
        el.querySelector("label[for^=\"".concat(id, "_\"][for$=\"_").concat(value, "\"]")).focus();
      };

      var handleRadioButtonKeyDown = makeHandleRadioButtonKeyDown(triggerFocus);
      var options = this.renderRadioOptions(availableValues);
      var control = React.createElement("div", _extends({
        id: id,
        className: controlClassName,
        role: "radiogroup",
        onKeyDown: function onKeyDown(event) {
          return handleRadioButtonKeyDown(event, breakpointProps, _this2.handleAvailableValuesValueChange);
        }
      }, this.generateAccessibilityProperties()), options);
      return control;
    }
  }]);

  return RadioButtonField;
}(FieldComponent);

_defineProperty(RadioButtonField, "propTypes", _objectSpread({}, FieldComponent.propTypes, {
  value: defaultAvailableValuePropType,
  defaultValue: defaultAvailableValuePropType,
  dataType: dataTypeShape,
  availableValues: PropTypes.arrayOf(availableValueObjectShape)
}));

_defineProperty(RadioButtonField, "defaultProps", _objectSpread({}, FieldComponent.defaultProps, {}, {
  availableValues: defaultAvailableValues,
  dataType: DATA_TYPE_STRING
}));

_defineProperty(RadioButtonField, "contextType", FieldComponent.contextType);

RadioButtonField.__docgenInfo = {
  componentName: "RadioButtonField",
  packageName: "@jutro/components",
  description: "Renders a list of radio buttons. You specify the items using component props.",
  displayName: "RadioButtonField",
  methods: [{
    name: "renderRadioOptions",
    docblock: "Render the radio buttons for the specified options\n\n@param {Array<any>} options - options to render\n@returns {React.ReactElement} JSX for the radio buttons",
    modifiers: [],
    params: [{
      name: "options",
      description: "options to render",
      type: {
        name: "Array",
        elements: [{
          name: "any"
        }]
      },
      optional: false
    }],
    returns: {
      description: "JSX for the radio buttons",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render the radio buttons for the specified options"
  }, {
    name: "generateAccessibilityProperties",
    docblock: "generates accessibility properties for the field component\n@returns {object} set of applicable wai-aria tags",
    modifiers: [],
    params: [],
    returns: {
      description: "set of applicable wai-aria tags",
      type: {
        name: "object"
      }
    },
    description: "generates accessibility properties for the field component"
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  actualName: "RadioButtonField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }, {
          name: "number"
        }, {
          name: "custom",
          raw: "availableValueObjectShape"
        }]
      },
      required: false,
      description: "Boolean or string value"
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "bool"
        }, {
          name: "number"
        }, {
          name: "custom",
          raw: "availableValueObjectShape"
        }]
      },
      required: false,
      description: "Default value"
    },
    dataType: {
      type: {
        name: "enum",
        value: [{
          value: "'object'",
          computed: false
        }, {
          value: "'string'",
          computed: false
        }]
      },
      required: false,
      description: "The format of the value"
    },
    availableValues: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "shape",
            value: {
              code: {
                name: "union",
                value: [{
                  name: "string"
                }, {
                  name: "number"
                }],
                required: false
              },
              name: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              },
              subtitle: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              },
              secondaryLabel: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              }
            }
          }, {
            name: "shape",
            value: {
              id: {
                name: "union",
                value: [{
                  name: "string"
                }, {
                  name: "number"
                }],
                required: false
              },
              displayName: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              },
              subtitle: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              },
              secondaryLabel: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              }
            }
          }]
        }
      },
      required: false,
      description: "Array of choice objects to display; choice objects contains 'code' and 'name'; if not provided, default 'Yes'/'No' will be used"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};