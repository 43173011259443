import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import hoistStatics from 'hoist-non-react-statics';
export var createHOC = function createHOC(config) {
  var ComponentToWrap = config.component,
      Wrapper = config.wrapper,
      displayName = config.displayName,
      wrapperParams = config.wrapperParams;
  var WrappedComponent = Wrapper(ComponentToWrap, _objectSpread({}, wrapperParams));
  WrappedComponent.displayName = displayName || "".concat(Wrapper.name || 'Wrapper', "(").concat(ComponentToWrap.displayName || ComponentToWrap.name, ")");
  WrappedComponent.WrappedComponent = ComponentToWrap;

  if (ComponentToWrap.propTypes) {
    WrappedComponent.propTypes = ComponentToWrap.propTypes;
  }

  if (ComponentToWrap.defaultProps) {
    WrappedComponent.defaultProps = ComponentToWrap.defaultProps;
  }

  return hoistStatics(WrappedComponent, ComponentToWrap);
};