import PropTypes from 'prop-types';
import { intlMessageShape } from './intlMessageShape';
import { intlToShape } from './toShape';
export var navLinkPropTypes = {
  id: PropTypes.string,
  text: intlMessageShape,
  icon: PropTypes.string,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  notifications: PropTypes.number,
  component: PropTypes.elementType
};
export var routePropTypes = {
  path: PropTypes.string,
  exact: PropTypes.bool,
  strict: PropTypes.bool,
  sensitive: PropTypes.bool,
  redirect: PropTypes.string,
  push: PropTypes.bool,
  to: intlToShape,
  from: PropTypes.string,
  id: PropTypes.string,
  title: intlMessageShape,
  showOnNavBar: PropTypes.bool,
  navLink: PropTypes.shape(navLinkPropTypes),
  component: PropTypes.elementType,
  componentProps: PropTypes.shape({}),
  href: PropTypes.string
};
export var routeShape = PropTypes.shape(routePropTypes).isRequired;
export var routesShape = PropTypes.arrayOf(routeShape);
routePropTypes.routes = routesShape;