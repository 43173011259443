import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";

var _context;

import React, { useContext } from 'react';
import cx from 'classnames';
import keys from "lodash/keys";
import { uniqueInnerId } from '@jutro/platform';
import { TranslatorContext } from '@jutro/locale';
import styles from "./CubeLoader.module.css";
import cubeElementStyles from "./CubeLoaderElement.module.css";
import { messages } from './CubeLoader.messages';

var cubeElementsClassNames = _filterInstanceProperty(_context = keys(cubeElementStyles)).call(_context, function (key) {
  return /cubeLoaderElement-[1-9]/.test(key);
});

export function CubeLoader() {
  var translator = useContext(TranslatorContext);

  var renderCube = function renderCube(cubeClass, index) {
    var cubeClasses = cx(styles.cubeLoaderElement, styles[cubeClass]);
    return React.createElement("div", {
      key: "cube_".concat(index),
      className: cubeClasses
    });
  };

  var cubes = _mapInstanceProperty(cubeElementsClassNames).call(cubeElementsClassNames, function (cubeClass, index) {
    return renderCube(cubeClass, index);
  });

  var _uniqueInnerId = uniqueInnerId('CubeLoader', 'labelId'),
      labelId = _uniqueInnerId.labelId;

  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: styles.cubeLoader,
    "aria-labelledby": labelId
  }, cubes), React.createElement("div", {
    id: labelId,
    className: styles.screenReaderOnly
  }, translator(messages.loading)));
}
CubeLoader.__docgenInfo = {
  componentName: "CubeLoader",
  packageName: "@jutro/components",
  description: "CubeLoader is default loader element for Loader component",
  displayName: "CubeLoader",
  methods: [],
  actualName: "CubeLoader"
};