import _Object$getOwnPropertySymbols from "@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols";
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import _Symbol$for from "@babel/runtime-corejs3/core-js-stable/symbol/for";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
import _valuesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/values";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _everyInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/every";

var _context;

import events from 'events';
import _ from 'lodash';
import { log } from '@jutro/logger';

function mapTopics(topics, callback) {
  if (_.isString(topics)) {
    return callback(topics);
  }

  if (_.isArray(topics) && _everyInstanceProperty(_).call(_, topics, _.isString)) {
    return _mapInstanceProperty(topics).call(topics, callback);
  }

  if (_.isObject(topics) && _everyInstanceProperty(_).call(_, _valuesInstanceProperty(_).call(_, topics), _.isString)) {
    return _.mapValues(_.mapKeys(topics, function (val) {
      return val;
    }), callback);
  }

  log.error("'subscribe'/'unsubscribeAll' supports only topics defined as:\n    - single string 'topic'\n    - array of strings ['topic1', 'topic2']\n    - object which values are strings {topic1: 'topic1', topic2: 'topic2'}");
  return undefined;
}

export var EventProvider = function EventProvider() {
  var _this = this;

  _classCallCheck(this, EventProvider);

  _defineProperty(this, "publish", function (topic, event) {
    _this.emitter.emit(topic, event);
  });

  _defineProperty(this, "subscribe", function (topics, handler) {
    return mapTopics(topics, function (topic) {
      var listener = function listener(event) {
        return handler(event, topic);
      };

      _this.emitter.on(topic, listener);

      return function () {
        return {
          topic: topic,
          listener: listener
        };
      };
    });
  });

  _defineProperty(this, "unsubscribe", function (handle) {
    if (!_.isFunction(handle)) {
      log.error("'unsubscribe' supports handles returned by 'subscribe' function.");
      return;
    }

    var _handle = handle(),
        listener = _handle.listener,
        topic = _handle.topic;

    _this.emitter.removeListener(topic, listener);
  });

  _defineProperty(this, "unsubscribeAll", function (topics) {
    if (_.isUndefined(topics)) {
      _this.emitter.removeAllListeners();

      return;
    }

    mapTopics(topics, function (topic) {
      _this.emitter.removeAllListeners(topic);
    });
  });

  this.emitter = new events.EventEmitter();
  this.emitter.setMaxListeners(Infinity);
};

var EVENT_PROVIDER_KEY = _Symbol$for('jutro.eventProvider');

if (_indexOfInstanceProperty(_context = _Object$getOwnPropertySymbols(global)).call(_context, EVENT_PROVIDER_KEY) === -1) {
  global[EVENT_PROVIDER_KEY] = new EventProvider();
}

var eventProvider = global[EVENT_PROVIDER_KEY];
var subscribe = eventProvider.subscribe;
export { subscribe };
var unsubscribe = eventProvider.unsubscribe;
export { unsubscribe };
var unsubscribeAll = eventProvider.unsubscribeAll;
export { unsubscribeAll };
var publish = eventProvider.publish;
export { publish };