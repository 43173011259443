import { defineMessages } from 'react-intl';
export var messages = defineMessages({
  decreaseBtnLabel: {
    id: 'jutro-components.widgets.inputs.StepperField.decrease',
    defaultMessage: 'Decrease value'
  },
  increaseBtnLabel: {
    id: 'jutro-components.widgets.inputs.StepperField.increase',
    defaultMessage: 'Increase value'
  }
});