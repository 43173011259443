import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
export var getKeyPressHandler = function getKeyPressHandler(callback) {
  var keys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ['Enter', ' '];
  var stopPropagation = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return function (evt) {
    if (_includesInstanceProperty(keys).call(keys, evt.key)) {
      evt.preventDefault();
      if (stopPropagation) evt.stopPropagation();
      callback(evt);
    }
  };
};