import React from 'react';
import PropTypes from 'prop-types';
import { intlMessageShape } from '@jutro/prop-types';
import { HelpElement } from './HelpElement';
var helpParagraphPropTypes = {
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, intlMessageShape])
};
export var HelpParagraph = function HelpParagraph(_ref) {
  var content = _ref.content,
      className = _ref.className;
  return React.createElement(HelpElement, {
    className: className,
    tag: "p",
    content: content
  });
};
HelpParagraph.propTypes = helpParagraphPropTypes;
HelpParagraph.__docgenInfo = {
  componentName: "HelpParagraph",
  packageName: "@jutro/components",
  description: "The `HelpParagraph` component is designed as a simple component presenting text inside the Help dropdown on Application Header.",
  displayName: "HelpParagraph",
  methods: [],
  actualName: "HelpParagraph",
  metadataType: "element",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component."
    },
    content: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: false
              },
              defaultMessage: {
                name: "string",
                required: false
              },
              args: {
                name: "shape",
                value: {},
                required: false
              }
            }
          }]
        }]
      },
      required: false,
      description: "Content to render"
    }
  }
};