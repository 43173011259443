import { Contract, types } from '@jutro/contract';
import { ServiceManager } from '@jutro/services';
export var MessageInterface = Contract.specify({
  getMessage: types.func
});
export function getMessageService() {
  var messageService = ServiceManager.getServiceByContract(MessageInterface);

  if (!messageService) {
    throw new Error('No implementation of MessageInterface provided.');
  }

  return messageService;
}
export function getMessageProp(messageKey, messageProps, messages) {
  return (messageProps === null || messageProps === void 0 ? void 0 : messageProps[messageKey]) || messages[messageKey];
}