import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isObject from "lodash/isObject";
import isFunction from "lodash/isFunction";
import { TranslatorContext } from '@jutro/locale';
import { Flex } from '@jutro/layout';
import { intlMessageShape, nestedTooltipShape } from '@jutro/prop-types';
import styles from "./Card.module.css";
import { TooltipIcon } from '../Tooltip/TooltipIcon';
var cardHeaderPropTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([intlMessageShape, PropTypes.func]),
  tooltip: nestedTooltipShape
};
export var CardHeader = function CardHeader(_ref) {
  var className = _ref.className,
      children = _ref.children,
      tooltip = _ref.tooltip;
  var translator = useContext(TranslatorContext);

  if (!children) {
    return null;
  }

  var classes = cx(styles.header, className);
  var header = translateHeader(children, translator);
  return React.createElement("div", {
    className: classes
  }, React.createElement(Flex, {
    alignItems: "center",
    gap: "small"
  }, React.createElement("h4", {
    className: styles.headerText
  }, header), tooltip && React.createElement(TooltipIcon, isObject(tooltip) ? tooltip : {
    text: tooltip
  })));
};
export function translateHeader(header, translator) {
  var headerToTranslate = isFunction(header) ? header(translator) : header;

  if (translator) {
    return translator(headerToTranslate);
  }

  return headerToTranslate;
}
CardHeader.propTypes = cardHeaderPropTypes;
CardHeader.__docgenInfo = {
  componentName: "CardHeader",
  packageName: "@jutro/components",
  description: "CardHeader",
  displayName: "CardHeader",
  methods: [],
  actualName: "CardHeader",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional styles to be applied to CardHeader"
    },
    children: {
      type: {
        name: "union",
        value: [{
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: false
              },
              defaultMessage: {
                name: "string",
                required: false
              },
              args: {
                name: "shape",
                value: {},
                required: false
              }
            }
          }]
        }, {
          name: "func"
        }]
      },
      required: false,
      description: "Content of the CardHeader"
    },
    tooltip: {
      type: {
        name: "custom",
        raw: "nestedTooltipShape"
      },
      required: false,
      description: "Card header tooltip"
    }
  }
};