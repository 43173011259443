import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from 'react';
import { GenericSelectComponents as components } from '../../GenericSelectControl/GenericSelectControl';
export var LookupDropdownIndicator = function LookupDropdownIndicator(props) {
  var customStyles = props.selectProps.customStyles;
  return React.createElement(components.DropdownIndicator, _extends({}, props, {
    className: customStyles.selectIcon,
    icon: "mi-search"
  }));
};
LookupDropdownIndicator.__docgenInfo = {
  componentName: "LookupDropdownIndicator",
  packageName: "@jutro/components",
  description: "",
  displayName: "LookupDropdownIndicator",
  methods: [],
  actualName: "LookupDropdownIndicator"
};