import _toConsumableArray from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/toConsumableArray";
import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import { isIntlShape } from '@jutro/locale';
import get from "lodash/get";
import set from "lodash/set";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import cloneDeep from "lodash/cloneDeep";
import isNil from "lodash/isNil";
import { _ITEM_DATA_, _ITEM_INDEX_, _ITEM_PATH_, VARIABLE_FORMAT_REGEX } from '../common/templates';

function formatString(content, repeatItem, repeatItemIndex, repeatItemPath) {
  var _objectSpread2;

  var isIntl = isIntlShape(content);
  var str = isIntl ? content.defaultMessage : content;

  if (!str || !_includesInstanceProperty(str).call(str, '{') || !repeatItem) {
    return content;
  }

  var variables = str.match(VARIABLE_FORMAT_REGEX);

  var formatted = _reduceInstanceProperty(variables).call(variables, function (formattedString, variable) {
    var key = variable.substr(1, variable.length - 2);
    var value;

    switch (key) {
      case _ITEM_PATH_:
        value = repeatItemPath;
        break;

      case _ITEM_INDEX_:
        value = repeatItemIndex;
        break;

      default:
        value = get(repeatItem, key, '');
        break;
    }

    if (formattedString === variable) {
      return value;
    }

    return formattedString.replace(variable, value);
  }, str);

  return isIntl ? _objectSpread({}, content, {
    id: content.id,
    defaultMessage: formatted,
    args: _objectSpread((_objectSpread2 = {}, _defineProperty(_objectSpread2, _ITEM_PATH_, repeatItemPath), _defineProperty(_objectSpread2, _ITEM_INDEX_, repeatItemIndex), _objectSpread2), repeatItem)
  }) : formatted;
}

function deepCloneAsNeeded(variableProp, componentProps, templateOverrides) {
  var firstDotIndex = _indexOfInstanceProperty(variableProp).call(variableProp, '.');

  if (firstDotIndex !== -1) {
    var firstProp = variableProp.substr(0, firstDotIndex);
    var firstValue = get(componentProps, firstProp);

    if (isObject(firstValue) && templateOverrides.componentProps[firstProp] === undefined) {
      set(templateOverrides.componentProps, firstProp, cloneDeep(firstValue));
    }
  }
}

function resolveContent(template, repeatPath, repeatItem, repeatIndex, variableProps) {
  var id = template.id,
      templateContent = template.content,
      componentProps = template.componentProps;
  var templateOverrides = {
    componentProps: {}
  };
  var repeatItemPath = repeatPath ? "".concat(repeatPath, ".").concat(repeatIndex) : repeatIndex;

  if (isArray(variableProps)) {
    _forEachInstanceProperty(variableProps).call(variableProps, function (variableProp) {
      switch (variableProp) {
        case 'content':
          if (!templateContent) {
            break;
          }

          templateOverrides.content = isArray(templateContent) ? _mapInstanceProperty(templateContent).call(templateContent, function (nestedTemplate) {
            return resolveContent(nestedTemplate, repeatPath, repeatItem, repeatIndex, variableProps);
          }) : formatString(templateContent, repeatItem, repeatIndex, repeatItemPath);
          break;

        case 'data':
          if (componentProps && componentProps.data) {
            templateOverrides.componentProps.data = componentProps.data === "{".concat(_ITEM_DATA_, "}") ? repeatItem : formatString(componentProps.data, repeatItem, repeatIndex, repeatItemPath);
          }

          break;

        default:
          {
            var variableValue = get(componentProps, variableProp);
            deepCloneAsNeeded(variableProp, componentProps, templateOverrides);

            if (variableValue !== undefined) {
              var resolvedValue = formatString(variableValue, repeatItem, repeatIndex, repeatItemPath);

              if (!isNil(resolvedValue) && resolvedValue !== '') {
                set(templateOverrides.componentProps, variableProp, resolvedValue);
              } else {
                set(templateOverrides.componentProps, variableProp, undefined);
              }
            }

            break;
          }
      }
    });
  }

  var repeatIndexId = "".concat(id).concat(repeatIndex);
  return _objectSpread({}, template, {}, templateOverrides, {
    componentProps: _objectSpread({}, componentProps, {}, templateOverrides.componentProps),
    key: repeatIndexId,
    id: repeatIndexId
  });
}

export function iterateMetadata(_ref) {
  var contentAfter = _ref.contentAfter,
      contentBefore = _ref.contentBefore,
      contentEmpty = _ref.contentEmpty,
      contentRepeat = _ref.contentRepeat,
      contentRepeatAfter = _ref.contentRepeatAfter,
      contentRepeatBefore = _ref.contentRepeatBefore,
      repeatData = _ref.repeatData,
      repeatPath = _ref.repeatPath;
  var resolvedContent;
  var repeatDataLength;

  if (isArray(repeatData) && repeatData.length > 0) {
    var template = contentRepeat.content,
        variableProps = contentRepeat.variableProps;
    repeatDataLength = repeatData.length;
    resolvedContent = _mapInstanceProperty(repeatData).call(repeatData, function (repeatItem, repeatIndex) {
      return resolveContent(template, repeatPath, repeatItem, repeatIndex, variableProps);
    });
  } else {
    resolvedContent = contentEmpty || [];
  }

  if (contentBefore || contentAfter || contentRepeatBefore || contentRepeatAfter) {
    resolvedContent = [].concat(_toConsumableArray(contentBefore || []), _toConsumableArray(repeatDataLength && contentRepeatBefore ? contentRepeatBefore : []), _toConsumableArray(resolvedContent), _toConsumableArray(repeatDataLength && contentRepeatAfter ? contentRepeatAfter : []), _toConsumableArray(contentAfter || []));
  }

  return resolvedContent;
}