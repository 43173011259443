import { GoogleApiWrapper as Wrapper } from 'google-maps-react';
import isFunction from "lodash/isFunction";
import { Loader } from '../widgets/loading/loader/Loader';

var mapOptionsFromProps = function mapOptionsFromProps(_ref) {
  var apiKey = _ref.googleMapsApiKey;
  return {
    LoadingContainer: Loader,
    apiKey: isFunction(apiKey) ? apiKey() : apiKey
  };
};

export var GoogleMapsApiWrapper = function GoogleMapsApiWrapper(ComponentToWrap) {
  return Wrapper(mapOptionsFromProps)(ComponentToWrap);
};