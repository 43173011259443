import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useBreakpoint } from '@jutro/layout';
import { routesShape, contextSwitcherShape } from '@jutro/prop-types';
import styles from "./TopNavigation.module.css";
import { NavigationContent } from '../NavigationContent';
import { removeNavLinkIcon } from '../navBarHelper';
import { NavBarDropdown } from './NavBarDropdown/NavBarDropdown';
export var TopNavigation = function TopNavigation(props) {
  var className = props.className,
      routes = props.routes,
      wrap = props.wrap,
      contextSwitcher = props.contextSwitcher,
      navBarRef = props.navBarRef;

  var _useBreakpoint = useBreakpoint(),
      breakpoint = _useBreakpoint.breakpoint;

  var classes = cx(styles.topNavigation, _defineProperty({}, styles.noWrap, !wrap), className);
  return React.createElement(NavigationContent, _extends({
    routes: _mapInstanceProperty(routes).call(routes, removeNavLinkIcon),
    nestedRoutesComponent: NavBarDropdown,
    alignment: "top"
  }, breakpoint === 'desktop' && {
    contextSwitcher: contextSwitcher
  }, {
    className: classes,
    contextSwitcherClassName: styles.contextSwitcher,
    navBarRef: navBarRef
  }));
};
TopNavigation.propTypes = {
  className: PropTypes.string,
  routes: routesShape.isRequired,
  contextSwitcher: contextSwitcherShape,
  wrap: PropTypes.bool,
  navBarRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })
};
TopNavigation.defaultProps = {
  wrap: true
};
TopNavigation.__docgenInfo = {
  componentName: "TopNavigation",
  packageName: "@jutro/router",
  description: "The `TopNavigation` component is designed as a container for navigation links and dropdowns in Header.",
  displayName: "TopNavigation",
  methods: [],
  actualName: "TopNavigation",
  metadataType: "container",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component"
    },
    routes: {
      type: {
        name: "arrayOf",
        value: {
          name: "custom",
          raw: "routeShape"
        }
      },
      required: true,
      description: "Routing metadata object"
    },
    contextSwitcher: {
      type: {
        name: "shape",
        value: {
          defaultLabel: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Default button label when no context is active",
            required: false
          },
          values: {
            name: "arrayOf",
            value: {
              name: "custom",
              raw: "contextShape.isRequired"
            },
            description: "Array of values for the contexts",
            required: true
          }
        }
      },
      required: false,
      description: "Context switcher object"
    },
    wrap: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Defines if items should wrap when not enough space",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    navBarRef: {
      type: {
        name: "shape",
        value: {
          current: {
            name: "instanceOf",
            value: "Element",
            required: false
          }
        }
      },
      required: false,
      description: "Reference to the navigation bar HTML element"
    }
  }
};