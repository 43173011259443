import _defineProperty2 from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty2(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";

function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();

  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
        result;

    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;

      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }

    return _possibleConstructorReturn(this, result);
  };
}

function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;

  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { getValueForInput } from '@jutro/data';
import { FieldComponent } from '../FieldComponent/FieldComponent';
import styles from "./InputField.module.css";
import { FieldIcon } from '../FieldComponent/FieldIcon';
import { getKeyPressHandler } from '../../../accessibility/getKeyPressHandler';
export var InputField = function (_FieldComponent) {
  _inherits(InputField, _FieldComponent);

  var _super = _createSuper(InputField);

  function InputField() {
    var _this;

    _classCallCheck(this, InputField);

    for (var _len = arguments.length, _args = new Array(_len), _key = 0; _key < _len; _key++) {
      _args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(_args));

    _defineProperty(_assertThisInitialized(_this), "handleClick", getKeyPressHandler(function () {
      var onEnter = _this.props.onEnter;

      if (onEnter) {
        onEnter.apply(void 0, arguments);
      }
    }, ['Enter']));

    return _this;
  }

  _createClass(InputField, [{
    key: "render",
    value: function render() {
      return _get(_getPrototypeOf(InputField.prototype), "render", this).call(this);
    }
  }, {
    key: "renderControl",
    value: function renderControl(breakpointProps, options) {
      var id = breakpointProps.id,
          value = breakpointProps.value,
          maxLength = breakpointProps.maxLength,
          disabled = breakpointProps.disabled,
          editable = breakpointProps.editable,
          controlClassName = breakpointProps.controlClassName,
          required = breakpointProps.required,
          autoComplete = breakpointProps.autoComplete,
          inputType = breakpointProps.inputType;
      var focusHandlers = this.getInputFocusHandlers();
      var inputStyle = cx(styles.input, {
        disabled: disabled,
        invalid: !options.isValid
      }, controlClassName);
      return React.createElement(FieldIcon, breakpointProps, React.createElement("input", _extends({
        id: id,
        type: inputType,
        className: inputStyle,
        maxLength: maxLength,
        value: getValueForInput(inputType, value),
        onChange: this.handleChange,
        onKeyPress: this.handleClick
      }, focusHandlers, {
        disabled: disabled,
        readOnly: !editable,
        "aria-readonly": !editable,
        required: required,
        autoComplete: autoComplete ? 'on' : 'off'
      }, this.generateDataPathProperty(), this.generateAccessibilityProperties())));
    }
  }]);

  return InputField;
}(FieldComponent);

_defineProperty(InputField, "propTypes", _objectSpread({}, FieldComponent.propTypes, {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoComplete: PropTypes.bool,
  maxLength: PropTypes.number
}, FieldIcon.propTypes, {
  onEnter: PropTypes.func,
  editable: PropTypes.bool
}));

_defineProperty(InputField, "defaultProps", _objectSpread({}, FieldComponent.defaultProps, {
  autoComplete: true,
  editable: true
}));

_defineProperty(InputField, "contextType", FieldComponent.contextType);

InputField.__docgenInfo = {
  componentName: "InputField",
  packageName: "@jutro/components",
  description: "Renders an input element.",
  displayName: "InputField",
  methods: [{
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} options - rendering options (like 'isInvalid', 'isValid', etc)\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "options",
      description: "rendering options (like 'isInvalid', 'isValid', etc)",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  actualName: "InputField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }]
      },
      required: false,
      description: "String or number passed as value"
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "number"
        }]
      },
      required: false,
      description: "Default value"
    },
    autoComplete: {
      type: {
        name: "bool"
      },
      required: false,
      description: "HTML5 native autoComplete support",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    maxLength: {
      type: {
        name: "number"
      },
      required: false,
      description: "Max length for the input field"
    },
    onEnter: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback to call on enter key press"
    },
    editable: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If set to false the input field is read-only but user can tab to it, highlight it, and copy the text from it",
      defaultValue: {
        value: "true",
        computed: false
      }
    }
  },
  composes: ["../FieldComponent/FieldComponent", "../FieldComponent/FieldIcon"]
};