import _parseFloat from "@babel/runtime-corejs3/core-js-stable/parse-float";
import _Number$MAX_SAFE_INTEGER from "@babel/runtime-corejs3/core-js-stable/number/max-safe-integer";
import isFunction from "lodash/isFunction";
import isNaN from "lodash/isNaN";
import isEmpty from "lodash/isEmpty";
import trim from "lodash/trim";
export function isEmptyValue(value) {
  return !isFunction(value) && (isNilValue(value) || value.length === 0 || isNaN(value));
}
export function isNilValue(value) {
  return value === undefined || value === null;
}
export function isValueSame(val1, val2) {
  return isEmptyValue(val1) && isEmptyValue(val2) || val1 === val2;
}
export function getValueForInput(fieldType, value) {
  if (fieldType === 'number') {
    return isNumber(value) ? Number(value) : '';
  }

  return value || '';
}
export function isNumber(n) {
  var type = typeof n;

  if (type === 'string') {
    var original = trim(n);
    n = +n;
    if (n === 0 && isEmpty(original)) return false;
  } else if (type !== 'number') return false;

  return n - n < 1;
}
export function isSafeNumber(number) {
  return number.replace(/[^0-9]/g, '') <= _Number$MAX_SAFE_INTEGER;
}
export function getDecimalRegex() {
  var decimals = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 2;
  var withDecimalRegex = '^-?(\\s*|\\d+\\.?\\d{0,decimalPlaces})$'.replace('decimalPlaces', "".concat(decimals));
  return decimals > 0 ? new RegExp(withDecimalRegex) : new RegExp(/^-?(\s*|\d+)$/);
}
export function truncateValue(value, decimalPlaces) {
  if (isNilValue(value)) {
    return value;
  }

  var decimalRegex = '^-?\\d+(?:\\.\\d{0,decimalPlaces})?'.replace('decimalPlaces', "".concat(decimalPlaces));
  var truncatedValue = value.toString().match(new RegExp(decimalRegex));
  return isEmptyValue(value) || isEmptyValue(truncatedValue) ? value : _parseFloat(truncatedValue[0]);
}