import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _ from 'lodash';

function appendClassName(inputClassName, className) {
  if (!className) {
    return inputClassName;
  }

  if (!inputClassName) {
    return className;
  }

  return "".concat(inputClassName, " ").concat(className);
}

function parseRootStyles(rootConfig, classNamePrefix, isDynamic, output) {
  if (_.isObject(rootConfig)) {
    var _context;

    output.classNames.root = appendClassName(output.classNames.root, "".concat(classNamePrefix, "Root"));

    if (isDynamic) {
      output.classNames.root = appendClassName(output.classNames.root, 'dynamicRoot');
    }

    output.styles.push({
      selector: _mapInstanceProperty(_context = output.classNames.root.split(' ')).call(_context, function (className) {
        return ".".concat(className);
      }).join(''),
      properties: rootConfig
    });
  } else {
    output.classNames.root = rootConfig;
  }
}

export function parseThemeConfig(config) {
  if (!config) {
    return undefined;
  }

  var classNamePrefix = config.prefix || _.camelCase(config.name);

  var isDynamic = _.isNil(config.prefix) || config.prefix === '';
  var parsedOutput = {
    classNames: {},
    styles: []
  };
  parseRootStyles(config.rootStyle, classNamePrefix, isDynamic, parsedOutput);
  return _.omitBy(parsedOutput, _.isEmpty);
}