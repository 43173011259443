import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import { getConfigValue } from '@jutro/config';
import loadMixpanel from '../mixpanel';
import { subscribe } from '../EventProvider';
import JUTRO_TOPICS from '../jutroTopics';
import WhitelistProvider from './WhitelistProvider';
import DictionaryProvider from './DictionaryProvider';
export default (function () {
  var trackingConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    whitelistConfig: {},
    dictionaryConfig: {}
  };
  var mixpanel = loadMixpanel(getConfigValue('MIXPANEL_TRACKING_ID'));
  var whitelist = WhitelistProvider.create(trackingConfig.whitelistConfig);
  var translate = DictionaryProvider.create(trackingConfig.dictionaryConfig);

  var UNKNOWN_ERROR = JUTRO_TOPICS.UNKNOWN_ERROR,
      REST_JUTRO_TOPICS = _objectWithoutProperties(JUTRO_TOPICS, ["UNKNOWN_ERROR"]);

  subscribe(UNKNOWN_ERROR, function (event) {
    return mixpanel.track(UNKNOWN_ERROR, event.error);
  });
  subscribe(REST_JUTRO_TOPICS, function (event, topic) {
    var transformedEvent = translate(whitelist(event));
    mixpanel.track(topic, transformedEvent);
  });
});