import _defineProperty2 from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty2(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _JSON$stringify from "@babel/runtime-corejs3/core-js-stable/json/stringify";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";

function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();

  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
        result;

    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;

      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }

    return _possibleConstructorReturn(this, result);
  };
}

function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;

  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { Map as GoogleMap, Marker } from 'google-maps-react';
import { log } from '@jutro/logger';
import { DATA_TYPE_OBJECT, dataTypeShape, intlMessageShape } from '@jutro/prop-types';
import { createHOC } from '@jutro/platform';
import { FieldComponent } from '../FieldComponent/FieldComponent';
import * as utils from '../../../googleUtils.js';
import styles from "./MapArea.module.css";
import { GoogleMapsApiWrapper } from '../../../hoc/GoogleMapsApiWrapper';
import { messages } from './MapArea.messages';
export var MapAreaInternal = function (_FieldComponent) {
  _inherits(MapAreaInternal, _FieldComponent);

  var _super = _createSuper(MapAreaInternal);

  function MapAreaInternal(props) {
    var _this;

    _classCallCheck(this, MapAreaInternal);

    _this = _super.call(this, props);

    _defineProperty(_assertThisInitialized(_this), "convertObjectValueIntoString", function (valObj) {
      var result = [];

      var _iterator = _createForOfIteratorHelper(_this.props.orderOfAddress),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var i = _step.value;

          if (valObj[i] !== undefined) {
            result.push(valObj[i]);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return result.join(', ');
    });

    _defineProperty(_assertThisInitialized(_this), "navigateToDefaultPosition", function () {
      var defaultValue = _this.props.defaultValue;

      if (defaultValue) {
        _this.setState({
          isMarkerShown: false
        });

        if (typeof defaultValue === 'object') {
          var address = _this.convertObjectValueIntoString(defaultValue);

          _this.geocoder.geocode({
            address: address
          }, function (results, status) {
            return _this.handleGeocodingResult(results, status, true);
          });
        } else {
          _this.geocoder.geocode({
            address: defaultValue
          }, function (results, status) {
            return _this.handleGeocodingResult(results, status, true);
          });
        }
      }
    });

    _defineProperty(_assertThisInitialized(_this), "handleGeocodingResult", function (results, status, isCurrentlyDecodingDefault, position) {
      if (results) {
        var result = results[0];

        if (status === _this.props.google.maps.GeocoderStatus.OK) {
          var _this$state$geocoding = _this.state.geocodingResult,
              bounds = _this$state$geocoding.bounds,
              center = _this$state$geocoding.center;
          var isMarkerShown = _this.state.isMarkerShown;
          var isMapClicked = !!position;

          if (!isMapClicked) {
            bounds = result.geometry.bounds || result.geometry.viewport;
            center = {
              lat: result.geometry.location.lat(),
              lng: result.geometry.location.lng()
            };
          }

          position = position || {
            lat: result.geometry.location.lat(),
            lng: result.geometry.location.lng()
          };
          var translatedAddress = utils.mapGooglePlacesAddressToDTO(result.address_components);

          _this.setState({
            geocodingResult: {
              formattedAddress: result.formatted_address,
              translatedAddress: translatedAddress,
              position: position,
              bounds: bounds,
              center: center
            },
            shouldMarkerBeRendered: isMarkerShown
          });

          if (isMapClicked) {
            if (_this.props.dataType === DATA_TYPE_OBJECT) {
              _this.notifyChange(translatedAddress);
            } else {
              _this.notifyChange(result.formatted_address);
            }
          }
        } else {
          if (isCurrentlyDecodingDefault) {
            log.error("Geocoding address ".concat(_JSON$stringify(_this.props.defaultValue), " failed for the following reason: ").concat(status));
          } else {
            log.error("Geocoding address ".concat(_JSON$stringify(_this.props.value), " failed for the following reason: ").concat(status));
          }

          if (!isCurrentlyDecodingDefault) {
            _this.navigateToDefaultPosition();
          }
        }
      }
    });

    _defineProperty(_assertThisInitialized(_this), "onMapClick", function (mapProps, map, clickEvent) {
      var latLng = {
        lat: clickEvent.latLng.lat(),
        lng: clickEvent.latLng.lng()
      };

      _this.setState({
        isMarkerShown: true
      });

      _this.geocoder.geocode({
        location: latLng
      }, function (results, status) {
        return _this.handleGeocodingResult(results, status, false, latLng);
      });
    });

    _defineProperty(_assertThisInitialized(_this), "renderMarker", function () {
      var _this$state = _this.state,
          shouldMarkerBeRendered = _this$state.shouldMarkerBeRendered,
          geocodingResult = _this$state.geocodingResult;
      if (!shouldMarkerBeRendered) return null;
      var formattedAddress = geocodingResult.formattedAddress,
          position = geocodingResult.position;
      return React.createElement(Marker, {
        name: formattedAddress,
        position: position
      });
    });

    _defineProperty(_assertThisInitialized(_this), "geocodeAddress", function () {
      var _this$props = _this.props,
          value = _this$props.value,
          dataType = _this$props.dataType;

      if (dataType === DATA_TYPE_OBJECT) {
        if (typeof value === 'object') {
          var address = _this.convertObjectValueIntoString(value);

          _this.setState({
            isMarkerShown: true
          });

          _this.geocoder.geocode({
            address: address
          }, function (results, status) {
            return _this.handleGeocodingResult(results, status, false);
          });
        } else {
          _this.navigateToDefaultPosition();

          log.error('Value must be an object');
        }
      } else if (typeof value === 'string') {
        if (value) {
          _this.setState({
            isMarkerShown: true
          });

          _this.geocoder.geocode({
            address: value
          }, function (results, status) {
            return _this.handleGeocodingResult(results, status, false);
          });
        } else {
          _this.navigateToDefaultPosition();
        }
      } else {
        _this.navigateToDefaultPosition();

        log.error('Value must be a string');
      }
    });

    _defineProperty(_assertThisInitialized(_this), "a11y", function (map) {
      var _this$props2 = _this.props,
          google = _this$props2.google,
          iframeTitle = _this$props2.title;
      google.maps.event.addListenerOnce(map, 'idle', function () {
        document.getElementsByTagName('iframe')[0].title = _this.translator(iframeTitle);
        document.getElementsByTagName('iframe')[0].removeAttribute('aria-hidden');
      });
    });

    _this.state = {
      geocodingResult: {},
      isMarkerShown: false
    };
    _this.geocoder = new props.google.maps.Geocoder();
    return _this;
  }

  _createClass(MapAreaInternal, [{
    key: "renderMap",
    value: function renderMap() {
      var _this2 = this;

      var geocodingResult = this.state.geocodingResult;

      if (_Object$keys(geocodingResult).length === 0) {
        return null;
      }

      var _this$props3 = this.props,
          mapStyles = _this$props3.mapStyles,
          google = _this$props3.google;
      var bounds = geocodingResult.bounds,
          center = geocodingResult.center;
      return React.createElement(GoogleMap, {
        google: google,
        streetViewControl: false,
        mapTypeControl: false,
        style: mapStyles,
        center: center,
        bounds: bounds,
        animatedZoom: false,
        onReady: function onReady(props, map) {
          map.fitBounds(bounds);

          _this2.a11y(map);
        },
        onClick: this.onMapClick
      }, this.renderMarker());
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      this.geocodeAddress();
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {
      var _this$props4 = this.props,
          value = _this$props4.value,
          dataType = _this$props4.dataType;
      var isVlaueAlreadyInState = dataType === DATA_TYPE_OBJECT ? _.isEqual(value, this.state.geocodingResult.translatedAddress) : value === this.state.geocodingResult.formattedAddress;

      if (!isVlaueAlreadyInState && !_.isEqual(value, prevProps.value)) {
        this.geocodeAddress();
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _get(_getPrototypeOf(MapAreaInternal.prototype), "render", this).call(this);
    }
  }, {
    key: "renderControl",
    value: function renderControl(breakpointProps) {
      var className = breakpointProps.className;
      return React.createElement("div", {
        className: cx(styles.mapArea, className)
      }, this.renderMap());
    }
  }]);

  return MapAreaInternal;
}(FieldComponent);

_defineProperty(MapAreaInternal, "propTypes", _objectSpread({}, FieldComponent.propTypes, {
  value: PropTypes.oneOfType([PropTypes.shape({
    country: PropTypes.string,
    countryCode: PropTypes.string,
    postalCode: PropTypes.string,
    state: PropTypes.string,
    city: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    addressLine3: PropTypes.string
  }), PropTypes.string]),
  dataType: dataTypeShape,
  defaultValue: PropTypes.oneOfType([PropTypes.shape({
    country: PropTypes.string,
    countryCode: PropTypes.string,
    postalCode: PropTypes.string,
    state: PropTypes.string,
    city: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    addressLine3: PropTypes.string
  }), PropTypes.string]),
  mapStyles: PropTypes.object,
  googleMapsApiKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  orderOfAddress: PropTypes.arrayOf(PropTypes.string),
  title: intlMessageShape
}));

_defineProperty(MapAreaInternal, "defaultProps", _objectSpread({}, FieldComponent.defaultProps, {
  mapStyles: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    borderRadius: '4px'
  },
  dataType: DATA_TYPE_OBJECT,
  defaultValue: '2850 S Delaware St #400, San Mateo, CA 94403, USA',
  orderOfAddress: ['addressLine1', 'addressLine2', 'addressLine3', 'city', 'state', 'postalCode', 'country', 'countryCode'],
  title: messages.googleMaps
}));

_defineProperty(MapAreaInternal, "displayName", 'MapArea');

export var MapArea = createHOC({
  component: MapAreaInternal,
  wrapper: GoogleMapsApiWrapper,
  displayName: 'MapArea'
});
MapAreaInternal.__docgenInfo = {
  componentName: "MapArea",
  packageName: "@jutro/components",
  description: "Renders an map element. It allows the user to pick a place in the map. Allows you to display address on the map and choose the format of the address.",
  displayName: "MapArea",
  methods: [{
    name: "convertObjectValueIntoString",
    docblock: "Helper function to convert address stored in an object to string in a good order.\n@param {object} valObj object to convert\n@returns {string} converted address",
    modifiers: [],
    params: [{
      name: "valObj",
      description: "object to convert",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "converted address",
      type: {
        name: "string"
      }
    },
    description: "Helper function to convert address stored in an object to string in a good order."
  }, {
    name: "navigateToDefaultPosition",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "handleGeocodingResult",
    docblock: null,
    modifiers: [],
    params: [{
      name: "results",
      optional: undefined,
      type: null
    }, {
      name: "status",
      optional: undefined,
      type: null
    }, {
      name: "isCurrentlyDecodingDefault",
      optional: undefined,
      type: null
    }, {
      name: "position",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "onMapClick",
    docblock: "Function invoked on the map click. It invokes the geocoding based on the click coordinates.\n\n@param {object} mapProps\n@param {object} map\n@param {object} clickEvent props of the clickEvent f.eg. latitude and longitude",
    modifiers: [],
    params: [{
      name: "mapProps",
      description: null,
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "map",
      description: null,
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "clickEvent",
      description: "props of the clickEvent f.eg. latitude and longitude",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Function invoked on the map click. It invokes the geocoding based on the click coordinates."
  }, {
    name: "renderMarker",
    docblock: "Render marker on the map\n\n@returns {React.ReactElement} JSX for the marker",
    modifiers: [],
    params: [],
    returns: {
      description: "JSX for the marker",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render marker on the map"
  }, {
    name: "renderMap",
    docblock: "Render the map element\n\n@returns {React.ReactElement} JSX for the map",
    modifiers: [],
    params: [],
    returns: {
      description: "JSX for the map",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render the map element"
  }, {
    name: "geocodeAddress",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "a11y",
    docblock: null,
    modifiers: [],
    params: [{
      name: "map",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  actualName: "MapAreaInternal",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "shape",
          value: {
            country: {
              name: "string",
              required: false
            },
            countryCode: {
              name: "string",
              required: false
            },
            postalCode: {
              name: "string",
              required: false
            },
            state: {
              name: "string",
              required: false
            },
            city: {
              name: "string",
              required: false
            },
            addressLine1: {
              name: "string",
              required: false
            },
            addressLine2: {
              name: "string",
              required: false
            },
            addressLine3: {
              name: "string",
              required: false
            }
          }
        }, {
          name: "string"
        }]
      },
      required: false,
      description: "Address to display on the map"
    },
    dataType: {
      type: {
        name: "enum",
        value: [{
          value: "'object'",
          computed: false
        }, {
          value: "'string'",
          computed: false
        }]
      },
      required: false,
      description: "Format of the value",
      defaultValue: {
        value: "'object'",
        computed: false
      }
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "shape",
          value: {
            country: {
              name: "string",
              required: false
            },
            countryCode: {
              name: "string",
              required: false
            },
            postalCode: {
              name: "string",
              required: false
            },
            state: {
              name: "string",
              required: false
            },
            city: {
              name: "string",
              required: false
            },
            addressLine1: {
              name: "string",
              required: false
            },
            addressLine2: {
              name: "string",
              required: false
            },
            addressLine3: {
              name: "string",
              required: false
            }
          }
        }, {
          name: "string"
        }]
      },
      required: false,
      description: "Default address to display on the map",
      defaultValue: {
        value: "'2850 S Delaware St #400, San Mateo, CA 94403, USA'",
        computed: false
      }
    },
    mapStyles: {
      type: {
        name: "object"
      },
      required: false,
      description: "Styles to apply to the map component",
      defaultValue: {
        value: "{\n    position: 'absolute',\n    top: '0',\n    bottom: '0',\n    left: '0',\n    right: '0',\n    borderRadius: '4px',\n}",
        computed: false
      }
    },
    googleMapsApiKey: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "func"
        }]
      },
      required: true,
      description: "The key required by the Google Maps API"
    },
    orderOfAddress: {
      type: {
        name: "arrayOf",
        value: {
          name: "string"
        }
      },
      required: false,
      description: "Defines the order of address items",
      defaultValue: {
        value: "[\n    'addressLine1',\n    'addressLine2',\n    'addressLine3',\n    'city',\n    'state',\n    'postalCode',\n    'country',\n    'countryCode',\n]",
        computed: false
      }
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Title attribute which will be added to the Google Maps iframe for the accessibility",
      defaultValue: {
        value: "messages.googleMaps",
        computed: true
      }
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};