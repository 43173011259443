import _defineProperty2 from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _toConsumableArray from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/toConsumableArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty2(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";

function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();

  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
        result;

    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;

      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }

    return _possibleConstructorReturn(this, result);
  };
}

function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;

  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { DATA_TYPE_STRING, DATA_TYPE_OBJECT } from '@jutro/prop-types';
import cx from 'classnames';
import { FieldComponent, InlineLoader } from '@jutro/components';
import { getMessageProp } from '@jutro/platform';
import 'intl-tel-input/build/css/intlTelInput.css';
import styles from "./IntlPhoneNumberField.module.css";
import { IntlPhoneNumberInputLazyLoaded, IntlPhoneNumberReadOnlyLazyLoaded, getDefaultCountry } from './IntlPhoneNumberHelpers';
import { messages } from './IntlPhoneNumberField.messages';
export var IntlPhoneNumberField = function (_FieldComponent) {
  _inherits(IntlPhoneNumberField, _FieldComponent);

  var _super = _createSuper(IntlPhoneNumberField);

  function IntlPhoneNumberField() {
    var _this;

    _classCallCheck(this, IntlPhoneNumberField);

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    _this = _super.call.apply(_super, [this].concat(args));

    _defineProperty(_assertThisInitialized(_this), "handleChange", function (value) {
      _this.notifyChange(value);
    });

    _defineProperty(_assertThisInitialized(_this), "state", {
      isValid: true,
      validationErrorCode: undefined
    });

    _defineProperty(_assertThisInitialized(_this), "validateNumber", function (validateNumber, validationErrorCode) {
      _this.setState({
        isValid: validateNumber
      });

      _this.setState({
        validationErrorCode: validationErrorCode
      });
    });

    return _this;
  }

  _createClass(IntlPhoneNumberField, [{
    key: "render",
    value: function render() {
      return _get(_getPrototypeOf(IntlPhoneNumberField.prototype), "render", this).call(this);
    }
  }, {
    key: "getValidationMessages",
    value: function getValidationMessages() {
      var _this$state = this.state,
          isValid = _this$state.isValid,
          validationErrorCode = _this$state.validationErrorCode;
      var superValidationMessages = _get(_getPrototypeOf(IntlPhoneNumberField.prototype), "getValidationMessages", this).call(this) || [];

      var validationMessages = _toConsumableArray(superValidationMessages);

      if (!isValid) {
        validationMessages.push("".concat(this.translator(getMessageProp('validatePhone', messages)), " ").concat(this.translator(getMessageProp("validatePhoneError".concat(validationErrorCode), messages))));
      }

      return validationMessages;
    }
  }, {
    key: "renderControl",
    value: function renderControl(breakpointProps, options) {
      var id = breakpointProps.id,
          disabled = breakpointProps.disabled,
          controlClassName = breakpointProps.controlClassName,
          required = breakpointProps.required,
          dataType = breakpointProps.dataType,
          value = breakpointProps.value,
          placeholder = breakpointProps.placeholder,
          defaultCountry = breakpointProps.defaultCountry,
          noDropdown = breakpointProps.noDropdown;
      var inputStyle = cx(styles.phoneNumber, {
        disabled: disabled,
        invalid: !options.isValid
      }, controlClassName);
      var divStyle = cx(_defineProperty2({}, styles.dropdownHidden, noDropdown));
      var initialCountry = getDefaultCountry(defaultCountry);
      return React.createElement("div", {
        className: divStyle
      }, React.createElement(IntlPhoneNumberInputLazyLoaded, _extends({
        fallback: React.createElement(InlineLoader, {
          id: "InlineLoader",
          loading: true,
          loadingMessage: "Loading..."
        }),
        id: id,
        className: inputStyle,
        onChange: this.handleChange,
        defaultCountry: initialCountry,
        disabled: disabled,
        required: required
      }, this.generateDataPathProperty(), this.generateAccessibilityProperties(), {
        dataType: dataType,
        validateNumber: this.validateNumber,
        value: value,
        placeholder: placeholder,
        noDropdown: noDropdown
      })));
    }
  }, {
    key: "renderControlReadOnly",
    value: function renderControlReadOnly(breakpointProps) {
      var id = breakpointProps.id,
          value = breakpointProps.value,
          showCountryCodeForReadOnly = breakpointProps.showCountryCodeForReadOnly,
          dataType = breakpointProps.dataType,
          defaultCountry = breakpointProps.defaultCountry;
      return React.createElement(IntlPhoneNumberReadOnlyLazyLoaded, {
        fallback: React.createElement(InlineLoader, {
          id: "InlineLoader",
          loading: true,
          loadingMessage: "Loading..."
        }),
        id: id,
        value: value,
        showCountryCodeForReadOnly: showCountryCodeForReadOnly,
        dataType: dataType,
        defaultCountry: defaultCountry
      });
    }
  }]);

  return IntlPhoneNumberField;
}(FieldComponent);

_defineProperty(IntlPhoneNumberField, "contextType", FieldComponent.contextType);

var phoneDataTypeShape = PropTypes.oneOf([DATA_TYPE_STRING, DATA_TYPE_OBJECT]);
var PhoneShape = PropTypes.shape({
  countryCode: PropTypes.shape({
    code: PropTypes.string.isRequired
  }),
  phoneNumber: PropTypes.string.isRequired
});
IntlPhoneNumberField.defaultProps = _objectSpread({}, FieldComponent.defaultProps, {
  dataType: DATA_TYPE_OBJECT,
  showCountryCodeForReadOnly: true,
  noDropdown: false
});
IntlPhoneNumberField.propTypes = _objectSpread({}, FieldComponent.propTypes, {
  dataType: phoneDataTypeShape,
  value: PropTypes.oneOfType([PhoneShape, PropTypes.string]),
  defaultCountry: PropTypes.string,
  showCountryCodeForReadOnly: PropTypes.bool,
  noDropdown: PropTypes.bool
});
IntlPhoneNumberField.displayName = 'IntlPhoneNumberField';
IntlPhoneNumberField.__docgenInfo = {
  componentName: "IntlPhoneNumberField",
  packageName: "@jutro/lab-preview-intl-phone-number",
  description: "IntlPhoneNumberField is a 'field' component that displays a label, control and message. It can be used to render\n for entering and validating international telephone numbers.",
  displayName: "IntlPhoneNumberField",
  methods: [{
    name: "handleChange",
    docblock: "Default change handler for `input` element. It will be used unless this method or `renderControl()`\nis overridden.\n\n@param {object} value - new value",
    modifiers: [],
    params: [{
      name: "value",
      description: "new value",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Default change handler for `input` element. It will be used unless this method or `renderControl()`\nis overridden."
  }, {
    name: "validateNumber",
    docblock: "Validation helper to retrieve validation boolean from intl-tel-input\n\n@param {boolean} validateNumber - validation state",
    modifiers: [],
    params: [{
      name: "validateNumber",
      description: "validation state",
      type: {
        name: "boolean"
      },
      optional: false
    }, {
      name: "validationErrorCode"
    }],
    returns: null,
    description: "Validation helper to retrieve validation boolean from intl-tel-input"
  }, {
    name: "getValidationMessages",
    docblock: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added.\nIf field phone number is not correct specific message will be shown.\n\n@returns {Array<any>} validation messages",
    modifiers: [],
    params: [],
    returns: {
      description: "validation messages",
      type: {
        name: "Array",
        elements: [{
          name: "any"
        }]
      }
    },
    description: "Get the validation messages to display.\nIf field is 'required' and no validation message is provided, it is added.\nIf field phone number is not correct specific message will be shown."
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} options - rendering options (like 'isInvalid', 'isValid', etc)\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "options",
      description: "rendering options (like 'isInvalid', 'isValid', etc)",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }, {
    name: "renderControlReadOnly",
    docblock: "Render readonly control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render readonly control for this component."
  }],
  actualName: "IntlPhoneNumberField",
  metadataType: "field",
  props: {
    dataType: {
      type: {
        name: "custom",
        raw: "phoneDataTypeShape"
      },
      required: false,
      description: "Type of returned value in onValeChange callback",
      defaultValue: {
        value: "'object'",
        computed: false
      }
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "custom",
          raw: "PhoneShape"
        }, {
          name: "string"
        }]
      },
      required: false,
      description: "Phone number value.\nPassed as a string f.eg. \"+48600500400\" or phone shape f.eg.\n{countryCode: { code: \"PL\" }, phoneNumber: \"600500400\"}\ncountryCode has to be iso2 country code lower or upper case."
    },
    defaultCountry: {
      type: {
        name: "string"
      },
      required: false,
      description: "Default country code to be rendered. Must be in iso2 country code string."
    },
    showCountryCodeForReadOnly: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Set country code visibility if rendered in readOnly mode.",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    noDropdown: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Hide the country dropdown, the country number is always displayed.",
      defaultValue: {
        value: "false",
        computed: false
      }
    }
  },
  composes: ["@jutro/components"]
};