var JUTRO_TOPICS;

(function (JUTRO_TOPICS) {
  JUTRO_TOPICS["COMPONENT_LOADED"] = "JUTRO_TOPICS.COMPONENT_LOADED";
  JUTRO_TOPICS["VALUE_CHANGED"] = "JUTRO_TOPICS.VALUE_CHANGED";
  JUTRO_TOPICS["FIELD_BLUR"] = "JUTRO_TOPICS.FIELD_BLUR";
  JUTRO_TOPICS["ROUTE_CHANGED"] = "JUTRO_TOPICS.ROUTE_CHANGED";
  JUTRO_TOPICS["FETCH_REQUEST"] = "JUTRO_TOPICS.FETCH_REQUEST";
  JUTRO_TOPICS["FETCH_RESPONSE"] = "JUTRO_TOPICS.FETCH_RESPONSE";
  JUTRO_TOPICS["FETCH_ERROR"] = "JUTRO_TOPICS.FETCH_ERROR";
  JUTRO_TOPICS["UNKNOWN_ERROR"] = "JUTRO_TOPICS.UNKNOWN_ERROR";
  JUTRO_TOPICS["BUTTON_CLICKED"] = "JUTRO_TOPICS.BUTTON_CLICKED";
  JUTRO_TOPICS["LINK_CLICKED"] = "JUTRO_TOPICS.LINK_CLICKED";
})(JUTRO_TOPICS || (JUTRO_TOPICS = {}));

export default JUTRO_TOPICS;