import { ModalNextProvider } from '@jutro/components';
import { messages } from './alertHelper.messages';
export function showAlert(message, title) {
  var callback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function () {};
  var useDefault = false;

  if (useDefault || false) {
    dom.window.alert(message);
    return;
  }

  var icon = 'mi-error-outline';
  var status = 'warning';

  var noop = function noop() {};

  var answer = ModalNextProvider.showAlert({
    title: title || messages.actionWarning,
    message: message,
    status: status,
    icon: icon,
    confirmButtonText: messages.ok
  });
  answer.then(callback, noop);
}