import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";

var _dec, _class;

import { service, ServiceManager } from '@jutro/services';
import { log } from '@jutro/logger';
export var PROMPT_SERVICE_ID = 'PROMPT_SERVICE';
var prompts = {};
var PromptServiceInternal = (_dec = service(PROMPT_SERVICE_ID), _dec(_class = function () {
  function PromptServiceInternal() {
    _classCallCheck(this, PromptServiceInternal);
  }

  _createClass(PromptServiceInternal, [{
    key: "pushPrompt",
    value: function pushPrompt(name, props) {
      if (false) {
        if (prompts[name]) {
          log.warning("The prompt name ".concat(name, " has been used"));
          return;
        }
      }

      prompts[name] = props;
    }
  }, {
    key: "popPrompt",
    value: function popPrompt(name) {
      var prompt = prompts[name];
      delete prompts[name];
      return prompt;
    }
  }]);

  return PromptServiceInternal;
}()) || _class);
export var PromptService = ServiceManager.getService(PROMPT_SERVICE_ID);