import { defineMessages } from 'react-intl';
export var messages = defineMessages({
  title: {
    id: 'jutro-datatable.ColumnsConfigModal.Title',
    defaultMessage: 'Customize Columns'
  },
  subtitle: {
    id: 'jutro-datatable.ColumnsConfigModal.Subtitle',
    defaultMessage: 'Uncheck columns to remove them from the table'
  },
  cancel: {
    id: 'jutro-datatable.ColumnsConfigModal.Cancel',
    defaultMessage: 'Cancel'
  },
  applyChanges: {
    id: 'jutro-datatable.ColumnsConfigModal.ApplyChange',
    defaultMessage: 'Apply Changes'
  }
});