import { LocaleService } from '@jutro/locale';
import { HttpRequestBuilder } from './HttpRequestBuilder';
import { analyticsHandler } from './handlers/analyticsHandler';
import { jsonOptions } from './options/jsonOptions';
import { langLocaleOptions } from './options/langLocaleOptions';
export function createHttpRequest(baseUrl) {
  var build = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var request = new HttpRequestBuilder(baseUrl).addOptions(langLocaleOptions(LocaleService.getCurrentLanguage(), LocaleService.getCurrentLocale())).addHandler(analyticsHandler);
  return build ? request.build() : request;
}
export function createJsonHttpRequest(baseUrl) {
  var build = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var request = createHttpRequest(baseUrl, false).addOptions(jsonOptions);
  return build ? request.build() : request;
}