import React from 'react';
import { uniqueInnerId } from '@jutro/platform';
import { Portal } from 'react-overlays';
import styles from "./DatePickerField.module.css";
export var PopperContainer = function PopperContainer(_ref) {
  var children = _ref.children,
      labelId = _ref.labelId,
      parentId = _ref.parentId;

  var _uniqueInnerId = uniqueInnerId(parentId, 'calendarId'),
      calendarId = _uniqueInnerId.calendarId;

  return React.createElement(Portal, null, React.createElement("div", {
    id: calendarId,
    role: "dialog",
    "aria-hidden": !children,
    "aria-labelledby": labelId,
    className: styles.datePicker
  }, children));
};
PopperContainer.__docgenInfo = {
  componentName: "PopperContainer",
  packageName: "@jutro/components",
  description: "Custom calendar popup container for the Datepicker",
  displayName: "PopperContainer",
  methods: [],
  actualName: "PopperContainer",
  metadataType: "container"
};