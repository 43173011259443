import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from 'react';
import PropTypes from 'prop-types';
import { TypeaheadMultiSelectField } from '@jutro/components';
import { availableValuesCodeNameObject } from '@jutro/prop-types';
export var timeZoneFieldPropTypes = _objectSpread({}, TypeaheadMultiSelectField.propTypes, {
  availableValues: PropTypes.arrayOf(PropTypes.shape(availableValuesCodeNameObject).isRequired)
});
export var TimeZoneField = function TimeZoneField(props) {
  return React.createElement(TypeaheadMultiSelectField, _extends({}, props, {
    singleSelect: true
  }));
};
TimeZoneField.__docgenInfo = {
  componentName: "TimeZoneField",
  packageName: "@jutro/lab-preview-date",
  description: "",
  displayName: "TimeZoneField",
  methods: [],
  actualName: "TimeZoneField"
};