import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
import _trimInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/trim";
import _ from 'lodash';
import mustache from 'mustache';
var START_TOKEN = '{{';
var END_TOKEN = '}}';
var variableExpr = new RegExp("".concat(START_TOKEN, ".+").concat(END_TOKEN));

function hasVariable(str) {
  return variableExpr.test(str);
}

function splitKey(keyMaybeValue) {
  var _context, _context2;

  var r = /([\w-.]+\s+)\|\|\s+(.*)/.exec(keyMaybeValue);
  return {
    key: r ? _trimInstanceProperty(_context = r[1]).call(_context) : keyMaybeValue,
    defaultValue: r ? _trimInstanceProperty(_context2 = r[2]).call(_context2).replace(/(^['"]|['"]$)/g, '') : undefined
  };
}

function getValue(keyMaybeValue, params) {
  var allowMissing = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  var _splitKey = splitKey(keyMaybeValue),
      key = _splitKey.key,
      defaultValue = _splitKey.defaultValue;

  var value = _.get(params, key, defaultValue);

  if (value === undefined || value === null) {
    if (!allowMissing) {
      console.warn('Error, missing key ', key, ' from: ', params);
    }

    value = "".concat(START_TOKEN).concat(keyMaybeValue).concat(END_TOKEN);
  }

  return value;
}

function substitute(str, params) {
  var allowMissing = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var tokens = mustache.parse(str, [START_TOKEN, END_TOKEN]);
  var buffer = '';

  _forEachInstanceProperty(tokens).call(tokens, function (token) {
    var symbol = token[0];
    var textOrKey = token[1];

    switch (symbol) {
      case 'name':
        buffer += getValue(textOrKey, params, allowMissing);
        break;

      case 'text':
        buffer += textOrKey;
        break;

      default:
        throw new Error("Parse error, unexpected symbol: ".concat(symbol));
    }
  });

  return buffer;
}

function concatPath(path, addPath) {
  var str = typeof addPath === 'number' ? "[".concat(addPath, "]") : addPath;

  if (path.length === 0) {
    return str;
  }

  if (typeof addPath === 'number') {
    return "".concat(path).concat(str);
  }

  return "".concat(path, ".").concat(str);
}

function traverse(val, callback) {
  var path = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';

  if (_.isArray(val)) {
    _forEachInstanceProperty(val).call(val, function (elem, i) {
      return traverse(elem, callback, concatPath(path, i));
    });
  } else if (_.isObject(val)) {
    var _context3;

    _forEachInstanceProperty(_context3 = _Object$keys(val)).call(_context3, function (key) {
      return traverse(val[key], callback, concatPath(path, key));
    });
  } else {
    callback(val, path);
  }
}

export default function substituteParametersValues(obj, params) {
  traverse(obj, function (val, path) {
    if (_.isString(val) && hasVariable(val)) {
      _.set(obj, path, substitute(val, params, true));
    }
  });
  traverse(obj, function (val, path) {
    if (typeof val === 'string' && hasVariable(val)) {
      _.set(obj, path, substitute(val, obj));
    }
  });
  return obj;
}