import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon } from '../../Icon/Icon';
var loadingIconPropTypes = {
  className: PropTypes.string,
  icon: PropTypes.string
};

var LoadingIconInternal = function LoadingIconInternal(props) {
  var className = props.className,
      icon = props.icon,
      other = _objectWithoutProperties(props, ["className", "icon"]);

  return React.createElement(Icon, _extends({
    icon: icon,
    className: cx(className, 'linear-spin')
  }, other));
};

LoadingIconInternal.propTypes = loadingIconPropTypes;
LoadingIconInternal.defaultProps = {
  icon: 'mi-autorenew'
};
LoadingIconInternal.displayName = 'LoadingIcon';
export var LoadingIcon = memo(LoadingIconInternal);
LoadingIconInternal.__docgenInfo = {
  componentName: "LoadingIcon",
  packageName: "@jutro/components",
  description: "LoadingIconInternal",
  displayName: "LoadingIcon",
  methods: [],
  actualName: "LoadingIconInternal",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    icon: {
      type: {
        name: "string"
      },
      required: false,
      description: "Material Icon name (mi-*) to be used",
      defaultValue: {
        value: "'mi-autorenew'",
        computed: false
      }
    }
  }
};