import { SELECT, TOGGLE, SET_ALL, CLEAR_ALL } from './types';
export var select = function select(rowId) {
  return {
    type: SELECT,
    payload: rowId
  };
};
export var toggle = function toggle(rowId) {
  return {
    type: TOGGLE,
    payload: rowId
  };
};
export var setAll = function setAll(newRows) {
  return {
    type: SET_ALL,
    payload: newRows
  };
};
export var clearAll = function clearAll() {
  return {
    type: CLEAR_ALL
  };
};