import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _findIndexInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find-index";
import _Array$from from "@babel/runtime-corejs3/core-js-stable/array/from";
import _someInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/some";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import { log } from '@jutro/logger';
export var DynamicStyleSheet = function () {
  function DynamicStyleSheet() {
    _classCallCheck(this, DynamicStyleSheet);
  }

  _createClass(DynamicStyleSheet, [{
    key: "mount",
    value: function mount() {
      var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'dynamicStyleSheet';

      if (!this.styleTag) {
        this.name = name;
        this.styleTag = this.createStyleTag(name);
      } else {
        log.warning("Dynamic stylesheet was already mounted: '".concat(name, "'"));
      }
    }
  }, {
    key: "unmount",
    value: function unmount() {
      if (this.styleTag) {
        this.removeStyleTag(this.styleTag);
        this.styleTag = null;
      } else {
        log.warning("Dynamic stylesheet was already unmounted: '".concat(this.name, "'"));
      }
    }
  }, {
    key: "createStyleTag",
    value: function createStyleTag(name) {
      var _context;

      if (_someInstanceProperty(_context = _Array$from(document.querySelectorAll('style'))).call(_context, function (style) {
        return style.title === name;
      })) return undefined;
      var style = document.createElement('style');
      style.setAttribute('title', name);
      style.setAttribute('type', 'text/css');
      style.appendChild(document.createTextNode(''));
      document.head.appendChild(style);
      return style;
    }
  }, {
    key: "removeStyleTag",
    value: function removeStyleTag(styleTag) {
      document.head.removeChild(styleTag);
    }
  }, {
    key: "getStyleSheet",
    value: function getStyleSheet() {
      return this.styleTag ? this.styleTag.sheet : {};
    }
  }, {
    key: "findRuleIndex",
    value: function findRuleIndex(selector) {
      var sheet = this.getStyleSheet();

      var cssRules = _Array$from(sheet.cssRules);

      return _findIndexInstanceProperty(cssRules).call(cssRules, function (rule) {
        return rule.selectorText === selector;
      });
    }
  }, {
    key: "addRule",
    value: function addRule(selector, properties) {
      var _context2;

      var sheet = this.getStyleSheet();
      var ruleIndex = this.findRuleIndex(selector);

      if (ruleIndex !== -1) {
        sheet.deleteRule(ruleIndex);
      }

      var rulePosition = ruleIndex === -1 ? sheet.cssRules.length : ruleIndex;

      var propertyParts = _mapInstanceProperty(_context2 = _Object$keys(properties)).call(_context2, function (propertyKey) {
        return "".concat(propertyKey, ": ").concat(properties[propertyKey]);
      });

      var propertyStrings = propertyParts.join('; ');

      if (sheet.insertRule) {
        sheet.insertRule("".concat(selector, " { ").concat(propertyStrings, " }"), rulePosition);
      } else if (sheet.addRule) {
        sheet.addRule(selector, propertyStrings, rulePosition);
      }
    }
  }, {
    key: "applyRules",
    value: function applyRules(styleRules) {
      var _this = this;

      if (!this.styleTag || !styleRules) {
        return;
      }

      _forEachInstanceProperty(styleRules).call(styleRules, function (_ref) {
        var selector = _ref.selector,
            properties = _ref.properties;
        return _this.addRule(selector, properties);
      });
    }
  }]);

  return DynamicStyleSheet;
}();