import _toConsumableArray from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/toConsumableArray";
import _objectWithoutProperties2 from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _defineProperty from "C:\\Building-application-prd\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _fillInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/fill";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import React from 'react';
import PropTypes from 'prop-types';
import { ComponentPropTypes } from '@jutro/prop-types';
import { warning } from '@jutro/logger';
import times from "lodash/times";
import { GridLayout } from './GridLayout';
import { useBreakpoint } from '../breakpoint/useBreakpoint';
import { mergeColumnsToFirst, mergeColumnsToLast, stackColumnsToFirst, stackColumnsToLast } from './columnsRearrangeFunctions';
var variantsMap = {
  mergeColumnsToFirst: mergeColumnsToFirst,
  mergeColumnsToLast: mergeColumnsToLast,
  stackColumnsToFirst: stackColumnsToFirst,
  stackColumnsToLast: stackColumnsToLast,
  default: null
};
export var columnsLayoutVariants = _Object$keys(variantsMap);
var columnsLayoutChildrenPropType = ComponentPropTypes.childOfComponentType(GridLayout).isRequired;
var columnsLayoutColumnsPropType = ComponentPropTypes.withLength(function (props) {
  return React.Children.count(props.children);
});

var columnsLayoutProps = _objectSpread({}, GridLayout.propTypes, {
  id: PropTypes.string.isRequired,
  children: columnsLayoutChildrenPropType,
  columns: columnsLayoutColumnsPropType,
  onColumnsRearrange: PropTypes.func,
  variant: PropTypes.oneOf(columnsLayoutVariants)
});

export var ColumnsLayout = function ColumnsLayout(_ref) {
  var columnsProp = _ref.columns,
      childrenProp = _ref.children,
      desktopGridItems = _ref.gridItems,
      props = _objectWithoutProperties2(_ref, ["columns", "children", "gridItems"]);

  var _context;

  var desktopColumns = columnsProp || _fillInstanceProperty(_context = Array(React.Children.count(childrenProp))).call(_context, 1);

  var _useBreakpoint = useBreakpoint(_objectSpread({
    gridItems: desktopGridItems,
    columns: desktopColumns
  }, props)),
      breakpointProps = _useBreakpoint.breakpointProps,
      breakpoint = _useBreakpoint.breakpoint;

  var columns = breakpointProps.columns,
      variant = breakpointProps.variant,
      onColumnsRearrange = breakpointProps.onColumnsRearrange,
      _breakpointProps$grid = breakpointProps.gridItems,
      gridItems = _breakpointProps$grid === void 0 ? [] : _breakpointProps$grid,
      rest = _objectWithoutProperties(breakpointProps, ["columns", "variant", "onColumnsRearrange", "gridItems"]);

  var rearrangeFunction = onColumnsRearrange || variant && variantsMap[variant];
  var children = childrenProp;

  if (columns.length > 1 && desktopColumns.length !== columns.length) {
    if (rearrangeFunction) {
      var rearrangedChildren = rearrangeFunction(React.Children.toArray(childrenProp), columns.length);

      if (rearrangedChildren.length !== columns.length) {
        warning('GridLayout: onColumnsRearrange returned incorrect number of columns. ' + "Expected length: ".concat(columns.length, " but received ").concat(rearrangedChildren.length, "."));
      }

      children = rearrangedChildren;
    } else {
      rest[breakpoint].gridItems = [gridItems[0]].concat(_toConsumableArray(times(desktopColumns.length - 2, function (index) {
        return _objectSpread({
          rowSpan: 2
        }, gridItems[index + 1]);
      })), [gridItems[desktopColumns.length - 1]]);
    }
  }

  return React.createElement(GridLayout, _extends({
    valignItems: "top",
    columns: columns,
    gridItems: desktopGridItems
  }, rest), children);
};
ColumnsLayout.propTypes = columnsLayoutProps;
ColumnsLayout.defaultProps = {
  gap: 'large',
  tablet: {
    columns: [1],
    gridItems: []
  },
  phoneWide: {
    columns: [1],
    gridItems: []
  },
  phone: {
    columns: [1],
    gridItems: []
  },
  tag: 'section',
  variant: 'default'
};
ColumnsLayout.displayName = 'ColumnsLayout';
ColumnsLayout.__docgenInfo = {
  componentName: "ColumnsLayout",
  packageName: "@jutro/layout",
  description: "Renders number of independent columns and manages its order for different breakpoints.\nExtends GridLayout component.\nBy default for desktop, it divides columns to have equal width (e.g 3 columns => [1, 1, 1]),\nfor tablet and mobile renders single column grid.",
  displayName: "ColumnsLayout",
  methods: [],
  actualName: "ColumnsLayout",
  metadataType: "layout",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Unique identifier"
    },
    children: {
      type: {
        name: "custom",
        raw: "columnsLayoutChildrenPropType"
      },
      required: false,
      description: "Each child is a separate column. Accepts only `GridLayout` components as children."
    },
    columns: {
      type: {
        name: "custom",
        raw: "columnsLayoutColumnsPropType"
      },
      required: false,
      description: "Grid's columns width/ratio configuration.\nThe columns length for desktop have to be equal to number of children (columns).\nIf not provided, it will be inferred as the number of children with a width of one fraction for each column."
    },
    onColumnsRearrange: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback to rearrange children (columns) that will be invoked on the breakpoint change.\nIt is invoked with the arguments of an array of the children and breakpoint columns' length, `(children, columnsLength) => newChildren`.\nWhen provided, it overrides the `variant` prop."
    },
    variant: {
      type: {
        name: "enum",
        value: [{
          value: "\"mergeColumnsToFirst\"",
          computed: false
        }, {
          value: "\"mergeColumnsToLast\"",
          computed: false
        }, {
          value: "\"stackColumnsToFirst\"",
          computed: false
        }, {
          value: "\"stackColumnsToLast\"",
          computed: false
        }, {
          value: "\"default\"",
          computed: false
        }]
      },
      required: false,
      description: "Specifies how the columns will be stacked or merged on the breakpoint.\nIgnored if `onColumnsRearrange` provided",
      defaultValue: {
        value: "'default'",
        computed: false
      }
    },
    gap: {
      defaultValue: {
        value: "'large'",
        computed: false
      },
      required: false
    },
    tablet: {
      defaultValue: {
        value: "{\n    columns: [1],\n    gridItems: [],\n}",
        computed: false
      },
      required: false
    },
    phoneWide: {
      defaultValue: {
        value: "{\n    columns: [1],\n    gridItems: [],\n}",
        computed: false
      },
      required: false
    },
    phone: {
      defaultValue: {
        value: "{\n    columns: [1],\n    gridItems: [],\n}",
        computed: false
      },
      required: false
    },
    tag: {
      defaultValue: {
        value: "'section'",
        computed: false
      },
      required: false
    }
  },
  composes: ["./GridLayout"]
};